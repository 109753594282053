
import { ComponentPublicInstance, defineComponent } from "@vue/runtime-core"
import { AppStore } from "@/store/modules/AppStore"
import useVuelidate from "@vuelidate/core"
import axios from 'axios'

import AppearObject from '@/components/not-connected/AppearObject.vue'
import LineByLine from '@/components/not-connected/LineByLine.vue'

import ErrorPopin from '@/components/not-connected/ErrorPopin.vue'
import ButtonSave from '@/components/buttons/ButtonSave.vue'
import LeavePopin from '@/components/connected/popins/LeavePopin.vue'

import FieldsetAvatar from './FieldsetAvatar.vue'
import FieldsetPreferences from './FieldsetPreferences.vue'
import FieldsetExperiences from './FieldsetExperiences.vue'
import { UserProfile } from "@/@types/props"

import { Viewport } from '@/store/modules/Viewport'
import formatMediaUrl from "@/utils/mediaUrl"
import { addRewards } from "@/store/modules/Rewards"
import { parseAchievements } from "@/store/data-sources/user"
import { requiredIf, required, helpers, maxLength } from "@vuelidate/validators"

import getOffsetTop from '@/utils/getOffsetTop'
import gsap from 'gsap'
import autosize from 'autosize'


export default defineComponent({
  setup() {

    return {
      v$: useVuelidate()
    }
  },

  data() {
    // TODO AVATAR UPDATE => CANVAS FOR BASE64

    const profile = AppStore.profile as UserProfile;
    const answers = [].concat(profile?.answers || []).concat([{text: ''}, {text: ''}, {text: ''}]).splice(0, 3);

    return {
      Viewport,
      profile,

      errorRequest: false,

      countries: null,
      states: null,
      cities: null,

      isLoading: true,

      form: {
        avatar: profile?.avatar?.double?.url as string | ArrayBuffer,
        surname: profile?.surname || '',

        countryId: profile?.address?.countryId || '',
        stateId: profile?.address?.stateId || '',
        city: profile?.address?.city,
        cityId: profile?.address?.cityId || '',

        facebook: profile?.socials?.facebook || '',
        instagram: profile?.socials?.instagram || '',
        linkedin: profile?.socials?.linkedin || '',
        tiktok: profile?.socials?.tiktok || '',

        aboutMe: profile?.aboutMe || '',

        answers,
      },

      edited: false,
      saved: false,
      loading: false,
      preventedTo: null,
      showLeavePopin: false,
      errorMessage: null,

      errors: [],
      serverValidation: {},
    }
  },

  components: {
    AppearObject,
    LineByLine,
    ErrorPopin,
    ButtonSave,
    FieldsetAvatar,
    FieldsetPreferences,
    FieldsetExperiences,
    LeavePopin,
  },

  async mounted() {
    if (!this.countries || !this.states) {
      const data = await Promise.all([
        await this.getCountries(),
        await this.getStates(),
        await this.getCities(),
      ])

      if (!data[0] || !data[1]) {
        this.isLoading = false
        return
      }

      this.countries = data[0]
      this.states = data[1]
      this.cities = data[2]
    }

    [...this.$el.querySelectorAll('textarea[data-autosize]')].forEach((field) => {
      autosize(field);
    })
  },

  watch: {
    async 'form.countryId'() {
      this.form.stateId = '';
      this.form.cityId = '';
      
      if (this.form.countryId === 230) {
        this.form.city = '';
      } else {
        this.form.stateId = 52;
      }

      if (this.form.countryId === 230 && this.form.stateId && (this.form.stateId as number) !== 52) {
        await this.loadCities()
      }
      (this.v$ as any)?.form.countryId?.$touch();
      (this.v$ as any)?.form.stateId?.$touch();
      (this.v$ as any)?.form.cityId?.$touch();
      (this.v$ as any)?.form.city?.$touch();
    },
    async 'form.stateId'() {
      if (this.form.countryId === 230) {
        this.form.city = '';
        this.form.cityId = ''
      }
      if (this.form.countryId === 230 && this.form.stateId !== 52) {
        await this.loadCities()
      }

      (this.v$ as any)?.form.stateId?.$touch();
      (this.v$ as any)?.form.cityId?.$touch();
      (this.v$ as any)?.form.city?.$touch();
    },

    'form.cityId'() {
      (this.v$ as any)?.form.cityId?.$touch();
    },

    'form': {
      handler() {
        this.hasChanged();
      },
      deep: true
    }
  },

  // The Vuelidate validations are defined here and react to changes in the
  // computed property it uses.
  validations() {
      return {
        form: {
          // countryId: { required },
          // stateId: { requiredIf: requiredIf(this.form.countryId === 230) },
          // cityId: { requiredIf: requiredIf(this.form.countryId === 230 && (this.form.stateId && this.form.stateId !== 52)) },
          // city: { requiredIf: requiredIf(this.form.countryId !== 230) },

          aboutMe: { maxLength: maxLength(250) },

          answers: {
            $each: helpers.forEach({
              text: {
                maxLength: maxLength(120)
              }
            })
          }
        },
      };
  },

  methods: {
    hasChanged() {
      console.log('form changed !');
      this.edited = true;
      this.saved = false;
      this.errorMessage = null;
    },

    onBeforeRouteLeaveGuard(to, from, next) {
      if (!this.saved && this.edited ) {
        this.preventedTo = to;
        next(false)
        this.showLeavePopin = true;
      } else {
        next();
      }
    },
    onLeaveAccept() {
      this.showLeavePopin = false;
      this.edited = false;
      if (this.preventedTo) {
        this.$router.push(this.preventedTo);
      }
    },
    onLeaveDecline() {
      this.showLeavePopin = false;
    },

    async loadCities() {
      this.isLoading = true
      this.form.cityId = ''
      this.cities = await this.getCities()
      this.isLoading = false
    },

    onAvatarChange(a) {
      this.form.avatar = a;
      this.edited = true
      this.saved = false
    },

    async onSubmit() {
      const result = await this.v$.$validate()

      if (!result) {
        console.warn('form not valid')
        this.errorMessage = this.$t('account.validation_error');

        const firstError = this.$el.querySelector('input.error, select.error, textarea.error');
        if (firstError) {
          gsap.to(
            [window, document.body],
            { scrollTo: {y: getOffsetTop(firstError) - 100}}
          )
        }

        return
      }

      this.sendForm();
    },

    async sendForm() {
      this.loading = true
      this.saved = false

      this.errors = []
      
      const preferences = [];
      (this.$refs.preferences as ComponentPublicInstance<typeof FieldsetPreferences>).tags.forEach((tag) => {
        preferences.push(tag)
      })

      const experiences = [];
      (this.$refs.experiences as ComponentPublicInstance<typeof FieldsetExperiences>).experiences.forEach((experience) => {
        experiences.push({
          jobTitle: experience.jobTitle,
          location: experience.location,
          place: experience.place,
          year: experience.year
        })
      })


      const answers = []
      this.form.answers.forEach((answer) => {
        answers.push(answer.text)
      })

      const data = {
        avatar: this.form.avatar,

        surname: this.form.surname,

        country: this.form.countryId,

        facebook: this.form.facebook,
        instagram: this.form.instagram,
        linkedin: this.form.linkedin,
        tiktok: this.form.tiktok,
       
        aboutMe: this.form.aboutMe,

        preferences,
        experiences,
        answers,
      }

      // IF USA use cityId
      if (this.form.countryId === 230) {
        data['city_id'] = this.form.cityId
        data['state'] = this.form.stateId
      } else {
        data['city'] = this.form.city
        data['state'] = 52
      }
      
      // PUSH DATA
      axios.post(
        process.env.VUE_APP_API + 'users/profile', 
        data,
        {
          headers: {
            'x-token-auth': AppStore.user?.token,
            'x-lang': AppStore.user?.lang,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
      .then((result: any) => {
        if (result?.data?.success) {
          this.saved = result.data?.success;

          if (this.saved) {
            this.edited = false;
            if (result.data?.data?.achievements) {
              addRewards(parseAchievements(result.data?.data?.achievements))
            }
            if (AppStore.user) {
              AppStore.user.avatar = {
                url: formatMediaUrl(result.data?.data?.avatar)
              };
              AppStore.user.hasCompletedProfile = result.data?.data?.profileCompleted;
            }
            AppStore.needToRefreshDashboard = true;
          }
        } else {
          this.errorRequest = true
          this.errorMessage = this.$t('account.default_error');
        }
      })
      .catch((error) => {
        this.saved = false;
        if (error.response?.data?.errors) {
         this.errors = Object.values(error.response?.data?.errors)
        } else {
          this.errorRequest = true
          this.errorMessage = this.$t('account.default_error');
        }
      })
      .finally(() => this.loading = false)
    },

    async getCountries() {
      try {
        const response = await axios.get(process.env.VUE_APP_API + 'countries', { headers: { 'x-token-auth': AppStore.user?.token, 'x-lang': AppStore.user?.lang, } });
        return response.data.data
      } catch (error) {
        console.error(error);
        this.errorRequest = true
        return null
      }
    },

    async getStates() {
      try {
        const response = await axios.get(process.env.VUE_APP_API + 'states', { headers: { 'x-token-auth': AppStore.user?.token, 'x-lang': AppStore.user?.lang, } });
        return response.data.data
      } catch (error) {
        console.error(error);
        this.errorRequest = true
        return null
      }
    },

    async getCities() {
      if (this.form.countryId === 230 && this.form.stateId && this.form.stateId !== 52) {
        try {
          const response = await axios.get(process.env.VUE_APP_API + `states/${ this.form.stateId }/cities`, { headers: { 'x-token-auth': AppStore.user?.token, 'x-lang': AppStore.user?.lang, } });
          return response.data.data
        } catch (error) {
          console.error(error);
          this.errorRequest = true
          return null
        }
      }
    },
  }
})
