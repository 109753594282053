
// import '@/utils/Ease'
import { computed, defineComponent, ref, watch } from 'vue';

import Stats from './components/Dev/Stats.vue';
import Sprite from './components/Global/Sprite.vue';
import RotationBlocker from './components/Global/RotationBlocker.vue';

import PageTransition from '@/components/not-connected/PageTransition.vue';

import { AppStore } from '@/store/modules/AppStore';
import { initViewport, disposeViewport} from '@/store/modules/Viewport';

import { useFavicon, usePreferredDark } from '@vueuse/core'

export default defineComponent({

  name: 'App',

  setup() {
    const isDark = usePreferredDark()
    const favicon = computed(() => isDark.value ? 'favicon-alt.ico' : 'favicon.ico')

    const user = AppStore.user;

    useFavicon(favicon)

    return {
      debug: AppStore.debug
    }
  },

  components: {
    Stats,
    Sprite,
    RotationBlocker,
    PageTransition
  },

  mounted() {
    initViewport()
  },

  unmounted() {
    disposeViewport()
  }

});

