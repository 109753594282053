
import { ComponentPublicInstance, defineComponent, inject, Ref } from "@vue/runtime-core"

import ButtonSave from '@/components/buttons/ButtonSave.vue'
import ButtonClose from '@/components/buttons/ButtonClose.vue'
import CursorFollow from '@/components/not-connected/CursorFollow.vue'
import JobBoardApplyPopin from './JobBoardApplyPopin.vue'
import JobBoardReportPopin from './JobBoardReportPopin.vue'
import { Job } from "@/@types/props";
import { AppStore } from "@/store/modules/AppStore";

export default defineComponent({
  setup() {
    const job = inject('job') as Ref<Job>
    const user = AppStore.user

    return {
      job,
      user
    }
  },

  components: {
    ButtonClose,
    ButtonSave,
    CursorFollow,
    JobBoardApplyPopin,
    JobBoardReportPopin,
  },

  methods: {
    onApply() {
      if (!this.job.applied) {
        (this.$refs.applyPopin as ComponentPublicInstance<typeof JobBoardApplyPopin>).open()
      }
    },

    onReport() {
      (this.$refs.reportPopin as ComponentPublicInstance<typeof JobBoardReportPopin>).open()
    }
  }
})
