
import theme from '@/styles/_theme.json'
import { ChapterSlide } from '@/@types/props'
import { defineComponent, PropType, ref } from 'vue'

import { Viewport } from '@/store/modules/Viewport'
import gsap from 'gsap'


const toMSS = (s) => {
    var sec_num = parseInt(s, 10); // don't forget the second param
    var hours: number | string   = Math.floor(sec_num / 3600) ;
    var minutes: number | string = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds: number | string = sec_num - (hours * 3600) - (minutes * 60);

    if (seconds < 10) {seconds = "0"+seconds;}
    return minutes+':'+seconds;
}

export default defineComponent ({
  props: {
    slide: {
      type: Object as PropType<ChapterSlide>,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  },

  setup() {
    return {
      Viewport,
      state: ref('pause'),
      currentTime: ref(toMSS(0)),
      duration: ref(0),
      progress: ref(0),

      mouseMoveTimer: 0,
      mouseState: ref('idle'),

      hasBeenPlayed: ref(false),

      waiting: ref(false),
      loadingTimeline: null as null | gsap.core.Timeline,

      theme,
    }
  },

  computed: {
    videoSrc() {
      const slide = this.slide as ChapterSlide
      if (Viewport.isMobile && slide.video?.mobile) {
        if (slide.video?.mobile?.mp4) return slide.video?.mobile?.mp4
        else if (slide.video?.mobile?.webm) return slide.video?.mobile?.webm
      } else {
        if (slide.video?.mp4) return slide.video?.mp4
        else if (slide.video?.webm) return slide.video?.webm
      }
      return ''
    }
  },

  mounted() {
    this.init();
  },
  unmounted() {
    this.loadingTimeline && this.loadingTimeline.kill();
  },

  watch: {
    active(is) {
      if (!is) {
        this.pauseVideo();
      } else {
        if (!Viewport.isMobile) {
          this.playVideo();
        }
      }
    }
  },

  methods: {
    mouseMove() {
      if (this.mouseMoveTimer) {
        clearTimeout(this.mouseMoveTimer);
      }
      this.mouseState = 'move';
      this.mouseMoveTimer = window.setTimeout(() => {
        this.mouseState = 'idle';
      }, Viewport.isMobile ? 3000 : 1000);
    },

    pauseVideo() {
      const video = this.$refs.video as HTMLVideoElement;
      video.pause();
    },

    playVideo() {
      console.log('try to playVideo')
      const video = this.$refs.video as HTMLVideoElement;
      video.play().then(() => {
        console.log('play resolved')
      }).catch((err) => {
        console.warn(err);
        console.log('play aborted')
        this.hasBeenPlayed = false;
        this.state = 'pause';
        video.pause();
      })
    },
    

    togglePlayPause() {
      const video = this.$refs.video as HTMLVideoElement;

      if (video.paused || video.ended) {
        video.play();
      } else {
        video.pause();
      }
    },

    progressClick(e: MouseEvent) {
      const video = this.$refs.video as HTMLVideoElement;
      const bar = this.$refs.bar as HTMLLIElement;

      const rect = bar.getBoundingClientRect();
      const pos = (e.clientX - rect.x) / rect.width;
      const time = Math.min(this.duration, pos * video.duration);

      video.currentTime = time
    },
    
    init() {
      // Does the browser actually support the video element?
      const supportsVideo = !!document.createElement('video').canPlayType;
      
      if (supportsVideo) {
        // Obtain handles to main elements
        // var videoContainer = document.getElementById('videoContainer');
        const video = this.$refs.video as HTMLVideoElement;

        // Hide the default controls
        video.controls = false;

        // Only add the events if addEventListener is supported (IE8 and less don't support it, but that will use Flash anyway)
        if (document.addEventListener) {
          video.addEventListener('loadedmetadata', () => {
            this.duration = video.duration;
          });
     
          // As the video is playing, update the progress bar
          video.addEventListener('timeupdate', () => {
            this.currentTime = toMSS(video.currentTime);
            this.progress = Math.floor((video.currentTime / video.duration) * 100);
          });

          video.addEventListener('play', () => {
            this.state = 'play';
            this.hasBeenPlayed = true;
          })
          video.addEventListener('pause', () => {
            this.state = 'pause';
            if (Viewport.isMobile) {
              this.hasBeenPlayed = false;
            }
          })
          
          this.initLoading();
        }
      }
    },

    initLoading() {
      const video = this.$refs.video as HTMLVideoElement;
      const timeline = gsap.timeline({repeat: -1});

      timeline.fromTo(
        this.$refs.loadingProgress as SVGCircleElement,
        {
          rotate: 0,
          transformOrigin: '50% 50%',
          strokeDashoffset: 0,
        },
        {
          rotate: 1080,
          strokeDashoffset: 716,
          duration: 3,
          ease: 'none'
        },
        0
      )
      this.loadingTimeline = timeline;

      this.waiting = video.readyState < 3;
      console.log('video.readyState', video.readyState);

      video.addEventListener('waiting', () => {
        console.log('playing');
        this.waiting = true;
      })
      video.addEventListener('playing', () => {
        console.log('playing');
        this.waiting = false;
      })
    }
  }
})
