
import { Image, Test, TestResult } from "@/@types/props";
import { ComponentPublicInstance, defineComponent, PropType } from "@vue/runtime-core";
import TestEndProgress  from './TestEndProgress.vue';
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import LazyPicture from '@/components/Global/LazyPicture.vue';
import { useRoute } from "vue-router";
import { AppStore } from "@/store/modules/AppStore";
import { FocusTrap } from 'focus-trap-vue'

export default defineComponent({
  props: {
    test: {
      type: Object as PropType<Test>,
      required: true
    },
    result: {
      type: Object as PropType<TestResult | null>,
      default: null
    },
    active: {
      type: Boolean,
      required: true
    },
  },

  setup () {
    const route = useRoute();

    return {
      tryCount: route.query.try ? parseInt(route.query.try as string) + 1 : 1
    }
  },

  components: {
    TestEndProgress,
    ButtonHover,
    LazyPicture,
    FocusTrap
  },

  computed: {
    status(): string {
      const result: TestResult = this.result as TestResult
      return result?.success ? 'success' : 'fail'
    },
    title(): string {
      return this.$t(`test.${this.status}_title`, {name: AppStore.user.firstName})
    },
    text(): string {
      const category = (this.test as Test).slug === '1' ? 'basics' : 'professional' // MISSING
      const result = this.result as TestResult
      return this.$t(`test.${this.status}_text_${category}`, { scoreToReach: result?.scoreToReach })
    },
  },

  methods: {
    getImage(index: number): Image {
      return {
        url: `/images/test/end-${this.status}-${index}.png`,
        double: {
          url: `/images/test/end-${this.status}-${index}@2x.png`
        }
      }
    },

    onTryAgainClick() {
      this.$emit('tryAgain');
    },
    reset() {
      (this.$refs.progress as ComponentPublicInstance<typeof TestEndProgress>).reset();
    }
  }
})
