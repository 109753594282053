
import { defineComponent } from "@vue/runtime-core";
import gsap, { Back } from 'gsap'

export default defineComponent({
  props: {
    index: {
      type: Number
    }
  },

  setup() {
    return {
      timeline: null as gsap.core.Timeline | null,
    }
  },

  mounted() {
    const timeline = gsap.timeline({
      repeat: -1,
      repeatDelay: 0,
      delay: 0.6,
      // yoyo: true,
    });


    timeline.set(
      this.$refs.pointerCursor as SVGElement,
      { opacity: 1, immediateRender: true },
      'grab-=0.1'
    )
    timeline.set(
      this.$refs.pointerGrab as SVGElement,
      { opacity: 0, immediateRender: true },
      'grab-=0.1'
    )

    timeline.fromTo(
      this.$refs.pointer as SVGElement,
      { x: -10, y: 20, rotate: -10, transformOrigin: '50% 500%' },
      { x: 0, y: 0, rotate: 0, transformOrigin: '50% 500%', duration: 1, ease: 'quart.inOut' },
      0
    )

    timeline.addLabel('grab');
    timeline.set(
      this.$refs.pointerCursor as SVGElement,
      { opacity: 0, immediateRender: false },
      'grab-=0.1'
    )
    timeline.set(
      this.$refs.pointerGrab as SVGElement,
      { opacity: 1, immediateRender: false },
      'grab-=0.1'
    )

    timeline.to(
      this.$refs.pointer as SVGElement,
      { x: -10, y: 20, rotate: -10, transformOrigin: '50% 500%', duration: 1, ease: Back.easeOut.config(5) },
      'grab'
    )

    timeline.fromTo(
      this.$refs.tiles as SVGElement,
      { x: 0, y: 0, transformOrigin: '50% 50%' },
      { x: -48, y: 48, duration: 1, ease: Back.easeOut.config(5) },
      'grab'
    )
    timeline.fromTo(
      this.$refs.tiles as SVGElement,
      { scale: 1, transformOrigin: '50% 50%' },
      { scale: 1.1, duration: 0.5, ease: 'quart.out'},
      'grab'
    )
    timeline.to(
      this.$refs.tiles as SVGElement,
      { scale: 1, duration: 0.5, ease: 'quart.in'},
      'grab+=0.5'
    )
    timeline.fromTo(
      (this.$refs.tiles as SVGElement).querySelectorAll('path'),
      { scale: 1, transformOrigin: '50% 50%' },
      { scale: 0.8, duration: 0.5, ease: 'quart.out' },
      'grab'
    )
    timeline.to(
      (this.$refs.tiles as SVGElement).querySelectorAll('path'),
      { scale: 1, duration: 0.5, ease: 'quart.in' },
      'grab+=0.5'
    )

    timeline.fromTo(
      this.$refs.initialTile as SVGElement,
      { opacity: 1 },
      { opacity: 0.2, duration: 0.3, ease: 'quart.out' },
      'grab'
    )

    timeline.addLabel('release', 'grab+=1')

    timeline.fromTo(
      this.$refs.finalTile as SVGElement,
      { opacity: 0.2 },
      { opacity: 1, duration: 0.3, ease: 'quart.out' },
      'release'
    )
    timeline.set(
      this.$refs.pointerCursor as SVGElement,
      { opacity: 1, immediateRender: false },
      'release'
    )
    timeline.set(
      this.$refs.pointerGrab as SVGElement,
      { opacity: 0, immediateRender: false },
      'release'
    )
    
    
    this.timeline = timeline;
  },

  unmounted() {
    if (this.timeline) {
      this.timeline.kill();
    }
  }
})
