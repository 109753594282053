import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bd5b0920"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Forgot connection" }
const _hoisted_2 = { class: "connection__left" }
const _hoisted_3 = { class: "connection__right" }
const _hoisted_4 = {
  key: 0,
  class: "connection__content"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_7 = { class: "connection__footer" }
const _hoisted_8 = { class: "connection__menu" }
const _hoisted_9 = {
  key: 0,
  class: "error-msg error-msg--center"
}
const _hoisted_10 = {
  key: 1,
  class: "connection__content"
}
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LazyPicture = _resolveComponent("LazyPicture")!
  const _component_AppearObject = _resolveComponent("AppearObject")!
  const _component_ButtonClose = _resolveComponent("ButtonClose")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_LineByLine = _resolveComponent("LineByLine")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ButtonSave = _resolveComponent("ButtonSave")!
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_LayoutConnected = _resolveComponent("LayoutConnected")!

  return (_openBlock(), _createBlock(_component_LayoutConnected, null, {
    default: _withCtx(() => [
      _createElementVNode("main", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AppearObject, { class: "connection__background" }, {
            default: _withCtx(() => [
              _createVNode(_component_LazyPicture, {
                image: { url: '/images/connection/forgot.jpg', double: {url: '/images/connection/forgot@2x.jpg' }},
                class: "picture picture--full",
                lazy: false
              }, null, 8, ["image"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("header", null, [
            _createVNode(_component_ButtonClose, {
              class: "connection__close",
              to: "/",
              variant: "dark"
            }),
            _createVNode(_component_Logo, { class: "connection__logo" })
          ]),
          (_ctx.step == 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_AppearObject, null, {
                  default: _withCtx(() => [
                    _createElementVNode("h1", null, [
                      _createVNode(_component_LineByLine, {
                        innerHTML: _ctx.$t('forgot.title')
                      }, null, 8, ["innerHTML"])
                    ])
                  ]),
                  _: 1
                }),
                _createElementVNode("form", {
                  class: "connection__form form",
                  onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"])),
                  novalidate: "",
                  autocomplete: "off"
                }, [
                  _createElementVNode("p", {
                    class: "connection__instructions",
                    innerHTML: _ctx.$t('forgot.instructions')
                  }, null, 8, _hoisted_5),
                  _createElementVNode("fieldset", null, [
                    _createElementVNode("legend", null, _toDisplayString(_ctx.$t('forgot.email')), 1),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.email) = $event)),
                      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.v$.form.email.$touch && _ctx.v$.form.email.$touch(...args))),
                      class: _normalizeClass({ 'error': _ctx.isAlreadyExist || _ctx.v$.form.email?.$errors.length }),
                      type: "email",
                      name: "email",
                      spellcheck: "false",
                      autocapitalize: "off",
                      autocorrect: "off",
                      autocomplete: "off"
                    }, null, 34), [
                      [_vModelText, _ctx.form.email]
                    ]),
                    (_ctx.v$.form.email?.$errors.length)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.v$.form.email?.$errors[0].$message), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_router_link, { to: "/login" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('forgot.login')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_router_link, { to: "/register" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('forgot.register')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createVNode(_component_ButtonSave, {
                      saved: _ctx.sended,
                      loading: _ctx.loading,
                      type: "submit",
                      class: "button"
                    }, {
                      saved: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('forgot.sended')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('forgot.action')) + " ", 1)
                      ]),
                      _: 1
                    }, 8, ["saved", "loading"])
                  ]),
                  (_ctx.errorMessage !== null)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.errorMessage), 1))
                    : _createCommentVNode("", true)
                ], 32)
              ]))
            : _createCommentVNode("", true),
          (_ctx.step == 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_AppearObject, null, {
                  default: _withCtx(() => [
                    _createElementVNode("h1", null, [
                      _createVNode(_component_LineByLine, {
                        innerHTML: _ctx.$t('forgot.success_title')
                      }, null, 8, ["innerHTML"])
                    ])
                  ]),
                  _: 1
                }),
                _createElementVNode("p", {
                  class: "connection__instructions",
                  innerHTML: _ctx.$t('forgot.success_message')
                }, null, 8, _hoisted_11)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_BackButton, {
          class: "connection__back",
          to: "/"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('forgot.back')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}