
import { defineComponent } from "@vue/runtime-core"
import { Viewport } from '@/store/modules/Viewport'

export default defineComponent({
  props: {
    progress: {
      type: Number,
      required: true
    },
  },

  setup() {
    return {
      Viewport
    }
  }
})
