
import { defineComponent } from 'vue'
import vClickOutside from 'click-outside-vue3'
import { Viewport } from '@/store/modules/Viewport'
import { AppStore } from '@/store/modules/AppStore'

const options = [
  {
    slug: 'en',
    label: 'en&nbsp;|&nbsp;us',
    option: 'English'
  },
  {
    slug: 'es',
    label: 'es&nbsp;|&nbsp;es',
    option: 'Español'
  }
]

export default defineComponent({
  props: {
    reload: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    const currentIndex = options.findIndex(object => {
      return object.slug === this.$i18n.locale;
    });

    return {
      active: false,
      current: currentIndex >= 0 ? options[currentIndex] : options[0],
      options,
      offset: currentIndex / options.length,
      allowLang: process.env.VUE_APP_DISABLE_LANG !== 'true',

      Viewport
    }
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  methods: {
    choose: function (slug: string) {
      if (slug && slug !== this.current.slug && this.allowLang) {
        const currentIndex = options.findIndex(object => {
          return object.slug === slug;
        });

        if (currentIndex >= 0) {
          this.current = options[currentIndex];
          this.$i18n.locale = slug;
          this.offset = currentIndex / options.length;
          if (AppStore.user) {
            AppStore.user.lang = slug
          }
        }
      }
    },

    onClick() {
      this.active = !this.active
    },
    onClickOutside() {
      this.active = false
    }
  }

})
