
import { defineComponent } from 'vue'

export default defineComponent({

  props: {
    light: { type: Boolean, default: false },
    connected: { type: Boolean, default: false }
  },

})
