export default function getCookie(cookieName:string): string {
    const cookieString = document.cookie

    if (cookieString.length !== 0) {
        const cookieValue = cookieString.match('(^| |;)[\\s]*' + cookieName + '=([^;]*)')
        return (cookieValue) ? decodeURIComponent(cookieValue[2]) : null
    }

    return null
}
