import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4bb79800"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Progress" }
const _hoisted_2 = { ref: "base" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, null, 512),
    _createElementVNode("span", {
      ref: "bar",
      style: _normalizeStyle(_ctx.Viewport.isMobile ? `transform: translateX(${_ctx.progress - 100}%);`: `transform: translateY(${_ctx.progress - 100}%);`)
    }, null, 4)
  ]))
}