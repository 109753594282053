
import { defineComponent } from 'vue'
import { AppStore } from '@/store/modules/AppStore'
import { Viewport } from '@/store/modules/Viewport'
import LazyPicture from '@/components/Global/LazyPicture.vue'
import vClickOutside from 'click-outside-vue3'


export default defineComponent({
  data() {
    return {
      active: false,
      user: AppStore.user,
      Viewport,
      adminUrl: process.env.VUE_APP_CMS_PERMALINK
    }
  },

  components: {
    LazyPicture
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  methods: {
    onClick() {
      this.active = !this.active
    },
    onClickOutside() {
      this.active = false
    }
  }

})
