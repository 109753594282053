import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54f7e6e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Terms" }
const _hoisted_2 = {
  class: "Terms-intro padded",
  "data-scroll-section": ""
}
const _hoisted_3 = { class: "Terms-intro-title h1 h1--big" }
const _hoisted_4 = {
  class: "Terms-content grid padded",
  "data-scroll-section": ""
}
const _hoisted_5 = { class: "Terms-content-right" }
const _hoisted_6 = { class: "wysiwyg" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  class: "Terms-content grid padded",
  "data-scroll-section": ""
}
const _hoisted_10 = { class: "Terms-content-right" }
const _hoisted_11 = { class: "wysiwyg" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutNotConnected = _resolveComponent("LayoutNotConnected")!

  return (_openBlock(), _createBlock(_component_LayoutNotConnected, null, {
    default: _withCtx(() => [
      _createElementVNode("main", _hoisted_1, [
        _createElementVNode("section", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('privacy.title1')), 1)
        ]),
        _createElementVNode("section", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("p", {
                innerHTML: _ctx.$t('privacy.introduction1')
              }, null, 8, _hoisted_7),
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (n) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: "n" }, [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.$t('privacy.subtitle1-'+n)), 1),
                  _createElementVNode("p", {
                    innerHTML: _ctx.$t('privacy.description1-'+n)
                  }, null, 8, _hoisted_8)
                ], 64))
              }), 64))
            ])
          ])
        ]),
        _createElementVNode("section", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("h4", null, _toDisplayString(_ctx.$t('privacy.title2')), 1),
              _createElementVNode("p", {
                innerHTML: _ctx.$t('privacy.introduction2')
              }, null, 8, _hoisted_12),
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(14, (n) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: "n" }, [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.$t('privacy.subtitle2-'+n)), 1),
                  _createElementVNode("p", {
                    innerHTML: _ctx.$t('privacy.description2-'+n)
                  }, null, 8, _hoisted_13)
                ], 64))
              }), 64))
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}