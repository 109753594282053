
import { AppStore } from "@/store/modules/AppStore"
import { defineComponent, ref } from "@vue/runtime-core"

export default defineComponent({
  setup() {
    return {
      profile: ref(AppStore.profile)
    }
  },
})
