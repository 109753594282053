
import { defineComponent } from "@vue/runtime-core";

import ButtonHover from '@/components/not-connected/ButtonHover.vue'
import { AppStore } from "@/store/modules/AppStore";
import { User, UserProfile } from "@/@types/props";


export default defineComponent({
  emits: ['change'],

  data() {
    return {
      avatar: this.getAvatarUrl(AppStore.profile),
      hasError: false,
    }
  },

  components: {
    ButtonHover
  },

  methods: {
    getAvatarUrl(user: UserProfile): string {
      let url = '';
      if (user && user.avatar) {
        if (user.avatar.double) {
          url = user.avatar.double.url
        } else {
          url = user.avatar.url
        }
      }

      return url;
    },
    onAvatarChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      this.hasError = false;
      if (!files.length)
        return;

      if (files[0].size > 5242880){
        e.target.value = '';
        this.hasError = true;
      }
        
      this.createImage(files[0]);
    },
    async createImage(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const clientBase64 = e.target.result;
        this.resizeImage(clientBase64).then((base64) => {
          this.avatar = base64
          this.$emit('change', this.avatar)
        })

        
      };
      reader.readAsDataURL(file);
    },


    async resizeImage(base64Str, maxWidth = 240, maxHeight = 240): Promise<string> {
      return new Promise((resolve) => {
        let img = new Image()
        img.src = base64Str
        img.onload = () => {
          let canvas = document.createElement('canvas')
          const MAX_WIDTH = maxWidth
          const MAX_HEIGHT = maxHeight
          let width = img.width
          let height = img.height

          if (width < height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width
              width = MAX_WIDTH
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height
              height = MAX_HEIGHT
            }
          }
          canvas.width = width
          canvas.height = height
          let ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)
          resolve(canvas.toDataURL())
        }
      })
    },

  }
})
