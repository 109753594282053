import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fbcaa32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Test__Title" }
const _hoisted_2 = {
  class: "Test__Scroller__Wrapper",
  ref: "scrollerContainer"
}
const _hoisted_3 = {
  class: "Test__Scroller",
  ref: "list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonClose = _resolveComponent("ButtonClose")!
  const _component_TestProgress = _resolveComponent("TestProgress")!
  const _component_TestQuestion = _resolveComponent("TestQuestion")!
  const _component_TestEnd = _resolveComponent("TestEnd")!
  const _component_LayoutConnected = _resolveComponent("LayoutConnected")!

  return (_openBlock(), _createBlock(_component_LayoutConnected, null, {
    default: _withCtx(() => [
      _createElementVNode("main", {
        class: _normalizeClass(['Test', 'theme--' + _ctx.test.theme, _ctx.isScrolling ? 'is-scrolling' : '']),
        ref: "main"
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(["Test__Background", [_ctx.Viewport.isMobile ? 'color--1' : 'color--5']])
        }, null, 2),
        _createElementVNode("div", {
          class: _normalizeClass(["Test__UI", [(_ctx.isEnded) ? 'is-ended' : '']])
        }, [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.test.title), 1),
          _createVNode(_component_ButtonClose, {
            class: "Test__Close",
            to: `/courses`,
            variant: "light",
            ref: "close"
          }, null, 512),
          _createVNode(_component_TestProgress, {
            class: "Test__Progress",
            progress: _ctx.y / (_ctx.test.questions.length * _ctx.itemHeight) * 100
          }, null, 8, ["progress"])
        ], 2),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.test.questions, (question, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: `question-${index}`,
                style: _normalizeStyle(_ctx.Viewport.isMobile ? '' : `transform: translateY(${_ctx.clampedY(this.y, index)}px)`),
                class: _normalizeClass({ 'active': _ctx.currentIndex === index })
              }, [
                _createVNode(_component_TestQuestion, {
                  ref_for: true,
                  ref: "question",
                  test: _ctx.test,
                  question: question,
                  active: _ctx.currentIndex === index,
                  index: index+1,
                  total: _ctx.test.questions.length,
                  onGoNext: _ctx.goNext,
                  close: _ctx.close
                }, null, 8, ["test", "question", "active", "index", "total", "onGoNext", "close"])
              ], 6))
            }), 128)),
            _createElementVNode("li", {
              style: _normalizeStyle(_ctx.Viewport.isMobile ? '' : `transform: translateY(${_ctx.clampedY(this.y, _ctx.test.questions.length - 1)}px)`),
              class: _normalizeClass({ 'active': _ctx.currentIndex === _ctx.test.questions.length })
            }, [
              _createVNode(_component_TestEnd, {
                ref: "end",
                test: _ctx.test,
                result: _ctx.result,
                active: _ctx.currentIndex === _ctx.test.questions.length,
                onTryAgain: _ctx.tryAgain,
                close: _ctx.close
              }, null, 8, ["test", "result", "active", "onTryAgain", "close"])
            ], 6)
          ], 512)
        ], 512)
      ], 2)
    ]),
    _: 1
  }))
}