
import { defineComponent } from "@vue/runtime-core"
import { AppStore } from "@/store/modules/AppStore"

import ButtonClose from '@/components/buttons/ButtonClose.vue'

const minInputSize = 2;

export default defineComponent({
  data() {
    return {
      tags: AppStore.profile?.preferences || [],
      hasError: false,
      inputSize: minInputSize,
    }
  },

  components: {
    ButtonClose
  },

  watch: {
    'tags': {
      handler() {
        this.$emit('changed')
      },
      deep: true
    }
  },

  methods: {
    removeTag(item: string) {
      const index = this.tags.indexOf(item);
      if (index > -1) {
        this.tags.splice(index, 1);
      }
    },
    removeLastTag() {
      this.tags.splice(this.tags.length - 1, 1);
    },

    addTag() {
      const val = (this.$refs.input as HTMLInputElement).value;
      (this.$refs.input as HTMLInputElement).value = '';
      this.refreshInputSize();
      this.tags.push(val);
    },

    refreshInputSize() {
      const val = (this.$refs.input as HTMLInputElement).value;
      this.inputSize = Math.max(minInputSize, val.length + 1);
    },

    onInputChange(event: KeyboardEvent) {
      const val = (this.$refs.input as HTMLInputElement).value;
      
      if (event.key === 'Enter') {
        event.preventDefault();
        if (val.length > 0) {
          this.addTag();
        }
      } if (event.key === 'Backspace' && val.length === 0) {
        this.removeLastTag();
      } else {
        this.inputSize = Math.max(minInputSize, val.length + 1);
        this.refreshInputSize()
      }
    },

    onBlur() {
      const val = (this.$refs.input as HTMLInputElement).value;
      if (val.length > 0) {
        this.addTag();
      }
    },
  }
})
