
  import { Scroll } from "@/@types/scroll"
  import { Viewport } from "@/store/modules/Viewport"
  import { defineComponent, inject, provide, ref, Ref, watch } from "@vue/runtime-core"
  import { onBeforeRouteUpdate, useRoute } from "vue-router"
  import EventsList from './EventsList.vue'
  import EventsItem from './EventsItem.vue'
  import StickyElement from '@/components/not-connected/StickyElement.vue';
  import { Event, EventPage } from "@/@types/props"
  import { AppStore } from "@/store/modules/AppStore"
  import { fetchEvent, fetchEventPage } from "@/store/data-sources/event"
  
  export default defineComponent({
    setup() {
      const eventPage: Ref<EventPage> = ref(AppStore.eventPage);
      const route = useRoute()
  
      const event = ref(AppStore.event) as Ref<Event | null>
      const isLoading = ref(false)
  
      watch(
        () => route.path,
        () => {
          if (route.path.startsWith('/events')) {
            if (route.params.event) {
              fetchEvent(route.params.event as string)
            } else {
              AppStore.event = null
            }
          }
        }
      )
      watch(
        () => AppStore.event,
        () => {
          event.value = AppStore.event
        }
      )
  
      const scroll: Ref<Scroll> = inject('scroll') as Ref<Scroll>;
      onBeforeRouteUpdate(() => {
        scroll.value.resetOnRouteChange = false
      })
  
      provide('event', event)
      provide('eventPage', eventPage)
      provide('isLoading', isLoading)
  
      return {
        eventPage,
        event,
        isLoading,
      }
    },
  
    components: { 
      // EventsForm,
      EventsList,
      EventsItem,
      StickyElement
    },
  
    mounted() {
      if (!Viewport.isMobile) {
        // TODO Redirect to first event
      }
    },
  })
  