
import { defineComponent, inject, ref } from 'vue'
import { Scroll } from '@/@types/scroll';

export default defineComponent({
  setup() {
    let intersectionObserver: IntersectionObserver 

    return {
      intersectionObserver,
      scroll: inject('scroll') as Scroll,
      isAppear: ref(false),
    }
  },

  props: {
    once: { type: Boolean, default: true },
    ratio: { type: Number, default: 0.3 },
    delay: { type: Number, default: 0 }
  },

  async mounted() {
    await this.$nextTick()

    if (!this.scroll.active) {
      this.isAppear = true
      this.$emit('appear');
      return
    }

    this.intersectionObserver = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > this.ratio) {
        
        
        this.isAppear = true
        this.$emit('appear');

        if (this.once) {
          this.intersectionObserver.unobserve(this.$el)
        }
      } else if (!this.once) {
        this.isAppear = false
        this.$emit('disappear');
      }
    }, { threshold: [this.ratio as number] })

    this.intersectionObserver.observe(this.$el)
  },

  unmounted() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect()
    }
  }
})
