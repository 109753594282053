import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f129a4b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "RewardPopin__Inner padded grid" }
const _hoisted_2 = { class: "RewardPopin__Content" }
const _hoisted_3 = { class: "RewardPopin__Category" }
const _hoisted_4 = { class: "RewardPopin__Img__Wrapper" }
const _hoisted_5 = { class: "RewardPopin__Img" }
const _hoisted_6 = ["srcset"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Vue3Lottie = _resolveComponent("Vue3Lottie")!
  const _component_ButtonHover = _resolveComponent("ButtonHover")!
  const _component_FocusTrap = _resolveComponent("FocusTrap")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, {
      name: "popin",
      duration: 1200
    }, {
      default: _withCtx(() => [
        (_ctx.active)
          ? (_openBlock(), _createElementBlock("div", {
              ref: "popin",
              class: _normalizeClass(["RewardPopin", {'from-left': _ctx.fromLeft, 'from-right': !_ctx.fromLeft}]),
              key: 'reward-popin-' + _ctx.reward.bottle
            }, [
              _createVNode(_component_FocusTrap, { active: _ctx.active }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_Vue3Lottie, {
                      class: "RewardPopin__Confetti",
                      animationData: _ctx.ConfettiJSON,
                      loop: false,
                      ref: "lottie",
                      rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}
                    }, null, 8, ["animationData"]),
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('reward.title')), 1),
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["RewardPopin__Img__Inner", 'bottle--' + _ctx.reward.bottle])
                          }, [
                            _createElementVNode("picture", null, [
                              _createElementVNode("source", {
                                srcset: `/images/backbar/bottles/bottle${_ctx.reward.bottle}.png 1x, /images/backbar/bottles/bottle${_ctx.reward.bottle}@2x.png 2x`
                              }, null, 8, _hoisted_6),
                              _createElementVNode("img", {
                                src: `/images/backbar/bottles/bottle${_ctx.reward.bottle}.png`,
                                alt: "Reward Bottle"
                              }, null, 8, _hoisted_7)
                            ])
                          ], 2)
                        ])
                      ]),
                      _createElementVNode("div", {
                        class: "RewardPopin__Title h1",
                        innerHTML: _ctx.$t('backbar.bottleTitle' + _ctx.reward.bottle).replace('<br/>', '')
                      }, null, 8, _hoisted_8),
                      _createElementVNode("div", {
                        class: "RewardPopin__Text text-lg",
                        innerHTML: _ctx.$t('backbar.bottleText' + _ctx.reward.bottle).replace('<br/>', '')
                      }, null, 8, _hoisted_9),
                      _createVNode(_component_ButtonHover, {
                        "data-fixed": "",
                        onClick: _ctx.onClick,
                        class: "button"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('reward.action')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["active"])
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}