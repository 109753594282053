
import { defineComponent, provide, Ref, ref } from 'vue'
import gsap from 'gsap'
import getOffsetTop from "@/utils/getOffsetTop";
import { Viewport } from '@/store/modules/Viewport';
import { Scroll } from '@/@types/scroll';

export interface Section {
  $el: HTMLElement
  size: number
  offset: number
  top: number
  bottom: number
  enabled: boolean
  isSticky: boolean
  isSticked: boolean
  isStickyTarget: boolean
  stickyOffset: number
  stickyTarget: number
}


export default defineComponent({
  setup() {
    let resizeObserver: ResizeObserver 

    const scroll: Ref<Scroll> = ref({
      value: 0,
      smoothValue: 0,
      height: 0,
      active: false,
      resetOnRouteChange: true
    });
    const NB_CUEPOINTS = 0 
    const cuePoints: Array<Section> = []

    provide('scroll', scroll)

    return {
      resizeObserver,
      cuePoints,
      NB_CUEPOINTS,
      scroll
    }
  },

  props: {
    securityCulling: { type: Number, default: 100 as number },
    toggleVisibility: { type: Boolean, default: false }
  },


  computed: {
    active() {
      return !Viewport.isMobile
    }
  },

  watch: {
    active: 'onActiveChange',
    $route: 'reset'
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize, { passive: true });
    window.addEventListener('keyup', this.onKeyUp)

    gsap.ticker.fps(60)
    gsap.ticker.add(this.onUpdate);

    if(!this.$refs.content) return

    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.contentRect) {
          this.onResize()
        }
      })
    })

    this.resizeObserver.observe((this.$refs.content as HTMLElement))

    this.scroll.active = this.active

    this.onResize()
  },

  unmounted() {
      window.removeEventListener('scroll', this.onScroll)
      window.removeEventListener('resize', this.onResize);
      window.removeEventListener('keyup', this.onKeyUp)


      gsap.ticker.remove(this.onUpdate);

      if(!this.$refs.content) return
      this.resizeObserver.unobserve((this.$refs.content as HTMLElement))
  },

  methods: {
    reset() {
      if(Viewport.isMobile) return

      if (this.scroll.resetOnRouteChange) {
        window.scrollTo(0, 0)
        this.scroll.value = 0
        this.scroll.smoothValue = 0
        this.scroll.active = this.active
      }
      this.scroll.resetOnRouteChange = true
    },

    onScroll() {
      this.scroll.value = window.scrollY
    },

    onResize() {
      if (!this.$refs.content) return

      const oldHeight = this.scroll.height
      this.scroll.height = (this.$refs.content as HTMLElement).clientHeight

      if (this.scroll.height !== oldHeight) {
          this.setPageHeight()
      }
      
      // ------------


      if(!this.active) return


      this.setCuePoints()

      this.cuePoints.forEach((section) => {
        section.size = section.$el.offsetHeight
        section.offset = getOffsetTop(section.$el)

        section.top = section.offset + section.size
        section.bottom = section.offset - Viewport.windowHeight

        if (section.isSticky) {
          const sOffset = section.$el.getAttribute('data-scroll-sticky-offset');

          if (sOffset.charAt(sOffset.length - 1) == '%') {
            section.stickyOffset = Math.round(parseFloat(sOffset) * section.size / 100);
          } else {
            section.stickyOffset = parseInt(sOffset);
          }

          if(section.isStickyTarget) {
            const sTarget = section.$el.getAttribute('data-scroll-target');
            section.stickyTarget = (this.$el.querySelector(sTarget) as HTMLElement).offsetHeight;
          }
        }

        let securityCulling = this.securityCulling as number


        if (section.isSticky && (section.top - section.size + section.stickyOffset - this.scroll.smoothValue) < 0) {
          section.isSticked = true;
          section.$el.classList.add('is-sticked');
          section.$el.style.transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ${section.size - section.stickyOffset - section.top}, 0, 1)`
        } else if ((section.top - this.scroll.smoothValue) + securityCulling < 0 ||
            (section.bottom - this.scroll.smoothValue) - securityCulling > 0) {

          const value = (section.top - this.scroll.smoothValue) + securityCulling < 0 ? section.top + securityCulling : section.bottom - securityCulling
          section.$el.style.transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ${-value}, 0, 1)`
        } else {
          section.$el.style.transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ${-this.scroll.smoothValue}, 0, 1)`
        }

        // section.enabled = true
      })
    },

    setPageHeight() {
        if (this.active) {
            (this.$refs.fakeScroll as HTMLElement).style.height = this.scroll.height + 'px'
        } else { // if disabled, reset values
            (this.$refs.fakeScroll as HTMLElement).style.height = '0px'

            for (let i = 0; i < this.NB_CUEPOINTS; i++) {
                this.cuePoints[i].$el.style.transform = 'none'
            }
        }
    },

    setCuePoints() {
      if (!this.$refs.content) return

      this.cuePoints = []

      const sections = Array.from((this.$refs.content as HTMLElement).querySelectorAll('[data-scroll-section]'))

      sections.forEach((el) => {
        // el.style.transform = 'none'

        const section = {
          $el: el,
          size: 0,
          offset: 0,
          top: 0,
          bottom: 0,
          enabled: true,
          isSticky: el.hasAttribute('data-scroll-sticky'),
          isSticked: false,
          isStickyTarget: el.hasAttribute('data-scroll-target'),
          stickyTarget: 0,
          stickyOffset: 0
        }

        if (this.toggleVisibility && this.active) {
          (el as HTMLElement).style.visibility = 'visible'
        }

        this.cuePoints.push(section as Section)
      })

      this.NB_CUEPOINTS = this.cuePoints.length
    },

    onUpdate() {
      let securityCulling = this.securityCulling as number
      
      if (this.active && this.scroll.height) {
        for (let i = 0; i < this.NB_CUEPOINTS; i++) {
          if (this.cuePoints[i].isSticky && (this.cuePoints[i].top - this.cuePoints[i].size + this.cuePoints[i].stickyOffset - this.scroll.smoothValue) < 0) {
            if (!this.cuePoints[i].isSticked) {
              this.cuePoints[i].isSticked = true;
              this.cuePoints[i].$el.classList.add('is-sticked');

              this.cuePoints[i].$el.style.transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ${this.cuePoints[i].size - this.cuePoints[i].stickyOffset - this.cuePoints[i].top}, 0, 1)`
            }

            const startTrigger = this.cuePoints[i].stickyTarget - this.cuePoints[i].stickyOffset - (Viewport.windowWidth / 2);

            if (this.cuePoints[i].isStickyTarget && this.cuePoints[i].isStickyTarget && (this.cuePoints[i].top - this.cuePoints[i].size + this.cuePoints[i].stickyOffset - this.scroll.smoothValue) < -startTrigger) {
              const stopValue = this.cuePoints[i].size - this.cuePoints[i].stickyOffset - this.cuePoints[i].top;
              const currentValue = -this.scroll.smoothValue + startTrigger;
              
              this.cuePoints[i].isSticked = false;
              this.cuePoints[i].$el.classList.remove('is-sticked');

              this.cuePoints[i].$el.style.transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ${currentValue}, 0, 1)`;
            }


          } else if ((this.cuePoints[i].top - this.scroll.smoothValue) + securityCulling < 0 ||
            (this.cuePoints[i].bottom - this.scroll.smoothValue) - securityCulling > 0) {
            if (this.cuePoints[i].enabled) {
              if (this.toggleVisibility) {
                this.cuePoints[i].$el.style.visibility = 'hidden'
              }

              const value = (this.cuePoints[i].top - this.scroll.smoothValue) + securityCulling < 0 ? this.cuePoints[i].top + securityCulling : this.cuePoints[i].bottom - securityCulling

              this.cuePoints[i].enabled = false
              this.cuePoints[i].$el.style.transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ${-value}, 0, 1)`
            }
          } else {
            if (this.cuePoints[i].isSticked) {
              this.cuePoints[i].isSticked = false;
              this.cuePoints[i].$el.classList.remove('is-sticked');
            }

            if (!this.cuePoints[i].enabled) {
              if (this.toggleVisibility) {
                this.cuePoints[i].$el.style.visibility = 'visible'
              }
              this.cuePoints[i].enabled = true
            }

            this.cuePoints[i].$el.style.transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ${-this.scroll.smoothValue}, 0, 1)`
          }
        }
      }

      this.scroll.smoothValue += (this.scroll.value - this.scroll.smoothValue) * 0.1
      this.scroll.smoothValue = Math.round(this.scroll.smoothValue * 100) / 100
    },

    onKeyUp(event: KeyboardEvent) {
      const target = (event.target as HTMLElement)
      const key = event.key

      if (key === 'Tab' && target) {
        const isFixed = target.hasAttribute('data-fixed');
        if (isFixed) { return }

        console.log('onTab', { key, target, isFixed });

        const offsetTop = getOffsetTop(target)

        if (offsetTop > this.scroll.smoothValue + (Viewport.windowHeight / 2) || offsetTop < this.scroll.smoothValue) {
          let targetScroll = offsetTop - (Viewport.windowHeight / 2) + target.offsetHeight / 2

          targetScroll = targetScroll < 0 ? 0 : targetScroll
          targetScroll = targetScroll > this.scroll.height - Viewport.windowHeight ? this.scroll.height - Viewport.windowHeight : targetScroll

          window.scrollTo(0, targetScroll)
          this.scroll.value = targetScroll
          this.scroll.smoothValue = targetScroll
        }
      }
    },

    onActiveChange() {
      this.scroll.active = this.active
      
      this.onResize()

      if (!this.active) return 

      this.scroll.value = window.scrollY
      this.scroll.smoothValue = this.scroll.value

      for (let i = 0; i < this.NB_CUEPOINTS; i++) {
        this.cuePoints[i].$el.style.transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ${-this.scroll.smoothValue}, 0, 1)`
      }
    }
  }
})
