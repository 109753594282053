
import { computed, defineComponent, inject, Ref } from "@vue/runtime-core"
import { JobPage } from "@/@types/props"

export default defineComponent({
  setup(props, context) {

    const jobPage = inject('jobPage') as Ref<JobPage>

    const countryId = computed({
      get: () => {
        const item = jobPage.value.countries.find((c) => c.id === jobPage.value.country)
        return item ? jobPage?.value.country : ''
      },
      set: (value) => {
        jobPage.value.country = value
        jobPage.value.state = ''
        jobPage.value.city = ''
        context.emit('onChange')
      }
    })
    const stateId = computed({
      get: () => {
        const item = jobPage?.value.states ? jobPage.value.states.find((c) => c.id === jobPage.value.state) : null
        return item ? jobPage?.value.state : ''
      },
      set: (value) => {
        jobPage.value.state = value
        jobPage.value.city = ''
        context.emit('onChange')
      }
    })
    const cityId = computed({
      get: () => {
        const item = jobPage?.value.cities ? jobPage.value.cities.find((c) => c.id === jobPage.value.city) : null
        return item ? jobPage?.value.city : ''
      },
      set: (value) => {
        jobPage.value.city = value
        context.emit('onChange')
      }
    })

    const isLoading = inject('isLoading') as boolean

    const onSubmit = async (event) => {
      event.preventDefault()
      context.emit('change')
    }
    
    return {
      jobPage,
      
      isLoading,

      countryId,
      stateId,
      cityId,

      onSubmit,
    }
  }
})
