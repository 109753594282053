
import { Question } from "@/@types/props";
import { defineComponent, PropType, ref } from "@vue/runtime-core";

import gsap from 'gsap';
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
gsap.registerPlugin(DrawSVGPlugin);

export default defineComponent({
  props: {
    question: {
      type: Object as PropType<Question>,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
  },

  setup(props) {
    const question:Question = props.question as Question;
    return {
      enterTimeline: null as gsap.core.Timeline | null,
      timeline: null as gsap.core.Timeline | null,
      current: ref(question.timeInSeconds),
      max: ref(question.timeInSeconds),
      timer: null as number | null,
    }
  },

  watch: {
    active() {
      if (this.active) {
        this.enter();
      }
    }
  },

  computed: {
    time(): string {
      return (this.current < 10 ? '0'+this.current : ''+this.current);
    }
  },

  mounted() {
    this.createEnterTimeline();
    if (this.active) {
      this.enter();
    }
  },
  unmounted() {
    if (this.timeline) {
      this.timeline.kill();
    }
    if (this.enterTimeline) {
      this.enterTimeline.kill();
    }
    clearInterval(this.timer);
  },

  methods: {
    reset() {
      if (this.enterTimeline) {
        this.enterTimeline.kill();
      }
      if (this.timeline) {
        this.timeline.kill();
      }
      clearInterval(this.timer);
      this.current = (this.question as Question).timeInSeconds;
      this.createEnterTimeline();
      if (this.active) {
        this.enter();
      }
    },
    start() {
      this.timer = window.setInterval(this.tick, 1000);

      const question:Question = this.question as Question;

      this.timeline = gsap.timeline().fromTo(
        (this.$refs.progress as SVGCircleElement),
        { drawSVG: '0% 100%' },
        { drawSVG: '100% 100%', duration: question.timeInSeconds, ease: 'none' }
      );
    },
    tick() {
      if (this.current === 0) {
        clearInterval(this.timer);
        this.leave();
        this.$emit('timeElapsed');
      } else {
        this.current = this.current - 1;
      }
    },
    stop() {
      clearInterval(this.timer);
      this.leave();
    },
    enter() {
      if (!this.enterTimeline) {
        this.createEnterTimeline();
      }
      this.enterTimeline.play();
    },
    createEnterTimeline() {
      this.enterTimeline = gsap.timeline({paused: true, onComplete: this.start})
      .fromTo(
        (this.$refs.progress as SVGCircleElement),
        { drawSVG: '0% 0%' },
        { drawSVG: '0% 100%', duration: 1, ease: 'quart.inOut' },
        0
      )
      .fromTo(
        (this.$refs.base as SVGCircleElement),
        { drawSVG: '0% 0%' },
        { drawSVG: '0% 100%', duration: 0.9, ease: 'quart.inOut' },
        0.1
      )
      .fromTo(
        [(this.$refs.char1 as HTMLSpanElement), (this.$refs.char2 as HTMLSpanElement), (this.$refs.char3 as HTMLSpanElement)],
        { yPercent: 100 },
        { yPercent: 0, stagger: 0.1, duration: 1, ease: 'quart.inOut' },
        0
      );
    },
    leave() {
      if (this.enterTimeline) {
        this.enterTimeline.kill();
      }
      if (this.timeline) {
        this.timeline.kill();
      }
      this.enterTimeline = gsap.timeline({delay: 1})
      .to(
        (this.$refs.progress as SVGCircleElement),
        { drawSVG: '100% 100%', duration: 1, ease: 'quart.inOut' },
        0
      )
      .to(
        (this.$refs.base as SVGCircleElement),
        { drawSVG: '100% 100%', duration: 0.9, ease: 'quart.inOut' },
        0.1
      )
      .to(
        [(this.$refs.char1 as HTMLSpanElement), (this.$refs.char2 as HTMLSpanElement), (this.$refs.char3 as HTMLSpanElement)],
        { yPercent: -100, stagger: 0.1, duration: 1, ease: 'quart.inOut' },
        0
      );
    }
  },
})
