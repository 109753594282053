
import { defineComponent, inject } from "@vue/runtime-core"
import BackBar from './BackBar/BackBar.vue'

export default defineComponent({
  setup() {
    const isRewards = inject('isRewards')
    const isMyProfile = inject('isMyProfile')
    const isUser = inject('isUser')

    return {
      isRewards,
      isMyProfile,
      isUser
    }
  },

  components: {
    BackBar
  }
})
