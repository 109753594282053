import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a8c26d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ProfileExperience" }
const _hoisted_2 = { class: "ProfileExperience__Title Profile__h2" }
const _hoisted_3 = { class: "ProfileExperience__Item__JobTitle" }
const _hoisted_4 = { class: "ProfileExperience__Item__Place" }
const _hoisted_5 = { class: "ProfileExperience__Item__Location" }
const _hoisted_6 = { class: "ProfileExperience__Item__Year" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('profile.experience')), 1),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profile.experiences, (experience, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: 'profile-experience-'+index,
          class: "ProfileExperience__Item"
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(experience.jobTitle), 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString(experience.place), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(experience.location), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(experience.year), 1)
        ]))
      }), 128))
    ])
  ]))
}