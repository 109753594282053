
import { defineComponent, PropType } from 'vue'
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import { requiredUnless, minLength, maxLength, minValue, maxValue, helpers } from '@vuelidate/validators'
import luhnCheck from '@/utils/luhnCheck'
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import ErrorPopin from '@/components/not-connected/ErrorPopin.vue'
import { gsap, Quart } from 'gsap'
import getOffsetTop from '@/utils/getOffsetTop'

let dataPrice = null;

export const isCreditCard = (value) => {
  const regex = new RegExp("^[0-9]{13,19}$");

  if (regex.test(value) && luhnCheck(value)){
      return true
  }

  return false
}

export default defineComponent({
  setup () {
    return { v$: useVuelidate() }
  },

  components: {
    ButtonHover,
    ErrorPopin
  },

  props: {
    initialPrice: {type: Number, default: 0},
    isLoading: { type: Boolean, default: false },
    errors: { type: Array, default: null },

    title: {type: String, required: false, default: null },
    promocodeType: { type: String as PropType<"market" | "event">, default: "market" },
    event: { type: String, default: null }
  },

  data() {
    return {
      errorRequest: false,
      errorPromo: '',
      discount: 0,
      hasPromoCode: false,
      hasUnlockProCode: false,

      promocode: '',
      number: '',
      expMonth: '',
      expYear: '',
      cvc: '',
    }
  },

  validations () {
    return {
      number: { requiredIfNotFree: requiredUnless(this.isFree), isCreditCard: helpers.withMessage("A valid credit card number is required.", isCreditCard) },

      expMonth: { requiredIfNotFree: requiredUnless(this.isFree), minLength: minLength(2), maxLength: maxLength(2), minValue: minValue(1), maxValue: maxValue(12) },
      expYear: { requiredIfNotFree: requiredUnless(this.isFree), minLength: minLength(4), maxLength: maxLength(4), minValue: minValue(new Date().getFullYear()) },

      cvc: { requiredIfNotFree: requiredUnless(this.isFree), minLength: minLength(3), maxLength: maxLength(4) },
    }
  },

  computed: {
    expDateIsInvalid() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth());
      const target = new Date(`${this.expYear}-${this.expMonth}`);

      return (target.getTime() < today.getTime())
    },

    price() {
      return (this.initialPrice as number - this.discount) > 0 ? (this.initialPrice as number - this.discount) : 0
    },

    isFree() {
      return this.price <= 0
    }
  },

  watch: {
    promocode() {
      this.discount = 0
      this.hasPromoCode = false
      this.hasUnlockProCode = false

      if (this.errorPromo) {
        this.errorPromo = ''
      }
    }
  },

  methods: {
    formatedPrice(p) {
      const pFloat = parseFloat(p);
      return this.$t('register-form.price-format', {price: pFloat.toFixed(2)});
    },

    async onPromoBlur() {
      if (!this.promocode.length) return

      const data = await this.checkPromoCode()

      if (data) {
        this.discount = data.discount

        this.hasPromoCode = true;
        this.hasUnlockProCode = !!data.unlockpro;
      }
    },

    async checkPromoCode() {
      try {
        const parameters = {
          type: this.promocodeType,
          code: this.promocode, 
        }
        const response = await axios.post(process.env.VUE_APP_API + 'promoCodes/check', this.event ? { event: this.event, ...parameters } : parameters);
        return response.data.data
      } catch (error) {
        console.error(error);
        if (error.response) {
          this.errorPromo = error.response.data.msg
        } else {
          this.errorRequest = true
        }
        return false
      }
    },

    async onSubmit() {
      const result = await this.v$.$validate()

      if ((!this.isFree && !result) || this.errorPromo !== '') {
        console.warn('form not valid')

        const firstInputError = this.$el.querySelector('.error')
        if (firstInputError) {
          const offset = getOffsetTop(firstInputError) - 20
          gsap.to(window, { duration: 0.6, scrollTo: offset, ease: Quart.easeOut })
        }

        return
      }

      if (this.isFree) {
        this.$emit('onSubmit', {
          promocode: this.promocode
        })
      } else {
        this.$emit('onSubmit', {
          promocode: this.promocode,
          payment: {
            number: this.number,
            exp_month: this.expMonth,
            exp_year: this.expYear,
            cvc: this.cvc
          }
        })
      }
    },

    onKeyUp(event: KeyboardEvent) {
      if(event.key === 'Tab' || event.key === 'Shift' || event.key === 'Meta' || event.key === 'Control' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') return
      
      const target = event.target as HTMLInputElement
      const { maxLength, value, nextElementSibling } = target;

      if (value.length >= maxLength) {
        (nextElementSibling as HTMLInputElement).focus()
      }
    },

    onKeyPress(event: KeyboardEvent) {
      let keyCode = (event.keyCode ? event.keyCode : event.which);

      if ((keyCode > 31 && (keyCode < 48 || keyCode > 57)) && keyCode !== 46) {
        event.preventDefault();
      }

      const target = event.target as HTMLInputElement
      const { maxLength, value } = target;

      if (value.length >= maxLength) {
        event.preventDefault();
      }
    }
  }
})
