
import { AppStore } from '@/store/modules/AppStore'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    hidden: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isMounted: !AppStore.shortcutsNeedToAppear
    }
  },

  mounted() {
    this.isMounted = true
    AppStore.shortcutsNeedToAppear = false
  },
  unmounted() {
    if (this.$route.path === '/dashboard' || this.$route.path === '/courses') {
      AppStore.shortcutsNeedToAppear = false
    } else {
      AppStore.shortcutsNeedToAppear = true
    }
  }
})
