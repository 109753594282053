
import { AppStore } from "@/store/modules/AppStore"
import { defineComponent, inject, Ref, ref } from "@vue/runtime-core"
import ButtonHover from '@/components/not-connected/ButtonHover.vue'
import LazyPicture from '@/components/Global/LazyPicture.vue'
import { MenuMobileStore } from "@/store/modules/MenuMobileStore"
import { Viewport } from "@/store/modules/Viewport"

export default defineComponent({
  setup() {
    const isUser = inject('isUser') as Ref<boolean>

    return {
      mobileHeaderLimit: ref(0),
      isUser,
      profile: ref(AppStore.profile)
    }
  },

  components: {
    ButtonHover,
    LazyPicture
  },


  mounted() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onResize);

    this.onResize();
  },
  unmounted() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onResize);
    MenuMobileStore.forceDark = false;
  },

  methods: {
    onScroll() {
      MenuMobileStore.forceDark = window.scrollY >= (this.mobileHeaderLimit - MenuMobileStore.headerHeight) && Viewport.isMobile;
    },

    onResize() {
      this.mobileHeaderLimit = this.$el.offsetParent.offsetParent.offsetTop
    }
  }
})
