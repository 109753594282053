import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1666faee"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  ref: "popin",
  class: "LeavePopin padded grid"
}
const _hoisted_2 = { class: "LeavePopin__Content" }
const _hoisted_3 = { class: "LeavePopin__Category" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "LeavePopin__Buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonHover = _resolveComponent("ButtonHover")!
  const _component_ButtonClose = _resolveComponent("ButtonClose")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, {
      name: "popin",
      duration: 1200
    }, {
      default: _withCtx(() => [
        (_ctx.active)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
                _createElementVNode("div", {
                  class: "LeavePopin__Text text-lg",
                  innerHTML: _ctx.text
                }, null, 8, _hoisted_4),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_ButtonHover, {
                    onClick: _ctx.onAccept,
                    class: "button"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.labelAccept), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_ButtonHover, {
                    onClick: _ctx.onDecline,
                    class: "button-secondary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.labelDecline), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _createVNode(_component_ButtonClose, {
                  class: "LeavePopin__Close",
                  variant: 'dark',
                  onClick: _ctx.onDecline
                }, null, 8, ["onClick"])
              ])
            ], 512))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}