import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["x1", "y1", "x2", "y2"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("line", {
    ref: "line",
    x1: _ctx.Viewport.isMobile ? 2 : 2,
    y1: _ctx.Viewport.isMobile ? 2 : 2,
    x2: _ctx.Viewport.isMobile ? 333 : 2,
    y2: _ctx.Viewport.isMobile ? 2 : this.max
  }, null, 8, _hoisted_1))
}