import { renderSlot as _renderSlot, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-controls", "aria-expanded", "id"]
const _hoisted_2 = ["id", "aria-labelledby", "hidden"]
const _hoisted_3 = {
  ref: "contentInner",
  class: "Collapse-content-inner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'is-active': _ctx.isActive }, "Collapse"])
  }, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onCollapse && _ctx.onCollapse(...args)), ["prevent"])),
      "aria-controls": _ctx.id,
      "aria-expanded": _ctx.isActive,
      id: _ctx.id+'-btn',
      type: "button",
      class: "Collapse-header"
    }, [
      _renderSlot(_ctx.$slots, "header")
    ], 8, _hoisted_1),
    _createElementVNode("div", {
      id: _ctx.id,
      role: "region",
      "aria-labelledby": _ctx.id+'-btn',
      hidden: _ctx.isHidden,
      ref: "content",
      class: "Collapse-content"
    }, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "content")
      ], 512)
    ], 8, _hoisted_2)
  ], 2))
}