

import { ChapterItem, Course } from '@/@types/props';
import { defineComponent, PropType } from 'vue'

import LazyPicture from '@/components/Global/LazyPicture.vue'

export default defineComponent({
  props: {
    chapter: {
      type: Object as PropType<ChapterItem>,
      required: true,
    },
    course: {
      type: Object as PropType<Course>,
      required: true,
    }
  },

  components: {
    LazyPicture
  }
});
