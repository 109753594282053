
import { defineComponent, Ref, ref } from "@vue/runtime-core";

import LineByLine from '@/components/not-connected/LineByLine.vue';
import AppearObject from '@/components/not-connected/AppearObject.vue';
import { Resources } from "@/@types/props";
import { AppStore } from "@/store/modules/AppStore";

export default defineComponent({
  setup() {
    const resources = ref(AppStore.resources) as Ref<Resources>

    return {
      resources
    }
  },

  components: {
    LineByLine,
    AppearObject
  }
})
