export function paragraph(text:string) {
  let result = '<p>' + text + '</p>';
  result = result.replace(/\r\n\r\n/g, '</p><p>').replace(/\n\n/g, '</p><p>');
  result = result.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>');

  return result;
}

export function breaks(text:string) {
  let result = text;
  result = result.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>');

  return result;
}