import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfilePage = _resolveComponent("ProfilePage")!
  const _component_LayoutConnected = _resolveComponent("LayoutConnected")!

  return (_openBlock(), _createBlock(_component_LayoutConnected, null, {
    default: _withCtx(() => [
      _createVNode(_component_ProfilePage)
    ]),
    _: 1
  }))
}