import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fbde233"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "DashboardMyEvents"
}
const _hoisted_2 = { class: "small-uppercase" }
const _hoisted_3 = { class: "DashboardMyEvents_list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LineByLine = _resolveComponent("LineByLine")!
  const _component_MyEventsItem = _resolveComponent("MyEventsItem")!

  return (_ctx.state !== 'no-events')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          _createVNode(_component_LineByLine, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('dashboard.myevents_title')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(), _createBlock(_component_MyEventsItem, {
            "start-date": _ctx.event.start_date,
            "end-date": _ctx.event.end_date,
            place: _ctx.event.city,
            name: _ctx.event.name,
            id: _ctx.event.id,
            timezone: _ctx.event.timezone,
            class: "DashboardMyEvents_listItem",
            key: 'dashboard-event-' + _ctx.event.id
          }, null, 8, ["start-date", "end-date", "place", "name", "id", "timezone"]))
        ])
      ]))
    : _createCommentVNode("", true)
}