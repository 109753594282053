
import { defineComponent } from "@vue/runtime-core";
import gsap, { Back } from 'gsap'

export default defineComponent({
  props: {
    index: {
      type: Number
    }
  },

  setup() {
    return {
      timeline: null as gsap.core.Timeline | null,
    }
  },

  mounted() {
    const timeline = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.2,
      delay: 0.6,
    });

    timeline.fromTo(
      this.$refs.pointer as HTMLElement,
      { x: -60 },
      { x: 0, duration: 0.5, ease: 'quart.out' },
      0
    )
    timeline.fromTo(
      this.$refs.pointer as HTMLElement,
      { rotate: -15, transformOrigin: '50% 100%' },
      { rotate: 0, duration: 0.3, ease: 'quart.out' },
      0.2
    )
    timeline.to(
      this.$refs.pointer as HTMLElement,
      { rotate: -15, x: -60, transformOrigin: '50% 100%', duration: 0.7, ease: 'quart.inOut' },
      0.5
    )

    timeline.addLabel('click', 0.3);

    timeline.set(
      this.$refs.mask as SVGCircleElement,
      { scale: 0 },
      0
    );
    timeline.fromTo(
      this.$refs.circle as SVGCircleElement,
      { scale: 0, transformOrigin: '50% 50%' },
      { scale: 1, duration: 1, ease: 'quart.out' },
      'click'
    );
    timeline.fromTo(
      this.$refs.mask as SVGCircleElement,
      { scale: 0, transformOrigin: '50% 50%' },
      { scale: 1, duration: 1, ease: 'quart.out' },
      'click+=0.2'
    );



    timeline.fromTo(
      (this.$refs.tiles as SVGElement).querySelectorAll('.tile'),
      { opacity: 0.2 },
      { opacity: 0, duration: 1, ease: 'quart.out' },
      'click'
    );
    timeline.fromTo(
      (this.$refs.tiles as SVGElement),
      { scale: 1, transformOrigin: '50% 50%' },
      { scale: 1.85, duration: 1, ease: 'quart.out' },
      'click'
    );
    timeline.fromTo(
      (this.$refs.tiles as SVGElement).querySelectorAll('path'),
      { scale: 1, transformOrigin: '50% 50%' },
      { scale: 0.85, duration: 1, ease: 'quart.out' },
      'click'
    );

    timeline.addLabel('return');
    timeline.to(
      (this.$refs.tiles as SVGElement).querySelectorAll('.tile'),
      { opacity: 0.2, duration: 1, ease: 'quart.in' },
      'return'
    );
    timeline.to(
      (this.$refs.tiles as SVGElement),
      { scale: 1, duration: 1, ease: 'quart.in' },
      'return'
    );
    timeline.to(
      (this.$refs.tiles as SVGElement).querySelectorAll('path'),
      { scale: 1, duration: 1, ease: 'quart.in' },
      'return'
    );


    
    
    this.timeline = timeline;
  },

  unmounted() {
    if (this.timeline) {
      this.timeline.kill();
    }
  }
})
