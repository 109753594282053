
import { defineComponent } from 'vue'
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, maxLength, helpers } from '@vuelidate/validators'
import UploadButton from '@/components/not-connected/UploadButton.vue'
import LayoutNotConnected from '@/layouts/NotConnected.vue'
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import ErrorPopin from '@/components/not-connected/ErrorPopin.vue';

export default defineComponent({
  setup () {
    return { v$: useVuelidate() }
  },

  components: {
    UploadButton,
    LayoutNotConnected,
    ButtonHover,
    ErrorPopin
  },

  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      about: '',
      message: '',
      file: '',

      isLoading: false,
      isSent: false,
      errorRequest: false,
      errors: null
    }
  },

  validations () {
    return {
      firstname: { required, minLength: minLength(2) },
      lastname: { required, minLength: minLength(2) },
      email: { 
        email: helpers.withMessage(this.$t('account.email_validation'), email), 
        required },
      about: { required },
      message: { required, minLength: minLength(2), maxLength: maxLength(800) },
    }
  },

  watch: {
    firstname: 'onIputChange',
    lastname: 'onIputChange',
    email: 'onIputChange',
    about: 'onIputChange',
    message: 'onIputChange'
  },

  methods: {
    onFileChange(file) {
      this.file = file
    },

    async onSubmit() {
      const result = await this.v$.$validate()

      if (!result) {
        console.warn('form not valid', this.v$)
        return
      }

      this.isLoading = true
      
      // send email

      const data = await this.sendForm()

      if (!data) {
        this.isLoading = false
        return
      }

      this.isLoading = false
      this.firstname = ''
      this.lastname = ''
      this.email = ''
      this.about = ''
      this.message = '';
      (this.$refs.upload as typeof UploadButton).onRemove()
      this.v$.$reset()

      this.$nextTick(() => {
        this.isSent = true
      })
    },

    async sendForm() {
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

      const formData = {
        "firstname": this.firstname,
        "lastname": this.lastname,
        "email": this.email,
        "about": this.about,
        "message": this.message,
        "file": this.file
      }

      const data = JSON.stringify(formData)

      try {
        const response = await axios.post(process.env.VUE_APP_API + 'notification/contact', data, { headers });
        return response.data
      } catch (error) {
        console.error(error);
        if (error.response) {
          this.errors = Object.values(error.response.data.errors)
        } else {
          this.errorRequest = true
        }
        return null
      }
    },

    onIputChange() {
      if (this.isSent) {
        this.isSent = false
      }
    }
  }
});
