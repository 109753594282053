
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    to: { type: String, default: null },
    for: { type: String, default: null },
  },

  computed: {
    type() {
      if(this.to) {
        return  'router-link'
      }

      if(this.for) {
        return  'label'
      }

      if(this.$attrs.href) {
        return 'a'
      }

      return 'button'
    }
  }
})
