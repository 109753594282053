
import { defineComponent } from 'vue'

import LayoutConnected from '@/layouts/Connected.vue';

export default defineComponent({
  components: {
    LayoutConnected
  }
})
