
import { computed, ComputedRef, defineComponent, inject, provide, ref, Ref } from 'vue'
import { AppStore } from '@/store/modules/AppStore'

import GlossaryHeader from '@/components/connected/Glossary/GlossaryHeader.vue'
import GlossarySection from '@/components/connected/Glossary/GlossarySection.vue'
import GlossaryDetail from '@/components/connected/Glossary/GlossaryDetail.vue'

import { Scroll } from '@/@types/scroll'
import { GlossaryItem } from '@/@types/props'

import getOffsetTop from "@/utils/getOffsetTop";

import gsap from 'gsap'
import { onBeforeRouteUpdate } from 'vue-router'
import { Viewport } from '@/store/modules/Viewport'
import { fetchGlossary, fetchGlossaryDefinition } from '@/store/data-sources/glossary'
import { reportUnhandledExceptionOnInvocation } from 'xstate/lib/utils'

export type GlossaryItemExtended = GlossaryItem & {
  top?: number
  isReached?: boolean
}

export default defineComponent({
  setup() {
    const glossary = ref(AppStore.glossary)
    const firstUnder: Ref<GlossaryItemExtended | null> = ref(null)

    const items: ComputedRef<Array<GlossaryItemExtended>> = computed(() => {
      const i = glossary.value.items
      i.sort((a, b) => a.letter > b.letter ? 1 : -1);

      return i
    })
  
    provide('firstUnder', firstUnder)

    const selectedDefinition = ref(AppStore.glossaryDefinition)
    const scroll = inject('scroll') as Ref<Scroll>;

    const lastScroll = ref(0);
    onBeforeRouteUpdate(() => {
      scroll.value.resetOnRouteChange = false
    })

    // const user = AppStore.user;
    
    return {
      glossary,
      scroll,
      resizeObserver: null as ResizeObserver | null,
      items,
      nbItems: computed(() => items.value.length),
      firstUnder,
      offsetHeader: 0,
      
      lastScroll,
      selectedDefinition,

      isDetailOpened: ref(false),

      // user,
    }
  },

  watch: {
    '$route.path': function() {
      this.scrollToSlug()
    },

    // 'user.lang': async function() {
    //   await fetchGlossary()
    //   this.glossary = AppStore.glossary
    // }
  },

  components: {
    GlossaryHeader,
    GlossarySection,
    GlossaryDetail
  },

  mounted() {
    // gsap.ticker.fps(60)
    // gsap.ticker.add(this.tick)

    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.contentRect) {
          this.onResize()
        }
      })
    })

    this.resizeObserver.observe((this.$el as HTMLElement))
    this.onResize()

    setTimeout(this.scrollToSlug, 600)
  },

  unmounted() {
    this.resizeObserver && this.resizeObserver.unobserve((this.$el as HTMLElement))
  },

  methods: {    
    onReachChange() {
      let first = null
      let i = 0;
      while (i < this.nbItems && !first) {
        if (this.items[i].isReached) {
          first = this.items[i];
        }
        i++;
      }

      this.firstUnder = first;
    },

    async scrollToSlug() {
      const currentSlug = this.$route?.params?.definition as string;
      this.selectedDefinition = await fetchGlossaryDefinition(currentSlug || '');

      if (!Viewport.isMobile) {
        const letter = currentSlug ? currentSlug.charAt(0) : null;
        if (letter) {
          this.goToLetter(letter);
        }
      }
    },

    onResize() {
      this.offsetHeader = Viewport.isMobile ? 55 * Viewport.windowWidth / 375 : 55 * Viewport.windowWidth / 1680;

      for (let i = 0; i < this.nbItems; i++) {
        this.items[i].top = getOffsetTop(this.$refs.section[i].$el as HTMLElement) - this.offsetHeader;
      }
    },

    goToLetter(letter) {
      const item = this.items.find(item => item.letter === letter);
      if (item) {
        console.log('scrollTo', item.top - this.offsetHeader);
        gsap.to(window, { scrollTo: { y: item.top - this.offsetHeader }, duration: 1, ease: 'quart.out' });
      }
    }
  }
})
