
import { computed, defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router';

import { AppStore } from '@/store/modules/AppStore';
import { fetchDashboard, getCourseBySlug } from '@/store/data-sources/course';

import LayoutConnected from '@/layouts/Connected.vue';
import Item  from '@/components/connected/Course/Item.vue'
import Nav  from '@/components/connected/Course/Nav.vue'
import Chapters  from '@/components/connected/Course/Chapters.vue'
import MobileShortcuts  from '@/components/connected/MobileShortcuts.vue'

import VirtualScroll from 'virtual-scroll'
import gsap from 'gsap'
import lerp from '@/utils/Lerp'
import { Viewport } from '@/store/modules/Viewport';
import { MenuMobileStore } from '@/store/modules/MenuMobileStore';


export default defineComponent({
  setup() {
    const gap = ref(11.25); // May change
    const isTouch = "ontouchstart" in window;

    const route = useRoute()

    const lastCourseSlug = window.localStorage && window.localStorage.getItem('lastCourseSlug') as string;
    const currentSlug: string  = (route?.params?.course || lastCourseSlug) as string;
    let { index, course } = getCourseBySlug(currentSlug);

    const factor = Viewport.isMobile ? 0.66 : 2;
    const pxPerItem = Math.min(window.innerHeight, window.innerWidth) * factor;

    return {
      isTouch,
      gap,

      vs: null as null | VirtualScroll,

      lastRotation: ref(0),
      rotation: ref(0),

      isScrolling: ref(false),

      scroll: {
        enabled: true,
        needReset: false,

        target: index * pxPerItem,
        current: index * pxPerItem,
        // currentRounded: 0,
        ease: 0.075,
        snapEase: 0.175,
        isSnapping: false,
        snapTimer: null as null | number,
        scrollEndTimer: null as null | number,

        pxPerItem,

        max: (AppStore.courses.length - 1) * pxPerItem,
      },

      state: computed(() => route.name === 'Courses' ? 'courses' : 'course'),

      current: ref(course),
      courses: AppStore.courses,

      themeForceDark: computed(() => AppStore.themeForceDark)
    }
  },

  watch: {
    '$route.path'() {
      this.goToCurrent(false);
    },
    current() {
      this.onCurrentChanged();
    },
    // 'user.lang': async function() {
    //   console.log('user.lang')
    //   await fetchDashboard()
    //   this.courses = AppStore.courses
    //   console.log('this.current', this.current)

    //   // await fetch()
    //   // this.glossary = AppStore.glossary
    // }
  },


  components: {
    LayoutConnected,
    Item,
    Nav,
    Chapters,
    MobileShortcuts
  },

  mounted() {
    this.vs = new VirtualScroll({
      passive: true,
      useKeyboard: false,
    })
    
    this.vs.on(this.onVirtualScroll)
    window.addEventListener('resize', this.onResize, {passive: true});
    window.addEventListener('keyup', this.onKeyUp, {passive: true});

    gsap.ticker.add(this.tick);

    this.onResize();
    this.onCurrentChanged();
  },

  unmounted() {
    AppStore.themeForceDark = false;

    if (this.vs) {
      this.vs.off(this.onVirtualScroll)
      this.vs.destroy();
    }
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('keyup', this.onKeyUp);

    gsap.ticker.remove(this.tick);
    if (this.scroll.snapTimer) {
      clearTimeout(this.scroll.snapTimer);
    }
    if (this.scroll.scrollEndTimer) {
      clearTimeout(this.scroll.scrollEndTimer);
    }

    console.log('Courses - unmounted', AppStore.themeForceDark);
  },

  methods: {
    onCurrentChanged() {
      AppStore.themeForceDark = this.current?.theme === 'pros3';
      console.log('Courses - onCurrentChanged', AppStore.themeForceDark);
    },

    onVirtualScroll(event) {
      if (this.state === 'courses' && (!Viewport.isMobile || !MenuMobileStore.isOpen) && !AppStore.showMainTutorial) {
        if (this.scroll.enabled) {
          this.isScrolling = true;
          this.scroll.isSnapping = false;
  
          const delta = Viewport.isTouch  ? event.deltaX * -1 : event.deltaY * -1;
          
          this.scroll.target += delta
          // EventBus.emit('SCROLL', {
          //   y: state.target,
          //   deltaY
          // })
  
          this.clampTarget()
          if (this.scroll.snapTimer) {
            clearTimeout(this.scroll.snapTimer);
          }
          if (this.scroll.scrollEndTimer) {
            clearTimeout(this.scroll.scrollEndTimer);
          }
          this.scroll.snapTimer = window.setTimeout(this.snapToClosest, 66);
          this.scroll.scrollEndTimer = window.setTimeout(this.scrollEnd, 600);
        }
      }
    },

    snapToClosest() {
      const destIndex = Math.round(this.scroll.target/this.scroll.pxPerItem);
      const destPx = destIndex * this.scroll.pxPerItem;
      this.scroll.target = destPx;
      this.scroll.isSnapping = true;
    },

    scrollEnd() {
      this.isScrolling = false;
      const destIndex = Math.round(this.scroll.target/this.scroll.pxPerItem);

      this.current = this.courses[destIndex];
    },

    onResize() {
      const factor = (Viewport.isMobile || Viewport.isTouch) ? 0.66 : 2;
      this.scroll.pxPerItem = Math.min(window.innerHeight, window.innerWidth) * factor;
      this.scroll.max = (AppStore.courses.length - 1) * this.scroll.pxPerItem;

      this.clampTarget();
      this.goToCurrent(true);
    },

    clampTarget() {
      this.scroll.target = Math.min(Math.max(this.scroll.target, 0), this.scroll.max)
    },

    tick() {
      if (this.scroll.enabled) {

        if (this.scroll.needReset) {
          this.scroll.needReset = false
          this.scroll.target = 0
          this.scroll.current = 0
          // this.scroll.currentRounded = 0
        }

        this.scroll.current = lerp(this.scroll.current, this.scroll.target, this.scroll.isSnapping ? this.scroll.snapEase : this.scroll.ease);
        // gsap.set(_scroller, { y: -state.currentRounded })
        // store.scroll = state.currentRounded
        // EventBus.emit('TICK', {
        //   target: state.target,
        //   current: state.currentRounded,
        //   deltaTime: deltaTime
        // })
      }

      const r = Math.round((this.scroll.current / this.scroll.pxPerItem * this.gap) * 100) / 100;
      if (this.lastRotation !== r) {
        this.rotation = r;
      }
      this.lastRotation = this.rotation;
    },

    goLeft() {
      const dest = Math.round(this.scroll.target/this.scroll.pxPerItem) - 1;
      this.goTo(dest);
    },

    goRight() {
      const dest = Math.round(this.scroll.target/this.scroll.pxPerItem) + 1;
      this.goTo(dest);
    },

    goTo(dest: number, forced = false, instant = false) {
      if (forced || (this.state === 'courses' && !this.isScrolling && dest >= 0 && dest < this.courses.length)) {
        this.scroll.enabled = false;
        if (!instant) {
          this.isScrolling = true;
        }
  
        const timeline = gsap.timeline({onComplete: () => {
          this.scroll.enabled = true;
          if (!instant) {
            this.isScrolling = false;
          }
        }});
  
        timeline.to(this.scroll, {current: this.scroll.pxPerItem * dest, target: this.scroll.pxPerItem * dest, duration: instant ? 0 : 1, ease: 'out.quint'});
        timeline.add(() => {
          if (!instant) {
            this.isScrolling = false;
          }
          this.current = this.courses[dest];
        }, 0.66);
      }
    },

    onKeyUp(event: KeyboardEvent) {
      if (event.key === 'ArrowLeft') {
        this.goLeft();
      } else if (event.key === 'ArrowRight') {
        this.goRight();
      } else {
        const target = (event.target as HTMLElement)
        const key = event.key

        if (key === 'Tab' && target) {
          
          if (target.hasAttribute('data-course-index')) {
            const courseIndex = parseInt(target.getAttribute('data-course-index'));
            
            console.log('onCourseTab', { key, target, courseIndex });
            if (this.scroll.current !== this.scroll.pxPerItem * courseIndex) {
              this.goTo(courseIndex);
            }
          }
        }
      }
    },

    goToCurrent(instant = false) {
      const lastCourseSlug = window.localStorage && window.localStorage.getItem('lastCourseSlug') as string;
      const currentSlug = ((this.current && this.current.slug) || this.$route?.params?.course || lastCourseSlug) as string;
      const { index, course } = getCourseBySlug(currentSlug);

      if (course && (instant || course !== this.current) && course.type === 'course') {
        this.goTo(index, true, instant);
      }
    },

    showTutorial() {
      AppStore.showMainTutorial = true
    }
  }
})

