
import { computed, defineComponent, provide, Ref, ref } from 'vue'

import { Chapter, ChapterSlide } from '@/@types/props';

import ButtonClose from '@/components/buttons/ButtonClose.vue'
import ScrollIndicator from '@/components/connected/Chapter/ScrollIndicator.vue';
import ScrollProgress from '@/components/connected/Chapter/ScrollProgress.vue';
import Pagination from '@/components/connected/Chapter/Pagination.vue';

import LayoutConnected from '@/layouts/Connected.vue';
import Hero from '@/components/connected/Chapter/Hero.vue';
import SlideTextImage from '@/components/connected/Chapter/SlideTextImage.vue';
import SlideText from '@/components/connected/Chapter/SlideText.vue';
import SlideImage from '@/components/connected/Chapter/SlideImage.vue';
import SlideList from '@/components/connected/Chapter/SlideList.vue';
import SlideQuestion from '@/components/connected/Chapter/SlideQuestion.vue';
import SlideTransition from '@/components/connected/Chapter/SlideTransition.vue';
import SlideVideo from '@/components/connected/Chapter/SlideVideo.vue';
import End from '@/components/connected/Chapter/End.vue';

import ChapterTutorial from '@/components/connected/Chapter/ChapterTutorial.vue';


import VirtualScroll from 'virtual-scroll'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin);
import lerp from '@/utils/Lerp'
import { AppStore } from '@/store/modules/AppStore';
import { fetchChapter, setChapterProgression } from '@/store/data-sources/course';

import theme from '@/styles/_theme.json';
import { Viewport } from '@/store/modules/Viewport';
import { MenuMobileStore } from '@/store/modules/MenuMobileStore';
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';

const getGap = () => {
  let factor = 1;
  if (window.innerWidth >= theme.breakpoints.xs) {
    factor = 0;
  }
  if (window.innerWidth >= theme.breakpoints.md) {
    factor = window.innerWidth / theme.breakpoints.vl;
  }
  if (window.innerWidth >= theme.breakpoints.vl) {
    factor = window.innerWidth / theme.breakpoints.xxl;
  }

  return 144 * factor;
};

export default defineComponent({
  setup() {
    const chapter: Ref<Chapter> = ref(AppStore.chapter);
    
    const gap = getGap()

    const pxPerItem = window.innerHeight * 2
    const itemHeight = ref(window.innerHeight - gap)
    const lastProgress = 0

    const isTouch = Viewport.isTouch

    const currentIndex = ref(0)
    const currentIndexLive = ref(0)
    const startAt = ref(0)

    const maxProgress: Ref<number> = ref(chapter?.value.progress);
    const maxIndex = ref(Math.round(maxProgress.value / 100 * (chapter?.value.slides.length + 2)));
    provide('maxProgress', maxProgress)
    provide('maxIndex', maxIndex)


    const saveProgress = async () => {
      const endAt = new Date().getTime();
      const timeSpent = Math.round((endAt - startAt.value) / 1000)
      startAt.value = endAt;

      let slideSlug = 'start';
      if (maxIndex.value >= chapter.value.slides.length + 1) {
        slideSlug = 'end'
      } else {
        const slide = chapter.value.slides.find((slide, index) => index + 2 === maxIndex.value)
        if (slide) {
          slideSlug = slide.slug
        }
      }

      await setChapterProgression(chapter.value, slideSlug, maxProgress.value, timeSpent)
    }

    onBeforeRouteLeave(async (to, from) => {
      if (from.path.startsWith('/chapter/')) {
        await saveProgress()
      }
    })

    onBeforeRouteUpdate(async (to, from, next) => {
      if (from.path.startsWith('/chapter/')) {
        await saveProgress()
      }

      const c = await fetchChapter(to.params?.chapter as string);
      AppStore.chapter = c;

      if (!c) {
        next({
          path: '/courses'
        })
      } else {
        next();
      }
    })


    const currentBackground = computed(() => {
      let bg = 'color--5';
      if (Viewport.isMobile && chapter.value) {
        const currentSlide = chapter.value.slides[currentIndex.value -1 ];
        console.log('currentBackground', {currentSlide});
        if (currentSlide) {
          switch (currentSlide.type) {
            case 'text':
              bg = 'color--4';
              break;
            case 'image':
              bg = 'color--black';
              break;
            case 'list':
              bg = 'color--4';
              break;

            case 'question':
              bg = 'color--4';
              break;
            case 'text_image':
              bg = 'color--1';
              break;
            case 'transition':
              bg = 'color--1';
              break;
            case 'video':
              bg = 'color--black';
              break;
          }
        } 
        if (chapter.value.slides.length === currentIndex.value - 1) {
          bg = 'color--1';
        }
        console.log({bg, currentIndex, nbSlide: chapter.value.slides.length})
      }

      return bg;
    })

    return {
      Viewport,
      currentBackground,

      chapter,

      vs: null as null | VirtualScroll,

      isScrolling: ref(false),
      isEnded: ref(false),
      isOnStart: ref(true),

      isTouch,

      currentIndex,
      currentIndexLive,
      themeUI: ref('light'),

      scroll: {
        enabled: true,
        needReset: false,

        target: lastProgress * pxPerItem,
        current: lastProgress * pxPerItem,
        // currentRounded: 0,
        ease: 0.075,
        snapEase: 0.125,
        isSnapping: false,
        snapTimer: null as null | number,
        scrollEndTimer: null as null | number,

        pxPerItem,
      },
      itemHeight,

      // current: ref(null),
      lastY: ref(0),
      y: ref(0),

      startAt,
      maxProgress,
      maxIndex,

      saveProgress,

      themeForceDark: computed(() => AppStore.themeForceDark)
    }
  },

  components: {
    LayoutConnected,

    ButtonClose,
    ScrollIndicator,
    Pagination,
    ScrollProgress,

    Hero,
    SlideTextImage,
    SlideText,
    SlideImage,
    SlideList,
    SlideQuestion,
    SlideTransition,
    SlideVideo,
    End,

    ChapterTutorial
  },

  watch: {
    currentIndex(newValue) {
      this.themeUI = this.getCurrentThemeUI(newValue);

      return newValue;
    }
  },

  methods: {
    getCurrentThemeUI(index) {
      let themeUI = 'light';
      if (index === 0) {
        themeUI  = 'light';
      } else if ((index - 1) < this.chapter.slides.length ) {
        const type = this.chapter.slides[index - 1].type;
        switch (type) {
          case 'text': 
            themeUI = 'dark';
            break;
          case 'text_image': 
            themeUI = AppStore.themeForceDark ? 'dark' : 'light';
            break;
          case 'question': 
            themeUI = 'dark';
            break;
          case 'image': 
            themeUI = AppStore.themeForceDark ? 'dark' : 'light';
            break;
          case 'list': 
            themeUI = 'dark';
            break;
          case 'video': 
            themeUI = 'light';
            break;
          case 'transition': 
            themeUI = AppStore.themeForceDark ? 'dark' : 'light';
            break;
        }
      } else if (index -1 === this.chapter.slides.length) {
        if (AppStore.themeForceDark) {
          themeUI = 'dark';
        }
      }

      return themeUI;
    },

    getComponentFromSlide(slide: ChapterSlide) {
      return 'slide-' + slide.type.replaceAll('_', '-');
    },

    clampedY(y, index) {
      const min = this.itemHeight * -1 + ((index - 1) * this.itemHeight);
      const max = this.itemHeight + ((index + 1) * this.itemHeight);

      const clamped = Math.max(min, Math.min(max, y));
      
      return clamped * -1;
    },

    onVirtualScroll(event) {
      if (this.scroll.enabled && window.innerWidth >= theme.breakpoints.md && (!Viewport.isMobile || !MenuMobileStore.isOpen)) {
        this.isScrolling = true;
        this.scroll.isSnapping = false;

        const delta = event.deltaY * -1;
        
        this.scroll.target += delta
        // EventBus.emit('SCROLL', {
        //   y: state.target,
        //   deltaY
        // })

        this.clampTarget()
        if (this.scroll.snapTimer) {
          clearTimeout(this.scroll.snapTimer);
        }
        if (this.scroll.scrollEndTimer) {
          clearTimeout(this.scroll.scrollEndTimer);
        }
        this.scroll.snapTimer = window.setTimeout(this.snapToClosest, 66);
        this.scroll.scrollEndTimer = window.setTimeout(this.scrollEnd, 600);
      }
    },

    snapToClosest() {
      const destIndex = Math.round(this.scroll.target/this.scroll.pxPerItem);
      const destPx = destIndex * this.scroll.pxPerItem;
      this.scroll.target = destPx;
      this.scroll.isSnapping = true;
    },

    scrollEnd() {
      this.isScrolling = false;
      const destIndex = Math.round(this.scroll.target/this.scroll.pxPerItem);
      this.currentIndex = destIndex;
      this.currentIndexLive = this.currentIndex;
    },

    onResize() {
      const gap = getGap();

      this.scroll.pxPerItem = Viewport.isTouch ? window.innerHeight : window.innerHeight * 2;
      this.itemHeight = window.innerHeight - gap;

      this.clampTarget();
      this.lastY = -1;
      this.snapToClosest();
    },

    max() {
      return (this.chapter.slides.length + 1) * this.scroll.pxPerItem;
    },

    clampTarget() {
      this.scroll.target = Math.min(Math.max(this.scroll.target, 0), this.max())
    },

    tick() {
      if (this.scroll.enabled) {

        if (this.scroll.needReset) {
          this.scroll.needReset = false
          this.scroll.target = 0
          this.scroll.current = 0
        }

        this.scroll.current = lerp(this.scroll.current, this.scroll.target, this.scroll.isSnapping ? this.scroll.snapEase : this.scroll.ease)

        this.currentIndexLive = Math.round(this.scroll.current/this.scroll.pxPerItem);
      }

      const y = Math.round((this.scroll.current / this.scroll.pxPerItem * this.itemHeight) * 100) / 100;
      if (this.lastY !== y) {
        this.y = y;
        this.isOnStart = Math.round(this.scroll.current) <= 0;
        this.isEnded = Math.round(this.scroll.current) >= this.max();
      }
      this.lastY = this.y;
    },


    goLeft() {
      const dest = Math.round(this.scroll.target/this.scroll.pxPerItem) - 1;
      this.goTo(dest);
    },

    goRight() {
      const dest = Math.round(this.scroll.target/this.scroll.pxPerItem) + 1;
      this.goTo(dest);
    },

    goTo(dest: number, forced = false) {
      if (forced || (!this.isScrolling && dest >= 0 && dest < this.chapter.slides.length + 2)) {
        this.scroll.enabled = false;
        this.isScrolling = true;
  
        const timeline = gsap.timeline({onComplete: () => {
          this.scroll.enabled = true;
          this.isScrolling = false;
        }});

        if (dest > 0 && dest < this.chapter.slides.length + 1) {
          const slide = this.$refs.slide[dest - 1]
          if (slide.slide?.type === 'video') {
            slide.playVideo && slide.playVideo();
          }
        }
  
        timeline.to(
          this.scroll, 
          { current: this.scroll.pxPerItem * dest, target: this.scroll.pxPerItem * dest, duration: 0.5, ease: 'out.quint' },
          0
        );
        timeline.to(
          this.$refs.scrollerContainer as HTMLElement,
          { scrollTo: {y: 0}, duration: 0.5, ease: 'out.quint'},
          0
        )
        timeline.add(() => {
          this.isScrolling = false;
          // this.current = this.courses[dest];
          this.currentIndex = dest;
        }, Viewport.isMobile ? 0 : 0.66);

        
      }
    },

    onBeforeUnload() {
      this.saveProgress();
    },
    onVisibilityChange() {
      if (document.visibilityState !== 'visible') {
        this.saveProgress();
      }
    },

    onFocus() {
      const main = (this.$refs.main as HTMLElement);
      if (main) {
        if (main.scrollTop > 0) {
          main.scrollTo(0, 0);
        }
      }
    },


    onKeyUp(event: KeyboardEvent) {
      const target = (event.target as HTMLElement)
      const key = event.key

      if (key === 'ArrowLeft' || key === 'ArrowUp') {
        this.goLeft();
      } else if (key === 'ArrowRight' || key === 'ArrowDown' || key === ' ') {
        this.goRight();
      }

      if (key === 'Tab' && target) {
        if (target.hasAttribute('data-slide-index')) {
          const slideIndex = parseInt(target.getAttribute('data-slide-index'));
          this.goTo(slideIndex);
        }
      }
      if (key == 'Escape') {
        this.$router.push(this.chapter.courseSlug ? `/course/${ this.chapter.courseSlug }` : '/courses/');
      }
    },
  },

  mounted() {
    this.startAt = new Date().getTime();

    this.vs = new VirtualScroll({
      useTouch: true,
      passive: true,
      useKeyboard: false,
    })

    this.vs.on(this.onVirtualScroll)
    window.addEventListener('resize', this.onResize, {passive: true});
    this.onResize();

    gsap.ticker.add(this.tick);
    

    if (window.localStorage && this.chapter) {
      window.localStorage.setItem('lastCourseSlug', this.chapter.courseSlug);
    }

    window.addEventListener('beforeunload', this.onBeforeUnload)
    document.addEventListener('visibilitychange', this.onVisibilityChange)

    window.addEventListener('keyup', this.onKeyUp, {passive: true});

    [...(this.$refs.main as HTMLElement).querySelectorAll('a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])')].forEach((focusable) => {
      focusable.addEventListener('focus', this.onFocus);
    })

    // FORCE THEME DARK
    AppStore.themeForceDark = this.chapter?.theme === 'pros3';
    console.log('Chapter - mounted', AppStore.themeForceDark);
  },

  unmounted() {
    if (this.vs) {
      this.vs.off(this.onVirtualScroll)
      this.vs.destroy();
    }
    window.removeEventListener('resize', this.onResize);

    gsap.ticker.remove(this.tick);
    if (this.scroll.snapTimer) {
      clearTimeout(this.scroll.snapTimer);
    }
    if (this.scroll.scrollEndTimer) {
      clearTimeout(this.scroll.scrollEndTimer);
    }

    window.removeEventListener('beforeunload', this.onBeforeUnload)
    document.removeEventListener('visibilitychange', this.onVisibilityChange)

    window.removeEventListener('keyup', this.onKeyUp);

    AppStore.themeForceDark = false;
    console.log('Chapter - unmounted', AppStore.themeForceDark);
  },

});
