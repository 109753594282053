import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a9b7156"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Timer color--2" }
const _hoisted_2 = { viewBox: "0 0 321 321" }
const _hoisted_3 = {
  ref: "base",
  cx: "160.5",
  cy: "160.5",
  r: "159.5"
}
const _hoisted_4 = {
  ref: "progress",
  cx: "160.5",
  cy: "160.5",
  r: "159.5"
}
const _hoisted_5 = { ref: "line" }
const _hoisted_6 = { ref: "char3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", _hoisted_2, [
      _createElementVNode("circle", _hoisted_3, null, 512),
      _createElementVNode("circle", _hoisted_4, null, 512)
    ])),
    _createElementVNode("span", null, [
      _createElementVNode("span", _hoisted_5, [
        _createElementVNode("span", { ref: "char1" }, _toDisplayString(_ctx.time.charAt(0)), 513),
        _createElementVNode("span", { ref: "char2" }, _toDisplayString(_ctx.time.charAt(1)), 513),
        _createElementVNode("span", _hoisted_6, "’", 512)
      ], 512)
    ])
  ]))
}