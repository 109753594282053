import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b13f232"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "MyAccount grid padded footer-white-after" }
const _hoisted_2 = { class: "MyAccount--inner" }
const _hoisted_3 = { class: "MyAccount-intro_inner" }
const _hoisted_4 = { class: "MyAccount-intro_title h1 h1--edit" }
const _hoisted_5 = { class: "MyAccount-nav" }
const _hoisted_6 = ["aria-selected"]
const _hoisted_7 = ["aria-selected"]
const _hoisted_8 = ["aria-selected"]
const _hoisted_9 = {
  class: "MyAccount-form",
  "data-scroll-section": ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LineByLine = _resolveComponent("LineByLine")!
  const _component_AppearObject = _resolveComponent("AppearObject")!
  const _component_AccountInfo = _resolveComponent("AccountInfo")!
  const _component_AccountGeneral = _resolveComponent("AccountGeneral")!
  const _component_AccountOrder = _resolveComponent("AccountOrder")!
  const _component_LayoutConnected = _resolveComponent("LayoutConnected")!

  return (_openBlock(), _createBlock(_component_LayoutConnected, null, {
    default: _withCtx(() => [
      _createElementVNode("main", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AppearObject, {
            class: "MyAccount-intro",
            "data-scroll-section": ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h1", _hoisted_4, [
                  _createElementVNode("span", null, [
                    (_ctx.currentTab === 1)
                      ? (_openBlock(), _createBlock(_component_LineByLine, {
                          key: 0,
                          innerHTML: _ctx.$t('account.title_general')
                        }, null, 8, ["innerHTML"]))
                      : (_ctx.currentTab === 2)
                        ? (_openBlock(), _createBlock(_component_LineByLine, {
                            key: 1,
                            innerHTML: _ctx.$t('account.title_order')
                          }, null, 8, ["innerHTML"]))
                        : (_openBlock(), _createBlock(_component_LineByLine, {
                            key: 2,
                            innerHTML: _ctx.$t('account.title_info')
                          }, null, 8, ["innerHTML"]))
                  ]),
                  _createVNode(_component_LineByLine, {
                    innerHTML: _ctx.user.firstName
                  }, null, 8, ["innerHTML"])
                ]),
                _createElementVNode("nav", _hoisted_5, [
                  _createElementVNode("div", {
                    onKeydown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onNavKeyDown && _ctx.onNavKeyDown(...args))),
                    class: "MyAccount-nav_inner",
                    role: "tablist",
                    "aria-label": "Form"
                  }, [
                    _createElementVNode("button", {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onNavClick(0))),
                      role: "tab",
                      "aria-selected": _ctx.currentTab === 0,
                      "aria-controls": "account-tab",
                      id: "account",
                      class: _normalizeClass({ 'is-active': _ctx.currentTab === 0 }),
                      type: "button"
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('account.step1')), 1)
                    ], 10, _hoisted_6),
                    _createElementVNode("button", {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onNavClick(1))),
                      role: "tab",
                      "aria-selected": _ctx.currentTab === 1,
                      "aria-controls": "general-tab",
                      id: "general",
                      class: _normalizeClass({ 'is-active': _ctx.currentTab === 1 }),
                      type: "button"
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('account.step2')), 1)
                    ], 10, _hoisted_7),
                    _createElementVNode("button", {
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onNavClick(2))),
                      role: "tab",
                      "aria-selected": _ctx.currentTab === 2,
                      "aria-controls": "checkout-tab",
                      id: "order",
                      class: _normalizeClass({ 'is-active': _ctx.currentTab === 2 }),
                      type: "button"
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('account.step3')), 1)
                    ], 10, _hoisted_8)
                  ], 32)
                ])
              ])
            ]),
            _: 1
          }),
          _createElementVNode("section", _hoisted_9, [
            _createElementVNode("div", {
              class: _normalizeClass([{ 'hidden': _ctx.currentTab !== 0 }, "MyAccount-form-tab"]),
              id: "account-tab",
              "aria-labelledby": "account"
            }, [
              _createVNode(_component_AccountInfo, { ref: "accountInfo" }, null, 512)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass([{ 'hidden': _ctx.currentTab !== 1 }, "MyAccount-form-tab"]),
              id: "general-tab",
              "aria-labelledby": "general"
            }, [
              _createVNode(_component_AccountGeneral, { ref: "accountGeneral" }, null, 512)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass([{ 'hidden': _ctx.currentTab !== 2 }, "MyAccount-form-tab"]),
              id: "order-tab",
              "aria-labelledby": "checkout"
            }, [
              _createVNode(_component_AccountOrder)
            ], 2)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}