
import { defineComponent } from "@vue/runtime-core"
import gsap, { Back } from 'gsap'

export default defineComponent({
  props: {
    playing: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      timeline: null as gsap.core.Timeline | null
    }
  },

  watch: {
    playing() {
      if (this.timeline) {
        if (this.playing) {
          this.timeline.play(0);
        } else {
          this.timeline.pause();
        }
      }
    }
  },

  mounted() {
    const _items = [...this.$el.querySelectorAll('.item')];
    const _groups = [...this.$el.querySelectorAll('.group')];
    const _rects = [...this.$el.querySelectorAll('rect')];

    this.timeline = gsap.timeline({ repeat: -1, repeatDelay: 0.5, pause: !this.playing })
      .set(
        _items,
        { transformOrigin: '50% 120px', immediateRender: true }
      )
      .set(
        _groups,
        { transformOrigin: '50% 120px', immediateRender: true }
      )
      .to(
        _rects,
        { transformOrigin: '50% 100%', immediateRender: true }
      )

      .fromTo(
        _items[0],
        { rotate: '-7deg' },
        { rotate: '-10deg', duration: 1, ease: 'quart.inOut' },
        0
      )
      .fromTo(
        [_items[0], _items[1]],
        { opacity: 0.3 },
        { opacity: 0.7, duration: 1, ease: 'quart.inOut' },
        0
      )
      .fromTo(
        _items[2],
        { opacity: 1 },
        { opacity: 0.7, duration: 1, ease: 'quart.inOut' },
        0
      )
      .fromTo(
        [_rects[0], _rects[1]],
        { scale: 0.875 },
        { scale: 0.75, duration: 1, ease: 'quart.out' },
        0
      )
      .fromTo(
        [_rects[2]],
        { scale: 1 },
        { scale: 0.75, duration: 1, ease: 'quart.out' },
        0
      )
      .fromTo(
        _items[1],
        { rotate: '7deg' },
        { rotate: '10deg', duration: 1, ease: 'quart.inOut' },
        0
      )

      .fromTo(
        _groups[0],
        { rotate: '0deg' },
        { rotate: '-30deg', duration: 1, ease: Back.easeIn.config(5) },
        0.2
      )
      .to(
        [_items[0], _items[2], _items[1]],
        { opacity: 0, duration: 0.3, ease: 'quad.out', stagger: 0.1 },
        0.95
      )


      .fromTo(
        _groups[1],
        { rotate: '30deg' },
        { rotate: '0deg', duration: 1, ease: Back.easeIn.config(5) },
        0.2
      )
      .to(
        _groups[1],
        { rotate: '-30deg', duration: 1, ease: Back.easeOut.config(5) },
        1.2
      )
      .fromTo(
        _groups[2],
        { rotate: '30deg' },
        { rotate: '0deg', duration: 1, ease: Back.easeOut.config(5) },
        1.2
      )

      .fromTo(
        [_items[3], _items[5], _items[4]],
        { opacity: 0 },
        { opacity: 0.7, duration: 0.2, ease: 'quad.out', stagger: 0.1 },
        0.95
      )
      .to(
        [_items[3], _items[5], _items[4]],
        { opacity: 0, duration: 0.2, ease: 'quad.out', stagger: 0.1 },
        1.15
      )


      .set(
        [_rects[3], _rects[5], _rects[4]],
        { scale: 0.75 },
        0
      )

      .set(
        _items[3],
        { rotate: '-10deg' },
        0
      )
      .set(
        _items[4],
        { rotate: '10deg' },
        0
      )

      .fromTo(
        [_items[6], _items[8], _items[7]],
        { opacity: 0 },
        { opacity: 0.7, duration: 0.3, ease: 'quad.out', stagger: 0.1 },
        1.15
      )

      .fromTo(
        [_rects[6], _rects[7]],
        { scale: 0.75 },
        { scale: 0.875, duration: 0.6, ease: 'quart.out' },
        1.95
      )
      .fromTo(
        [_rects[8]],
        { scale: 0.75 },
        { scale: 1, duration: 0.6, ease: 'quart.out' },
        1.95
      )
      .fromTo(
        _items[6],
        { rotate: '-10deg' },
        { rotate: '-7deg', duration: 0.6, ease: 'quart.out' },
        1.95
      )
      .fromTo(
        _items[7],
        { rotate: '10deg' },
        { rotate: '7deg', duration: 0.6, ease: 'quart.out' },
        1.95
      )

      .to(
        [_items[6], _items[7]],
        { opacity: 0.3, duration: 0.6, ease: 'quart.out' },
        1.95
      )
      .to(
        _items[8],
        { opacity: 1, duration: 0.6, ease: 'quart.out' },
        1.95
      )
  },

  unmounted() {
    this.timeline && this.timeline.kill()
  }
})
