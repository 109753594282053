
import { Viewport } from '@/store/modules/Viewport'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    to: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: ''
    },
    variant: {
      type: String as PropType<'right' | 'left'>,
      default: 'right'
    },
    size: {
      type: String as PropType<'default' | 'big'>,
      default: 'default'
    },
    color: {
      type: String as PropType<'black' | 'white'>,
      default: 'black'
    },
  },

  data() {
    return {
      clicked: false
    }
  },

  computed: {
    type() {
      return this.to ? 'router-link' : (this.href ? 'a' : 'button')
    }
  },

  methods: {
    onClick() {
      if (Viewport.isMobile) {
        this.clicked = true;
        setTimeout(() => {
          this.clicked = false;
        }, 500)
      } else {
        this.clicked = false;
      }
    }
  }
})
