import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["srcset"]
const _hoisted_2 = ["src", "loading", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("picture", {
    class: _normalizeClass({ 'loaded': _ctx.loaded })
  }, [
    (_ctx.image?.double?.url)
      ? (_openBlock(), _createElementBlock("source", {
          key: 0,
          srcset: `${_ctx.image?.url} 1x, ${_ctx.image?.double?.url} 2x`
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.image?.url)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _ctx.image?.url,
          class: _normalizeClass(_ctx.imgClass),
          onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLoad && _ctx.onLoad(...args))),
          loading: _ctx.lazy ? 'lazy' : '',
          alt: _ctx.alt
        }, null, 42, _hoisted_2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}