
import { defineComponent, onUnmounted, inject, Ref, ref } from "vue";
import gsap from "gsap";
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import ButtonSave from "@/components/buttons/ButtonSave.vue";
import ButtonClose from "@/components/buttons/ButtonClose.vue";
import FormCheckout from "@/components/Register/Standard/FormCheckout.vue";
import { Event } from "@/@types/props";
import { FocusTrap } from "focus-trap-vue";
import EventsDate from "@/components/Events/EventsDate.vue";
import EventsRegisterEnd from "./EventsRegisterEnd.vue";
import { Viewport } from "@/store/modules/Viewport";
import getOffsetTop from "@/utils/getOffsetTop";
import { AppStore } from "@/store/modules/AppStore";
import { fetchEventPage } from "@/store/data-sources/event";

interface Payment {
  number: number;
  exp_month: number;
  exp_year: number;
  cvc: number;
}

export interface EventRegister {
  event: string;
  promocode: string;
  payment: Payment;
}

export default defineComponent({
  setup() {

    onUnmounted(() => {
      document.body.parentElement.classList.remove('has-events-register-popin-open');
    })

    return {
      active: ref(false),
      event: inject("event") as Ref<Event>,
      saved: ref(false),
      loading: ref(false),
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      formData: {} as EventRegister,

      isLoading: false,
      errors: null,
      errorRequest: false,

      ended: false,
    };
  },

  computed: {
    closeButtonVariant() {
      return Viewport.isMobile ? "light" : "dark";
    },
  },

  components: {
    ButtonSave,
    ButtonClose,
    FocusTrap,
    EventsDate,
    FormCheckout,
    EventsRegisterEnd,
  },

  emits: ["close"],

  methods: {
    open() {
      this.active = true;
      document.body.parentElement.classList.add('has-events-register-popin-open');
    },

    onClose() {
      this.isLoading = false;
      this.ended = false;
      this.active = false;
      document.body.parentElement.classList.remove('has-events-register-popin-open');
      this.$emit("close");
    },

    mergeFormData(fields: EventRegister) {
      for (const key in fields) {
        if (
          fields[key] &&
          typeof fields[key] === "object" &&
          !Array.isArray(fields[key])
        ) {
          for (const subKey in fields[key]) {
            if (!this.formData[key]) this.formData[key] = {};
            this.formData[key][subKey] = fields[key][subKey];
          }
        } else {
          this.formData[key] = fields[key];
        }
      }
    },

    async onCheckoutSubmit(fields) {
      this.isLoading = true;

      this.mergeFormData({ event: this.event.id, ...fields });

      const data = await this.sendForm();

      if (!data) {
        this.isLoading = false;
        return;
      }

      if (data.success) {
        const popin = this.$refs.popin as HTMLElement;
        gsap.to(popin, {
          scrollTo: { y: 0 },
        });
        this.ended = true;
      } else {
        this.errors = data.msg;
      }
    },

    async sendForm() {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-token-auth": AppStore.user?.token,
      };

      const data = JSON.stringify(this.formData);

      try {
        const response = await axios.post(
          process.env.VUE_APP_API + "events/checkout",
          data,
          { headers }
        );
        return response.data;
      } catch (error) {
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          this.errors = Object.values(error.response.data.errors);
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.msg
        ) {
          this.errors = [error.response.data.msg];
        } else {
          this.errorRequest = true;
        }
        return null;
      }
    },

    onCompleted() {
      this.active = false;
    },

    scrollToForm() {
      const popin = this.$refs.popin as HTMLElement;
      const form = this.$refs.form as HTMLElement;
      gsap.to(popin, {
        scrollTo: { y: getOffsetTop(form) },
      });
    },
  },
});
