
import { defineComponent, provide, Ref, ref } from 'vue'
import { Viewport } from '@/store/modules/Viewport';
import { Scroll } from '@/@types/scroll';

export default defineComponent({
  setup() {
    const scroll: Ref<Scroll> = ref({
      value: 0,
      smoothValue: 0,
      height: 0,
      active: false,
      resetOnRouteChange: true
    });

    provide('scroll', scroll)

    return {
      scroll
    }
  },

  props: {
    securityCulling: { type: Number, default: 100 as number },
    toggleVisibility: { type: Boolean, default: false }
  },


  computed: {
    active() {
      return !Viewport.isMobile
    }
  },

  watch: {
    active: 'onActiveChange',
    $route: 'reset'
  },

  mounted() {
    this.scroll.active = this.active
  },

  methods: {
    reset() {
      if(Viewport.isMobile) return

      if (this.scroll.resetOnRouteChange) {
        this.scroll.active = this.active
      }
      this.scroll.resetOnRouteChange = true
    },

    onActiveChange() {
      this.scroll.active = this.active
    }
  }
})
