<template>
  <div class="AccountOrders">
    <ul v-if="orders.length > 0">
      <li class="AccountOrder" v-for="order in orders" :key="order.cid">
        <div class="AccountOrder-Background" v-if="order.type !== 'cancel'">
          <picture>
            <source :srcset="`/dev/order-${order.type}-bg.png 1x, /dev/order-${order.type}-bg@2x.png 2x`" />
            <img :src="`/dev/order-${order.type}-bg.png`" alt="bottle image" />
          </picture>
        </div>
        <div class="AccountOrder-Logo">
          <svg viewBox="0 0 190 66">
            <use xlink:href="#i-logo" />
          </svg>
        </div>
        <div class="AccountOrder-Price">
          <p class="AccountOrder-Price_title">{{ $t(`account.order_name_${order.type}`) }}</p>
          <span class="AccountOrder-Price_item">{{ $t('account.order_amount', { amount: (order.amount).toFixed(2) }) }}</span>
        </div>
        <div class="AccountOrder-Infos">
          <p class="AccountOrder-Infos_date">{{ formatDateUS(order.date) }}</p>
          <p class="AccountOrder-Infos_id">{{ order.cid }}</p>
        </div>
      </li>
    </ul>
    <div class="AccountOrders__Empty" v-else>
      <h2 class="h3" v-html="$t('account.no_order')"></h2>
    </div>
  </div>
</template>

<script>
import { AppStore } from "@/store/modules/AppStore";
import { ref } from "@vue/reactivity";
export default {
  setup() {
    const orders = ref(AppStore.user?.orders || []);

    // Just for test:
    // const name = "BarSmarts Program";
    // const date = "12/20/2021";
    // const price = "$29.99";
    // const id = "0000106962";

    return {
      orders,
      // id,
      // name,
      // date,
      // price
    };
  },

  methods: {
    formatDateUS(date) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Date(date).toLocaleDateString('en-US', options)
    },
  }
};
</script>

<style lang="stylus" scoped>
.AccountOrders {
  padding: rem(48) 0 0;

  &__Empty {
    h2 {
      text-align: center;
      margin: rem(32) 0 0;
    }
  }
}

.AccountOrder {
  position: relative;
  width: 100%;
  min-height: rem(288);
  color: $colors.white;
  background: $colors.brand;
  border-radius: 4px;
  overflow: hidden;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.15));
  margin: 0 0 rem(32);

  & + .AccountOrder {
    margin-top: rem(40);
  }

  .AccountOrder-Background {
    position: absolute;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .AccountOrder-Logo {
    position: absolute;
    left: rem(10);
    top: rem(10);
    width: 30%;

    svg {
      fill: $colors.white;
    }
  }

  .AccountOrder-Price {
    position: absolute;
    left: rem(40);
    bottom: rem(48);

    span {
      font-size: rem(48);
      letter-spacing: -0.05em;
      line-height: 1;
    }
  }

  .AccountOrder-Infos {
    position: absolute;
    right: rem(40);
    top: rem(48);
    text-align: right;
  }
}
</style>