
import { defineComponent, inject, Ref, ref } from "vue";
import gsap from "gsap";
import EventsDate from "@/components/Events/EventsDate.vue";
import ButtonHover from "@/components/not-connected/ButtonHover.vue";
import LineByLine from "@/components/not-connected/LineByLine.vue";
import AppearObject from "@/components/not-connected/AppearObject.vue";
import { Event } from "@/@types/props";

export default defineComponent({
  setup() {
    return {
      event: inject("event") as Ref<Event>,
    };
  },

  data() {
    return {
      active: false,
    };
  },

  components: {
    LineByLine,
    EventsDate,
    AppearObject,
    ButtonHover,
  },

  computed: {},

  methods: {
    onEnter(el, onComplete) {
      gsap
        .timeline({
          onComplete,
        })
        .fromTo(
          el,
          {
            opacity: 0,
            scale: 0.9,
            transformOrigin: "50% 100%",
            clipPath: "inset(0rem 0rem 0px round 1.5rem 1.5rem 0px 0px)",
          },
          {
            opacity: 1,
            scale: 1,
            clipPath: "inset(0rem 0rem 0px round 0px 0px 0px 0px)",
            ease: "quart.inOut",
          }
        );
    },

    onAfterEnter() {
      this.active = true;
    },

    onLeave(el, onComplete) {
      gsap.timeline({ onComplete }).to(el, {
        opacity: 0,
        duration: 0.5,
        ease: "quart.out",
      });
    },
  },
});
