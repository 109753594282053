
import { defineComponent, ref } from 'vue'
import AppVersion from '@/components/Dev/AppVersion.vue'
import HeaderConnected from '@/components/Header/HeaderConnected.vue'
import Footer from '@/components/Footer/Footer.vue'
import RewardPopin from '@/components/connected/popins/RewardPopin.vue'
// import PracticalPopin from '@/components/connected/popins/PracticalPopin.vue'
import NewTermsPopin from '@/components/connected/popins/NewTermsPopin.vue'
import MainTutorial from '@/components/connected/popins/MainTutorial.vue'
import SmoothScroll from '@/components/not-connected/SmoothScroll.vue'
import SmoothScrollProxy from '@/components/connected/SmoothScrollProxy.vue'

import { AppStore } from '../store/modules/AppStore'
import { useRoute } from 'vue-router'

import { Viewport } from '@/store/modules/Viewport'

export default defineComponent({
  setup() {
    const route = useRoute();

    return {
      debug: AppStore.debug,
      hasMenu: ref(route?.meta?.hideMenu ? false : true),
      smoothScroll: route?.meta?.smoothScroll,
      Viewport,
      route: ref(route)
    }
  },

  mounted () {
    if(!this.smoothScroll) document.body.parentElement.classList.add('fullscreen')
  },
  unmounted () {
    if(!this.smoothScroll) document.body.parentElement.classList.remove('fullscreen')
  },

  watch: {
    '$route.meta': {
      handler(v) {
        this.hasMenu = v.hideMenu ? false : true;
        this.smoothScroll = v.smoothScroll !== undefined;

        !this.smoothScroll
          ? document.body.parentElement.classList.add('fullscreen')
          : document.body.parentElement.classList.remove('fullscreen');
      },
      deep: true,
      immediate: true
    },
  },

  components: {
    AppVersion,
    HeaderConnected,
    RewardPopin,
    // PracticalPopin,
    NewTermsPopin,
    MainTutorial,
    SmoothScroll,
    SmoothScrollProxy,
    Footer
  }
});
