
import { defineComponent } from 'vue'
import gsap from 'gsap'

export default defineComponent({
  props: {
    to: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: ''
    },
    saved: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      savedCompleted: false,
      moving: false
    }
  },

  computed: {
    type() {
      return this.to ? 'router-link' : (this.href ? 'a' : 'button')
    }
  },

  mounted() {
    if (this.saved) {
      this.onEnterSaved(null, null, true)
    }
  },

  methods: {
    onEnterSaved(el, done, instant = false) {
      const saved = (this.$refs.saved as HTMLElement)
      this.moving = true
      gsap.timeline({ onComplete: () => {
        this.moving = false;
        if (done) {
          done();
        }
        if (!instant) {
          this.$nextTick(() => {
            this.$emit('completed');
          })
        }
      } })
        .to(
          this.$el,
          {
            width: saved.offsetWidth,
            duration: instant ? 0 : 0.6,
            ease: 'quart.out'
          },
          0
        )
        .add(() => {
          this.savedCompleted = true;
        })
        .set(
          this.$el,
          { clearProps: 'width' }
        )
    },

    onBeforeUnsaved(el) {
      gsap.set(
        this.$el,
        { width: el.offsetWidth }
      )
      this.savedCompleted = false
      this.moving = true
    },
    async onUnsaved(el, done) {
      await this.$nextTick()
      const base = (this.$refs.base as HTMLElement)
      gsap.timeline({ onComplete: () => {
        this.moving = false
        done && done()
      } })
        .to(
          this.$el,
          {
            width: base.offsetWidth,
            duration: 0.6,
            ease: 'quart.out'
          }
        )
        .set(
          this.$el,
          { clearProps: 'width' },
        )
    }
  }
})
