
import { defineComponent, PropType } from 'vue'
import Slider from '@/components/not-connected/Slider.vue'
import CursorFollow from '@/components/not-connected/CursorFollow.vue'
import RoundSection from '@/components/not-connected/RoundSection.vue'
import LineByLine from '@/components/not-connected/LineByLine.vue'
import AppearObject from '@/components/not-connected/AppearObject.vue'
import { event } from 'vue-gtag'

interface Testimonial {
  text: string
  name: string
  position: string
  place: string
}

type Testimonials = Array<Testimonial>

export default defineComponent({
  components: {
    Slider,
    CursorFollow,
    RoundSection,
    LineByLine,
    AppearObject
},

  props: {
    content: { type: Array as PropType<Testimonials>, required: true },
    backgroundColor: { type: String, default:"black" }
  },

  methods: {
    onPrev() {
      (this.$refs.slider as typeof Slider).onPrev()
    },

    onNext() {
      (this.$refs.slider as typeof Slider).onNext()
    },

    onDragStart() {
      event('testimonials', {
        'event_category': 'home',
      })
    }
  }
})
