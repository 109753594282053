
import { AppStore } from "@/store/modules/AppStore"
import { Viewport } from "@/store/modules/Viewport"
import { computed, defineComponent, inject, ref } from "vue"
import { GlossaryItem } from "@/@types/props"

import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import { MenuMobileStore } from "@/store/modules/MenuMobileStore"
gsap.registerPlugin(ScrollToPlugin)

export default defineComponent({
  setup() {
    const hasMobileHeaderDeployed = computed(() => MenuMobileStore.hasMobileHeaderDeployed)

    const glossary = computed(() => AppStore.glossary)

    const buttons = ref([])

    return {
      hasMobileHeaderDeployed,
      glossary,
      scroll,
      firstUnder: inject('firstUnder') as GlossaryItem,
      buttons
    }
  },

  watch: {
    firstUnder() {
      if (Viewport.isMobile) {
        if (this.firstUnder) {
          const button = this.buttons[this.firstUnder.letter];
          gsap.to(
            this.$refs.scroller as HTMLElement,
            { scrollTo: { x: button.offsetLeft - Viewport.windowWidth/2 + button.offsetWidth/2 }, duration: 1, ease: 'quart.out' }
          )
        }
      }
    }
  },

  methods: {
    onClick(event, item) {
      event.preventDefault();
      this.$emit('goToLetter', item.letter)
    }
  }
})
