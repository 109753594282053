
import { defineComponent, inject } from 'vue'
import { gsap } from 'gsap'
import getOffsetTop from "@/utils/getOffsetTop";
import { Viewport } from '@/store/modules/Viewport';
import { Scroll } from '@/@types/scroll';
import remapRange from '@/utils/RemapRange'
import clamp from '@/utils/Clamp'

export default defineComponent({
  setup() {
    const offsetTop = 0
    const offsetBottom = 0

    const minRadius = 24
    const maxRadius = 50

    const minSize = 0
    const maxSize = 5

    return {
      offsetTop,
      offsetBottom,

      minRadius,
      maxRadius,
      minSize,
      maxSize,


      scroll: inject('scroll') as Scroll,
    }
  },

  watch: {
    'scroll.active': 'onResize'
  },

  mounted() {
    window.addEventListener('resize', this.onResize)
    gsap.ticker.add(this.onUpdate);

    this.onResize()
  },

  unmounted() {
    window.removeEventListener('resize', this.onResize)
    gsap.ticker.remove(this.onUpdate);
  },

  methods: {
    onResize() {
      if (!this.scroll.active) {
        this.$el.style.clipPath = `inset(${this.minSize}rem ${this.minSize}rem 0px round ${this.minRadius}px ${this.minRadius}px 0px 0px)`
        return
      }
      
      this.offsetTop = getOffsetTop(this.$el) - Viewport.windowHeight
      this.offsetBottom = this.offsetTop + Viewport.windowHeight/2
    },

    onUpdate() {
      if (!this.scroll.active) return

      if (this.scroll.smoothValue > this.offsetTop && this.scroll.smoothValue < this.offsetBottom) {
        let value = remapRange(this.offsetTop, this.offsetBottom, this.maxSize, this.minSize, this.scroll.smoothValue)
        value = clamp(value, this.minSize, this.maxSize)
        value = Math.round(value * 100) / 100

        let radius = remapRange(this.offsetTop, this.offsetBottom, this.maxRadius, this.minRadius, this.scroll.smoothValue)
        radius = clamp(radius, this.minRadius, this.maxRadius)
        radius = Math.round(radius * 100) / 100

        this.$el.style.clipPath = `inset(${value}rem ${value}rem 0px round ${radius}px ${radius}px 0px 0px)`
      } else if (this.scroll.smoothValue < this.offsetTop) {
        this.$el.style.clipPath = `inset(${this.maxSize}rem ${this.maxSize}rem 0px round ${this.maxRadius}px ${this.maxRadius}px 0px 0px)`
      } else {
        this.$el.style.clipPath = `inset(${this.minSize}rem ${this.minSize}rem 0px round ${this.minRadius}px ${this.minRadius}px 0px 0px)`
      }
    }
  }
})
