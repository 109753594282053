
import { defineComponent, inject, ref, Ref } from "@vue/runtime-core";

import Tutorial from '@/components/connected/Tutorial/Tutorial.vue'
import TutorialStep from '@/components/connected/Tutorial/TutorialStep.vue'
import BackBarTutorialAnimation1 from './BackBarTutorialAnimation1.vue'
import BackBarTutorialAnimation2 from './BackBarTutorialAnimation2.vue'
import { AppStore } from "@/store/modules/AppStore";

export default defineComponent({
  components: {
    Tutorial,
    TutorialStep,
    BackBarTutorialAnimation2,
    BackBarTutorialAnimation1,
  },

  setup() {
    const isRewards = inject('isRewards') as Ref<boolean>;
    return {
      showTutorial: ref(false),
      isRewards
    }
  },

  mounted() {
    // this.showTutorial = true;
    if (window.localStorage) {
      const token = window.localStorage.getItem('has_view_tutorial--backbar::' + AppStore.user?.slug);
      // const token = null;
      if (token !== (process.env.VUE_APP_TUTORIAL_TOKEN || 'yes')) {
        this.showTutorial = true;
      }
    }
  },

  methods: {
    tutorialEnded() {
      this.showTutorial = false;
      window.localStorage.setItem('has_view_tutorial--backbar::' + AppStore.user?.slug, (process.env.VUE_APP_TUTORIAL_TOKEN || 'yes'));
    }
  }
})
