
import { defineComponent } from 'vue'
import gsap from 'gsap'

export default defineComponent({
  props: {
    current: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
  },

  setup() {
    return {

    }
  },

  watch: {
    current() {
      this.onChange();
    }
  },

  mounted() {
    this.onChange();
  },

  methods: {
    onChange() {
      const max = -(100 / ((this.total as number) + 1));
      gsap.to(
        this.$refs.list as HTMLElement,
        {
          yPercent: Math.min(max, (this.current as number) * -(100 / ((this.total as number) + 1))),
          duration: 0.5,
          ease: 'quart.out'
        }
      );
    }
  }
})
