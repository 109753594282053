import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d303c68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submenu__wrapper submenu__wrapper--right" }
const _hoisted_2 = { class: "submenu" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "submenu__admin"
}
const _hoisted_7 = ["href"]
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LazyPicture = _resolveComponent("LazyPicture")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["submenu__trigger AccountLink__Wrapper", { 'active': this.active && _ctx.Viewport.isMobile }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.Viewport.isMobile ? 'button' : 'router-link'), {
      tabindex: "-1",
      to: _ctx.Viewport.isMobile ? '' : '/my-account',
      class: "AccountLink"
    }, {
      default: _withCtx(() => [
        (_ctx.user)
          ? (_openBlock(), _createBlock(_component_LazyPicture, {
              key: 0,
              image: _ctx.user.avatar,
              alt: _ctx.user?.firstName,
              class: "picture picture--full"
            }, null, 8, ["image", "alt"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["to"])),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/my-account" }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                innerHTML: _ctx.$t('menu_account.item1')
              }, null, 8, _hoisted_3)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/my-profile" }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                innerHTML: _ctx.$t('menu_account.item2')
              }, null, 8, _hoisted_4)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/logout" }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                innerHTML: _ctx.$t('menu_account.item3')
              }, null, 8, _hoisted_5)
            ]),
            _: 1
          })
        ]),
        (_ctx.user?.role === 'ReportsAdmin' || _ctx.user?.role === 'MarketAdmin' || _ctx.user?.role === 'SuperAdmin')
          ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
              _createElementVNode("a", { href: _ctx.adminUrl }, [
                _createElementVNode("span", {
                  innerHTML: _ctx.$t('menu_connected.admin')
                }, null, 8, _hoisted_8)
              ], 8, _hoisted_7)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2)), [
    [_directive_click_outside, _ctx.onClickOutside]
  ])
}