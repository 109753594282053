import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    "data-id": _ctx.elementid,
    class: "lottie-animation-container",
    style: _normalizeStyle(_ctx.getCurrentStyle),
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hoverStarted && _ctx.hoverStarted(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hoverEnded && _ctx.hoverEnded(...args)))
  }, null, 44, _hoisted_1))
}