import { createRouter, createWebHistory } from "vue-router";

import { AppStore } from "@/store/modules/AppStore";
import { fetchChapter, fetchDashboard, fetchTest } from '@/store/data-sources/course'
import { isAuthenticated, logout, fetchProfile, fetchOrders } from '@/store/data-sources/user'
import { fetchDashboardJobs, fetchInitialJobPage } from '@/store/data-sources/job'
import { fetchDashboardEvents, fetchInitialEventPage } from '@/store/data-sources/event'
import { fetchGlossary, fetchGlossaryDefinition } from '@/store/data-sources/glossary'

import HomeVue from '@/views/not-connected/Home.vue';
import TeamVue from '@/views/not-connected/Team.vue';
import FaqVue from '@/views/not-connected/Faq.vue';
import CompanyVue from '@/views/not-connected/Company.vue';
import ContactVue from '@/views/not-connected/Contact.vue';
import TermsVue from '@/views/not-connected/Terms.vue';
import PrivacyVue from '@/views/not-connected/Privacy.vue';
import AdsVue from '@/views/not-connected/Ads.vue';
import LoginVue from '@/views/not-connected/Login.vue';
import RegisterVue from '@/views/not-connected/Register.vue';
import RegisterStandard from '@/views/not-connected/register/Standard.vue';
import RegisterStaff from '@/views/not-connected/register/Staff.vue';
import ForgotVue from '@/views/not-connected/Forgot.vue';
import ResetPasswordVue from '@/views/not-connected/ResetPassword.vue';
import PracticalVue from '@/views/not-connected/Practical.vue';

import DashboardVue from "@/views/connected/Dashboard.vue";
import CoursesVue from '@/views/connected/Courses.vue';
import ChapterVue from '@/views/connected/Chapter.vue';
import TestVue from '@/views/connected/Test.vue';
import MyAccount from '@/views/connected/MyAccount.vue';
import ProfileVue from '@/views/connected/Profile.vue';
import ProfileEditVue from '@/views/connected/ProfileEdit.vue';
import LogoutVue from '@/views/connected/Logout.vue';
import JobBoardVue from '@/views/connected/JobBoard.vue';
import EventsVue from '@/views/connected/Events.vue';
import GlossaryVue from '@/views/connected/Glossary.vue';
import ResourcesVue from '@/views/connected/Resources.vue';

import { Course, Test } from "@/@types/props";
import { fetchResources } from "@/store/data-sources/resources";
import components from "@/components";


export const ROUTES = {
  "home": '/',
  "register" : '/register',
  "team": '/team',
  "faq": '/faq',
  "company": '/company',
  "contact": '/contact',
  // "terms": '/terms',
  "privacy": '/privacy',
  "ads": '/terms-ads',
  "dashboard": '/dashboard',
  "glossary": '/glossary',
  "glossary_definition": '/glossary/:definition',
  "resources": '/resources',
  "courses": '/courses',
  "course": '/course/:course',
  "test": '/test/:test',
  "chapter": '/chapter/:chapter',
  "my_account": '/my-account',
  "my_account_tab": '/my-account/:tab',
  "my_profile": '/my-profile',
  "my_profile_edit": '/my-profile/edit',
  "my_rewards": '/my-profile/rewards',
  "user": '/user/:user',
  "login": '/login',
  "logout": '/logout',
  "forgot_password": '/forgot-password',
  "reset_password": '/reset-password',
  "job_board": '/job-board',
  "job_board_item": '/job-board/:job',
  "events": '/events',
  "events_item": '/events/:event',
}




const getCourse = (slug: string): Course|null => {
  // TODO Branch To API
  let course: Course | null = null;

  if (slug) {
    for (let i = 0; i < AppStore.courses.length; i++) {
      if (AppStore.courses[i].slug === slug) {
        course = AppStore.courses[i];
        break;
      }
    }
  }

  return course;
}


const dashboardGuard = async () => {
  if (AppStore.needToRefreshDashboard) {
    AppStore.needToRefreshDashboard = false
    AppStore.courses = await fetchDashboard()
    AppStore.dashboard.jobs = await fetchDashboardJobs()
    AppStore.dashboard.events = await fetchDashboardEvents()
  }
}

const courseGuard = async (to, from, next) => {
  const courseSlug = to?.params?.course;

  AppStore.course = getCourse(courseSlug);

  if (AppStore?.course?.status === 'opened') {
    next();
  } else {
    next({
      name: 'Courses'
    });
  }
}
const chapterGuard = async (to, from, next) => {
  const chapterSlug = to?.params?.chapter;
  const chapter = await fetchChapter(chapterSlug);

  if (chapter?.status === 'opened') {
    AppStore.chapter = chapter
    next();
  } else {
    if (AppStore.course) {
      next({
        path: '/course/' + AppStore.course.slug
      })
    } else {
      next({
        name: 'Courses',
      });
    }
  }
}
const testGuard = async (to, from, next) => {
  const testSlug = to?.params?.test;

  const test:Test = await fetchTest(testSlug);
  AppStore.test = test

  if (test) {
    next();
  } else {
    next({
      name: 'Courses'
    });
  }
}
const glossaryGuard = async (to, from, next) => {
  await fetchGlossary()
  next();
}
const glossaryDefinitionGuard = async (to, from, next) => {
  await fetchGlossaryDefinition(to?.params?.definition)

  if (AppStore.glossaryDefinition) {
    next();
  } else {
    next({
      name: 'Glossary'
    });
  }
}

const resourcesGuard = async (to, from, next) => {
  await fetchResources()
  next();
}

const jobsGuard = async (to, from, next) => {
  await fetchInitialJobPage(to.params.job)
  next();
}


const eventsGuard = async (to, from, next) => {
  await fetchInitialEventPage(to.params.event)
  next();
}
// const jobGuard = async (to, from, next) => {
//   await fetchJob(to.params.job)
//   next();
// }

const logoutAction = (to, from, next) => {
  logout();

  next({
    name: 'Home',
    path: '/'
  });
  // next();
}

const profileGuard = async (to, from, next) => {
  const profile = await fetchProfile(to?.params?.user)
  // next()
  if (profile || !to?.params?.user) {
    next()
  } else {
    next({
      path: 'dashboard'
    })
  }
}


const myAccountGuard = async (to, from, next) => {
  await fetchOrders()
  next();
}

const resetPasswordGuard = (to, from, next) => {
  const token = to.query.token;
  if (token) {
    next();
  } else {
    next({ path: 'dashboard'});
  }
}


const routes = [
  // NO AUTH
  {
    name: 'Home',
    path: '/', 
    component: HomeVue,
    meta: {
      requiresAuth: false,
      
    }
  },
  {
    name: 'Team',
    path: '/team', 
    component: TeamVue,
    meta: {
      requiresAuth: false,
      
    }
  },
  {
    name: 'Practical',
    path: '/practical', 
    component: PracticalVue,
    meta: {
      requiresAuth: false,
      footerLight: false
    }
  },
  {
    name: 'Faq',
    path: '/faq', 
    component: FaqVue,
    meta: {
      requiresAuth: false,
      footerLight: true
    }
  },
  {
    name: 'Company',
    path: '/company', 
    component: CompanyVue,
    meta: {
      requiresAuth: false,
      footerLight: true
    }
  },
  {
    name: 'Contact',
    path: '/contact', 
    component: ContactVue,
    meta: {
      requiresAuth: false,
      footerLight: true
    }
  },
  // {
  //   name: 'Terms',
  //   path: '/terms', 
  //   component: TermsVue,
  //   meta: {
  //     requiresAuth: false,
  //     footerLight: true
  //   }
  // },
  {
    name: 'Privacy',
    path: '/privacy', 
    component: PrivacyVue,
    meta: {
      requiresAuth: false,
      footerLight: true
    }
  },
  {
    name: 'Ads',
    path: '/terms-ads', 
    component: AdsVue,
    meta: {
      requiresAuth: false,
      footerLight: true
    }
  },
  { 
    name: 'Login',
    path: ROUTES['login'], 
    component: LoginVue,
    meta: {
      requiresAuth: false,
      hideMenu: true
    }
  },
  { 
    name: 'Register',
    path: ROUTES['register'], 
    component: RegisterVue,
    meta: {
      requiresAuth: false,
      headerLight: true,
    }
  },
  { 
    name: 'RegisterStandard',
    path: '/register/standard', 
    component: RegisterStandard,
    meta: {
      requiresAuth: false,
    }
  },
  { 
    name: 'RegisterStaff',
    path: '/register/staff', 
    component: RegisterStaff,
    meta: {
      requiresAuth: false,
    }
  },
  { 
    name: 'Forgot Password',
    path: ROUTES['forgot_password'], 
    component: ForgotVue,
    meta: {
      requiresAuth: false,
      hideMenu: true
    }
  },
  { 
    name: 'Reset Password',
    path: ROUTES['reset_password'], 
    component: ResetPasswordVue,
    beforeEnter: resetPasswordGuard,
    meta: {
      requiresAuth: false,
      hideMenu: true
    }
  },

  // REQUIRES AUTH
  { 
    name: 'Dashboard',
    path: ROUTES["dashboard"], 
    component: DashboardVue,
    beforeEnter: dashboardGuard,
    meta: {
      requiresAuth: true,
      smoothScroll: true,
    }
  },
  { 
    name: 'My Account',
    path: ROUTES["my_account"], 
    component: MyAccount,
    meta: {
      requiresAuth: true,
      smoothScroll: true,
      unique: true
    }, 
    beforeEnter: myAccountGuard,
    children: [
      { 
        name: 'My account tab',
        path: ROUTES["my_account_tab"], 
        component: MyAccount,
        meta: {
          requiresAuth: true,
          smoothScroll: true,
          unique: true
        },
      },
    ],
  },
  { 
    name: 'My Profile',
    path: ROUTES["my_profile"], 
    component: ProfileVue,
    meta: {
      requiresAuth: true,
      smoothScroll: true,
      headerLight: true,
    },
    beforeEnter: profileGuard,
    children: [
      { 
        name: 'My Rewards',
        path: ROUTES["my_rewards"], 
        component: ProfileVue,
        meta: {
          requiresAuth: true,
  
          smoothScroll: true,
          headerLight: true,
          hideMenu: true,
        },
        beforeEnter: profileGuard,
      },
    ]
  },
  { 
    name: 'My Profile - Edit',
    path: ROUTES["my_profile_edit"], 
    component: ProfileEditVue,
    meta: {
      requiresAuth: true,
      smoothScroll: true,
    },
    beforeEnter: profileGuard,
  },
  { 
    name: 'User',
    path: ROUTES["user"], 
    component: ProfileVue,
    meta: {
      requiresAuth: true,
      smoothScroll: true,
      headerLight: true,
      unique: true
    },
    beforeEnter: profileGuard,
  },
  { 
    name: 'Logout',
    path: ROUTES['logout'], 
    component: LogoutVue,
    meta: {
      requiresAuth: true,
      
    },
    beforeEnter: logoutAction,
  },
  { 
    name: 'Job Board',
    path: ROUTES["job_board"], 
    component: JobBoardVue,
    meta: {
      requiresAuth: true,
      smoothScroll: true,
    },
    beforeEnter: jobsGuard,
    children: [
      { 
        name: 'Job Board Item',
        path: ROUTES["job_board_item"], 
        component: JobBoardVue,
        meta: {
          requiresAuth: true,
  
          smoothScroll: true,
        },
        // beforeEnter: jobGuard,
      },
    ],
  },
  { 
    name: 'Events',
    path: ROUTES["events"], 
    component: EventsVue,
    meta: {
      requiresAuth: true,
      smoothScroll: true,
    },
    beforeEnter: eventsGuard,
    children: [
      { 
        name: 'Event Item',
        path: ROUTES["events_item"], 
        component: EventsVue,
        meta: {
          requiresAuth: true,
  
          smoothScroll: true,
        },
      },
    ],
  },
  { 
    name: 'Glossary',
    path: ROUTES["glossary"], 
    component: GlossaryVue,
    meta: {
      requiresAuth: true,
      smoothScroll: true,
    },
    beforeEnter: glossaryGuard,
    children: [
      { 
        name: 'Glossary Definition',
        path: ROUTES["glossary_definition"], 
        component: GlossaryVue,
        meta: {
          requiresAuth: true,
  
          smoothScroll: true,
        },
        beforeEnter: glossaryDefinitionGuard,
      },
    ],
  },
  { 
    name: 'Resources',
    path: ROUTES["resources"], 
    component: ResourcesVue,
    meta: {
      requiresAuth: true,
      smoothScroll: true,
    },
    beforeEnter: resourcesGuard
  },
  { 
    name: 'Courses',
    path: ROUTES["courses"], 
    component: CoursesVue,
    meta: {
      requiresAuth: true,
      headerLight: true,
      
    },
    beforeEnter: dashboardGuard,
    children: [
      { 
        name: 'Course',
        path: ROUTES["course"], 
        component: CoursesVue,
        meta: {
          requiresAuth: true,
          
        },

        beforeEnter: courseGuard,
      },
    ],
  },
  { 
    name: 'Chapter',
    path: ROUTES["chapter"], 
    component: ChapterVue,
    meta: {
      requiresAuth: true,
      unique: true,
      hideMenu: true,
    },

    beforeEnter: chapterGuard
  },
  { 
    name: 'Test',
    path: ROUTES["test"], 
    component: TestVue,
    meta: {
      requiresAuth: true,
      hideMenu: true,
      unique: true
    },

    beforeEnter: testGuard,
  },
  // CATCH EVERYTHING ELSE WITH A 404 PAGE
  {
    name: '404',
    path: '/:pathMatch(.*)*', 
    component: HomeVue,
    meta: {
      requiresAuth: false,
    }
  },
]

const Router = createRouter({
  history: createWebHistory(),
  routes,
})



Router.beforeEach((to, from, next) => {
  if (to.name === '404') {
    // REDIRECT 404 TO HOME
    next({
      name: 'Home'
    })
  } else {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!AppStore.user) {
        isAuthenticated().then((isLogged) => {
          if (isLogged) {
            next();
          } else {
            next({
              name: 'Login'
            });
          }
        })
      } else {
        next();
      }
    } else if(to.name === 'Login') {
      let path = '/dashboard'
      if (to.hash === '#events') {
        path = '/events'
      }
      isAuthenticated().then((isLogged) => {
        if (isLogged) {
          next({
            path
          });
        } else {
          next();
        }
      })
    } else {
      next()
    }
  }

})

export default Router;