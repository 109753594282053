import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "Image" }
const _hoisted_2 = { class: "Image__Inner section__inner" }
const _hoisted_3 = { class: "Image__Subinner section__subinner" }
const _hoisted_4 = { class: "Image__Caption grid" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LazyPicture = _resolveComponent("LazyPicture")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("figure", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_LazyPicture, {
          class: "picture picture--full picture--appear",
          image: _ctx.slide.image,
          alt: _ctx.slide.title
        }, null, 8, ["image", "alt"]),
        _createElementVNode("figcaption", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("div", {
              class: "section-text-large color--4",
              innerHTML: _ctx.paragraph(_ctx.slide.text)
            }, null, 8, _hoisted_5)
          ])
        ])
      ])
    ])
  ]))
}