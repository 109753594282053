import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9392cdee"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "GlossarySection grid padded",
  "data-scroll-section": ""
}
const _hoisted_2 = {
  class: "GlossarySection__Limit",
  ref: "limit"
}
const _hoisted_3 = { class: "GlossarySection__Title" }
const _hoisted_4 = { class: "h4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512),
    _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.item.letter), 1),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.definitions, (definition, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: `glossary-definition-${_ctx.item.letter}-${index}`
        }, [
          _createVNode(_component_router_link, {
            to: '/glossary/'+definition.slug
          }, {
            default: _withCtx(() => [
              _createElementVNode("h4", _hoisted_4, _toDisplayString(definition.title), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128))
    ])
  ]))
}