
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    legend: { type: String, default: 'Upload'},
    cta: { type: String, default: 'Upload'},
    name: { type: String, default: 'attach'},
    accept: { type: String, default: 'image/*'},
  },

  data() {
    return {
      fileName: '',
      file: null,
    }
  },
  
  computed: {
    labelBtn() {
      return this.fileName || this.cta
    },
  },

  watch: {
    file() {
      this.$emit('onFileChange', this.file)
    }
  },

  methods: {
    onClick() {
      (this.$refs.input as HTMLInputElement).click()
    },

    onFileChange(event: Event):void {
      const input = event.target as HTMLInputElement;

      if (!input.files?.length) {
          return;
      }

      const file = input.files[0];
      this.createBase64File(file);

      this.fileName = file.name
    },

    createBase64File(file) {
      var reader = new FileReader();

      reader.onload = (e) => {
        this.file = e.target.result;
      };

      reader.readAsDataURL(file);
    },

    onRemove() {
      (this.$refs.input as HTMLInputElement).value = ''
      this.fileName = ''
      this.file = ''
    }
  }
})
