
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    certificates: {
      type: Array,
      required: true
    },
    oldCertificates: {
      type: Array,
      required: true
    },
  }
})

