import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1576f68a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Step__Visual__Wrapper" }
const _hoisted_2 = { class: "Step__Visual" }
const _hoisted_3 = { class: "Step__Inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "step" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["Step", _ctx.variant ? 'Step--' + _ctx.variant : ''])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "animation", {}, undefined, true)
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "text", {}, undefined, true)
        ])
      ], 2)
    ]),
    _: 3
  }))
}