
import { defineComponent } from 'vue'
import LayoutConnected from '@/layouts/Connected.vue'
import EventsPage from '@/components/connected/Events/EventsPage.vue'

export default defineComponent({
  components: {
    LayoutConnected,
    EventsPage,
  },
})
