import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5cf5e3ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "Profile__Inner",
  "data-scroll-section": ""
}
const _hoisted_2 = {
  class: "Profile__Subinner",
  ref: "inner"
}
const _hoisted_3 = { class: "Profile__Grid grid" }
const _hoisted_4 = { class: "Profile__Right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileHero = _resolveComponent("ProfileHero")!
  const _component_ProfileHeader = _resolveComponent("ProfileHeader")!
  const _component_ProfileAboutMe = _resolveComponent("ProfileAboutMe")!
  const _component_ProfileCertifications = _resolveComponent("ProfileCertifications")!
  const _component_ProfilePreferences = _resolveComponent("ProfilePreferences")!
  const _component_ProfileExperience = _resolveComponent("ProfileExperience")!
  const _component_ProfileAnswers = _resolveComponent("ProfileAnswers")!

  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass(["Profile footer-white-after", _ctx.isRewardsCompleted ? 'Profile--is-rewards' : ''])
  }, [
    _createVNode(_component_ProfileHero),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ProfileHeader, { ref: "header" }, null, 512),
          (_ctx.profile?.aboutMe)
            ? (_openBlock(), _createBlock(_component_ProfileAboutMe, { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.profile?.certifications?.length || _ctx.profile?.oldCertifications?.length )
              ? (_openBlock(), _createBlock(_component_ProfileCertifications, { key: 0 }))
              : _createCommentVNode("", true),
            (_ctx.profile?.preferences && _ctx.profile?.preferences.length)
              ? (_openBlock(), _createBlock(_component_ProfilePreferences, { key: 1 }))
              : _createCommentVNode("", true)
          ]),
          (_ctx.profile?.experiences && _ctx.profile?.experiences.length)
            ? (_openBlock(), _createBlock(_component_ProfileExperience, { key: 1 }))
            : _createCommentVNode("", true)
        ]),
        (_ctx.profile?.answers && _ctx.profile?.answers.length)
          ? (_openBlock(), _createBlock(_component_ProfileAnswers, { key: 0 }))
          : _createCommentVNode("", true)
      ], 512)
    ])
  ], 2))
}