
import { defineComponent } from 'vue'
import Player from '@vimeo/player';
import { event } from 'vue-gtag'

import LayoutNotConnected from '@/layouts/NotConnected.vue'
import ParallaxObject from '@/components/not-connected/ParallaxObject.vue'
import LineParallax from '@/components/not-connected/LineParallax.vue'
import Testimonials from '@/components/not-connected/Testimonials.vue'
import CursorFollow from '@/components/not-connected/CursorFollow.vue'
import RoundSection from '@/components/not-connected/RoundSection.vue';
import PassionSlider from '@/components/not-connected/PassionSlider.vue';
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import LineByLine from '@/components/not-connected/LineByLine.vue';
import AppearObject from '@/components/not-connected/AppearObject.vue';
// import RegisterPopin from '@/components/not-connected/RegisterPopin.vue';

export default defineComponent({
  setup() {
    let player: Player
    const cuePoints = []
    const nextCuePoint = 0

    return {
      player,
      cuePoints,
      nextCuePoint,
    }
  },

  components: {
    LayoutNotConnected,
    ParallaxObject,
    LineParallax,
    Testimonials,
    CursorFollow,
    RoundSection,
    PassionSlider,
    ButtonHover,
    LineByLine,
    AppearObject,
    // RegisterPopin
  },

  beforeRouteEnter(to, from, next) {
    const img = new Image()
    img.onload = () => {
      next()
    }

    img.srcset = "/images/home/intro.jpg 1x, /images/home/intro@2x.jpg 2x"
  },

  data() {
    return {
      videoActive: false,
      // showRegisterPopin: false,

      currentLineItem: 0,
    }
  },

  computed: {
    testimonials() {
      return [
        {
          text: this.$t('home.testimonial-1'), 
          name: this.$t('home.testimonial-1-name'), 
          position: this.$t('home.testimonial-1-position'),
          place: this.$t('home.testimonial-1-place'),
          picture: this.$t('home.testimonial-1-picture'),
        },
        {
          text: this.$t('home.testimonial-2'), 
          name: this.$t('home.testimonial-2-name'), 
          position: this.$t('home.testimonial-2-position'),
          place: this.$t('home.testimonial-2-place'),
          picture: this.$t('home.testimonial-2-picture'),
        },
        {
          text: this.$t('home.testimonial-3'), 
          name: this.$t('home.testimonial-3-name'), 
          position: this.$t('home.testimonial-3-position'),
          place: this.$t('home.testimonial-3-place'),
          picture: this.$t('home.testimonial-3-picture'),
        }
      ]
    }
  },

  watch: {
    videoActive() {
      if(this.videoActive) {
        this.player.play();
        (this.player.element as HTMLIFrameElement).focus();
        
        event('watch-trailer', {
          'event_category': 'home',
        })
      }
    }
  },

  mounted() {
    this.player = new Player(this.$refs.iframe as HTMLElement);
  },

  methods: {
    onMouseEnter(line, item) {
      this.currentLineItem = line * 10 + item;
      // console.log({ currentLineItem: this.currentLineItem });
    },

    onRegisterClick() {
      // this.showRegisterPopin = true

      event('home-register', {
        'event_category': 'home',
      })
    },

    // onRegisterPopinClose() {
    //   this.showRegisterPopin = false
    // }
  }
});
