
import { defineComponent, inject } from 'vue'
import { gsap } from 'gsap'
import getOffsetTop from '@/utils/getOffsetTop'
import { Scroll } from '@/@types/scroll';
import { Viewport } from '@/store/modules/Viewport';

import StickyElement from '@/components/not-connected/StickyElement.vue';
import AppearObject from '@/components/not-connected/AppearObject.vue';

export default defineComponent({
  setup() {
    const cuePoints = []
    const nextCuePoint = 0
    const scrollVal = 0
    const oldWindowWidth = 0

    return {
      scrollVal,
      cuePoints,
      nextCuePoint,
      oldWindowWidth,
      scroll: inject('scroll') as Scroll,
    }
  },

  components: {
    StickyElement,
    AppearObject
  },

  data() {
    return {
      currentPassion: 0,
    }
  },

  mounted() {
    window.addEventListener('resize', this.onResize, { passive: true })
    gsap.ticker.add(this.onUpdate);

    this.onResize()
  },

  unmounted() {
    window.removeEventListener('resize', this.onResize)
    gsap.ticker.remove(this.onUpdate);
  },

  methods: {
    async onResize() {
      if (Viewport.isMobile && Viewport.windowWidth === this.oldWindowWidth) return
      
      this.$el.style.height = `${Viewport.windowHeight* 3}px`
      this.oldWindowWidth = Viewport.windowWidth

      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(true)
        }, 10)
      })

      const passionTop = getOffsetTop(this.$el)

      this.cuePoints = []

      for (let i = 0; i < 3; i++) {
        this.cuePoints.push(passionTop + (Viewport.windowHeight*i) - (Viewport.windowHeight/2))
      }
    },

    onUpdate() {
      this.scrollVal = this.scroll.active ? this.scroll.smoothValue : this.scroll.value

      this.cuePoints.forEach((cuePoint, i) => {
        this.nextCuePoint = this.cuePoints[i + 1] ? this.cuePoints[i + 1] : 9999999

        if (this.scrollVal > cuePoint && this.scrollVal < this.nextCuePoint && this.currentPassion !== i) {
          this.currentPassion = i
        }
      })
    }
  }
});
