
import { defineComponent, PropType } from 'vue'
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf, minLength, maxLength, minValue, maxValue, helpers } from '@vuelidate/validators'
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import ErrorPopin from '@/components/not-connected/ErrorPopin.vue'
import { gsap, Quart } from 'gsap'
import getOffsetTop from '@/utils/getOffsetTop'

interface City {
  id: number
  name: string
}

type Cities = Array<City>

interface Country {
  id: number
  name: string
  code: string
}

type Countries = Array<Country>

interface State {
  country: number
  id: number
  name: string
  abbreviation: string
  assoc_press: string
  latitude: string
  longitude: string
}

type States = Array<State>

export default defineComponent({
  setup () {
    return { v$: useVuelidate() }
  },

  components: {
    ButtonHover,
    ErrorPopin
  },

  props: {
    countries: { type: Array as PropType<Countries>, default: null },
    states: { type: Array as PropType<States>, default: null }
  },

  data() {
    return {
      isSubmit: false,
      errorRequest: false,
      cities: [],
      // workplaceCities: [],
      underage: false,

      country: null,
      state: null,
      city: '',
      cityId: '',
      address: '',
      zip: '',
      birthdayMonth: '',
      birthdayDay: '',
      birthdayYear: '',
      position: '',
      establishment: '',
      // workplaceCountry: null,
      // workplaceState: null,
      // workplaceCity: '',
      // workplaceCityId: '',
      marketing: false,
      terms: false,
      marketingGroup: false
    }
  },

  validations () {
    return {
      city: { requiredIfNotUS: requiredIf(this.country !== 230), minLength: minLength(2), maxLength: maxLength(255) },
      cityId: { requiredIfUS: requiredIf(this.country === 230) },
      address: { required, minLength: minLength(2), maxLength: maxLength(255) },
      zip: { 
        required, 
        minLength: minLength(2), 
        maxLength: helpers.withMessage(this.$t('register-form.zip-validation'), maxLength(9)),
      },
      position: { maxLength: maxLength(255) },
      establishment: { maxLength: maxLength(255) },

      birthdayMonth: { required, minLength: minLength(2), maxLength: maxLength(2), minValue: minValue(1), maxValue: maxValue(12) },
      birthdayDay: { required, minLength: minLength(2), maxLength: maxLength(2), minValue: minValue(1), maxValue: maxValue(31) },
      birthdayYear: { required, minLength: minLength(4), maxLength: maxLength(4) },

      country: { required },
      state: { required },
      // workplaceCountry: { required },
      // workplaceState: { required },
      // workplaceCity: { requiredIfUS: requiredIf(this.workplaceCountry !== 230), minLength: minLength(2), maxLength: maxLength(255) },
      // workplaceCityId: { requiredIfUS: requiredIf(this.workplaceCountry === 230) },
    }
  },

  watch: {
    countries() {
      this.country = 230
      // this.workplaceCountry = 230
    },

    country() {
      if (this.country !== 230) {
        this.state = 52
      } else {
        this.state = ''
      }

      // this.workplaceCountry = this.country
    },

    async state() {
      if (this.country === 230) {
        this.cityId = ''
        if(this.state !== '') {
          const data = await this.getCities(this.state)
          this.cities = data as Cities
        }
      }
    },

    // workplaceCountry() {
    //   if (this.workplaceCountry !== 230) {
    //     this.workplaceState = 52
    //   } else {
    //     this.workplaceState = ''
    //   }
    // },

    // async workplaceState() {
    //   if (this.workplaceCountry === 230) {
    //     this.workplaceCityId = ''
    //     if(this.workplaceState !== '') {
    //       const data = await this.getCities(this.workplaceState)
    //       this.workplaceCities = data as Cities
    //     }
    //   }
    // }
  },

  methods: {
    async onSubmit() {
      this.isSubmit = true

      const result = await this.v$.$validate()

      if (!result || !this.terms) {
        console.warn('form not valid')
        
        const firstInputError = this.$el.querySelector('.error')
        if (firstInputError) {
          const offset = getOffsetTop(firstInputError) - 20
          gsap.to(window, { duration: 0.6, scrollTo: offset, ease: Quart.easeOut })
        }

        return
      }

      const mydate = new Date();
      mydate.setFullYear(parseInt(this.birthdayYear), parseInt(this.birthdayMonth)-1, parseInt(this.birthdayDay));

      const currdate = new Date();
      currdate.setFullYear(currdate.getFullYear() - 21);

      if ((currdate.getTime() - mydate.getTime()) < 0){
        this.underage = true;

        return
      }

      this.$emit('onSubmit', {
        user: {
          address: this.address,
          city: this.city,
          city_id: this.cityId || null,
          zip: this.zip,
          birthdate: `${this.birthdayYear}-${this.birthdayMonth}-${this.birthdayDay}`,
          state: this.state,
          country: this.country,
          position_title: this.position,
          work_stablishment: this.establishment,
          // workplace_city: this.workplaceCity,
          // workplace_city_id: this.workplaceCityId || null,
          // workplace_state: this.workplaceState,
          // workplace_country: this.workplaceCountry,
          terms: this.terms,
          marketing_barsmarts: this.marketing,
          marketing_group: this.marketingGroup,
        },
      })
    },

    async getCities(state) {
      try {
        const response = await axios.get(process.env.VUE_APP_API + 'states/' + state + '/cities');
        return response.data.data
      } catch (error) {
        console.error(error);
        this.errorRequest = true
        return []
      }
    },

    onKeyUp(event: KeyboardEvent) {
      if(event.key === 'Tab' || event.key === 'Shift' || event.key === 'Meta' || event.key === 'Control' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') return
      
      const target = event.target as HTMLInputElement
      const { maxLength, value, nextElementSibling } = target;

      if (value.length >= maxLength) {
        (nextElementSibling as HTMLInputElement).focus()
      }
    },

    onKeyPress(event: KeyboardEvent) {
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      const target = event.target as HTMLInputElement
      const currentValue = target.value;
      const maxlength = parseInt(target.getAttribute('maxlength'))
      if ((currentValue.length) >= maxlength) {
        event.preventDefault();
        return false;
      }

      if ((keyCode > 31 && (keyCode < 48 || keyCode > 57)) && keyCode !== 46) {
        event.preventDefault();
      }
    }
  }
})
