import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2aec9754"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "popin",
  class: "NewWebsitePopin padded grid",
  key: 'new-website-popin'
}
const _hoisted_2 = { class: "NewWebsitePopin__Content" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "NewWebsitePopin__Button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonHover = _resolveComponent("ButtonHover")!
  const _component_FocusTrap = _resolveComponent("FocusTrap")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "popin",
    duration: 1200
  }, {
    default: _withCtx(() => [
      (_ctx.active)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_FocusTrap, { active: _ctx.active }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", {
                    class: "NewWebsitePopin__Title h1",
                    innerHTML: _ctx.$t('newWebsite.title')
                  }, null, 8, _hoisted_3),
                  _createElementVNode("div", {
                    class: "NewWebsitePopin__Text text-lg",
                    innerHTML: _ctx.$t('newWebsite.text')
                  }, null, 8, _hoisted_4),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_ButtonHover, {
                      "data-fixed": "",
                      onClick: _ctx.onClick,
                      class: "button"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('newWebsite.action')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["active"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}