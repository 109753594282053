
import { Viewport } from '@/store/modules/Viewport'
import { ComponentPublicInstance, defineComponent, inject, Ref } from "@vue/runtime-core"
import { computed } from 'vue';

import ButtonSave from '@/components/buttons/ButtonSave.vue'
import ButtonClose from '@/components/buttons/ButtonClose.vue'
import CursorFollow from '@/components/not-connected/CursorFollow.vue'
// import EventsConfirmPopin from './EventsConfirmPopin.vue'
import EventsRegisterPopin from './EventsRegisterPopin.vue'
import { Event } from "@/@types/props";
import { AppStore } from "@/store/modules/AppStore";
import { useI18n } from 'vue-i18n';
import { formatEventTime, formatEventDate } from '@/utils/EventFormatters'
import { fetchEvent } from "@/store/data-sources/event";

export default defineComponent({
  setup() {
    const event = inject('event') as Ref<Event>
    const user = AppStore.user
    const { locale } = useI18n();

    return {
      event,
      locale,
      user,
    }
  },

  components: {
    ButtonClose,
    ButtonSave,
    CursorFollow,
    // EventsConfirmPopin,
    EventsRegisterPopin,
  },

  methods: {
    onAfterEnter() {
      Viewport.isMobile && document.body.parentElement.classList.add('has-events-item-popin-open');
    },
    onAfterLeave() {
      Viewport.isMobile && document.body.parentElement.classList.remove('has-events-item-popin-open');
    },
    onGetTicket() {
      if (this.event.can_register === 'yes') {
        // (this.$refs.confirmPopin as ComponentPublicInstance<typeof EventsConfirmPopin>).open()
        (this.$refs.registerPopin as ComponentPublicInstance<typeof EventsRegisterPopin>).open()
      }
    },
    async onClose () {
      console.log('onClose', this.event.id)
      await fetchEvent(this.event.id, true)
    },
    formatTime(date){
      return formatEventTime(date, this.locale);
    },
    formatDate(date){
      return formatEventDate(date, this.locale);
    }
  }
})
