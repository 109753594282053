
import { defineComponent } from 'vue'
import ParallaxObject from '@/components/not-connected/ParallaxObject.vue'
import Testimonials from '@/components/not-connected/Testimonials.vue'
import RoundSection from '@/components/not-connected/RoundSection.vue'
import LayoutNotConnected from '@/layouts/NotConnected.vue'
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import AppearObject from '@/components/not-connected/AppearObject.vue'
import LineByLine from '@/components/not-connected/LineByLine.vue'
import Questions from '@/components/not-connected/Questions.vue'
// import RegisterPopin from '@/components/not-connected/RegisterPopin.vue'
import { event } from 'vue-gtag'

export default defineComponent({
  components: {
    LayoutNotConnected,
    ParallaxObject,
    Testimonials,
    RoundSection,
    ButtonHover,
    AppearObject,
    LineByLine,
    Questions,
    // RegisterPopin
  },

  data() {
    return {
      // showRegisterPopin: false,

      testimonials: [
        {
          text: this.$t('staff-certified.testimonial-1'), 
          name: this.$t('staff-certified.testimonial-1-name'), 
          position: this.$t('staff-certified.testimonial-1-position'),
          place: this.$t('staff-certified.testimonial-1-place'),
          picture: this.$t('staff-certified.testimonial-1-picture'),
        },
        {
          text: this.$t('staff-certified.testimonial-2'), 
          name: this.$t('staff-certified.testimonial-2-name'), 
          position: this.$t('staff-certified.testimonial-2-position'),
          place: this.$t('staff-certified.testimonial-2-place'),
          picture: this.$t('staff-certified.testimonial-2-picture'),
        },
        {
          text: this.$t('staff-certified.testimonial-3'), 
          name: this.$t('staff-certified.testimonial-3-name'), 
          position: this.$t('staff-certified.testimonial-3-position'),
          place: this.$t('staff-certified.testimonial-3-place'),
          picture: this.$t('staff-certified.testimonial-3-picture'),
        }
      ]
    }
  },

  methods: {
    onApply() {
      // this.showRegisterPopin = true

      event('staff-certified-apply', {
        'event_category': 'staff-certified',
      })
    },

    onContact() {
      console.log("onContact")
      event('staff-certified-contact', {
        'event_category': 'staff-certified',
      })
    },

    // onRegisterPopinClose() {
    //   this.showRegisterPopin = false
    // }
  }
})
