export default function getOffsetTop(el: HTMLElement):number {
  let offsetTop = 0

  do {
    offsetTop += el.offsetTop

    el = (el.offsetParent as HTMLElement)
  } while (el)

  return offsetTop
}