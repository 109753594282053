import { AppStore } from "../modules/AppStore";

import { Event, EventPage } from "@/@types/props";
import axios from "axios";
import { Viewport } from "../modules/Viewport";

export async function fetchDashboardEvents(): Promise<Array<Event> | null> {
  return axios.get(
    process.env.VUE_APP_API + 'events/dashboard', 
    {
      headers: {
        'x-token-auth': AppStore.user.token,
        'x-lang': AppStore.user?.lang,
      }
    }
  ).then((result) => {
    if (result.status === 200 && result.data.success) {
      AppStore.eventPage = parseEventPage(result.data.data)

      return parseEvents(result.data.data)
    }

    return null
  })
  .catch((error) => {
    console.warn(error);
    return null
  })
}

export async function fetchInitialEventPage(eventId = null): Promise<EventPage | null> {
  if (eventId) {
    await fetchEvent(eventId);
  }
  AppStore.eventPage = await innerFetchEvents()

  if (AppStore.eventPage) {
    if (!Viewport.isMobile && !AppStore.event) {
      AppStore.event = AppStore.eventPage.events[0]
    }
  }

  return AppStore.eventPage;
}


export async function fetchEventPage(): Promise<EventPage | null> {
  AppStore.eventPage = await innerFetchEvents()
  if (AppStore.eventPage) {
    if (!Viewport.isMobile && !AppStore.event) {
      AppStore.event = AppStore.eventPage.events[0]
    }
  }
  return AppStore.eventPage;
}

async function innerFetchEvents(): Promise<EventPage | null> {
  if (!AppStore.eventPage || !AppStore.eventPage.events || AppStore.eventPage.events.length === 0) {
    await fetchDashboardEvents()
  }

  return AppStore.eventPage 
}

export async function fetchEvent(eventId: string, forceReload: boolean = false) : Promise<Event> {
  if (!forceReload && AppStore.event && AppStore.event.id === eventId) {
    return Promise.resolve().then(() => AppStore.event)
  } else if (!forceReload && AppStore.eventPage && AppStore.eventPage?.events && AppStore.eventPage?.events?.length !== 0) {
    const event = AppStore.eventPage.events.find((event)=> event.id === eventId)
    AppStore.event = parseEvent(event)
  } else {
    await fetchDashboardEvents()
    const event = AppStore.eventPage.events.find((event)=> event.id === eventId)
    AppStore.event = parseEvent(event)
  }
}

function parseEventPage(data): EventPage | null {
  return {
    events: parseEvents(data)
  }
}

function parseEvents(items): Array<Event> {
  const events: Array<Event> = [];

  items.forEach((item) => {
    events.push(parseEvent(item))
  })

  return events
}

function parseEvent(item): Event {
  return {
    id: item.id,
    name: item.name,
    description: item.description,
    start_date: item.start_date,
    end_date: item.end_date,
    capacity: item.capacity,
    timezone: item.timezone,
    open_registration_date: item.open_registration_date,
    close_registration_date: item.close_registration_date,
    price: item.price,
    zip: item.zip,
    location: item.location,
    address: item.address,
    city: item.city,
    can_register: item.can_register,
  }
}


export async function registerToEvent(eventId: string): Promise<boolean> {
  return axios.post(
    process.env.VUE_APP_API + 'events/' + eventId + '/register', 
    {}, 
    {
      headers: {
        'x-token-auth': AppStore.user.token,
        'x-lang': AppStore.user?.lang,
      }
    }
  ).then((result) => {
    if (result.status === 200 && result.data.success) {
      const event: Event = AppStore.eventPage.events.find((event) => event.id === eventId)
      if (event) {
        event.can_register = "interested"
      }
      if (AppStore.event && AppStore.event.id === eventId) {
        AppStore.event.can_register = "interested"
      }
      return true
    }

    return false
  })
  .catch((error) => {
    console.warn(error);
    
    return false
  })
}
