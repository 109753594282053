
import { defineComponent, PropType, computed } from 'vue';
import { Course } from '@/@types/props';

export default defineComponent({
  setup(props) {
    const chapter = computed(
      () => (props.course as Course).chapters.find(v => v.status == "opened")
    )

    return {
      chapter
    }
  },

  props: {
    course: {
      type: Object as PropType<Course>,
      required: false
    }, 
    theme: {
      type: String,
      required: false
    }
  }
})

