
import { defineComponent, inject, Ref } from "@vue/runtime-core";


export default defineComponent({
  props: {
    variant: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    bottle: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
  },

  setup() {
    const zoomOn = inject('zoomOn') as Ref<boolean>
    const isRewards = inject('isRewards') as Ref<boolean>

    return {
      zoomOn,
      isRewards
    }
  }
})
