
import { defineComponent } from 'vue'
import getCookie from '@/utils/GetCookie'
import { bootstrap } from 'vue-gtag'
import ButtonHover from '@/components/not-connected/ButtonHover.vue';

let cookieDenied = false;
let hasBootstrap = false;

export default defineComponent({
  setup() {
    let intersectionObserver: IntersectionObserver 

    return {
      intersectionObserver
    }
  },

  components: {
    ButtonHover
  },

  data() {
    return {
      isActive: false,
      isOut: false,
      isIn: false,
    }
  },

  mounted() {
    const cookie = getCookie('Barsmart:cookie')

    if (cookieDenied) {
      return
    }

    if (cookie !== null) {
      if (!hasBootstrap) {
        hasBootstrap = true;
        bootstrap()
      }
      return
    }

    this.intersectionObserver = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0) {
        this.isIn = true
      }
    })

    this.intersectionObserver.observe(this.$el)
    
    this.isActive = true
  },

  unmounted() {
    this.intersectionObserver && this.intersectionObserver.unobserve(this.$el)
  },

  methods: {
    onAccept() {
      console.log('onAccept');

      const d = new Date()
      d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000))
      const expires = `expires=${d.toUTCString()}`
      document.cookie = `Barsmart:cookie=true;${expires};path=/`

      if (!hasBootstrap) {
        hasBootstrap = true;
        bootstrap()
      }

      this.isOut = true

      setTimeout( () => {
        this.isActive = false
        this.intersectionObserver && this.intersectionObserver.unobserve(this.$el)
      }, 1200)
    },

    onDeny() {
      cookieDenied = true;
      this.isOut = true

      setTimeout( () => {
        this.isActive = false
        this.intersectionObserver && this.intersectionObserver.unobserve(this.$el)
      }, 1200)
    }
  }
});
