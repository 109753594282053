import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cf774280"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "EventsCalendarItem-title" }
const _hoisted_2 = { class: "EventsCalendarItem-date h4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([`EventsCalendarItem EventsCalendarItem--size-${_ctx.size}`])
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.place), 1),
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.formattedDate[0]) + " ", 1),
      _createElementVNode("span", {
        class: _normalizeClass({'--small': _ctx.isCurrentYear === false})
      }, _toDisplayString(_ctx.formattedDate[1]), 3)
    ])
  ], 2))
}