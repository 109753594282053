import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8341d034"),n=n(),_popScopeId(),n)
const _hoisted_1 = { viewBox: "0 0 22 14" }
const _hoisted_2 = ["xlink:href"]
const _hoisted_3 = { viewBox: "0 0 22 14" }
const _hoisted_4 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.type), {
    to: _ctx.to,
    class: _normalizeClass(["ButtonArrow", ['variant--' + _ctx.variant, 'size--' + _ctx.size, 'color--' + _ctx.color, _ctx.clicked ? 'clicked' : '']]),
    onClick: _ctx.onClick
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", null, [
        (_openBlock(), _createElementBlock("svg", _hoisted_1, [
          _createElementVNode("use", {
            "xlink:href": '#i-arrow-' + _ctx.variant
          }, null, 8, _hoisted_2)
        ]))
      ]),
      _createElementVNode("span", null, [
        (_openBlock(), _createElementBlock("svg", _hoisted_3, [
          _createElementVNode("use", {
            "xlink:href": '#i-arrow-' + _ctx.variant
          }, null, 8, _hoisted_4)
        ]))
      ])
    ]),
    _: 1
  }, 8, ["to", "class", "onClick"]))
}