
import { ComponentPublicInstance, defineComponent, Ref, ref } from 'vue'

import { QuestionResponse, Test, TestResult } from '@/@types/props';

import LayoutConnected from '@/layouts/Connected.vue'
import ButtonClose from '@/components/buttons/ButtonClose.vue'

import TestQuestion from '@/components/connected/Test/TestQuestion.vue';
import TestEnd from '@/components/connected/Test/TestEnd.vue';
import TestProgress from '@/components/connected/Test/TestProgress.vue';


// import VirtualScroll from 'virtual-scroll'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin);
import lerp from '@/utils/Lerp'
import { AppStore } from '@/store/modules/AppStore';

import theme from '@/styles/_theme.json';
import { Viewport } from '@/store/modules/Viewport';
import { fetchTest } from '@/store/data-sources/course';
import { onBeforeRouteUpdate } from 'vue-router';

// import { FocusTrap, createFocusTrap } from 'focus-trap';

const getGap = () => {
  let factor = 1;
  if (window.innerWidth >= theme.breakpoints.xs) {
    factor = 0;
  }
  if (window.innerWidth >= theme.breakpoints.md) {
    factor = window.innerWidth / theme.breakpoints.vl;
  }
  if (window.innerWidth >= theme.breakpoints.vl) {
    factor = window.innerWidth / theme.breakpoints.xxl;
  }

  return 144 * factor;
};

export default defineComponent({

  // TODO Load from API
  // async setup () {
  setup() {
    // const { data: attributes } = await axios.get('user/get-profile?access-token=1');
    const test: Ref<Test> = ref(AppStore.test)

    const gap = getGap();

    const pxPerItem = window.innerHeight * 2;
    const itemHeight = ref(window.innerHeight - gap);
    const lastProgress = 0;

    const currentIndex = ref(0);

    const result: Ref<TestResult> = ref(null)

    onBeforeRouteUpdate(async (to, from, next) => {
      const t = await fetchTest(to.params?.test as string);
      AppStore.test = t;

      if (!t) {
        next({
          path: '/courses'
        })
      } else {
        next();
      }
    });

    return {
      Viewport,
      test,

      // vs: null as null | VirtualScroll,

      isScrolling: ref(false),
      isEnded: ref(false),

      currentIndex,

      // trapByIndex: [] as Array<FocusTrap>,

      scroll: {
        enabled: true,
        needReset: false,

        target: lastProgress * pxPerItem,
        current: lastProgress * pxPerItem,
        // currentRounded: 0,
        ease: 0.075,
        snapEase: 0.125,
        isSnapping: false,
        snapTimer: null as null | number,
        scrollEndTimer: null as null | number,

        pxPerItem,
      },
      itemHeight,

      // current: ref(null),
      lastY: ref(0),
      y: ref(0),

      result
    }
  },

  components: {
    LayoutConnected,
    ButtonClose,

    TestQuestion,
    TestEnd,
    TestProgress
  },

  methods: {    
    tryAgain() {
      // Fetch again to get new questions;
      this.goTo(0).add(() => {
        fetchTest(this.test.slug);
        this.test = null;
        this.result = null;
        this.test = AppStore.test;

        (this.$refs.question as Array<ComponentPublicInstance<typeof TestQuestion>>).forEach((question: ComponentPublicInstance<typeof TestQuestion>) => {
          question.reset();
        });
        (this.$refs.end as ComponentPublicInstance<typeof TestEnd>).reset();
      })
    },

    clampedY(y, index) {
      const min = this.itemHeight * -1 + ((index - 1) * this.itemHeight);
      const max = this.itemHeight + ((index + 1) * this.itemHeight);

      const clamped = Math.max(min, Math.min(max, y));
      
      return clamped * -1;
    },

    snapToClosest() {
      const destIndex = Math.round(this.scroll.target/this.scroll.pxPerItem);
      const destPx = destIndex * this.scroll.pxPerItem;
      this.scroll.target = destPx;
      this.scroll.isSnapping = true;
    },

    onResize() {
      const gap = getGap();

      this.scroll.pxPerItem = window.innerHeight * 2;
      this.itemHeight = window.innerHeight - gap;

      this.goTo(this.currentIndex, true);
      // this.clampTarget();
      // this.lastY = -1;
      // this.snapToClosest();
    },

    max() {
      return (this.test.questions.length) * this.scroll.pxPerItem;
    },

    clampTarget() {
      this.scroll.target = Math.min(Math.max(this.scroll.target, 0), this.max())
    },

    tick() {
      if (this.scroll.enabled) {

        if (this.scroll.needReset) {
          this.scroll.needReset = false
          this.scroll.target = 0
          this.scroll.current = 0
        }

        this.scroll.current = lerp(this.scroll.current, this.scroll.target, this.scroll.isSnapping ? this.scroll.snapEase : this.scroll.ease)
      }

      const y = Math.round((this.scroll.current / this.scroll.pxPerItem * this.itemHeight) * 100) / 100;
      if (this.lastY !== y) {
        this.y = y;
        this.isEnded = Math.round(this.scroll.current) >= this.max();
      }
      this.lastY = this.y;
    },

    goTo(dest: number, instant = false) {
      const timeline = gsap.timeline({onComplete: () => {
        this.scroll.enabled = true;
        this.isScrolling = false;
      }});
      if (!this.isScrolling && (dest >= 0 && dest < this.test.questions.length + 1)) {
        this.scroll.enabled = false;
        this.isScrolling = true;
  
        timeline.to(
          this.scroll, 
          { current: this.scroll.pxPerItem * dest, target: this.scroll.pxPerItem * dest, duration: instant ? 0 : 0.5, ease: 'out.quint' },
          0
        );
        timeline.to(
          this.$refs.scrollerContainer as HTMLElement,
          { scrollTo: {y: 0}, duration: instant ? 0 : 0.5, ease: 'out.quint'},
          0
        )
        timeline.add(() => {
          this.isScrolling = false;
          // this.current = this.courses[dest];
          this.currentIndex = dest;
        }, (Viewport.isMobile || instant) ? 0 : 0.66);
      }

      return timeline;
    },

    goNext(response: QuestionResponse) {
      if (response.end) {
        this.result = response.end
      }
      const dest = Math.round(this.scroll.target/this.scroll.pxPerItem) + 1;
      this.goTo(dest);
    },

    onFocus() {
      const main = (this.$refs.main as HTMLElement);
      if (main) {
        if (main.scrollTop > 0) {
          main.scrollTo(0, 0);
        }
      }
    },

    onKeyUp(event: KeyboardEvent) {
      // const target = (event.target as HTMLElement)
      const key = event.key

      if (key == 'Escape') {
        this.$router.push('/courses/');
      }
    },
  },

  mounted() {
    window.addEventListener('resize', this.onResize, {passive: true});
    gsap.ticker.add(this.tick);

    // AppStore.user.lastCourseSlug = this.test.slug;
    window.localStorage.setItem('lastCourseSlug', this.test.slug);
    window.addEventListener('keyup', this.onKeyUp, {passive: true});

    [...(this.$refs.main as HTMLElement).querySelectorAll('a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])')].forEach((focusable) => {
      focusable.addEventListener('focus', this.onFocus);
    })
  },

  unmounted() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('keyup', this.onKeyUp);

    gsap.ticker.remove(this.tick);
    if (this.scroll.snapTimer) {
      clearTimeout(this.scroll.snapTimer);
    }
    if (this.scroll.scrollEndTimer) {
      clearTimeout(this.scroll.scrollEndTimer);
    }
  }

});
