
import { AppStore } from '@/store/modules/AppStore';
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    rotation: {
      type: Number,
      required: true,
    },
    gap: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    goLeft() {
      this.$emit('go-left');
    },
    goRight() {
      this.$emit('go-right');
    }
  },

  setup() {
    return {
      themeForceDark: computed(() => AppStore.themeForceDark),
    }
  }
})

