
import Stats from 'stats.js';
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      stats: null,
      ticker: null
    }
  },

  mounted() {
    var stats = new Stats();
    stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
  
    if (this.$refs.container) {
      // (this.$refs.container as HTMLElement).appendChild( stats.dom );
    }

    this.stats = stats;
    
    // gsap.ticker.add(this.animate);
    this.animate();
  },

  unmounted() {
    // gsap.ticker.remove(this.animate);
    cancelAnimationFrame(this.ticker);
  },

  methods: {
    animate() {
      this.stats.begin();

      // monitored code goes here

      this.stats.end();

      this.ticker = requestAnimationFrame(this.animate)
    }
  }
}); 
