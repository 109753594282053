
import { ChapterSlide } from '@/@types/props'
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent ({
  props: {
    slide: {
      type: Object as PropType<ChapterSlide>,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  },

  setup() {
    return {
      answerIndex: ref(null),
      isCorrected: ref(false),
    }
  },

  methods: {
    reset() {
      this.answerIndex = null;
      this.isCorrected = false;
    },
    
    onClick(event: Event, i: number) {
      event.preventDefault();

      if ( this.answerIndex === null) {
        this.answerIndex = i;

        setTimeout(() => {
          this.showCorrection();
        }, 500);
      }
    },

    showCorrection() {
      this.isCorrected = true;
    }
  }
})
