
import { defineComponent } from 'vue'
import LayoutNotConnected from '@/layouts/NotConnected.vue'
import Collapse from '@/components/not-connected/Collapse.vue'

export default defineComponent({
  components: {
    LayoutNotConnected,
    Collapse
  },
  computed: {
    faqs() {
      return Array.from({ length: 23 }, (_, i) => i + 1)
    }
  }
});
