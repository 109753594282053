
import { defineComponent } from 'vue'
import { Viewport } from '@/store/modules/Viewport';
import { gsap } from 'gsap'

export default defineComponent({
  setup() {

    const targetX = 0
    const targetY = 0

    const x = 0
    const y = 0

    let intersectionObserver: IntersectionObserver 

    return {
      targetX,
      targetY,
      x,
      y,
      intersectionObserver
    }
  },

  data() {
    return {
      isVisible: false,
      isActive: false
    }
  },

  mounted() {
    window.addEventListener('pointermove', this.onPointerMove)
    gsap.ticker.add(this.onUpdate);

    this.intersectionObserver = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0) {
        this.x = this.targetX
        this.y = this.targetY

        this.isVisible = true
      } else {
        this.isVisible = false
      }
    })

    this.intersectionObserver.observe(this.$el)
  },

  unmounted() {
    window.removeEventListener('pointermove', this.onPointerMove)
    gsap.ticker.remove(this.onUpdate);

    this.intersectionObserver.disconnect()
  },

  methods: {
    onPointerEnter(event: PointerEvent) {
      if (Viewport.isTouch) { return }

      this.targetX = event.x
      this.targetY = event.y

      this.isActive = true
    },

    onPointerMove(event: PointerEvent) {
      if (Viewport.isTouch) { return }

      this.targetX = event.x
      this.targetY = event.y
      
    },

    onPointerLeave() {
        this.isActive = false
    },

    onUpdate() {
      if(!this.isVisible) return

      this.x += (this.targetX - this.x) * 0.1
      this.y += (this.targetY - this.y) * 0.1

      this.x = Math.round(this.x * 100) / 100;
      this.y = Math.round(this.y * 100) / 100;

      (this.$refs.cursor as HTMLElement).style.transform = `translateX(${this.x}px) translateY(${this.y}px) translateZ(0)`
    }
  }
})
