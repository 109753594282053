
import { defineComponent, inject } from 'vue'
import { gsap } from 'gsap'
import getOffsetTop from '@/utils/getOffsetTop'
import { Scroll } from '@/@types/scroll';
import { throttle } from '@/utils/throttle'

export default defineComponent({
  setup() {
    let container: HTMLElement
    let resizeObserver: ResizeObserver 
    let throttleResize

    const scrollVal = 0
    const elHeight = 0
    const top = 0
    const containerTop = 0
    const containerHeight = 0
    const triggerTop = 0
    const triggerBottom = 0
    const transform = ''

    return {
      scrollVal,
      container,
      resizeObserver,
      throttleResize,

      elHeight,
      top,
      containerTop,
      containerHeight,
      triggerTop,
      triggerBottom,
      transform,

      scroll: inject('scroll') as Scroll,
    }
  },

  props: {
    enable: { type: Boolean, default: true },
    inScrollSection: { type: Boolean, default: true },
    gapTop: { type: Number, default: 0 },
    gapBottom: { type: Number, default: 0 }
  },

  data() {
    return {
      activeClass: ''
    }
  },

  watch: {
    enable: 'onEnableChange',
    gapTop: 'onResize',
    gapBottom: 'onResize'
  },

  mounted() {
    this.container = this.getStickyContainer()

    if (!this.container) {
      return console.warn('No parent founded for this Sticky Element. This component must be a child/subchild of a js-sticky-container', this)
    }

    this.throttleResize = throttle(this.onResize, 10)
    window.addEventListener('resize', this.throttleResize, { passive: true });

    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.contentRect) {
          this.throttleResize()
        }
      })
    })

    this.resizeObserver.observe(this.container)
    // Observe the element itself in case of content update
    this.resizeObserver.observe(this.$el)

    gsap.ticker.add(this.onUpdate);

    this.onResize()
  },

  unmounted() {
    gsap.ticker.remove(this.onUpdate);
  },

  methods: {
    onEnableChange() {
      if (this.enable && this.scroll.active) {
        this.onUpdate()
      }
    },

    getStickyContainer() {
      let element = this.$el.parentNode

      while (!element.hasAttribute('data-sticky-container')) {
        element = element.parentNode
      }

      return element
    },

    async onResize() {
      await this.$nextTick()
      this.elHeight = this.$el.offsetHeight

      this.top = this.$el.offsetTop

      // Container offset
      // -------------------
      this.containerTop = getOffsetTop(this.container)
      this.containerHeight = this.container.offsetHeight

      // Trigger
      // -------------------

      const gapTop = this.gapTop as number
      const gapBottom = this.gapBottom as number

      this.triggerTop = (this.containerTop + this.top - (gapTop))
      this.triggerBottom = ((this.containerTop + this.containerHeight) - this.elHeight - gapTop - gapBottom)
    },

    onUpdate() {
      this.scrollVal = this.scroll.active ? this.scroll.smoothValue : this.scroll.value
      
      if (this.scrollVal >= this.triggerTop && this.scrollVal <= this.triggerBottom) {
        this.activeClass = 'is-sticky'

        if (this.inScrollSection) {
          this.transform = `translateY(${this.scrollVal - this.triggerTop}px) translateZ(0)`
        } else {
          this.transform = `translateY(${-this.triggerTop}px) translateZ(0)`
        }
      } else if (this.scrollVal > this.triggerBottom) {
        this.activeClass = 'is-sticky-end'

        if (this.inScrollSection) {
          this.transform = `translateY(${this.triggerBottom - this.triggerTop}px) translateZ(0)`
        } else {
          this.transform = `translateY(${-this.scrollVal + this.triggerBottom - this.triggerTop}px) translateZ(0)`
        }
      } else {
        this.activeClass = ''

        if (this.inScrollSection) {
          this.transform = 'translateY(0px) translateZ(0)'
        } else {
          this.transform = `translateY(${-this.scrollVal}px) translateZ(0)`
        }
      }

      if (!this.scroll.active) {
         this.transform = 'none'
      }

      this.$el.style.transform = this.transform
    }
  }
})
