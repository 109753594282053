
import { computed, defineComponent, Ref, ref } from 'vue'
import { AppStore } from '@/store/modules/AppStore'
import LayoutConnected from '@/layouts/Connected.vue'
import DashboardCard from '@/components/connected/Dashboard/DashboardCard.vue'
import DashboardJobs from '@/components/connected/Dashboard/DashboardJobs.vue'
import DashboardEvents from '@/components/connected/Dashboard/DashboardEvents.vue'
import DashboardMyEvents from '@/components/connected/Dashboard/DashboardMyEvents.vue'
import DashboardCertificate from '@/components/connected/Dashboard/DashboardCertificate.vue'
import DashboardCompletionProgress from '@/components/connected/Dashboard/DashboardCompletionProgress.vue'
import StickyElement from '@/components/not-connected/StickyElement.vue'
import MobileShortcuts from '@/components/connected/MobileShortcuts.vue'
import LineByLine from '@/components/not-connected/LineByLine.vue'
import AppearObject from '@/components/not-connected/AppearObject.vue'
import LazyPicture from '@/components/Global/LazyPicture.vue'
import { ChapterItem, Course, Image } from '@/@types/props'

export default defineComponent({
  setup() {
    const user = ref(AppStore.user);
    const certificates = computed(() => AppStore.user?.certifications)
    const oldCertificates = computed(() => AppStore.user?.oldCertifications)
    const hasOneCertificate = computed(() => AppStore.user?.certifications?.length > 0 || AppStore.user?.oldCertifications?.length > 0)
    const hasAllCertificates = computed(() => (AppStore.user?.certifications?.length) == 2)

    const getLastCourse = (): Course | null => AppStore.courses.length > 0 ? AppStore.courses.find((course: Course) => course.status === 'opened' && course.progress < 100 && !course.success) : null
    const currentCourse = computed(getLastCourse)
    const currentChapter = computed(
      () => {
        const course: Course | null = getLastCourse();
        return course ? course.chapters.find((chapter) => chapter.status === 'opened' && chapter.progress < 100) : null
      }
    )

    const differenceInTime = new Date().getTime() - new Date(AppStore.user.startedAt).getTime()
    const daysAgo = Math.floor(differenceInTime / (1000 * 3600 * 24));
    const hasStarted = ref(AppStore.user.startedAt && daysAgo > 0) as Ref<boolean>;

    const achievements = computed(() => AppStore.user?.achievements)

    // ALREADY CAME ?
    let alreadyCame = false;
    if (AppStore.user) {
      const alreadyCameToken = window.localStorage.getItem('already_came_'+AppStore.user.slug);
      if (alreadyCameToken) {
        const diffTime = Date.now() - parseInt(alreadyCameToken);
        if (diffTime >= 43200000) { // 0.5 DAY
          alreadyCame = true;
        } 
      } else {
        window.localStorage.setItem('already_came_'+AppStore.user.slug, ((new Date).getTime() + ''))
      }
    }

    return {
      user,
      alreadyCame,
      
      achievements,
      certificates,
      oldCertificates,
      hasOneCertificate,
      hasAllCertificates,
      hasStarted,
      currentCourse,
      currentChapter,

      daysAgo
    }
  },

  computed: {
    gapTop() {
      return 40
    },
    gapBottom() {
      return 40
    },

    courseTitle(): string {
      if (this.currentCourse) {
        const course = this.currentCourse as Course
        const title = course.type === 'course' ? course.title : this.$t('course.testTitle');
  
        return title
      }

      return '';
    },

    courseImage(): Image | null {
      if (this.currentCourse) {
        const course = this.currentCourse as Course
        const chapter = this.currentChapter as ChapterItem
        const image = course.type === 'course' ? chapter?.image : {
          url: `/images/courses/${course.category}-test.jpg`,
          double: {
            url: `/images/courses/${course.category}-test@2x.jpg`
          }
        }

        return image
      }

      return null
    }
  },

  components: {
    LayoutConnected,
    DashboardCard,
    DashboardJobs,
    DashboardEvents,
    DashboardMyEvents,
    DashboardCertificate,
    DashboardCompletionProgress,
    StickyElement,
    MobileShortcuts,
    AppearObject,
    LineByLine,
    LazyPicture
  },

  // mounted() {
  //   window.addEventListener('resize', this.onResize, { passive: true })
  //   gsap.ticker.add(this.onUpdate);

  //   this.onResize()
  // },

  // unmounted() {
  //   window.removeEventListener('resize', this.onResize)
  //   gsap.ticker.remove(this.onUpdate);
  // }
})
