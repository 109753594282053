import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, Transition as _Transition, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3cd5a7a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "MainTutorial padded grid",
  key: 'new-terms-popin'
}
const _hoisted_2 = { class: "MainTutorial__Content" }
const _hoisted_3 = {
  key: 0,
  class: "MainTutorial__Preamble"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 2,
  class: "MainTutorial__Steps"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "MainTutorial__Step__Content" }
const _hoisted_11 = { class: "MainTutorial__Step__Visual" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { key: 3 }
const _hoisted_14 = { class: "MainTutorial__Progress" }
const _hoisted_15 = { class: "MainTutorial__Button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonHover = _resolveComponent("ButtonHover")!
  const _component_ButtonArrow = _resolveComponent("ButtonArrow")!
  const _component_ButtonClose = _resolveComponent("ButtonClose")!
  const _component_FocusTrap = _resolveComponent("FocusTrap")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, {
      name: "popin",
      duration: 1200
    }, {
      default: _withCtx(() => [
        (_ctx.active)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_FocusTrap, { active: _ctx.active }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    (_ctx.first && (!_ctx.Viewport.isMobile || (_ctx.isPreamble)))
                      ? (_openBlock(), _createElementBlock("header", _hoisted_3, [
                          _createElementVNode("div", {
                            class: "MainTutorial__Title h1",
                            innerHTML: _ctx.$t('mainTutorial.welcome')
                          }, null, 8, _hoisted_4),
                          _createElementVNode("p", {
                            class: "MainTutorial__Introduction text-lg",
                            innerHTML: _ctx.$t('mainTutorial.introduction')
                          }, null, 8, _hoisted_5),
                          _createVNode(_component_ButtonHover, {
                            "aria-hidden": [!_ctx.Viewport.isMobile],
                            "data-fixed": "",
                            onClick: _ctx.onPreambleEnd,
                            class: "button button--small"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('mainTutorial.next')), 1)
                            ]),
                            _: 1
                          }, 8, ["aria-hidden", "onClick"])
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.first)
                      ? (_openBlock(), _createElementBlock("header", _hoisted_6, [
                          _createElementVNode("div", {
                            class: "MainTutorial__Title h1",
                            innerHTML: _ctx.$t('mainTutorial.title')
                          }, null, 8, _hoisted_7)
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.first || (!_ctx.Viewport.isMobile || (_ctx.first && !_ctx.isPreamble)))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createElementVNode("ul", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nbStep, (index) => {
                              return (_openBlock(), _createElementBlock("li", {
                                key: 'main-tutorial-step-' + index,
                                class: _normalizeClass(["MainTutorial__Step", { 'active': index === _ctx.step }])
                              }, [
                                _createElementVNode("div", {
                                  class: "MainTutorial__Step__Title",
                                  innerHTML: _ctx.$t('mainTutorial.stepTitle' + index)
                                }, null, 8, _hoisted_9),
                                _createElementVNode("div", _hoisted_10, [
                                  _createElementVNode("div", _hoisted_11, [
                                    (_openBlock(), _createBlock(_resolveDynamicComponent('main-tutorial-icon-'+index), {
                                      playing: index === _ctx.step
                                    }, null, 8, ["playing"]))
                                  ]),
                                  _createElementVNode("div", {
                                    class: "MainTutorial__Step__Text",
                                    innerHTML: _ctx.$t('mainTutorial.stepText' + index)
                                  }, null, 8, _hoisted_12)
                                ])
                              ], 2))
                            }), 128))
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.first || (!_ctx.Viewport.isMobile || (_ctx.first && !_ctx.isPreamble)))
                      ? (_openBlock(), _createElementBlock("footer", _hoisted_13, [
                          _createElementVNode("ul", _hoisted_14, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nbStep, (index) => {
                              return (_openBlock(), _createElementBlock("li", {
                                key: 'button-'+index,
                                class: _normalizeClass({ 'active': _ctx.step === index })
                              }, null, 2))
                            }), 128))
                          ]),
                          _createElementVNode("div", _hoisted_15, [
                            _createVNode(_component_ButtonHover, {
                              "data-fixed": "",
                              onClick: _ctx.onAction,
                              class: "button button--small"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('mainTutorial.action')), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _createVNode(_component_ButtonArrow, {
                            "data-fixed": "",
                            class: "MainTutorial__Next",
                            variant: "right",
                            onClick: _ctx.next
                          }, null, 8, ["onClick"]),
                          _createVNode(_component_ButtonArrow, {
                            "data-fixed": "",
                            class: "MainTutorial__Previous",
                            variant: "left",
                            onClick: _ctx.previous
                          }, null, 8, ["onClick"])
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.first)
                      ? (_openBlock(), _createBlock(_component_ButtonClose, {
                          key: 4,
                          "data-fixed": "",
                          class: "MainTutorial__Close",
                          variant: 'dark',
                          onClick: _ctx.close
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["active"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}