
import { defineComponent, onMounted, Ref, ref, watch} from 'vue'
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import { Reward } from '@/@types/props';
import { Rewards } from '@/store/modules/Rewards'
import Vue3Lottie from '@/components/Global/vue3-lottie.vue'
import ConfettiJSON from './lotties/confetti.json'

import { FocusTrap } from 'focus-trap-vue'



export default defineComponent({
  setup() {
    const active =  ref(false);
    const lottie = ref(null);

    const reward:Ref<Reward | null> = ref(null)
    const fromLeft = ref(false)
    
    const checkIfNeeded = () => {
      active.value = Rewards.items.length > 0
      if (active.value) {
        reward.value = Rewards.items[0]
        fromLeft.value = !fromLeft.value
      }
    }

    watch(
      () => [...Rewards.items],
      checkIfNeeded
    )

    onMounted(checkIfNeeded)

    return {
      lottie,
      active,
      reward,
      fromLeft,
      ConfettiJSON
    }
  },

  components: {
    ButtonHover,
    Vue3Lottie,
    FocusTrap
  },

  // mounted() {
  //   setTimeout(() => {
  //     addRewards([
  //       {bottle: 1}, 
  //       {bottle: 2}, 
  //       {bottle: 3}, 
  //       {bottle: 4}, 
  //       {bottle: 5}, 
  //       {bottle: 6}, 
  //       {bottle: 7}, 
  //       {bottle: 8}, 
  //       {bottle: 9}, 
  //       {bottle: 10}, 
  //       {bottle: 11}, 
  //       {bottle: 12}, 
  //       {bottle: 13}, 
  //       {bottle: 14}]
  //     )
  //   }, 1000);
  // },

  methods: {
    onClick() {
      Rewards.items.shift();
    }
  }
})
