
import { defineComponent, ref, watch } from "@vue/runtime-core"
import { MenuMobileStore } from '@/store/modules/MenuMobileStore'

import gsap from 'gsap'
import { Viewport } from "@/store/modules/Viewport";
import theme from '@/styles/_theme.json'

export default defineComponent({
  setup() {

    const line1 = ref(null);
    const line2 = ref(null);
    const line3 = ref(null);

    let timeline = null as gsap.core.Timeline | null;
    const openMenu = (instant = false): gsap.core.Timeline => {
      if (timeline) {
        timeline.kill();
      }
      timeline = gsap.timeline();

      const y = Viewport.windowWidth >= theme.breakpoints.mm ? 9 : 6

      timeline.to(
        line1.value as HTMLElement,
        { y: y, duration: instant ? 0 : 0.5, ease: 'quart.in' },
        0
      )
      timeline.to(
        line3.value as HTMLElement,
        { y: -y, duration: instant ? 0 : 0.5, ease: 'quart.in' },
        0
      )
      timeline.to(
        line2.value as HTMLElement,
        { opacity: 0, duration: 0 },
        instant ? 0 : 0.5
      )

      timeline.to(
        line1.value as HTMLElement,
        { rotate: 45, duration: instant ? 0 : 0.5, ease: 'quart.out' },
        instant ? 0 : 0.5
      )
      timeline.to(
        line3.value as HTMLElement,
        { rotate: 135, duration: instant ? 0 : 0.5, ease: 'quart.out' },
        instant ? 0 : 0.5
      )

      return timeline;
    }
    const closeMenu = (instant = false): gsap.core.Timeline => {
      if (timeline) {
        timeline.kill();
      }
      timeline = gsap.timeline();

      timeline.to(
        line1.value as HTMLElement,
        { rotate: 0, duration: instant ? 0 : 0.5, ease: 'quart.in' },
        0
      )
      timeline.to(
        line3.value as HTMLElement,
        { rotate: 0, duration: instant ? 0 : 0.5, ease: 'quart.in' },
        0
      )
      timeline.to(
        line2.value as HTMLElement,
        { opacity: 1, duration: 0 },
        instant ? 0 : 0.5
      )
      timeline.to(
        line1.value as HTMLElement,
        { y: 0, duration: instant ? 0 : 0.5, ease: 'quart.out' },
        instant ? 0 : 0.5
      )
      timeline.to(
        line3.value as HTMLElement,
        { y: 0, duration: instant ? 0 : 0.5, ease: 'quart.out' },
        instant ? 0 : 0.5
      )

      return timeline;
    }

    watch(MenuMobileStore, () => {
      if (MenuMobileStore.isOpen) {
        openMenu();
      } else {
        closeMenu();
      }
    })
    
    return {
      timeline,
      MenuMobileStore,
      line1,
      line2,
      line3,
      closeMenu,
      openMenu
    }
  },

  // watch {
  //   MenuMobileStore()
  // },

  mounted() {
    if (MenuMobileStore.isOpen) {
      this.openMenu(true);
    } else {
      this.closeMenu(true);
    }
    window.addEventListener('resize', this.onResize);
  },

  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      if (MenuMobileStore.isOpen) {
        this.openMenu(true);
      }
    },

    toggleMenu() {
      MenuMobileStore.isOpen = !MenuMobileStore.isOpen;
    }
  }
})
