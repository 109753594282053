import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Transition as _Transition, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a99ac8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ApplyPopin__Content" }
const _hoisted_2 = { class: "ApplyPopin__Category" }
const _hoisted_3 = { class: "ApplyPopin__Card" }
const _hoisted_4 = { class: "ApplyPopin__Title text-lg" }
const _hoisted_5 = { class: "text-sm" }
const _hoisted_6 = { class: "text-sm" }
const _hoisted_7 = { class: "ApplyPopin__Recruiter" }
const _hoisted_8 = {
  key: 0,
  class: "round"
}
const _hoisted_9 = ["src", "alt"]
const _hoisted_10 = { class: "text-sm" }
const _hoisted_11 = { class: "ApplyPopin__Text text-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonSave = _resolveComponent("ButtonSave")!
  const _component_ButtonClose = _resolveComponent("ButtonClose")!
  const _component_FocusTrap = _resolveComponent("FocusTrap")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, {
      name: "popin",
      duration: 1200
    }, {
      default: _withCtx(() => [
        (_ctx.active)
          ? (_openBlock(), _createElementBlock("div", {
              ref: "popin",
              class: "ApplyPopin padded grid",
              key: 'job-apply-popin-' + _ctx.job.slug
            }, [
              _createVNode(_component_FocusTrap, { active: _ctx.active }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('jobBoard.reportTitle')), 1),
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.job.title), 1),
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.job.restaurant), 1),
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.job.location), 1),
                      _createElementVNode("div", _hoisted_7, [
                        (_ctx.job.recruiter_img)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createElementVNode("img", {
                                src: _ctx.job.recruiter_img,
                                alt: _ctx.job.recruiter
                              }, null, 8, _hoisted_9)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t("dashboard.posted_by")) + " " + _toDisplayString(_ctx.job.recruiter), 1)
                      ])
                    ]),
                    _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('jobBoard.reportExplanation')), 1),
                    _createVNode(_component_ButtonSave, {
                      "data-fixed": "",
                      onClick: _ctx.onApply,
                      saved: _ctx.job.reported,
                      loading: _ctx.loading,
                      onCompletedOnce: _ctx.onCompleted
                    }, {
                      saved: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('jobBoard.reported')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('jobBoard.report')) + " ", 1)
                      ]),
                      _: 1
                    }, 8, ["onClick", "saved", "loading", "onCompletedOnce"]),
                    _createVNode(_component_ButtonClose, {
                      "data-fixed": "",
                      class: "ApplyPopin__Close",
                      onClick: _ctx.onClose,
                      variant: 'dark'
                    }, null, 8, ["onClick"])
                  ])
                ]),
                _: 1
              }, 8, ["active"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}