
import { AppStore } from "@/store/modules/AppStore"
import { defineComponent, ref } from "@vue/runtime-core"

export default defineComponent({
  setup() {
    return {
      profile: ref(AppStore.user)
    }
  },
  computed: {
    oldCertifications() {
      return AppStore.user?.oldCertifications.filter((certification) => certification.label !== 'Practical') || []
    },

    practicalCertifications() {
      return AppStore.user?.oldCertifications.filter((certification) => certification.label === 'Practical') || []
    }
  }
})
