
import { Chapter } from "@/@types/props";
import { Viewport } from "@/store/modules/Viewport";
import { defineComponent, inject, PropType, Ref, ref } from "@vue/runtime-core";
import ScrollProgressLine from './ScrollProgresLine.vue';

const __default__ = defineComponent({
  props: {
    chapter: {
      type: Object as PropType<Chapter>,
      required: true,
    },
    y: {
      type: Number,
      required: true,
    },
    itemHeight: {
      type: Number,
      required: true,
    }
  },

  setup(props) {
    // const chapter = (props.chapter as Chapter);
    const current = ref(Math.round((props.y as number) / (props.itemHeight as number)) + 1);

    const maxProgress = inject('maxProgress') as Ref<number>
    const maxIndex = inject('maxIndex') as Ref<number>

    return {
      Viewport,
      current,
      maxIndex,
      maxProgress,
    }
  },

  computed: {
    currentLength(): number {
      const cl = Viewport.isMobile ? 335 - (this.getMobileItemGap() * ((this.chapter as Chapter).slides.length + 2)) : 33;
      return cl;
    },
    progressPercent() {
      return `${-100 + Math.ceil((this.current-1) / (this.maxIndex-1) * 100) }%`;
    }
    // style() {
    //   return {
    //     '--progress': `${-100 + Math.ceil((this.current-1) / (this.maxIndex-1) * 100) }%`
    //   }
    // }
  },

  watch: {
    chapter(newChapter) {
      this.maxIndex = newChapter.progress / 100 * (newChapter.slides.length + 2);
    },
    y(newY) {
      this.current = Math.round((newY as number) / (this.itemHeight as number)) + 1; 

       if (this.current > this.maxIndex)  {
        this.maxIndex = this.current;
        const chapter = this.chapter as Chapter;
        this.maxProgress = Math.round(this.maxIndex / (chapter.slides.length + 2) * 100 * 100) / 100;
      }
    }
  },

  methods: {
    getMobileItemGap() {
      const nbSlides = ((this.chapter as Chapter).slides.length + 2);
      let gap = 7.9;
      if (nbSlides <= 31) {
        gap = 10
      }
      if (nbSlides <= 21) {
        gap = 14;
      }
      return gap;
    },
    getStart(index) {
      const start = 0 + (Viewport.isMobile ? this.getMobileItemGap() : 14) * index + (this.current - 1 < index ? this.currentLength : 0);
      return start;
    },
    getEnd(index) {
      const end = this.getStart(index) + (this.current - 1 === index ? this.currentLength : 0.1);
      return end;
    },

    reset() {
      this.maxIndex = 0;
      this.maxProgress = 0;
    }
  },

  components: {
    ScrollProgressLine
  }

})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "3089ddc1": (_ctx.progressPercent)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__