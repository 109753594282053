import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1bea17b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "GlossaryMenu__Scroller",
  ref: "scroller"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "base" }
const _hoisted_4 = ["data-letter"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(["GlossaryMenu", { 'has-header-offset': _ctx.hasMobileHeaderDeployed }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.glossary.items, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            key: 'glossary-menu-item-'+item.letter
          }, [
            _createElementVNode("button", {
              tabindex: "-1",
              type: "button",
              class: _normalizeClass(_ctx.firstUnder?.letter === item.letter ? 'active': ''),
              onClick: (event) => _ctx.onClick(event, item),
              ref_for: true,
              ref: el => { _ctx.buttons[item.letter] = el }
            }, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(item.letter), 1),
              _createElementVNode("span", {
                class: "alt",
                "data-letter": item.letter
              }, null, 8, _hoisted_4)
            ], 10, _hoisted_2)
          ]))
        }), 128))
      ])
    ], 512)
  ], 2))
}