import { Glossary, GlossaryDefinition, GlossaryItem } from "@/@types/props";
import { AppStore } from "../modules/AppStore";

import axios from "axios";
import i18n from "@/utils/i18n";

export function fetchGlossary(): Promise<Glossary | null> {
  console.log('fetchGlossary', {lang: AppStore.user?.lang})
  
  if (AppStore.glossary && AppStore.glossary.lang === AppStore.user?.lang) {
    console.log('Glossary already loaded with same lang')
    return Promise.resolve().then(() => AppStore.glossary)
  } else {
    console.log('Glossary need to load')
    return axios.get(
      process.env.VUE_APP_API + 'glossary', 
      {
        headers: {
          'x-token-auth': AppStore.user.token,
          'x-lang': AppStore.user?.lang,
        }
      }
    ).then((result) => {
      if (result.status === 200 && result.data.success) {
        AppStore.glossary = parseGlossary(result?.data?.data, AppStore.user?.lang)
  
        return AppStore.glossary
      }
  
      return null
    })
    .catch((error) => {
      console.warn(error);
      return null
    })
  }
}

function parseGlossary(data, lang: string): Glossary {
  const items = []
  data.items.forEach((i) => {
    const definitions = []
    i.definitions.forEach((d) => {
      definitions.push({
        slug: d.slug,
        title: i18n(d.title),
        text: i18n(d.text),
      })
    })
    items.push({
      letter: i.letter,
      definitions,
    })
  })
  return {
    items,
    lang
  }
}

export async function fetchGlossaryDefinition(slug:string): Promise<GlossaryDefinition | null> {
  await fetchGlossary()

  const item: GlossaryItem = AppStore.glossary.items.find(current => current.letter === slug.charAt(0))
  AppStore.glossaryDefinition = item ? item.definitions.find(definition => definition.slug === slug) : null

  return AppStore.glossaryDefinition;
}
