
import { defineComponent } from 'vue'
import ParallaxObject from '@/components/not-connected/ParallaxObject.vue'
import RoundSection from '@/components/not-connected/RoundSection.vue'
import LayoutNotConnected from '@/layouts/NotConnected.vue'
import AppearObject from '@/components/not-connected/AppearObject.vue'
import LineByLine from '@/components/not-connected/LineByLine.vue'
import Slider from '@/components/not-connected/Slider.vue'
import Questions from '@/components/not-connected/Questions.vue'
import EventsCalendarItem from '@/components/Events/EventsCalendarItem.vue'
import axios from 'axios'
import CursorFollow from '@/components/not-connected/CursorFollow.vue'
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import i18n from '@/utils/i18n'

let events = []
let download = { title: '', url: '' }

export default defineComponent({
  components: {
    LayoutNotConnected,
    ParallaxObject,
    RoundSection,
    Slider,
    AppearObject,
    LineByLine,
    Questions,
    EventsCalendarItem,
    CursorFollow,
    ButtonHover
  },

  data() {
    return {
      sliderImages: [
        {
          image: "slider-img-1",
          alt: "practical event"
        },
        {
          image: "slider-img-2",
          alt: "practical event"
        },
        {
          image: "slider-img-3",
          alt: "practical event"
        },
        {
          image: "slider-img-4",
          alt: "practical event"
        },
        {
          image: "slider-img-5",
          alt: "practical event"
        },
        {
          image: "slider-img-6",
          alt: "practical event"
        }
      ],
      events,
      download
    }
  },
  async beforeRouteEnter(to, from, next) {
    const responseEvents = await axios.get(process.env.VUE_APP_API + 'events/public')
    if (responseEvents.status === 200 && responseEvents.data.success) {
      events = responseEvents.data.data
    }

    const responsePractical = await axios.get(process.env.VUE_APP_API + 'practical')
    if (responsePractical.status === 200 && responsePractical.data.success) {
      // download = responsePractical.data.data
      if (responsePractical.data.data) {
        download.url = responsePractical.data.data?.url || null
        download.title = i18n(responsePractical.data.data?.title) || ''
      }
    }

    next()
  },
  methods: {
  }
})
