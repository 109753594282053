
import { defineComponent } from 'vue'
import { gsap } from 'gsap'
import { SplitText } from "gsap/SplitText";
import { throttle } from '@/utils/throttle'
import { Viewport } from '@/store/modules/Viewport';

gsap.registerPlugin(SplitText);

export default defineComponent({
  props: {
    debug: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    let textSplited: SplitText
    let textSplitedParent: SplitText
    let throttleResize
    const oldWindowWidth = 0

    return {
      textSplited,
      textSplitedParent,
      throttleResize,
      oldWindowWidth
    }
  },

  mounted() {    
    const text = this.$el.innerText
    const words = text.split(/\s|\n/)
    const bold = this.$el.querySelector('b')

    if (bold) {
      const wordsBold = bold.innerText.split(' ')
      let newText = ''

      words.forEach((word, i) => {
        if (wordsBold.includes(word)) {
          word = `<b>${word}</b>`
        }

        newText += word + ' '
      });

      this.$el.innerHTML = newText
    }

    this.throttleResize = throttle(this.onResize, 10)
    window.addEventListener('resize', this.throttleResize, { passive: true });

    this.onResize()
  },

  unmounted() {
    window.removeEventListener('resize', this.throttleResize)
  },

  methods: {
    async onResize(forceRefresh: boolean = false) {
      if (!forceRefresh && Viewport.windowWidth === this.oldWindowWidth) return
      
      this.oldWindowWidth = Viewport.windowWidth

      this.$el.style.height = null

      if (this.textSplited || this.textSplitedParent) {
        this.textSplitedParent.revert()
        this.textSplited.revert()
      }

      await this.$nextTick()
      
      this.textSplited = new SplitText(this.$el, { type: 'lines', linesClass: 'LineByLine LineByLine--++' });
      this.textSplitedParent = new SplitText(this.$el, { type: 'lines', linesClass: 'LineByLine-parent' });

      this.$el.style.height = this.textSplited.lines.length + 'em'
    }
  },

  watch: {
    '$i18n.locale'() {
        this.onResize(true)
    },
  }
})
