import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d9735940"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Header__left" }
const _hoisted_2 = { class: "Header__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuMobileTrigger = _resolveComponent("MenuMobileTrigger")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_MenuConnected = _resolveComponent("MenuConnected")!
  const _component_LangChooser = _resolveComponent("LangChooser")!
  const _component_AccountLink = _resolveComponent("AccountLink")!
  const _component_ButtonHover = _resolveComponent("ButtonHover")!
  const _component_MenuMobile = _resolveComponent("MenuMobile")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass([{ 'light': _ctx.MenuMobileStore.isOpen || (_ctx.theme === 'light' && !_ctx.MenuMobileStore.forceDark && !_ctx.themeForceDark), 'dark': _ctx.themeForceDark, 'animated': _ctx.animated, 'scrolled': _ctx.MenuMobileStore.isScrolled, 'scroll-up': _ctx.MenuMobileStore.isScrollingTop }, "Header grid padded"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.Viewport.isMobile)
        ? (_openBlock(), _createBlock(_component_MenuMobileTrigger, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_Logo, { connected: true }),
      (!_ctx.Viewport.isMobile)
        ? (_openBlock(), _createBlock(_component_MenuConnected, { key: 1 }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.Viewport.isMobile && _ctx.allowLang)
        ? (_openBlock(), _createBlock(_component_LangChooser, {
            key: 0,
            reload: true
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_AccountLink),
      (_ctx.user?.role === 'ReportsAdmin' || _ctx.user?.role === 'MarketAdmin' || _ctx.user?.role === 'SuperAdmin')
        ? (_openBlock(), _createBlock(_component_ButtonHover, {
            key: 1,
            href: _ctx.adminUrl,
            class: "Header__Admin button-secondary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('menu_connected.admin')), 1)
            ]),
            _: 1
          }, 8, ["href"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.Viewport.isMobile)
      ? (_openBlock(), _createBlock(_component_MenuMobile, {
          key: 0,
          items: [
        {to: '/dashboard', i18n: 'menu_connected.item1'}, 
        {to: '/courses', i18n: 'menu_connected.item2'}, 
        {to: '/glossary', i18n: 'menu_connected.item3'}, 
        {to: '/job-board', i18n: 'menu_connected.item4'},
        {to: '/events', i18n: 'menu_connected.item5'},
        {to: '/resources', i18n: 'menu_connected.item6'},
      ]
        }, null, 8, ["items"]))
      : _createCommentVNode("", true)
  ], 2))
}