<template>
    <Tutorial :active="showTutorial" @ended="tutorialEnded" :nbSteps="2">
      <template #step1>
        <TutorialStep :variant="'right'">
          <template #animation>
            <ChapterTutorialAnimation :index="1" />
          </template>
          <template #text>
            <p v-html="$t('chapter.tutorialStep1')"></p>
          </template>
        </TutorialStep>
      </template>
      <template #step2>
        <TutorialStep :variant="'left'">
          <template #animation>
            <ChapterTutorialAnimation :index="2" :variant="'reverse'" />
          </template>
          <template #text>
            <p v-html="$t('chapter.tutorialStep2')"></p>
          </template>
        </TutorialStep>
      </template>
    </Tutorial>
</template>

<script>
import { defineComponent, ref } from "@vue/runtime-core";

import Tutorial from '@/components/connected/Tutorial/Tutorial.vue'
import TutorialStep from '@/components/connected/Tutorial/TutorialStep.vue'
import ChapterTutorialAnimation from './ChapterTutorialAnimation.vue'
import { Viewport } from "@/store/modules/Viewport";
import { AppStore } from "@/store/modules/AppStore";

export default defineComponent({
  components: {
    Tutorial,
    TutorialStep,
    ChapterTutorialAnimation
  },

  setup() {
    return {
      showTutorial: ref(false),
    }
  },

  mounted() {
    // this.showTutorial = true;
    if (Viewport.isMobile && window.localStorage) {
      const token = window.localStorage.getItem('has_view_tutorial--chapter::' + AppStore.user?.slug);
      if (token !== (process.env.VUE_APP_TUTORIAL_TOKEN || 'yes')) {
        this.showTutorial = true;
      }
    }
  },

  methods: {
    tutorialEnded() {
      this.showTutorial = false;
      window.localStorage.setItem('has_view_tutorial--chapter::'+AppStore.user?.slug, (process.env.VUE_APP_TUTORIAL_TOKEN || 'yes'));
    }
  }
})
</script>