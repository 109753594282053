
import { defineComponent } from 'vue'
import RoundSection from '@/components/not-connected/RoundSection.vue'
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import AppearObject from '@/components/not-connected/AppearObject.vue'
import LineByLine from '@/components/not-connected/LineByLine.vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    cta: {
      type: Object, 
      default: null
    },
    backgroundColor: { 
      type: String, 
      default: "white" 
    }
  },
  components: {
    RoundSection,
    ButtonHover,
    AppearObject,
    LineByLine,
  },
  methods: {
    onClickCta() {
      this.$emit('onClickCta')
    },
  },
})
