import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "Upload" }
const _hoisted_2 = { class: "Upload-inner" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["name", "accept"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
        type: "button",
        class: _normalizeClass([{ 'is-active': _ctx.fileName }, "Upload-button"])
      }, _toDisplayString(_ctx.labelBtn), 3),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onRemove && _ctx.onRemove(...args))),
        type: "button",
        class: _normalizeClass([{ 'is-active': _ctx.fileName }, "Upload-remove"])
      }, null, 2)
    ]),
    _createElementVNode("p", {
      class: "Upload-instruction text",
      innerHTML: _ctx.legend
    }, null, 8, _hoisted_3),
    _createElementVNode("input", {
      ref: "input",
      onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onFileChange && _ctx.onFileChange(...args))),
      type: "file",
      name: _ctx.name,
      accept: _ctx.accept
    }, null, 40, _hoisted_4)
  ]))
}