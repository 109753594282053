
import { defineComponent } from 'vue';
import LayoutConnected from '@/layouts/Connected.vue';
import GlossaryPage from '@/components/connected/Glossary/GlossaryPage.vue';


export default defineComponent({
  components: {
    LayoutConnected,
    GlossaryPage,
  },
})
