
import { defineComponent } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, maxLength, sameAs, helpers } from '@vuelidate/validators'
import axios from 'axios'
import { gsap, Quart } from 'gsap'
import getOffsetTop from '@/utils/getOffsetTop'
import ErrorPopin from '@/components/not-connected/ErrorPopin.vue'
import ButtonHover from '@/components/not-connected/ButtonHover.vue';

export default defineComponent({
  setup () {
    return { v$: useVuelidate() }
  },

  components: {
    ErrorPopin,
    ButtonHover
  },

  props: {
    title: { type: String, default: '' },
  },

  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      emailConfirm: '',
      password: '',
      passwordConfirm: '',
      lang: this.$i18n.locale,

      isPasswordObscurated: true,
      isPasswordObscurated2: true,

      isLoading: false,
      isAlreadyExist: false,

      errorEmail: '',
      errorRequest: false,

      countries: null,
      states: null
    }
  },

  watch: {
    email() {
      if (this.isAlreadyExist) {
        this.isAlreadyExist = false
      }
    }
  },

  validations () {
    return {
      firstname: { required, minLength: minLength(2), maxLength: maxLength(255) },
      lastname: { required, minLength: minLength(2), maxLength: maxLength(255) },
      email: { 
        email: helpers.withMessage(this.$t('account.email_validation'), email), 
        required, 
        maxLength: maxLength(255)
      },
      emailConfirm: { sameAs: helpers.withMessage(this.$t('register-form.error-email-same-as'), sameAs(this.email)) },
      password: { 
        required, 
        minLength: helpers.withMessage(this.$t('account.password_validation'), minLength(8)), 
        maxLength: maxLength(255)
      },
      passwordConfirm: { 
        minLength: helpers.withMessage(this.$t('account.password_validation'), minLength(8)), 
        sameAs: helpers.withMessage(this.$t('register-form.error-password-same-as'), sameAs(this.password))
      },
    }
  },

  methods: {
    async onSubmit() {
      const result = await this.v$.$validate()

      if (!result) {
        console.warn('form not valid')

        const firstInputError = this.$el.querySelector('.error')
        if (firstInputError) {
          const offset = getOffsetTop(firstInputError) - 20
          gsap.to(window, { duration: 0.6, scrollTo: offset, ease: Quart.easeOut })
        }
        
        return
      }

      this.isLoading = true

      this.isAlreadyExist = await this.checkEmailAlreadyRegistered()

      if (this.isAlreadyExist) {
        this.isLoading = false
        return
      }

      if (!this.countries || !this.states) {
        const data = await Promise.all([
          await this.getCountries(),
          await this.getStates()
        ])

        if (!data[0] || !data[1]) {
          this.isLoading = false
          return
        }

        this.countries = data[0]
        this.states = data[1]
      }

      this.$emit('onSubmit', {
        user: {
          first_name: this.firstname,
          last_name: this.lastname,
          email: this.email,
          password: this.password,
          lang: this.lang
        }
      }, this.countries, this.states)

      this.isLoading = false
    },

    async checkEmailAlreadyRegistered() {
      try {
        const response = await axios.post(process.env.VUE_APP_API + 'programs/checkEmail/', { u: this.email });
        return !response.data.success
      } catch (error) {
        console.error(error);
        if (error.response) {
          this.errorEmail = error.response.data.msg
        } else {
          this.errorRequest = true
        }
        return true
      }
    },

    async getCountries() {
      try {
        const response = await axios.get(process.env.VUE_APP_API + 'countries');
        return response.data.data
      } catch (error) {
        console.error(error);
        this.errorRequest = true
        return null
      }
    },

    async getStates() {
      try {
        const response = await axios.get(process.env.VUE_APP_API + 'states');
        return response.data.data
      } catch (error) {
        console.error(error);
        this.errorRequest = true
        return null
      }
    },
  }
})
