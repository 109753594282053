
import { ComponentPublicInstance, defineComponent } from 'vue'
import LayoutConnected from '@/layouts/Connected.vue';
import ProfileEditPage from '@/components/connected/Profile/Edit/ProfileEditPage.vue'

export default defineComponent({

  components: {
    LayoutConnected,
    ProfileEditPage,
  },

  beforeRouteLeave(to, from, next) {
    (this.$refs.page as ComponentPublicInstance<typeof ProfileEditPage>).onBeforeRouteLeaveGuard(to, from, next)
  }
})
