
import { defineComponent } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf, email, minLength, maxLength, sameAs, helpers } from '@vuelidate/validators'
import axios from 'axios'
import ErrorPopin from '@/components/not-connected/ErrorPopin.vue'
import LeavePopin from '@/components/connected/popins/LeavePopin.vue'
import ButtonSave from '@/components/buttons/ButtonSave.vue'
import { AppStore } from '@/store/modules/AppStore'
import { updateCurrentUser } from '@/store/data-sources/user'

import gsap from 'gsap'
import getOffsetTop from '@/utils/getOffsetTop'

export default defineComponent({
  setup () {
    return { 
      v$: useVuelidate(),
    }
  },

  components: {
    ErrorPopin,
    ButtonSave,
    LeavePopin
  },

  data() {
    return {
      langs: [{slug: 'en', name: 'English'}, {slug: 'es', name: 'Spanish'}],
      allowLang: process.env.VUE_APP_DISABLE_LANG !== 'true',

      form: {
        firstname: AppStore.user.firstName,
        lastname: AppStore.user.lastName,
        email: AppStore.user.email,
        lang: AppStore.user.lang,
        preferredLang: AppStore.user.preferredLang,
        current_password: '',
        new_password: '',
        confirm_new_password: '',
        new_email: '',
        confirm_new_email: '',
      },

      isPasswordObscurated: true,
      isLoading: false,
      isAlreadyExist: false,

      errorEmail: '',
      errorMessage: null,
      errorRequest: false,

      edited: false,
      saved: false,
      showLeavePopin: false,
      preventedTo: null,
    }
  },

  watch: {
    'form.new_email'() {
      if (this.isAlreadyExist) {
        this.isAlreadyExist = false
      }
    },
    'form.confirm_new_email'() {
      if (this.isAlreadyExist) {
        this.isAlreadyExist = false
      }
    },
    'form': {
      handler() {
        this.saved = false;
        this.edited = true;
        this.errorMessage = null;
      },
      deep: true
    },
    // 'form.lang'() {
    //   this.$i18n.locale = this.form.lang;
    // }
  },

  validations () {
    return {
      form: {
        firstname: { required, minLength: minLength(1), maxLength: maxLength(255) },
        lastname: { required, minLength: minLength(1), maxLength: maxLength(255) },
        // email: { email, required, maxLength: maxLength(255) },
        lang: { requiredIf: requiredIf(this.allowLang) },
        
        current_password: { 
          minLength: helpers.withMessage(this.$t('account.password_validation'), minLength(8)), 
          maxLength: maxLength(255), 
          requiredIf: requiredIf(this.form.new_password !== '' || this.form.confirm_new_password !== '') 
        },
        new_password: { 
          minLength: helpers.withMessage(this.$t('account.password_validation'), minLength(8)), 
          maxLength: maxLength(255), 
          requiredIf: requiredIf(this.form.current_password !== '' || this.form.confirm_new_password !== ''), 
          sameAs: helpers.withMessage(this.$t('account.password_error'), sameAs(this.form.confirm_new_password)),
        },
        confirm_new_password: { 
          requiredIf: requiredIf(this.form.current_password !== '' || this.form.new_password !== ''), 
          sameAs: helpers.withMessage(this.$t('account.password_error'), sameAs(this.form.new_password)),
        },

        new_email: { 
          email: helpers.withMessage(this.$t('account.email_validation'), email), 
          requiredIf: requiredIf(this.form.confirm_new_email !== ''), 
          maxLength: maxLength(255), 
          sameAs: helpers.withMessage(this.$t('account.email_error'), sameAs(this.form.confirm_new_email)),
        },
        confirm_new_email: { 
          requiredIf: requiredIf(this.form.new_email !== ''), 
          sameAs: helpers.withMessage(this.$t('account.email_error'), sameAs(this.form.new_email)),
        },
      }
    }
  },

  methods: {
    onBeforeRouteLeaveGuard(to, from, next) {
      if (!this.saved && this.edited ) {
        this.preventedTo = to;
        next(false)
        this.showLeavePopin = true;
      } else {
        next();
      }
    },
    onLeaveAccept() {
      this.showLeavePopin = false;
      this.edited = false;
      if (this.preventedTo) {
        this.$router.push(this.preventedTo);
      }
    },
    onLeaveDecline() {
      this.showLeavePopin = false;
    },

    async onSubmit() {
      const result = await this.v$.$validate()

      if (!result) {
        console.warn('form not valid')
        this.errorMessage = this.$t('account.validation_error');

        const firstError = this.$el.querySelector('input.error, select.error');
        if (firstError) {
          gsap.to(
            [window, document.body],
            { scrollTo: {y: getOffsetTop(firstError) - 100}}
          )
        }


        return
      }

      this.isLoading = true

      const data: {
        first_name?: string
        last_name?: string
        lang: string
        email?: string
        currentPassword?: string
        newPassword?: string
      } = {
        first_name: this.form.firstname,
        last_name: this.form.lastname,
        lang: this.form.preferredLang,
      }

      if (this.form.new_email) {
        data.email = this.form.new_email
        
        this.isAlreadyExist = await this.checkEmailAlreadyRegistered()

        if (this.isAlreadyExist) {
          this.isLoading = false
          return
        }
      }
      if (this.form.current_password) {
        data.currentPassword = this.form.current_password
        data.newPassword = this.form.new_password
      }


      const response = await updateCurrentUser(data)

      this.isLoading = false
      this.saved = response === true
      this.edited = false

      if (response !== true) {
        this.errorMessage = response ? response : this.$t('account.default_error');
      }
    },

    async checkEmailAlreadyRegistered() {
      if (this.form.email !== AppStore.user?.email) {
        try {
          const response = await axios.post(process.env.VUE_APP_API + 'programs/checkEmail/', { u: this.form.email });
          return !response.data.success
        } catch (error) {
          this.errorEmail = error.response.data.msg || error
          console.error(error.response.data.msg || error);
          return true
        }
      }
    },
  }
})
