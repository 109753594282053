import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19339c39"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ProfileHero" }
const _hoisted_2 = {
  key: 1,
  class: "ProfileHero__Overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BackBar = _resolveComponent("BackBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMyProfile)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          class: "ProfileHero__Link",
          to: "/my-profile/rewards"
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_BackBar),
    (_ctx.isUser)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}