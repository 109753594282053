import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackBarTutorialAnimation1 = _resolveComponent("BackBarTutorialAnimation1")!
  const _component_TutorialStep = _resolveComponent("TutorialStep")!
  const _component_BackBarTutorialAnimation2 = _resolveComponent("BackBarTutorialAnimation2")!
  const _component_Tutorial = _resolveComponent("Tutorial")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_component_Tutorial, {
      active: _ctx.showTutorial && _ctx.isRewards,
      onEnded: _ctx.tutorialEnded,
      nbSteps: 2
    }, {
      step1: _withCtx(() => [
        _createVNode(_component_TutorialStep, null, {
          animation: _withCtx(() => [
            _createVNode(_component_BackBarTutorialAnimation1, { index: 1 })
          ]),
          text: _withCtx(() => [
            _createElementVNode("p", {
              innerHTML: _ctx.$t('backbar.tutorialStep1')
            }, null, 8, _hoisted_1)
          ]),
          _: 1
        })
      ]),
      step2: _withCtx(() => [
        _createVNode(_component_TutorialStep, null, {
          animation: _withCtx(() => [
            _createVNode(_component_BackBarTutorialAnimation2, { index: 2 })
          ]),
          text: _withCtx(() => [
            _createElementVNode("p", {
              innerHTML: _ctx.$t('backbar.tutorialStep2')
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["active", "onEnded"])
  ]))
}