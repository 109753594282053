
import { defineComponent } from 'vue'
import { Viewport } from '@/store/modules/Viewport';
import ButtonHover from '@/components/not-connected/ButtonHover.vue';

export default defineComponent({
  setup() {
    let intersectionObserver: IntersectionObserver 

    return {
      intersectionObserver,
      Viewport
    }
  },

  components: {
    ButtonHover
  },

  props: {
    active: { type: Boolean, default: false },
    title: { type: String, default: '' },
    text: { type: String, default: '' },
    back: { type: Boolean, default: false }
  },

  data() {
    return {
      isIn: false,
    }
  },

  mounted() {
    this.intersectionObserver = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0) {
        this.isIn = true
      }
    })

    this.intersectionObserver.observe(this.$el)
  },

  unmounted() {
    this.intersectionObserver.unobserve(this.$el)
  },
})
