
import { ComponentPublicInstance, defineComponent, ref } from 'vue'
import LayoutConnected from '@/layouts/Connected.vue';
import { AppStore } from '@/store/modules/AppStore';
import AccountInfo from '@/components/connected/Account/AccountInfo.vue';
import AccountGeneral from '@/components/connected/Account/AccountGeneral.vue';
import AccountOrder from '@/components/connected/Account/AccountOrder.vue';
import AppearObject from '@/components/not-connected/AppearObject.vue';
import LineByLine from '@/components/not-connected/LineByLine.vue';

import axios from 'axios';
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';

export enum ACCOUNT_NAME {
  GENERAL = 'general-info',
  ORDER   = 'order-history'
}

export default defineComponent({

  setup() {
    const currentTab = ref(0)
    const accountInfo = ref(null)
    const accountGeneral = ref(null)

    const preventLeavingWithoutSaving = (to, from, next) => {
      switch(currentTab.value) {
        case 0:
          (accountInfo.value as ComponentPublicInstance<typeof AccountInfo>).onBeforeRouteLeaveGuard(to, from, next)
          break;

        case 1:
          (accountGeneral.value as ComponentPublicInstance<typeof AccountGeneral>).onBeforeRouteLeaveGuard(to, from, next)
          break;

        default: 
          next();
          break;
      }
    }

    onBeforeRouteLeave(preventLeavingWithoutSaving)
    onBeforeRouteUpdate(preventLeavingWithoutSaving)

    return {
      currentTab,
      accountInfo,
      accountGeneral,
      preventLeavingWithoutSaving
    }
  },

  data() {
    return {
      user: AppStore.user,
      formData: {},
      currentIdx: 0,

      countries: null,
      states: null,
      email: '',

      isLoading: false,
      error: ''
    }
  },

  components: {
    AccountInfo,
    AccountGeneral,
    AccountOrder,
    LayoutConnected,
    AppearObject,
    LineByLine
    // ScrollController,
  },

  mounted() {
    const params = this.$route.params.tab;

    switch(params) {
      case ACCOUNT_NAME.GENERAL:
        this.currentTab = 1;
        break;
      case ACCOUNT_NAME.ORDER:
        this.currentTab = 2;
    }
  },

  methods: {
    onNavClick(tab) {
      if (this.currentTab !== tab) {
        switch(tab) {
          case 0:
            this.$router.push('/my-account')
            break;
          case 1:
            this.$router.push('/my-account/general-info')
            break;
          case 2:
            this.$router.push('/my-account/order-history')
            break;
        }
      }
    },

    onAccountSubmit(countries, states) {
      if (!this.countries || !this.states) {
        this.countries = countries 
        this.states = states 
      }
    },

    async sendForm() {
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

      const data = JSON.stringify(this.formData)

      try {
        // NEED TO UPDATE ENDPOINT
        const response = await axios.post(process.env.VUE_APP_API + 'users/info', data, { headers });
        return response.data
      } catch (error) {
        console.error(error.response.data.msg || error);
        this.error = error.response.data.msg || error
        return null
      }
    }
  }

})
