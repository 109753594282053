import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47442d62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Chapter__Title" }
const _hoisted_2 = {
  key: 0,
  class: "Chapter__Unpublished"
}
const _hoisted_3 = {
  class: "Chapter__Scroller__Wrapper",
  ref: "scrollerContainer"
}
const _hoisted_4 = { class: "Chapter__Scroller" }
const _hoisted_5 = { class: "Chapter__MobileScroller" }
const _hoisted_6 = ["data-slug"]
const _hoisted_7 = { class: "Chapter__MobileScroller" }
const _hoisted_8 = { class: "Chapter__MobileScroller" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonClose = _resolveComponent("ButtonClose")!
  const _component_ScrollProgress = _resolveComponent("ScrollProgress")!
  const _component_ScrollIndicator = _resolveComponent("ScrollIndicator")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_Hero = _resolveComponent("Hero")!
  const _component_End = _resolveComponent("End")!
  const _component_ChapterTutorial = _resolveComponent("ChapterTutorial")!
  const _component_LayoutConnected = _resolveComponent("LayoutConnected")!

  return (_openBlock(), _createBlock(_component_LayoutConnected, null, {
    default: _withCtx(() => [
      (_ctx.chapter)
        ? (_openBlock(), _createElementBlock("main", {
            key: 0,
            class: _normalizeClass(['Chapter', 'theme--' + _ctx.chapter.theme, _ctx.isScrolling ? 'is-scrolling' : '', (_ctx.isEnded) ? 'is-ended' : '', (_ctx.isOnStart) ? 'on-start' : '']),
            ref: "main"
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(["Chapter__Background", [_ctx.Viewport.isMobile ? _ctx.currentBackground : 'color--5']])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass(['Chapter__UI', _ctx.Viewport.isMobile ? _ctx.themeUI : (_ctx.themeForceDark ? 'dark' : 'light')])
            }, [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.chapter.title), 1),
              _createVNode(_component_ButtonClose, {
                tabindex: "0",
                class: "Chapter__Close",
                to: _ctx.chapter.courseSlug ? `/course/${ _ctx.chapter.courseSlug }` : '/courses/',
                variant: _ctx.Viewport.isMobile ? _ctx.themeUI : (_ctx.themeForceDark ? 'dark' : 'light')
              }, null, 8, ["to", "variant"]),
              _createVNode(_component_ScrollProgress, {
                class: "Chapter__Progress",
                chapter: _ctx.chapter,
                y: _ctx.y,
                itemHeight: _ctx.itemHeight
              }, null, 8, ["chapter", "y", "itemHeight"]),
              _createVNode(_component_ScrollIndicator, { class: "Chapter__Scroll" }),
              _createVNode(_component_Pagination, {
                class: "Chapter__Pagination",
                current: _ctx.currentIndexLive,
                total: _ctx.chapter.slides.length + 1
              }, null, 8, ["current", "total"]),
              (!_ctx.chapter.published)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('chapter.unpublished')), 1))
                : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("ul", _hoisted_4, [
                _createElementVNode("li", {
                  style: _normalizeStyle(_ctx.Viewport.isMobile ? '' : `transform: translateY(${_ctx.clampedY(this.y, 0)}px)`),
                  class: _normalizeClass({ 'active': _ctx.currentIndex === 0, 'previous': _ctx.currentIndex === 1 })
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_Hero, {
                      tabindex: "0",
                      "data-slide-index": 0,
                      chapter: _ctx.chapter,
                      y: _ctx.y,
                      index: 0
                    }, null, 8, ["chapter", "y"]),
                    _createElementVNode("div", {
                      class: "Chapter__MobileScroller__Left",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goLeft && _ctx.goLeft(...args)))
                    }),
                    _createElementVNode("div", {
                      class: "Chapter__MobileScroller__Right",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goRight && _ctx.goRight(...args)))
                    })
                  ])
                ], 6),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chapter.slides, (slide, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: `slide-${index}`,
                    style: _normalizeStyle(_ctx.Viewport.isMobile ? '' : `transform: translateY(${_ctx.clampedY(this.y, index + 1)}px)`),
                    class: _normalizeClass({ 'active': _ctx.currentIndex === index + 1, 'next': _ctx.currentIndex === index, 'previous': _ctx.currentIndex === index + 2 }),
                    "data-slug": slide.slug
                  }, [
                    _createElementVNode("div", _hoisted_7, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getComponentFromSlide(slide)), {
                        tabindex: "0",
                        role: "tabpanel",
                        "data-slide-index": index+1,
                        slide: slide,
                        active: _ctx.currentIndex === index + 1,
                        index: index+1,
                        ref_for: true,
                        ref: "slide"
                      }, null, 8, ["data-slide-index", "slide", "active", "index"])),
                      _createElementVNode("div", {
                        class: "Chapter__MobileScroller__Left",
                        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goLeft && _ctx.goLeft(...args)))
                      }),
                      _createElementVNode("div", {
                        class: "Chapter__MobileScroller__Right",
                        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.goRight && _ctx.goRight(...args)))
                      })
                    ])
                  ], 14, _hoisted_6))
                }), 128)),
                _createElementVNode("li", {
                  style: _normalizeStyle(_ctx.Viewport.isMobile ? '' : `transform: translateY(${_ctx.clampedY(this.y, _ctx.chapter.slides.length)}px)`),
                  class: _normalizeClass({ 'active': _ctx.currentIndex === _ctx.chapter.slides.length + 1, 'next': _ctx.currentIndex === _ctx.chapter.slides.length })
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_End, {
                      tabindex: "0",
                      role: "tabpanel",
                      "data-slide-index": _ctx.chapter.slides.length + 1,
                      chapter: _ctx.chapter,
                      active: _ctx.currentIndex === _ctx.chapter.slides.length + 1
                    }, null, 8, ["data-slide-index", "chapter", "active"]),
                    _createElementVNode("div", {
                      class: "Chapter__MobileScroller__Left",
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.goLeft && _ctx.goLeft(...args)))
                    }),
                    _createElementVNode("div", {
                      class: "Chapter__MobileScroller__Right",
                      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.goRight && _ctx.goRight(...args)))
                    })
                  ])
                ], 6)
              ])
            ], 512),
            _createVNode(_component_ChapterTutorial)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}