import { Certification, Order, Reward, User, UserProfile } from "@/@types/props";
import i18n from "@/core/i18n";
import getCookie from "@/utils/GetCookie";
import formatMediaUrl from "@/utils/mediaUrl";
import setCookie from "@/utils/SetCookie";
import axios from "axios";
import { AppStore } from "../modules/AppStore";
import sha1 from 'sha1';

/*************************
 * 
 * BEGIN - LOGIN
 * 
 /***********************/
 export function login(email: string, password: string): Promise<User | null> {
  // RESET PROPS
  AppStore.user = null;
  AppStore.courses = [];

  return axios.post(process.env.VUE_APP_API + 'login', { email, password }).then((result) => {
    if (result.status === 200 && result.data?.success && result.data?.data) {
      AppStore.needToRefreshDashboard = true;
      const token = result.data?.data?.token || ''
      AppStore.user = parseUser(result.data?.data, token)

      return AppStore.user
    } else {
      AppStore.hasError = true

      return null
    }
  })
}


export function parseCertifications(data): Array<Certification> {
  const certifications = []
  if (data) {
    const { cert_1, cert_2 } = data
    console.log('parseCertifications', { data })
    if (cert_1) {
      certifications.push({
        category: 'basics',
        url: formatMediaUrl(cert_1)
      }) 
    }
    if (cert_2) {
      certifications.push({
        category: 'professional',
        url: formatMediaUrl(cert_2)
      })
    }
  }

  return certifications
}

export function parsePastCertifications(data): Array<{
  label?: string
}> {
  const certifications = []
  if (data) {
    const { cert_adv, cert_practical, cert_smarts, cert_starts } = data
    console.log('parsePastCertifications', { data })
    if (cert_adv) {
      certifications.push({
        label: 'Advanced',
        url: formatMediaUrl(cert_adv)
      })
    }
    if (cert_practical) {
      certifications.push({
        label: 'Practical',
        url: formatMediaUrl(cert_practical)
      })
    }
    if (cert_smarts) {
      certifications.push({
        label: 'BarSmarts',
        url: formatMediaUrl(cert_smarts)
      })
    }
    if (cert_starts) {
      certifications.push({
        label: 'BarStarts',
        url: formatMediaUrl(cert_starts)
      })
    }
  }

  return certifications
}

export function parseAchievements(items: Array<string>): Array<Reward> {
  const rewards = [];

  items.forEach((bottleName) => {

    const bottleByName = {
      'profile_complete': 1,
      'job_apply': 2,
      'beginner_1stchapter': 3,
      'beginner_1stmodule': 4,
      'time_2mod1day': 5,
      'time_basics': 6,
      'time_pro': 7,
      'prog_half': 8,
      'prog_basics': 9,
      'prog_pro': 10,
      'test_perfectbasics': 11,
      'test_perfectpro': 12,
      'cert_basics': 13,
      'cert_pro': 14,
      'practical': 15,
      'absolut_ally': 17,
    }

    if (bottleByName[bottleName]) {
      rewards.push({
        bottle: bottleByName[bottleName]
      })
    } else {
      console.warn('Reward does exist yet', bottleName)
    }
  })
  
  return rewards.reverse()
}

function parseUser(data, token): User {
  // USED BY CMS
  setCookie("user-name",`${data.user?.first_name} ${data.user?.last_name}`, process.env.VUE_APP_COOKIE_DOMAIN);
  if (data.user?.roles?.name) {
    setCookie("user-rol", data.user?.roles.name, process.env.VUE_APP_COOKIE_DOMAIN);
  }
  if (data.user?.roles?.id) {
    setCookie('role', data.user?.roles.id+'-'+sha1(data.user?.roles.id+'-'+token), process.env.VUE_APP_COOKIE_DOMAIN)
  }
  // COOKIE SESSION
  setCookie('token_auth', token, process.env.VUE_APP_COOKIE_DOMAIN)


  // @IMPORTANT: Set user token to allow applications to authenticate user.
  // window.localStorage.setItem('token', token);

  // DEBUG RESET PROGRESS
  // axios.post(process.env.VUE_APP_API + 'programs/reset', {}, {headers: {'x-token-auth': token}})
  // RESET TESTS
  // axios.post(process.env.VUE_APP_API + 'programs/test/reset', {}, {headers: {'x-token-auth': token}})
  // RESET REWARDS
  // axios.post(process.env.VUE_APP_API + 'users/reset', {}, {headers: {'x-token-auth': token}})

  const certifications = parseCertifications(data.user)
  const oldCertifications = parsePastCertifications(data.user)

  const allowLang = process.env.VUE_APP_DISABLE_LANG !== 'true'

  const avatar = data.avatar ? { url: formatMediaUrl(data.avatar) } : { url: '/images/user/default-user-pic.png', double: {url: '/images/user/default-user-pic@2x.png'} }

  const params = new URLSearchParams(window.location.search)
  const forcedLang = params.has('lang') ? params.get('lang') : null;

  const user:User = {
    slug: data.userId,
    token,
    role: data.rol,

    avatar,

    firstName: data.user?.first_name,
    lastName: data.user?.last_name,
    email: data.user?.email,
    // creationDate: data.user?.createdAt,
    startedAt: data.startedAt,
    lang: forcedLang ? forcedLang : (allowLang ? data.user?.lang : 'en'),
    preferredLang: (allowLang ? data.user?.lang : 'en'),
    // lang: 'es',

    hasCompletedProfile: data.profileCompleted,
    needToRevalidateTerms: !data.user?.terms,

    address: {
      country: data.user?.country?.name,
      countryId: data.user?.country?.id,
      state: data.user?.state?.name,
      stateId: data.user?.state?.id,
      city: data.user?.city,
      cityId: data.user?.city_id,

      streetAddress: data.user?.address,
      zip: data.user?.zip,
    },
    birthday: data.user?.birthdate,
    workplace: {
      establishment: data.user?.work_stablishment,
      position: data.user?.position_title,

      // countryId: data.user?.workplace_country,
      // stateId: data.user?.workplace_state,
      // city: data.user?.workplace_city,
      // cityId: data.user?.workplace_city_id,
    },

    // oldCertifications?: Array<{
    //   label?: string
    // }>
    oldCertifications,
    certifications,

    achievements: []
  }

  // SET CURRENT LOCALE
  i18n.global.locale.value = forcedLang ? forcedLang : (user?.preferredLang || 'en');

  console.log('lang', {value: i18n.global.locale.value, forcedLang})
  // i18n.global.locale.value = 'en';

  return user
}

export async function isAuthenticated(): Promise<boolean> {
  if (process.env.VUE_APP_PREVENTLOG) {
    return Promise.resolve().then(() => false);
  } else {
    const success = await fetchCurrentUser().catch(() => false);
  
    return success;
  }
}

export async function fetchCurrentUser(): Promise<boolean> {
  const token = getCookie('token_auth');
  // const token = window.localStorage.getItem('token');

  return axios.get(
    process.env.VUE_APP_API + 'users/current', 
    {
      headers: {
        'x-token-auth': token
      }
    }
  ).then((result) => {
    if (result.status === 200 && result.data?.success && result.data?.data) {
      const data = result.data.data;
      
      AppStore.user = parseUser(data, token)

      return true
    } else {
      AppStore.hasError = true
    }

    return false
  })
}
/*************************
* 
* END - LOGIN
* 
/***********************/

/*************************
* 
* BEGIN - UPDATE
* 
/***********************/
export async function updateCurrentUser(data): Promise<boolean | string> {
  return axios.post(
    process.env.VUE_APP_API + 'users/current', 
    data,
    {
      headers: {
        'x-token-auth': AppStore.user.token
      }
    }
  ).then((result) => {
    if (result.status === 200 && result.data?.success && result.data?.data) {
      // GET REFRESHED USER

      if (data.lang && (data.lang !== AppStore.user.lang)) {
        window.location.href = location.protocol + '//' + location.host + location.pathname
      }

      return fetchCurrentUser();
    }

    return false
  }).catch((err) => {
    console.warn(err);

    return err?.response?.data?.data?.message
  })
}
/*************************
* 
* END - UPDATE
* 
/***********************/

/*************************
* 
* BEGIN - LOGOUT
* 
/***********************/

export function logout(): void {
// window.localStorage.setItem('token', null);
setCookie('token_auth', null, process.env.VUE_APP_COOKIE_DOMAIN)
AppStore.needToRefreshDashboard = true;
// AppStore.user = null;
}

/*************************
* 
* END - LOGOUT
* 
/***********************/


/*************************
* 
* BEGIN - PROFILE
* 
/***********************/
export function fetchProfile(userSlug: null): Promise<UserProfile | null> {
  const isMine = !userSlug
  const endpoint = isMine ? 'users/profile' : 'users/' + userSlug + '/profile';

  return axios.get(
    process.env.VUE_APP_API + endpoint, 
    {
      headers: {
        'x-token-auth': AppStore.user?.token,
        'x-lang': AppStore.user?.lang,
      }
    }
  )
    .then((result) => {
      if (result.status === 200 && result.data?.success) {
        const data = result.data?.data;

        if (data) {
          const certifications = [];
          data.certifs.forEach((index) => {
            let certification = null;
            if (index === '1') {
              certification = 'basics'
            } else if (index === '2') {
              certification = 'professional'
            }
            if (certification) {
              certifications.push(certification)
            }
          })
          const experiences = data.experiences ? data.experiences.filter(item => item.jobTitle || item.location || item.place || item.year) : null
          const preferences = (data.preferences) ? data.preferences : ['BarSmarts'];

          AppStore.profile = {
            firstName: isMine ? AppStore.user.firstName : data.first_name,
            lastName: isMine ? AppStore.user.lastName : data.last_name,
            email: isMine ? AppStore.user.email : data.email,

            avatar: data.avatar ? {
              url: formatMediaUrl(data.avatar),
            } : null,
            achievements: parseAchievements(data.achievements),

            surname: data.surname,
            // address: {
            //   stateId: data.state,
            //   countryId: data.country,
            //   city: data.city,
            //   cityId: data.city_id,
            // },

            socials: {
              facebook: data.facebook,
              instagram: data.instagram,
              linkedin: data.linkedin,
              tiktok: data.tiktok
            },

            aboutMe: data.aboutMe,
            preferences,
            experiences,
            answers: data.answers ? (data.answers.filter(item => item !== '').map(str => { return { text: str }})) : [],

            certifications,
            oldCertifications: data.past
          }
        } else {
          AppStore.profile = null
        }

        return AppStore.profile
      } else {
        AppStore.hasError = true

        return null;
      }
    })
    .catch((error) => {
      console.error(error)
      AppStore.hasError = true

      return null;
    })
}
/*************************
* 
* END - PROFILE
* 
/***********************/


/*************************
* 
* BEGIN - ORDERS
* 
/***********************/
export function fetchOrders(): Promise<boolean> {
  return axios.get(
    process.env.VUE_APP_API + 'users/current/orders', 
    {
      headers: {
        'x-token-auth': AppStore.user?.token,
        'x-lang': AppStore.user?.lang,
      }
    }
  ).then((result) => {
    console.log({result});

    if (result.data?.success) {
      AppStore.user.orders = parseOrders(result.data?.data)
    } else {
      return true;
    }

    return true;
  }).catch((error) => {
    console.error(error);

    return false;
  })
}

function parseOrders(data): Array<Order> {
  return data.orders
}

/*************************
* 
* END - ORDERS
* 
/***********************/