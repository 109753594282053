
import { defineComponent } from 'vue'
import axios from 'axios'
import Logo from '@/components/Header/Logo.vue'
import FormAccount from '@/components/Register/Standard/FormAccount.vue';
import FormGeneral from '@/components/Register/Staff/FormGeneral.vue';
import FormStaff from '@/components/Register/Staff/FormStaff.vue'
import ThanksPanel from '@/components/Register/ThanksPanel.vue'
import HeaderNotConnected from '@/components/Header/HeaderNotConnected.vue';
import ErrorPopin from '@/components/not-connected/ErrorPopin.vue';
import { pageview } from 'vue-gtag'

export default defineComponent({
  components: {
    Logo,
    FormAccount,
    FormGeneral,
    FormStaff,
    ThanksPanel,
    HeaderNotConnected,
    ErrorPopin
  },

  data() {
    return {
      formData: {},

      currentTab: 0,
      progression: 0,

      countries: null,
      states: null,

      isLoading: false,
      errors: null,
      errorRequest: false
    }
  },

  mounted() {
    pageview({page_path: "/staff-certified-form"+this.currentTab+1});
  },

  methods: {
    onNavKeyDown(event: KeyboardEvent) {
      if (event.key === "ArrowLeft") {
        this.prevTab()
      } else if (event.key === "ArrowRight")  {
        this.nextTab()
      }
    },

    nextTab() {
      this.currentTab++

      if (this.currentTab >= 3 || this.currentTab > this.progression) {
        this.currentTab = 0;
      }

      pageview({page_path: "/staff-certified-form"+this.currentTab+1});

      window.scrollTo(0, 0)
    },

    prevTab() {
      this.currentTab--

      if (this.currentTab < 0) {
        this.currentTab = this.progression;
      }

      this.progression = this.currentTab

      window.scrollTo(0, 0)
    },

    onNavClick(tab) {
      if (tab > this.progression) return

      this.currentTab = tab
      this.progression = tab
    },

    mergeFormData(fields) {
      for (const key in fields) {
        if (fields[key] && typeof fields[key] === 'object' && !Array.isArray(fields[key])) {
          for (const subKey in fields[key]) {
            if(!this.formData[key]) this.formData[key] = {}
            this.formData[key][subKey] =  fields[key][subKey]
          }
        } else {
          this.formData[key] = fields[key]
        }
      }
    },

    onAccountSubmit(fields, countries, states) {
      this.mergeFormData(fields)

      if (!this.countries || !this.states) {
        this.countries = countries 
        this.states = states 
      }

      this.progression = 1
      this.nextTab()
    },

    onGeneralSubmit(fields) {
      this.mergeFormData(fields)
      
      this.progression = 2
      this.nextTab()
    },

    async onStaffSubmit(fields) {
      this.isLoading = true

      this.mergeFormData(fields)

      const data = await this.sendForm()

      if (!data) {
        this.isLoading = false
        return
      }

      this.progression = 3
      pageview({page_path: "/staff-certified-confirmation"});
    },

    async sendForm() {
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

      const data = JSON.stringify(this.formData)

      try {
        const response = await axios.post(process.env.VUE_APP_API + 'users/register', data, { headers });
        return response.data
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data && error.response.data.errors) {
          this.errors = Object.values(error.response.data.errors)
        } else if (error.response && error.response.data && error.response.data.msg ) {
          this.errors = [error.response.data.msg]
        } else {
          this.errorRequest = true
        }
        return null
      }
    },
  }
});
