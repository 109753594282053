import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "Tags" }
const _hoisted_2 = { for: "tag-input" }
const _hoisted_3 = { class: "Tag__text" }
const _hoisted_4 = ["size"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonClose = _resolveComponent("ButtonClose")!

  return (_openBlock(), _createElementBlock("fieldset", _hoisted_1, [
    _createElementVNode("legend", null, _toDisplayString(_ctx.$t('profileEdit.preferences')), 1),
    _createElementVNode("label", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (item, index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: 'tag-'+index,
          class: "Tag"
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(item), 1),
          _createVNode(_component_ButtonClose, {
            class: "Tag__Button",
            onClick: () => _ctx.removeTag(item),
            variant: "dark",
            size: "small"
          }, null, 8, ["onClick"])
        ]))
      }), 128)),
      _createElementVNode("input", {
        type: "text",
        size: _ctx.inputSize,
        id: "tag-input",
        onKeydown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInputChange && _ctx.onInputChange(...args))),
        onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
        ref: "input"
      }, null, 40, _hoisted_4)
    ])
  ]))
}