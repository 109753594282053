import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppVersion = _resolveComponent("AppVersion")!
  const _component_HeaderConnected = _resolveComponent("HeaderConnected")!
  const _component_RewardPopin = _resolveComponent("RewardPopin")!
  const _component_NewTermsPopin = _resolveComponent("NewTermsPopin")!
  const _component_MainTutorial = _resolveComponent("MainTutorial")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.smoothScroll ? 'smooth-scroll' : 'smooth-scroll-proxy'), {
    "security-culling": 4,
    class: _normalizeClass(["app-layout", { 'app-layout--fullscreen': !_ctx.smoothScroll }]),
    "data-layout-fixed": !_ctx.smoothScroll
  }, {
    default: _withCtx(() => [
      (_ctx.debug && !_ctx.smoothScroll)
        ? (_openBlock(), _createBlock(_component_AppVersion, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_Transition, { name: "header" }, {
        default: _withCtx(() => [
          (_ctx.hasMenu)
            ? (_openBlock(), _createBlock(_component_HeaderConnected, {
                key: 0,
                theme: _ctx.$route.meta.headerLight ? 'light' : 'dark',
                "data-scroll-section": _ctx.smoothScroll
              }, null, 8, ["theme", "data-scroll-section"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _createVNode(_component_RewardPopin),
      _createVNode(_component_NewTermsPopin),
      _createVNode(_component_MainTutorial),
      (_ctx.smoothScroll)
        ? (_openBlock(), _createBlock(_component_Footer, {
            key: 1,
            theme: _ctx.$route.meta.footerLight ? 'light' : 'dark',
            "data-scroll-section": ""
          }, null, 8, ["theme"]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["data-layout-fixed", "class"]))
}