
import { defineComponent, ref, Ref } from 'vue';
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import EventsCalendarItem from '@/components/Events/EventsCalendarItem.vue';
import { AppStore } from '@/store/modules/AppStore';

type State = 'no-events' | 'default'

export default defineComponent({
  setup() {
    const state: Ref<State>  = ref('default');
    let events = AppStore.dashboard?.events || [];

    if (!events || events.length === 0) {
      state.value = 'no-events'
    }

    events = events.slice(0, 5);

    return {
      state,
      events
    }
  },

  components: {
    EventsCalendarItem,
    ButtonHover
  }
})

