
import { defineComponent, PropType } from "@vue/runtime-core";

import LangChooser from './LangChooser.vue';
import { MenuMobileStore } from '@/store/modules/MenuMobileStore';
import { Viewport } from '@/store/modules/Viewport';
import { pageview } from 'vue-gtag'

import gsap from 'gsap';
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

export default defineComponent({
  props: {
    items: {
      type: Object as PropType<Array<{to?: string; href?: string; i18n: string; class?: string}>>
    }
  },
  setup() {
    return {
      lastScrollPosition: 0,
      MenuMobileStore,
      Viewport,
      allowLang: process.env.VUE_APP_DISABLE_LANG !== 'true'
    }
  },
  components: {
    LangChooser
  },

  mounted() {
    if (MenuMobileStore.isOpen) {
      this.onEnter(null, true).add(() => {
        MenuMobileStore.isOpen = false;
      });
    }
  },

  methods: {
    onClickItem(e: Event) {
      const link = (e.target as HTMLElement).tagName === 'A' ? e.target as HTMLElement : (e.target as HTMLElement).closest('a');
      if (link.classList.contains('router-link-exact-active')) {
        MenuMobileStore.isOpen = false;
      }
    },

    onEnter(done, instant = false) {
      const _background = this.$refs.background as HTMLElement;
      const _inner = this.$refs.inner as HTMLElement;
      const _veil = this.$refs.veil as HTMLElement;

      const timeline = gsap.timeline({
        onComplete: done
      });

      timeline.add(() => {
        this.lastScrollPosition = window.scrollY;
        (document.body.parentNode as HTMLElement).classList.add('menu-mobile-open');
      }, instant ? 0 : 1.2)

      timeline.fromTo(
        _veil,
        {
          opacity: 0
        },
        {
          opacity: 1,
          duration: instant ? 0 : 0.5,
          ease: 'Quart.easeOut'
        },
        0
      )

      timeline.fromTo(
        _background, 
        {
          opacity: 0,
          scale: 0.9,
          transformOrigin: '50% 100%',
          clipPath: 'inset(0rem 0rem 0px round 1.5rem 1.5rem 0px 0px)',
        }, 
        {
          opacity: 1,
          scale: 1,
          clipPath: 'inset(0rem 0rem 0px round 0px 0px 0px 0px)',
          duration: instant ? 0 : 1.2,
          ease: 'Quart.easeInOut'
        },
        0
      )
      timeline.fromTo(
        _inner,
        {
          opacity: 0,
          y: '1rem'
        },
        {
          opacity: 1,
          y: 0,
          duration: instant ? 0 : 1.2,
          ease: 'Quart.easeInOut'
        },
        0
      )

      pageview({page_path: "/menu"});

      return timeline;
    },
    onLeave(el, done) {
      (document.body.parentNode as HTMLElement).classList.remove('menu-mobile-open');
      

      const timeline = gsap.timeline({
        onComplete: done
      });
      timeline.set(
        [window, document.body],
        {
          scrollTo: { y: this.lastScrollPosition },
          onComplete: () => {
            MenuMobileStore.isScrollingTop = true;
            MenuMobileStore.lastScroll = this.lastScrollPosition;
          }
        },
        0
      )


      timeline.to(
        el,
        {
          opacity: 0,
          duration: 0.5,
          ease: 'Quart.out'
        },
        0
      )

      return timeline;
    }
  }
})
