
import { Glossary, GlossaryDefinition } from "@/@types/props"
import { defineComponent, PropType } from "@vue/runtime-core"
import ButtonClose from '@/components/buttons/ButtonClose.vue'
import gsap from 'gsap'
import { Viewport } from "@/store/modules/Viewport"

import LineByLine from '@/components/not-connected/LineByLine.vue';
import AppearObject from '@/components/not-connected/AppearObject.vue';
import { FocusTrap } from 'focus-trap-vue'

export default defineComponent({
  props: {
    glossary: {
      type: Object as PropType<Glossary>,
      required: false
    },
    selectedDefinition: {
      type: Object as PropType<GlossaryDefinition>,
      required: false
    },
  },

  setup() {
    return {
      lastScrollY: 0,
    }
  },

  components: {
    ButtonClose,
    LineByLine,
    AppearObject,
    FocusTrap
  },

  mounted() {
    if (this.selectedDefinition) {
      document.body.parentElement.classList.add('has-detail-open');
    }
  },
  unmounted() {
    document.body.parentElement.classList.remove('has-detail-open');
  },

  // watch: {
  //   selectedDefinition() {
  //     console.log('selectedDefinition', this.selectedDefinition)
  //     if (this.selectedDefinition) {

  //     } else {

  //     }
  //   }
  // },

  methods: {
    async onEnter(el, done, instant = false) {
      const _background = (el as HTMLElement).querySelector('.GlossaryDetail__Background');
      const _inner = (el as HTMLElement).querySelector('.GlossaryDetail__Inner');

      console.log('window.scrollY', window.scrollY);
      this.lastScrollY = window.scrollY;

      const timeline = gsap.timeline(
        Viewport.isMobile ? {
          onComplete:() => {
            document.body.parentElement.classList.add('has-detail-open');
          }
        } : {
          onStart: () => {
            document.body.parentElement.classList.add('has-detail-open');
          }
        });

      timeline.set(
        el,
        { zIndex: 1 },
        0
      );

      timeline.fromTo(
        el, 
        {
          scale: 0.95,
          xPercent: Viewport.isMobile ? 0 : 100,
          yPercent: Viewport.isMobile ? 100 : 0,
          transformOrigin: Viewport.isMobile ? '50% 100%' : '100% 50%',
        }, 
        {
          scale: 1,
          xPercent: 0,
          yPercent: 0,
          clearProps: 'all',
          duration: instant ? 0 : 1.2,
          ease: 'Quart.easeInOut'
        },
        0
      )
      timeline.fromTo(
        _background,
        {
          clipPath: Viewport.isMobile ? 'inset(0rem 0rem 0px round 1.5rem 1.5rem 0px 0px)' : 'inset(0rem 0rem 0px round 1.5rem 0px 0px 1.5rem)',
        },
        {
          clipPath: 'inset(0rem 0rem 0px round 0px 0px 0px 0px)',
          duration: instant ? 0 : 1.2,
          ease: 'Quart.easeIn'
        },
        0
      )
      timeline.fromTo(
        _inner,
        {
          xPercent: Viewport.isMobile ? 0 : -33,
          yPercent: Viewport.isMobile ? -33 : 0,
        },
        {
          xPercent: 0,
          yPercent: 0,
          duration: instant ? 0 : 1.2,
          ease: 'Quart.easeInOut'
        },
        0
      )

      await this.$nextTick()
      done();

      return timeline;
    },
    onLeave(el, done, instant = false) {
      const timeline = gsap.timeline({
        onStart: () => {
          document.body.parentElement.classList.remove('has-detail-open');
          if (Viewport.isMobile && this.lastScrollY) {
            console.log('reset scroll');
            gsap.set(window, { scrollTo: {y: this.lastScrollY}});
            this.lastScrollY = 0;
          }
        },
        onComplete: () => {
          done && done()
        }
      });

      if (el) {
        const _background = (el as HTMLElement).querySelector('.GlossaryDetail__Background');
        const _inner = (el as HTMLElement).querySelector('.GlossaryDetail__Inner');

        timeline.set(
          el,
          { zIndex: 0 },
          0
        );
        timeline.to(
          el,
          {
            xPercent: Viewport.isMobile ? 0 : 50,
            yPercent: Viewport.isMobile ? 50 : 0,
            scale: 0.9,
            duration: instant ? 0 : 0.6,
            ease: 'Quart.inOut'
          },
          0
        )
        timeline.to(
          el,
          {
            opacity: 0,
            duration: instant ? 0 : 0.4,
            ease: 'Quart.in'
          },
          instant ? 0 : 0.2
        )
        timeline.to(
        _inner,
        {
          xPercent: Viewport.isMobile ? 0 : -16,
          yPercent: Viewport.isMobile ? -16 : 0,
          duration: instant ? 0 : 0.6,
          ease: 'Quart.inOut'
        },
        0
      )
        timeline.to(
          _background,
          {
            clipPath: Viewport.isMobile ? 'inset(0rem 0rem 0px round 1.5rem 1.5rem 0px 0px)' : 'inset(0rem 0rem 0px round 1.5rem 0px 0px 1.5rem)',
            duration: instant ? 0 : 0.6,
            ease: 'Quart.inOut'
          },
          0
        )
      }

      return timeline;
    }
  }
})
