
import { ChapterItem as Chapter, Course } from '@/@types/props'
import { computed, defineComponent, PropType, ref } from 'vue'
import ChapterItem from './ChapterItem.vue'
import ButtonClose from '@/components/buttons/ButtonClose.vue'

import VirtualScroll from 'virtual-scroll'
import gsap from 'gsap'
import lerp from '@/utils/Lerp'
import { AppStore } from '@/store/modules/AppStore'
import { Viewport } from '@/store/modules/Viewport'
import { FocusTrap } from 'focus-trap-vue'
import getOffsetTop from "@/utils/getOffsetTop";

export default defineComponent({
  props: {
    chapters: {
      type: Object as PropType<Array<Chapter>>,
      required: true,
    },
    course: {
      type: Object as PropType<Course>,
      required: true,
    },
    state: {
      type: String,
      required: true
    }
  },

  setup(props) {

    return {
      isTouch: ref(Viewport.isTouch),

      vs: null as null | VirtualScroll,

      courseTitle: computed(() => {
        return ((props.course as Course).title as string).replace('<br/>', '').replace('<br>', '');
      }),

      scroll: {
        enabled: true,
        needReset: false,

        target: 0,
        ease: 0.075,

        max: 0,
        leaveTimeline: null as null | gsap.core.Timeline
      },

      current: ref(0),
      currentRounded: ref(0),

      themeForceDark: computed(() => AppStore.themeForceDark)
    }
  },

  components: {
    ChapterItem,
    ButtonClose,
    FocusTrap
  },

  mounted() {
    this.vs = new VirtualScroll({
      passive: true,
      useKeyboard: false,
    })

    if (this.isTouch) {
      console.log('need to scroll native');
      // this.vs.on(this.onVirtualScroll)
    } else {
      this.vs.on(this.onVirtualScroll)
    }
    
    window.addEventListener('resize', this.onResize, {passive: true});
    window.addEventListener('keyup', this.onKeyUp, {passive: true});
    this.onResize();

    gsap.ticker.add(this.tick);
  },

  unmounted() {
    if (this.vs) {
      this.vs.off(this.onVirtualScroll)
      this.vs.destroy();
    }

    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('keyup', this.onKeyUp);

    gsap.ticker.remove(this.tick);
  },

  methods: {
    onVirtualScroll(event) {
      if (this.state === 'course' && this.scroll.enabled && !AppStore.showMainTutorial) {
        const delta = event.deltaY * -1
        this.scroll.target += delta

        this.clampTarget()
      }
    },

    onResize() {
      if (this.$refs.list && this.$refs.scroller) {
        const max = Math.max(0, (this.$refs.list as HTMLElement).offsetHeight - (this.$refs.scroller as HTMLElement).offsetHeight);
        this.scroll.max = max;

        this.clampTarget();
      }
    },

    clampTarget() {
      this.scroll.target = Math.min(Math.max(this.scroll.target, 0), this.scroll.max)
    },

    onBeforeEnter() {
      this.scroll.enabled = true;
    },

    onAfterEnter() {
      this.onResize();
    },

    onAfterLeave() {
      this.scroll.enabled = false;
      this.scroll.needReset = true;
    },

    tick() {
      // TODO
      if (this.scroll.enabled) {

        if (this.scroll.needReset) {
          this.scroll.needReset = false
          this.scroll.target = 0
          this.current = 0
          this.currentRounded = 0
        }

        this.current = lerp(this.current, this.scroll.target, this.scroll.ease);
        this.currentRounded = Math.round(this.current * 100) / 100;
      }
    },

    onFocusItem() {
      const scroller = (this.$refs.scroller as HTMLElement);
      if (scroller.scrollTop > 0) {
        scroller.scrollTo(0, 0);
      }
    },

    onKeyUp(event: KeyboardEvent) {
      const target = (event.target as HTMLElement)
      const key = event.key

      if (key === 'Tab' && target) {
        const isChapterItem = target.hasAttribute('data-chapter-item');
        if (!isChapterItem) { return }

        const offsetTop = getOffsetTop(target)
        const scrollerHeight = (this.$refs.scroller as HTMLElement).offsetHeight;

        if (offsetTop > this.scroll.target + (scrollerHeight / 2) || offsetTop < this.scroll.target) {
          let targetScroll = offsetTop - (scrollerHeight / 2) + target.offsetHeight / 2
          
          this.scroll.target = targetScroll
          this.clampTarget();
        }
      }
      if (key == 'Escape') {
        this.$router.push('/courses');
      }
    },
  },
})

