import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-226a822f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ProfileAboutMe" }
const _hoisted_2 = { class: "ProfileAboutMe__Title Profile__h2" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('profile.about')), 1),
    _createElementVNode("div", {
      class: "ProfileAboutMe__Text",
      innerHTML: _ctx.profile.aboutMe
    }, null, 8, _hoisted_3)
  ]))
}