import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b9c08f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Header__left" }
const _hoisted_2 = { class: "Header__center" }
const _hoisted_3 = {
  key: 0,
  class: "Header__right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuMobileTrigger = _resolveComponent("MenuMobileTrigger")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_MenuNotConnected = _resolveComponent("MenuNotConnected")!
  const _component_LangChooser = _resolveComponent("LangChooser")!
  const _component_ButtonHover = _resolveComponent("ButtonHover")!
  const _component_MenuMobile = _resolveComponent("MenuMobile")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass([{ 'light': _ctx.theme === 'light', 'register': _ctx.register, 'scrolled': _ctx.isScrolled === true }, "Header grid padded"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.Viewport.isMobile)
        ? (_openBlock(), _createBlock(_component_MenuMobileTrigger, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_Logo, { class: "Header__logo" })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MenuNotConnected)
    ]),
    (!_ctx.register)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.allowLang)
            ? (_openBlock(), _createBlock(_component_LangChooser, {
                key: 0,
                class: "Header__right-langs"
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ButtonHover, {
            to: "/login",
            class: "Header__right-btn button"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('menu_not_connected.login')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.Viewport.isMobile)
      ? (_openBlock(), _createBlock(_component_MenuMobile, {
          key: 1,
          items: [
        {to: '/', i18n: 'menu_not_connected.program'}, 
        {to: '/register', i18n: 'menu_not_connected.register'}, 
        {to: '/team', i18n: 'menu_not_connected.staff-certified'}, 
        {to: '/practical', i18n: 'menu_not_connected.practical'}, 
      ]
        }, null, 8, ["items"]))
      : _createCommentVNode("", true)
  ], 2))
}