
import { Course } from '@/@types/props'
import { defineComponent, inject, PropType, ref } from 'vue'
import Progress from './Progress.vue'
import LazyPicture from '@/components/Global/LazyPicture.vue'

import gsap from 'gsap'
import SplitText from "gsap/SplitText"
import { Scroll } from '@/@types/scroll'
import sleep from '@/utils/Sleep'
import { breaks } from '@/utils/text'

const options = {
  scale: {
    ease: gsap.parseEase('power1.in'),
    // factor: 0.05,
    factor: 0.1,
    max: 3,
  }
}

export default defineComponent({
  props: {
    courses: {
      type: Object as PropType<Array<Course>>,
      required: true,
    },
    course: {
      type: Object as PropType<Course>,
      required: true,
    },
    current: {
      type: Object as PropType<Course>,
      required: true,
    },
    gap: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    rotation: {
      type: Number,
      required: true,
    },
    isScrolling: Boolean,
    hidden: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {
      scale: ref(0),
      splits: [],

      isAppear: ref(false),
      isShown: ref(false),

      intersectionObserver: null as IntersectionObserver | null,
      io: null as IntersectionObserver | null,

      scroll: inject('scroll') as Scroll,
      ratio: 0.3,
      once: true,
      
    }
  },

  components: {
    Progress,
    LazyPicture
  },
  
  computed: {
    lockedMessage() {
      const course: Course = this.course as Course
      return this.$t(`course.${course.category}_${course.type}_locked`)
    },
    tryMessage() {
      const course: Course = this.course as Course
      return this.$t(`course.${course.category}_testTry`)
    },

    readTime() {
      let time = ''
      const course = this.course as Course
      if (course.duration < 60) {
        time = this.$t('course.minutes', { duration: course.duration })
      } else {
        const hours = Math.round(course.duration / 60 * 10) / 10;
        if (hours < 1 || hours >= 2) {
          time = this.$t('course.hours', { duration: Math.round(hours) })
        } else {
          time = this.$t('course.hour', { duration: Math.round(hours) })
        }
      }

      return time
    },

    itemTitle() {
      const course = this.course as Course
      const title = course.type === 'course' ? course.title : this.$t('course.testTitle');

      return breaks(title);
    },

    itemImage() {
      const course = this.course as Course
      const image = course.type === 'course' ? course.image : {
        url: `/images/courses/${course.category}-test.jpg`,
        double: {
          url: `/images/courses/${course.category}-test@2x.jpg`
        }
      }

      return image
    }
  },

  async mounted() {
    gsap.ticker.add(this.tick);

    gsap.registerPlugin(SplitText)

    this.split();
    window.addEventListener('resize', this.split);
    
    // this.initVisualIntersectionObserver();

    await this.$nextTick();
    
    if (!this.scroll.active) {
      this.isAppear = true
      return
    }
    this.intersectionObserver = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > this.ratio) {
        
        this.isAppear = true

        if (this.once) {
          this.intersectionObserver.unobserve(this.$el)
        }
      } else if (!this.once) {
        this.isAppear = false
      }
    }, { threshold: [this.ratio as number] })

    this.intersectionObserver.observe(this.$el)


    sleep(600).then(() => {
      this.isShown = true;  
    })
  },

  unmounted() {
    gsap.ticker.remove(this.tick);
    window.removeEventListener('resize', this.split);

    this.intersectionObserver && this.intersectionObserver.disconnect();
    this.io && this.io.disconnect();
  },

  methods: {
    // initVisualIntersectionObserver() {
    //   this.io = new IntersectionObserver((entries) => {
    //     if (entries[0].intersectionRatio > 0) {
    //       this.$el.style.visibility = 'visible';
    //     } else {
    //       this.$el.style.visibility = '';
    //     }
    //   }, { threshold: [0] })

    //   this.io.observe(this.$refs.visual as HTMLElement)
    // },

    split() {
      this.splits.forEach(split => {
        split.revert();
      });

      this.splits = [];

      this.splits.push(new SplitText(
        this.$refs.title as HTMLElement, 
        { type: 'lines', linesClass: 'subline' }
      ));
      this.splits.push(new SplitText(
        this.$refs.title as HTMLElement,
        { type: 'lines', linesClass: 'line' }
      ));
    },

    calculateScale() {
      const progress = Math.round(Math.abs((this.gap as number) * (this.index as number) - (this.rotation as number)) / ((this.gap as number) * 2) * 1000) / 1000;
      const easedProgress = Math.min(options.scale.max, options.scale.ease(progress));
      const scale = 1 + easedProgress * options.scale.factor;

      return scale;
    },
    
    tick() {
      this.scale = this.calculateScale();      
    }
  }
})

