
import { defineComponent } from "@vue/runtime-core"
import gsap, { Back } from 'gsap'

export default defineComponent({
  props: {
    playing: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      timeline: null as gsap.core.Timeline | null
    }
  },

  watch: {
    playing() {
      if (this.timeline) {
        if (this.playing) {
          this.timeline.play(0);
        } else {
          this.timeline.pause();
        }
      }
    }
  },

  mounted() {
    const _items = [...this.$el.querySelectorAll('.item')];
    const _groups = [...this.$el.querySelectorAll('.group')];
    const _rects = [...this.$el.querySelectorAll('rect')];

    this.timeline = gsap.timeline({ repeat: -1, repeatDelay: 0.5, pause: !this.playing })
      .to(
        _rects,
        { transformOrigin: '50% 50%', immediateRender: true }
      )
      .set(
        [_items[2], _items[5], _items[8]],
        { y: -12 },
        0
      )
      .set(
        [_items[0], _items[3], _items[6]],
        { y: 0 },
        0
      )
      .set(
        [_items[1], _items[4], _items[7]],
        { y: 12 },
        0
      )
      .set(
        [_rects[0], _rects[1]],
        { scale: 0.8 },
        0
      )

      .fromTo(
        _items[2],
        { opacity: 1, duration: 0.6, ease: 'quad.out' },
        { opacity: 0.7 },
        0
      )
      .fromTo(
        [_items[0], _items[1]],
        { opacity: 0.3, duration: 0.6, ease: 'quad.out' },
        { opacity: 0.7 },
        0
      )

      .fromTo(
        [_rects[0], _rects[1]],
        { scale: 0.8 },
        { scale: 0.7, duration: 1, ease: 'quart.inOut' },
        0
      )
      .fromTo(
        _rects[2],
        { scale: 1 },
        { scale: 0.7, duration: 1, ease: 'quart.inOut' },
        0
      )

      .fromTo(
        _groups[0],
        { y: 0 },
        { y: -36, duration: 1, ease: Back.easeIn.config(2) },
        0.4
      )
      .to(
        _groups[0],
        { y: -72, duration: 1, ease: Back.easeOut.config(2) },
        1.4
      )
      .to(
        [_items[2], _items[0], _items[1]],
        { opacity: 0, duration: 0.3, ease: 'quad.out', stagger: 0.1 },
        1.2
      )


      .fromTo(
        _groups[1],
        { y: 36 },
        { y: 0, duration: 1, ease: Back.easeIn.config(2) },
        0.4
      )
      .to(
        _groups[1],
        { y: -36, duration: 1, ease: Back.easeOut.config(2) },
        1.4
      )
      .set(
        [_rects[3], _rects[4], _rects[5]],
        { scale: 0.7},
        0
      )
      .fromTo(
        [_items[5], _items[3], _items[4]],
        { opacity: 0 },
        { opacity: 0.7, duration: 0.2, ease: 'quad.out', stagger: 0.1 },
        1.2
      )
      .to(
        [_items[5], _items[3], _items[4]],
        { opacity: 0, duration: 0.2, ease: 'quad.out', stagger: 0.1 },
        1.6
      )


      .fromTo(
        _groups[2],
        { y: 72 },
        { y: 36, duration: 1, ease: Back.easeIn.config(2) },
        0.4
      )
      .to(
        _groups[2],
        { y: 0, duration: 1, ease: Back.easeOut.config(2) },
        1.4
      )

      .fromTo(
        [_items[8], _items[6], _items[7]],
        { opacity: 0 },
        { opacity: 0.7, duration: 0.3, ease: 'quad.out', stagger: 0.1 },
        1.4
      )

      .fromTo(
        [_rects[6], _rects[7]],
        { scale: 0.7 },
        { scale: 0.8, duration: 1, ease: 'quart.inOut' },
        1.7
      )
      .fromTo(
        _rects[8],
        { scale: 0.7 },
        { scale: 1, duration: 1, ease: 'quart.inOut' },
        1.7
      )

      .to(
        _items[8],
        { opacity: 1, duration: 0.6, ease: 'quad.out' },
        2.1
      )
      .to(
        [_items[6], _items[7]],
        { opacity: 0.3, duration: 0.6, ease: 'quad.out' },
        2.1
      )
      
  },

  unmounted() {
    this.timeline && this.timeline.kill()
  }
})
