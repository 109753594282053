
import { defineComponent, inject, Ref } from "@vue/runtime-core";

import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import ButtonArrow from '@/components/buttons/ButtonArrow.vue';
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const isRewards = inject('isRewards') as Ref<boolean>
    const isZoomIn = inject('isZoomIn') as Ref<boolean>
    const currentBottle = inject('currentBottle') as Ref<number>

    const router = useRouter();

    return {
      router,

      isRewards,
      isZoomIn,
      currentBottle
    }
  },

  components: {
    ButtonHover,
    ButtonArrow
  },

  methods: {
    onClose() {
      this.router.back();
    },

    onZoomOut() {
      this.$emit('onZoomOut');
    },

    onPrevious() {
      this.$emit('onPrevious');
    },

    onNext() {
      this.$emit('onNext');
    },
  }
})
