
import { defineComponent, PropType } from "@vue/runtime-core"
import { GlossaryItemExtended } from "./GlossaryPage.vue";

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<GlossaryItemExtended>,
      required: true
    }
  },

  setup() {
    return {
      observer: null as IntersectionObserver | null,
    }
  },

  mounted() {
    this.observer = new IntersectionObserver((entries) => {

      if(entries[0].isIntersecting) {
        (this.item as GlossaryItemExtended).isReached = true;
      }
      else {
        (this.item as GlossaryItemExtended).isReached = false;
      }
      this.$emit('onReachChange');
    });

    this.observer.observe(this.$refs.limit as HTMLElement);
  },

  unmounted() {
    this.observer.disconnect();
  }
})
