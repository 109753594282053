
import { defineComponent, inject, nextTick, onMounted, provide, Ref, ref, watch } from "@vue/runtime-core"
import ProfileHero from './ProfileHero.vue'
import ProfileHeader from './ProfileHeader.vue'
import ProfileAboutMe from "./ProfileAboutMe.vue"
import ProfileCertifications from "./ProfileCertifications.vue"
import ProfilePreferences from "./ProfilePreferences.vue"
import ProfileExperience from "./ProfileExperience.vue"
import ProfileAnswers from "./ProfileAnswers.vue"
import { AppStore } from "@/store/modules/AppStore"
import { onBeforeRouteUpdate, useRoute } from "vue-router"

import gsap from 'gsap'
import { Scroll } from "@/@types/scroll"
import { Viewport } from "@/store/modules/Viewport"

export default defineComponent({
  setup() {
    const profile = ref(AppStore.profile);
    
    const inner = ref(null)
    const header = ref(null)
    const route = useRoute()
    const scroll = inject('scroll') as Ref<Scroll>

    const isUser = ref(route.params.user !== undefined)
    const isMyProfile = ref(route.path === "/my-profile")
    const isRewards = ref(route.path === '/my-profile/rewards');
    const isRewardsCompleted = ref(route.path === '/my-profile/rewards');

    let isProfileTransition = false;

    provide('isUser', isUser)
    provide('isRewards', isRewards)
    provide('isMyProfile', isMyProfile)

    let timerFullscreen = null
    const checkFullscreen = () => {
      clearTimeout(timerFullscreen);
      timerFullscreen = window.setTimeout(() => {
        const fullscreen = (!AppStore.isPageTransition && isRewards.value && !isProfileTransition);
        document.body.parentElement.classList.toggle('fullscreen', fullscreen)
      }, 0);
    }


    let timeline = null as gsap.core.Timeline | null;
    const pageTransition = async (instant = false) => {
      if (timeline) {
        timeline.kill()
      }

      if (isMyProfile.value) {
        isRewardsCompleted.value = false;
      }

      const debug = 1;

      const scale = Viewport.isMobile ? 1 : 1.1
      const y = (() => {
        if (Viewport.isMobile) {
          return Viewport.windowHeight - (Viewport.windowHeight - ((inner.value as HTMLElement).parentNode as HTMLElement).getBoundingClientRect().top)
        } else {
          return (header.value as typeof ProfileHeader).$el.offsetHeight * scale
        }
      })();

      if (!instant) {
        isProfileTransition = true;
      }

      timeline = gsap.timeline({
        paused: true,
        onComplete: () => {
          if (isRewards.value) {
            isRewardsCompleted.value = true;
          }
          isProfileTransition = false
          checkFullscreen();
        }
      })

      if (!instant) {
        timeline.to(
          window,
          { scrollTo: { y: 0 }, duration: instant ? 0 : 1 * debug, ease: 'quart.out' },
          0
        )
      }
      timeline.fromTo(
        inner.value as HTMLElement,
        {
          y: isRewards.value ? 0 : y,
          scale: isRewards.value ? 1 : scale,
        },
        {
          y: isRewards.value ? y : 0,
          scale: isRewards.value ? scale : 1,
          transformOrigin: '50% 0%',
          duration: instant ? 0 : 1 * debug,
          ease: 'quart.out'
        },
        isRewards.value ? 0 : 0.5
      )

      await nextTick()
      timeline.play();
    }

    watch(
      () => route.path,
      (newPath) => {
        if (newPath === '/my-profile/rewards' || newPath === '/my-profile') {
          isMyProfile.value = newPath === '/my-profile'
          isRewards.value = newPath === '/my-profile/rewards'
          pageTransition();
        } else {
          isMyProfile.value = false
          isRewards.value = false
        }
      }
    )

    
    onBeforeRouteUpdate((to) => {
      if (to.path === '/my-profile/rewards' || to.path === '/my-profile') {
        scroll.value.resetOnRouteChange = false
      }
    })

    onMounted(() => {
      pageTransition(true);
      checkFullscreen();
    })

    watch(
      () => [AppStore.isPageTransition, isRewards.value],
      () => {
        checkFullscreen();
      }
    )

    return {
      inner,
      header,
      profile,
      isUser,
      isRewards,
      isRewardsCompleted,
      isMyProfile,
      scroll,
      pageTransition
    }
  },

  components: {
    ProfileHero,
    ProfileHeader,
    ProfileAboutMe,
    ProfileCertifications,
    ProfilePreferences,
    ProfileExperience,
    ProfileAnswers,
  }
})
