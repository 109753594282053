import { Chapter, ChapterItem, ChapterSlide, Course, Question, QuestionOption, QuestionResponse, Test } from "@/@types/props"
import i18n from "@/utils/i18n"
import formatMediaUrl from "@/utils/mediaUrl"
import axios from "axios"
import gsap from "gsap"
import { AppStore } from "../modules/AppStore"
import { addRewards } from "../modules/Rewards"
import { parseCertifications, parseAchievements, parsePastCertifications } from "./user"


export function getCourseBySlug(slug: string = null): {index: number; course: Course} {
  let index = 0;
  let course: Course = AppStore.courses ? AppStore.courses[index] : null;

  if (slug && AppStore.courses) {
    for (let i = 0; i < AppStore.courses.length; i++) {
      if (AppStore.courses[i].slug === slug) {
        index = i;
        course = AppStore.courses[i];
        break;
      }
    }
  }

  return {
    index,
    course
  };
}

export function getChapterItemBySlugs(courseSlug: string = null, chapterSlug: string = null): ChapterItem | null {
  const { course } = getCourseBySlug(courseSlug);

  let chapterItem: ChapterItem | null = null
  if (course) {
      let index = 0;
      chapterItem = course ? course.chapters[index] : null;
    
      if (chapterSlug && course) {
        for (let i = 0; i < course.chapters.length; i++) {
          if (course.chapters[i].slug === chapterSlug) {
            index = i;
            chapterItem = course.chapters[i];
            break;
          }
        }
      }

  }


  return chapterItem;
}



/*************************
 * 
 * BEGIN - COURSES
 * 
 /***********************/
 export function fetchDashboard(): Promise<Array<Course> | null> {

  AppStore.courses = []

  return axios.get(
    process.env.VUE_APP_API + 'programs/dashboard', 
    {
      headers: {
        'x-token-auth': AppStore.user?.token,
        'x-lang': AppStore.user?.lang,
      }
    }
  )
    .then((result) => {
      if (result.status === 200 && result.data?.success && result.data?.data) {
        console.log(result.data?.data)
        const { globalProgress, achievements, unreadAchievements } = result.data?.data;

        // const unreadAchievements = ["cert_basics", "prog_basics", "cert_pro", "prog_pro", "practical"];
        addRewards(parseAchievements(unreadAchievements));

        // if (result.data?.data?.startedAt) {
        //   AppStore.startedAt = result.data?.data?.startedAt;
        // } else {
        //   AppStore.startedAt = null;
        // }
        
        AppStore.user.certifications = parseCertifications(result.data?.data)
        AppStore.user.oldCertifications = parsePastCertifications(result.data?.data)
        AppStore.user.progress = globalProgress;
        AppStore.user.achievements = parseAchievements(achievements)
        AppStore.courses = parseCourses(result.data?.data?.programs)

        return AppStore.courses;
      } else {
        AppStore.hasError = true

        return null
      }
    })
    .catch((error) => {
      console.error(error)
      AppStore.hasError = true

      return null
    })

}


function parseCourses(programs): Array<Course> {
  const courses = []

  programs.forEach((program, indexProgram) => {
    
    program.sections.forEach((section, index) => {
      const chapters: Array<ChapterItem> = [];
      section.chapters.forEach((item) => {
        const chapter: ChapterItem = {
          slug: item.id,
          title: i18n(item.title),
          status: item.open ? 'opened' : 'closed',
          progress: item.progress,
          readTime: item.read_time,
          published: !!item.published,
          thumbnail: {
            url: formatMediaUrl(item.thumbnail),
          },
          image:  {
            url: item.introduction ? formatMediaUrl(item.introduction) : formatMediaUrl(item.thumbnail),
          },
        }
        chapters.push(chapter)
      })

      const course:Course = {
        slug: section.id,
        theme: section.theme || 'basics1',
        type: 'course',
        
        category: indexProgram === 0 ? 'basics' : 'professional',
        categoryName: i18n(program.name),

        number: (index + 1),
        
        title: i18n(section.name),

        duration: section.read_time,
        progress: section.progress,
        status: section.open ? 'opened' : 'closed',
        image: {
          url: formatMediaUrl(section.thumbnail)
        },

        chapters: chapters,
        nbChapters: chapters.length,
      }
      courses.push(course);
    })

    if (program.test) {
      const test:Course = {
        slug: program.test.id,
        theme: 'tests1',
        type: 'test',
        
        category: indexProgram === 0 ? 'basics' : 'professional',
        categoryName: i18n(program.name),

        status: program.test.open ? 'opened' : 'closed',
        progress: program.test.bestScore,
        success: program.test.success,

        chapters: []
      }

      courses.push(test)
    }
  })

  return courses;
}

/*************************
 * 
 * END - COURSES
 * 
 /***********************/


/*************************
 * 
 * BEGIN - CHAPTER
 * 
 /***********************/
 export async function fetchChapter(slug: string): Promise<Chapter | null> {

  return axios.get(
    process.env.VUE_APP_API + 'programs/chapter/' + slug, 
    {
      headers: {
        'x-token-auth': AppStore.user.token,
        'x-lang': AppStore.user?.lang,
      }
    }
  ).then((result) => {
    if (result.status === 200 && result.data.success) {
      const chapter = parseChapter(slug, result?.data?.data)
      return chapter
    }

    return null
  })
  .catch((error) => {
    console.warn(error);
    return null
  })
}

function parseChapter(slug, data): Chapter | null {
  const slides: Array<ChapterSlide> = [];

  const fragments = (data.content[AppStore.user.lang] || data.content['en']).fragments
  fragments.forEach((fragment) => {
    switch(fragment.type) {
      case 'text': {
        if (fragment.title || fragment.content) {
          slides.push({
            slug: fragment.id,
            type: fragment.type,
            title: fragment.title,
            text: fragment.content,
          })
        }
        break;
      }

      case 'image': {
        slides.push({
          slug: fragment.id,
          type: fragment.type,
          image: {
            url: formatMediaUrl(fragment.url)
          },
          text: fragment.content,
        })
        break;
      }  

      case 'text_image': {
        slides.push({
          slug: fragment.id,
          type: fragment.type,
          image: {
            url: formatMediaUrl(fragment.url)
          },
          title: fragment.title,
          text: fragment.content,
        })
        break;
      }

      case 'list': {
        const items = [];
        [...fragment.content.matchAll('<li(|\\s+[^>]*)>(.*?)<\\/li\\s*>', 'g')].forEach((match) => {
          items.push(match[2])
        });

        slides.push({
          slug: fragment.id,
          type: fragment.type,
          title: fragment.title,
          items,
        });
        break;
      }

      case 'question': {
        const options = fragment.options
        gsap.utils.shuffle(options)

        slides.push({
          slug: fragment.id,
          type: fragment.type,
          title: fragment.title,
          options,
        });
        break;
      }

      case 'transition': {
        slides.push({
          slug: fragment.id,
          type: fragment.type,
          title: fragment.title,
          text: fragment.content,
          image: {
            url: formatMediaUrl(fragment.url)
          }
        });
        break;
      }

      case 'video': {
        const video = {}
        if (fragment.desktop_video) {
          const fileExt = fragment.desktop_video.split('.').pop()
          video[fileExt] = formatMediaUrl(fragment.desktop_video)
        }

        if (fragment.mobile_video) {
          const mobile = {}
          const fileExt = fragment.mobile_video.split('.').pop()
          mobile[fileExt] = formatMediaUrl(fragment.mobile_video)
          video['mobile'] = mobile;
        }
        

        slides.push({
          slug: fragment.id,
          type: fragment.type,
          title: fragment.title,
          text: fragment.content,
          video
        });
        break;
      }

      default: {
        break;
      }
    }
  })

  const chapter: Chapter = {
    slug: slug,
    title: i18n(data.title),
    status: data.open ? 'opened' : 'closed',
    progress: data.progress,
    readTime: data.read_time,
    category: data.section?.program || 0,

    theme: data.section?.theme || 'basics1',
    courseSlug: data.section?.id,
    courseTitle: i18n(data.section?.name),
    published: !!data.published,

    image: {
      url: formatMediaUrl(data.introduction),
    },
    slides,

    end: {
      type: (data.next?.type === 'section') ? 'course' : data.next?.type,
      id: data.next?.id,
      title: i18n(data.next?.title),
      readTime: data.next?.read_time,
      nbChapters: data.next?.nb_chapters,
      thumbnail: {
        url: formatMediaUrl(data.next?.thumbnail)
      }
    }
  }

  return chapter
}

export async function setChapterProgression(chapter: Chapter, slideSlug: string, progress: number, timeSpent: number): Promise<boolean> {
  const data = { fragment: slideSlug, percent: progress, time: timeSpent };

  return axios.post(
    process.env.VUE_APP_API + 'programs/chapter/' + chapter.slug, 
    data,
    {
      headers: {
        'x-token-auth': AppStore.user.token,
        'x-lang': AppStore.user?.lang,
      }
    }
  ).then((result) => {
    if (result.status === 200 && result.data.success) {
      AppStore.needToRefreshDashboard = true;
      addRewards(parseAchievements(result.data?.data?.achievements));
      // const chapterItem = getChapterItemBySlugs(chapter.courseSlug, chapter.slug);
      // if (chapterItem) {
      //   chapterItem.progress = progress
      // }

      return true
    }
    return false
  }).catch((error) => {
    console.warn(error)
    return false
  })

  // return Promise.resolve().then(() => true)
}

/*************************
 * 
 * END - CHAPTER
 * 
 /***********************/



/*************************
 * 
 * BEGIN - TEST
 * 
 /***********************/
 export async function fetchTest(slug: string): Promise<Test | null> {

  return axios.get(
    process.env.VUE_APP_API + 'programs/test/' + slug, 
    {
      headers: {
        'x-token-auth': AppStore.user.token,
        'x-lang': AppStore.user?.lang,
      }
    }
  ).then((result) => {
    if (result.status === 200 && result.data.success) {
      const test = parseTest(slug, result?.data?.data)

      return test
    }

    return null
  })
  .catch((error) => {
    console.warn(error);
    return null
  })
}


function parseTest(slug, data): Test {

  const questions: Array<Question> = [];
  data.questions.forEach((item) => {
    const options: Array<QuestionOption> = [];

    item.answers.forEach((o) => {
      options.push({
        text: i18n(o.text),
        slug: o.id
      })
    })

    questions.push({
      slug: item.id,
      title: i18n(item.text),
      options,
      timeInSeconds: data.allowed_time,
    })
  })

  const test:Test = {
    theme: 'tests1',
    slug,
    game: data.game,

    questions
  }
  return test
}





export async function sendAnswer(slug: string, game: string, question: string, answer: string): Promise<QuestionResponse | null> {

  return axios.post(
    process.env.VUE_APP_API + 'programs/test/' + slug, 
    {
      game,
      question,
      answer,
    },
    {
      headers: {
        'x-token-auth': AppStore.user.token,
        'x-lang': AppStore.user?.lang,
      }
    }
  ).then((result) => {
    if (result.status === 200 && result.data.success) {
      const r = result.data?.data;

      let end = undefined
      if (r.end) {
        end = {
          score: r.end.score,
          success: r.end.success,
          scoreToReach: r.end.score_to_reach,
          certificateUrl: formatMediaUrl(r.end.certificate_url),
          achievements: r.end.achievements
        }

        if (r.end.achievements) {
          addRewards(parseAchievements(r.end.achievements))
        }

        AppStore.needToRefreshDashboard = true;
      }

      return {
        correct: r.correct,
        answer: r.answer,
        solution: r.solution,
        end
      }
    }

    return null
  })
  .catch((error) => {
    console.warn(error);
    return null
  })
}



/*************************
 * 
 * END - TEST
 * 
 /***********************/