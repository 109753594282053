
import { computed, defineComponent, ref } from 'vue'
import Logo from './Logo.vue'
import LangChooser from './LangChooser.vue'
import AccountLink from './AccountLink.vue'
import MenuConnected from './MenuConnected.vue'
import MenuMobileTrigger from './MenuMobileTrigger.vue'
import MenuMobile from './MenuMobile.vue'
import ButtonHover from '@/components/not-connected/ButtonHover.vue'

import { Viewport } from '@/store/modules/Viewport';
import { MenuMobileStore } from '@/store/modules/MenuMobileStore';
import { AppStore } from '@/store/modules/AppStore'

export default defineComponent({

  setup() {
    return {
      animated: ref(false),
      limit: 0,

      Viewport,
      MenuMobileStore,
      user: AppStore.user,

      themeForceDark: computed(() => AppStore.themeForceDark),

      adminUrl: process.env.VUE_APP_CMS_PERMALINK,
      allowLang: process.env.VUE_APP_DISABLE_LANG !== 'true',

    }
  },

  components: {
    Logo,
    LangChooser,
    AccountLink,
    ButtonHover,
    MenuConnected,
    MenuMobileTrigger,
    MenuMobile
  },

  props: {
    theme: { type: String, default: 'dark' }
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onResize);

    this.onResize();
  },
  unmounted() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.limit = this.$el.offsetHeight;
      MenuMobileStore.headerHeight = this.limit;
      document.documentElement.style.setProperty('--mobile-header-height', this.limit + 'px');
    },
    onScroll() {
      const sY = window.scrollY;
      if (!MenuMobileStore.isScrolled && sY > this.limit) {
        MenuMobileStore.isScrolled = true
        setTimeout((() => this.animated = true))
      } else if(MenuMobileStore.isScrolled && sY <= 0) {
        MenuMobileStore.isScrolled = false
        this.animated = false
      }

      if (MenuMobileStore.lastScroll > sY) {
        MenuMobileStore.isScrollingTop = true;
      } else if (MenuMobileStore.lastScroll < sY) {
        MenuMobileStore.isScrollingTop = false;
      }
      MenuMobileStore.lastScroll = sY;
      MenuMobileStore.hasMobileHeaderDeployed = MenuMobileStore.isScrollingTop && MenuMobileStore.isScrolled;
    }
  }

})
