export const formatEventTime = (date, locale) => {
  let time =  new Date(date).toLocaleTimeString(locale,  { hour: 'numeric', minute: '2-digit', hour12: true, timeZone: "UTC" })
  time = time.replace(/\s/g, '');
  time = time.replaceAll('.', '');
  return time.replaceAll(':00', '');
}

export const formatEventDate = (date, locale) => {
  return new Date(date).toLocaleDateString(locale, { month: 'long', day: 'numeric',  year: 'numeric' })
}

export const formatSplitEventDate = (date, locale) => {
  const eventDate = new Date(date)
  return [eventDate.toLocaleDateString(locale, { month: 'long' }), eventDate.toLocaleDateString(locale, { day: 'numeric' }), eventDate.toLocaleDateString(locale, { year: 'numeric' })];
}