import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Stats = _resolveComponent("Stats")!
  const _component_Sprite = _resolveComponent("Sprite")!
  const _component_PageTransition = _resolveComponent("PageTransition")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_RotationBlocker = _resolveComponent("RotationBlocker")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.debug)
      ? (_openBlock(), _createBlock(_component_Stats, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_Sprite),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component, route }) => [
        _createVNode(_component_PageTransition, null, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
              key: route.meta?.unique ? route.fullPath : '',
              "data-slug": route.meta?.unique ? route.fullPath : ''
            }, null, 8, ["data-slug"]))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }),
    _createVNode(_component_RotationBlocker)
  ]))
}