export default function setCookie(cookieName: string, cookieValue: string, cookieDomain: string): void {

    
    const domain = cookieDomain || window.location.hostname;
    const path = '/'

    const myDate = new Date()
    myDate.setMonth(myDate.getMonth() + 12)
    document.cookie = `${cookieName}=${cookieValue};expire=${myDate};domain=${domain};path=${path}`;
}
