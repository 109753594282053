
import { defineComponent } from 'vue'

export enum STATE {
  ENABLE = 'enable',
  DISABLE = 'disable'
}

export enum DIRECTION {
  BACKWARDS = 'backwards',
  FORWARDS = 'forwards'
}

export enum THEME {
  LIGHT = 'light',
  DARK = 'dark'
}

export default defineComponent({
  props: {
    disabled: {
      type: String as () => STATE,
      default: STATE.ENABLE
    },
    direction: {
      type: String as () => DIRECTION,
      default: DIRECTION.FORWARDS
    },
    theme: {
      type: String as () => THEME,
      default: THEME.DARK
    }
  }
})
