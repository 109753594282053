// import { AppStateEnum } from "@/store/states/AppStateMachine";
import { reactive } from "@vue/runtime-core";
import { User, Course, Chapter, Test, Job, Glossary, GlossaryDefinition, Resources, Dashboard, JobPage, EventPage, Event, Reward, UserProfile } from '@/@types/props';

type AppStore = {
  isPageTransition: boolean
  hasError: boolean
  showMainTutorial: boolean

  themeForceDark: boolean

  load_progress: number
  debug: boolean

  popins: {
    ageGate: boolean
    newTerms: boolean,
    practicalEn: Object,
    practicalEs: boolean
  }

  user?: User
  profile?: UserProfile
  needToRefreshDashboard: boolean
  dashboard?: Dashboard
  courses: Array<Course>
  course?: Course
  chapter: Chapter
  test?: Test
  jobPage?: JobPage
  job?: Job,
  eventPage: EventPage,
  event: Event,
  glossary?: Glossary
  glossaryDefinition?: GlossaryDefinition
  resources: Resources

  shortcutsNeedToAppear: boolean
}

export const AppStore = reactive<AppStore>({
  isPageTransition: false,
  hasError: false,
  showMainTutorial: false,

  themeForceDark: false,

  popins: {
    ageGate: false,
    newTerms: false,
    practicalEn: false,
    practicalEs: false,
  },

  load_progress: 0,
  debug: process.env.VUE_APP_DEBUG === 'true',

  user: null,
  needToRefreshDashboard: true,
  dashboard: {
    jobs: [],
    events: []
  },
  courses: [],
  course: null,
  chapter: null,
  jobPage: null,
  job: null,
  eventPage: null,
  event: null,
  glossary: null,
  resources: null,

  shortcutsNeedToAppear: true,
})

export function setAppLoadProgress(v: number) {
  AppStore.load_progress = v;
}