
import { defineComponent, inject } from 'vue'
import { gsap } from 'gsap'
import getOffsetTop from "@/utils/getOffsetTop";
import { Scroll } from '@/@types/scroll';
import { Viewport } from '@/store/modules/Viewport';

export default defineComponent({
  setup() {
    const offsetTop = 0
    const elHeight = 0
    const triggerTop = 0
    const triggerBottom = 0
    const pointerX = 0
    const smoothPointerX = 0
    const oldWindowWidth = 0

    return {
      offsetTop,
      elHeight,
      triggerTop,
      triggerBottom,
      pointerX,
      smoothPointerX,
      oldWindowWidth,
      scroll: inject('scroll') as Scroll,
    }
  },

  props: {
    direction: {
      type: String,
      default: 'right'
    }
  },

  mounted() {
    window.addEventListener('resize', this.onResize)
    window.addEventListener('pointermove', this.onPointerMove)
    gsap.ticker.add(this.onUpdate);

    this.onResize()
  },

  unmounted() {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('pointermove', this.onPointerMove)
    gsap.ticker.remove(this.onUpdate);
  },

  methods: {
    async onResize() {
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(true)
        }, 10)
      })

      if (Viewport.isMobile && Viewport.windowWidth === this.oldWindowWidth) return
      
      this.oldWindowWidth = Viewport.windowWidth

      this.offsetTop = getOffsetTop(this.$el)
      this.elHeight = this.$el.offsetHeight

      this.triggerTop = this.offsetTop - Viewport.windowHeight
      this.triggerBottom = this.triggerTop + Viewport.windowHeight + this.elHeight
    },

    onPointerMove(event: PointerEvent) {
      if (Viewport.isTouch) return

      this.pointerX = (((event.x - (Viewport.windowWidth/2)) / Viewport.windowWidth) * 600)
    },

    onUpdate() {
      this.smoothPointerX += (this.pointerX - this.smoothPointerX) * 0.1

      const scrollVal = this.scroll.active ? this.scroll.smoothValue : this.scroll.value

      if (scrollVal >= this.triggerTop && scrollVal < this.triggerBottom) {

        if(this.direction === 'right') {
          this.$el.style.transform = `translateX(${this.smoothPointerX - (scrollVal - this.triggerTop) * 0.3}px) translateZ(0)`
        } else {
          this.$el.style.transform = `translateX(${-this.smoothPointerX + (scrollVal - this.triggerBottom) * 0.3}px) translateZ(0)`
        }
      }
    }
  }
})
