import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03fd02a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "CookieBanner-text text-lg" }
const _hoisted_2 = { class: "CookieBanner-btns" }
const _hoisted_3 = { class: "CookieBanner-btns-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonHover = _resolveComponent("ButtonHover")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'is-active': _ctx.isActive, 'is-out': _ctx.isOut, 'is-in': _ctx.isIn }, "CookieBanner"])
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('cookies.description')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ButtonHover, {
        to: "/privacy",
        class: "CookieBanner-btns-item button-secondary"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('cookies.link')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ButtonHover, {
          onClick: _ctx.onAccept,
          type: "button",
          class: "CookieBanner-btns-item button-primary"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('cookies.cta')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_ButtonHover, {
          onClick: _ctx.onDeny,
          class: "CookieBanner-btns-item button-secondary"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('cookies.deny')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ])
  ], 2))
}