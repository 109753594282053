
import { defineComponent } from 'vue'
import { Viewport } from '@/store/modules/Viewport';
import Logo from '@/components/Header/Logo.vue'
import LangChooser from '@/components/Header/LangChooser.vue'
import MenuNotConnected from '@/components/Header/MenuNotConnected.vue'
import ButtonHover from '@/components/not-connected/ButtonHover.vue';

import MenuMobileTrigger from '@/components/Header/MenuMobileTrigger.vue';
import MenuMobile from '@/components/Header/MenuMobile.vue';

export default defineComponent({
  setup() {
    return {
      Viewport,
      limit: 0,
      allowLang: process.env.VUE_APP_DISABLE_LANG !== 'true'
    }
  },
  data() {
    return {
      isScrolled: false
    }
  },
  components: {
    Logo,
    LangChooser,
    MenuNotConnected,
    ButtonHover,
    MenuMobileTrigger,
    MenuMobile,
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onResize);

    this.onResize();
  },
  unmounted() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onResize);
  },

  props: {
    theme: { type: String, default: 'dark' },
    register: { type: Boolean, default: false }
  },
  methods: {
    onResize() {
      this.limit = this.$el.offsetHeight;
    },
    onScroll() {
      const sY = window.scrollY;
      if (!this.isScrolled && sY > this.limit) {
        this.isScrolled = true
      } else if (this.isScrolled && sY <= 0) {
        this.isScrolled = false
      }
    }
  },
})
