import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ecbfaa3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "EventsList" }
const _hoisted_2 = {
  class: "EventsList__Summary__Top",
  ref: "stickyTop"
}
const _hoisted_3 = { class: "EventsList__Summary__Inner EventsList__Summary__Inner--Default" }
const _hoisted_4 = { class: "EventsList__Summary__Inner__Title small-uppercase" }
const _hoisted_5 = { class: "EventsList__Summary__Inner EventsList__Summary__Inner--Sticked" }
const _hoisted_6 = { class: "small-uppercase" }
const _hoisted_7 = { class: "EventsList__Items" }
const _hoisted_8 = { ref: "list" }
const _hoisted_9 = ["data-index"]
const _hoisted_10 = { class: "EventsList" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventsCard = _resolveComponent("EventsCard")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512),
    _createElementVNode("header", {
      class: _normalizeClass(["EventsList__Summary", { 'is-sticked': _ctx.isMobileSticked && _ctx.Viewport.isMobile, 'has-header-offset': _ctx.hasMobileHeaderDeployed }])
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('events.title')), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('events.title')), 1)
      ])
    ], 2),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_TransitionGroup, {
          name: "card",
          tag: "ul",
          onBeforeEnter: _ctx.onBeforeEnter,
          onEnter: _ctx.onEnter,
          onBeforeLeave: _ctx.onBeforeLeave,
          onLeave: _ctx.onLeave
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eventPage.events, (event, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: 'events-card-' + event.slug,
                "data-index": index
              }, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_EventsCard, { event: event }, null, 8, ["event"])
                ])
              ], 8, _hoisted_9))
            }), 128))
          ]),
          _: 1
        }, 8, ["onBeforeEnter", "onEnter", "onBeforeLeave", "onLeave"])
      ], 512)
    ])
  ]))
}