import { Reward } from "@/@types/props";
import sleep from "@/utils/Sleep";
import { reactive } from "@vue/runtime-core";

type Rewards = {
  items: Array<Reward>
}


export const Rewards = reactive<Rewards>({
  items: []
}) as Rewards


export async function addRewards(rewards: Array<Reward>): Promise<void> {
  await sleep(1000)
  if (rewards && rewards.length) {
    Rewards.items = Rewards.items.concat(rewards)
  }
}

// setTimeout(() => {
//   addRewards([
//     {bottle: 1}, 
//     {bottle: 2}, 
//     {bottle: 3}, 
//     {bottle: 4}, 
//     {bottle: 5}, 
//     {bottle: 6}, 
//     {bottle: 7}, 
//     {bottle: 8}, 
//     {bottle: 9}, 
//     {bottle: 10}, 
//     {bottle: 11}, 
//     {bottle: 12}, 
//     {bottle: 13}, 
//     {bottle: 14}
//   ])
// }, 1000);