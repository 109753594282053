
import { defineComponent } from 'vue'
import { event } from 'vue-gtag'

export default defineComponent({
  props: {
    theme: { type: String, default: 'dark' }
  },

  methods: {
    onSocialClick(type) {
      event(type, {
        'event_category': 'footer',
      })
    }
  }
})
