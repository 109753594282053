
import { defineComponent, inject } from 'vue'
import { gsap } from 'gsap'
import getOffsetTop from "@/utils/getOffsetTop";
import { Viewport } from '@/store/modules/Viewport';
import { Scroll } from '@/@types/scroll';

export default defineComponent({
  setup() {
    const height = 0
    const offsetY = 0
    const translateY = 0

    return {
      height,
      offsetY,
      translateY,
      scroll: inject('scroll') as Scroll,
    }
  },

  props: {
    ratio: { type: Number, default: 1 },
    screenPos: { type: Number, default: 0.5 }
  },

  watch: {
    'scroll.active': 'onResize'
  },

  mounted() {
    window.addEventListener('resize', this.onResize)
    gsap.ticker.add(this.onUpdate);

    this.onResize()
  },

  unmounted() {
    window.removeEventListener('resize', this.onResize)
    gsap.ticker.remove(this.onUpdate);
  },

  methods: {
    onResize() {
      if(!this.scroll.active) {
        this.$el.style.transform = 'none'
        return
      }

      this.height = this.$el.offsetHeight
      this.offsetY = getOffsetTop(this.$el)
    },

    onUpdate() {
      if (!this.scroll.active) { return }

      const ratio = this.ratio as number
      const screenPos = this.screenPos as number

      // if (ratio < 0) {
      //   this.translateY = (this.scroll.smoothValue - this.offsetY) * ratio + ((Viewport.windowHeight - this.height) * (ratio * screenPos))
      // } else {
        this.translateY = (this.scroll.smoothValue - this.offsetY) * ratio + this.offsetY * ratio
      // }
      this.translateY = Math.round(this.translateY * 100) / 100

      if (this.offsetY - this.translateY - this.scroll.smoothValue - Viewport.windowHeight < 0 &&
        this.offsetY - this.translateY - this.scroll.smoothValue + this.height > 0) {
        this.$el.style.transform = `translate3d(0px, ${-this.translateY}px, 0px)`
      }
    }
  }
})
