
import { Viewport } from "@/store/modules/Viewport"
import { ComponentPublicInstance, defineComponent, inject, provide, ref, Ref, watch } from "@vue/runtime-core"
import Cell from './Cell.vue'
import BackBarUI from './BackBarUI.vue'
import BackBarTutorial from './BackBarTutorial.vue'
import { FocusTrap } from 'focus-trap-vue'
// import VirtualScroll from 'virtual-scroll'
import { AppStore } from "@/store/modules/AppStore"

import gsap from 'gsap'
import Draggable from 'gsap/Draggable'
import InertiaPlugin from 'gsap/InertiaPlugin'
import getOffsetTop from "@/utils/getOffsetTop"
gsap.registerPlugin(Draggable)
gsap.registerPlugin(InertiaPlugin)


// import { Application as PIXIApplication } from 'pixi.js';

// import { MenuMobileStore } from '@/store/modules/MenuMobileStore';
// import lerp from "@/utils/Lerp"

const nbCells = 15;

export default defineComponent({

  setup() {
    const achievements = ref(AppStore.profile?.achievements)

    console.log('achievements', achievements.value)

    const isActive = (bottle): boolean => {
      const reward = achievements.value ? achievements.value.find((a) => a.bottle === bottle) : null
      return reward != null
    }

    const inner = ref(null) as Ref<HTMLElement>
    const zoomOn = ref(null) as Ref<ComponentPublicInstance<typeof Cell> | null>
    const currentBottle = ref(0) as Ref<number>
    const isZoomIn = ref(false)
    const isRewards = inject('isRewards') as Ref<boolean>
    const draggable = ref(null) as Ref<null | Draggable>

    const h = ref(0)
    const w = ref(0)

    watch(
      () => isRewards.value,
      () => {
        if (draggable.value) {
          if (isRewards.value) {
            draggable.value.enable()

            const center = {
              x: +Viewport.windowWidth/2 - w.value/2,
              y: +Viewport.windowHeight/2 - h.value/2,
            }

            gsap.set(
              inner.value,
              {
                x: center.x,
                y: center.y,
                onComplete: () => {
                  draggable.value.update()
                }
              }
            )
          } else {
            draggable.value.disable()
            isZoomIn.value = false
            zoomOn.value = null
            currentBottle.value = 0;

            const center = {
              x: +Viewport.windowWidth/2 - w.value/2,
              y: +Viewport.windowHeight/2 - h.value/2,
            }

            gsap.to(
              inner.value,
              {
                x: center.x,
                y: center.y,
                duration: 2,
                ease: 'quart.inOut',
                onComplete: () => {
                  draggable.value.update()
                }
              }
            )
          }
        }
      }
    )

    provide('isZoomIn', isZoomIn)
    provide('zoomOn', zoomOn)
    provide('currentBottle', currentBottle)

    return {
      achievements,
      isActive,

      Viewport,
      isZoomIn,
      isRewards,
      zoomOn,
      currentBottle,

      inner,
      draggable,
      h,
      w,

      resizeObserver: null as ResizeObserver | null,

      app: null,
      
      // vs: null as null | VirtualScroll,
      // isScrolling: ref(false),

      // scroll: {
      //   enabled: true,
      //   needReset: false,

      //   target: {
      //     x: 0, 
      //     y: 0
      //   },
      //   current: {
      //     x: 0, 
      //     y: 0
      //   },
      //   currentRounded: {
      //     x: 0, 
      //     y: 0
      //   },
      //   ease: 0.075,
      // },
    }
  },
  
  components: {
    Cell,
    BackBarUI,
    BackBarTutorial,
    FocusTrap
  },

  async mounted() {
    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.contentRect) {
          this.onResize()
        }
      })
    })

    this.resizeObserver.observe((this.$refs.inner as HTMLElement))

    this.onResize()
    await this.$nextTick()
    this.initDrag()

    window.addEventListener('keyup', this.onKeyUp, {passive: true});
    
    [...(this.$el as HTMLElement).querySelectorAll('a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])')].forEach((focusable) => {
      focusable.addEventListener('focus', this.onFocus);
    })
  },

  unmounted() {
    window.removeEventListener('keyup', this.onKeyUp);
  },

  methods: {

    onResize() {
      const inner = this.$refs.inner as HTMLElement

      if (this.$refs.cell1) {
        const cell = ((this.$refs.cell1 as ComponentPublicInstance).$el as HTMLElement);
        const cellH = cell.offsetHeight;
        const cellW = cell.offsetWidth;
        const grid = this.$refs.grid as HTMLElement;
        const gridH = grid.offsetHeight;
        const gridW = grid.offsetWidth;

        this.h = Math.round((gridH + Viewport.windowHeight/2 + cellH/2) * 100) /100
        this.w = Math.round((gridW + Viewport.windowWidth/2 + cellW*1.5 + 51*Viewport.windowWidth/1680) * 100) /100
        
        gsap.set(
          inner,
          {
            width: this.w,
            height: this.h,
          }
        )        

        if (this.isZoomIn) {
          this.onZoomOn(this.zoomOn, true, true)
        } else {
          this.center();
        }
      }

    },

    initDrag() {
      const inner = this.$refs.inner as HTMLElement
      this.draggable = Draggable.create(
        inner,
        {
          bounds: this.$el as HTMLElement,
          zIndexBoost: false,
          onClick: this.onClickDraggable,
          inertia: true,
          maxDuration: 1
        }
      )[0]

      this.center();
      
      if (!this.isRewards) {
        this.draggable.disable();
      }
    },

    center() {
      const inner = this.$refs.inner as HTMLElement
      const center = {
        x: +Viewport.windowWidth/2 - this.w/2,
        y: +Viewport.windowHeight/2 - this.h/2,
      };

      gsap.set(
        inner,
        {
          x: center.x,
          y: center.y,
          onComplete: () => {
            if (this.draggable) {
              this.draggable.update();
            }
          }
        }
      )
    },

    onClickDraggable(event) {
      if ((event.target as HTMLElement).tagName === 'BUTTON') {
        const index = event.target.getAttribute('data-index');

        const cell = this.$refs['cell'+index] as ComponentPublicInstance<typeof Cell>;
        this.onZoomOn(cell)
      }
    },

    onZoomOn(cell: ComponentPublicInstance<typeof Cell> | null, force = false, instant = false) {
      if ((!this.isZoomIn || force) && cell) {
        this.isZoomIn = true;
        this.currentBottle = cell.bottle;
        this.zoomOn = cell;
        const inner = this.$refs.inner as HTMLElement

        const target = cell.$el as HTMLElement;
  
        const y = target.offsetTop + (target.offsetParent as HTMLElement).offsetTop + target.offsetHeight/2
        const x = target.offsetLeft + (target.offsetParent as HTMLElement).offsetLeft + target.offsetWidth/2
  
        this.draggable.disable();
        gsap.to(
          inner,
          { 
            x: Viewport.windowWidth/2 - x, 
            y: Viewport.windowHeight/2 - y - (Viewport.isMobile ? Viewport.windowHeight/11 : Viewport.windowHeight/13), 
            duration: instant ? 0 : 1, 
            ease: 'quart.out' 
          }
        )
      }
    },

    onZoomOut() {
      if (this.isZoomIn) {
        this.isZoomIn = false
        this.zoomOn = null
        this.draggable.enable();
        // this.currentBottle = 0
      }
    },

    onPrevious() {
      if (this.isZoomIn && this.zoomOn) {
        const destIndex = this.zoomOn.index === 1 ? nbCells : this.zoomOn.index - 1;
        this.isZoomIn = false
        const dest = this.$refs['cell'+destIndex] as ComponentPublicInstance<typeof Cell>
        this.onZoomOn(dest)
      }
    },

    onNext() {
      if (this.isZoomIn && this.zoomOn) {
        const destIndex = this.zoomOn.index === nbCells ? 1 : this.zoomOn.index + 1;
        this.isZoomIn = false
        const dest = this.$refs['cell'+destIndex] as ComponentPublicInstance<typeof Cell>
        this.onZoomOn(dest)
      }
    },

    onFocus() {
      const el = (this.$el as HTMLElement);
      if (el) {
        console.log('onFocus', el.scrollTop, el.scrollLeft);
        if (el.scrollTop > 0 || el.scrollLeft > 0) {
          el.scrollTo(0, 0);
        }
      }
    },

    onKeyUp(event: KeyboardEvent) {
      if (event.key === 'ArrowLeft') {
        this.onPrevious();
      } else if (event.key === 'ArrowRight') {
        this.onNext();
      } else if (event.key === 'Escape') {
        if (this.isZoomIn) {
          this.onZoomOut();
        } else {
          this.$router.push('/my-profile');
        }
      } else {
        const target = (event.target as HTMLElement)
        const key = event.key

        if (key === 'Tab' && target) {

          console.log('Tab on', {target});

          if (target.hasAttribute('data-index')) {
            const index = parseInt(target.getAttribute('data-index'));
            // const rect = target.getBoundingClientRect();

            // console.log({index, rect});

            const cell = this.$refs['cell'+index] as ComponentPublicInstance<typeof Cell>;
            if (cell) {
              this.onZoomOn(cell, true)
            }
          }
        }
      }
    },
  }
})
