
import { defineComponent } from "@vue/runtime-core"

import { Viewport } from '@/store/modules/Viewport'

import gsap from 'gsap'
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
gsap.registerPlugin(DrawSVGPlugin);

export default defineComponent({
  props: {
    start: {
      type: Number,
      required: true,
    },
    end: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },

  setup() {
    return {
      Viewport,
      tween: null as gsap.core.Tween | null,
    }
  },

  watch: {
    start() {
      this.onChange();
    },
    end() {
      this.onChange();
    }
  },

  mounted() {
    this.onChange(true);
  },

  methods: {
    onChange(instant = false) {
      if (this.tween) {
        this.tween.kill();
      }
      this.tween = gsap.to(this.$refs.line as SVGLineElement, {drawSVG: this.start + ' ' + this.end, duration: instant ? 0 : 0.5, ease: 'quart.inOut'});
    }
  }

})
