
import { defineComponent } from 'vue'
import { Viewport } from '@/store/modules/Viewport';
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import { FocusTrap } from 'focus-trap-vue';
import { AppStore } from '@/store/modules/AppStore';

export default defineComponent({
  setup() {
    let timerError
    let oldWindowWidth = 0

    return {
      timerError,
      oldWindowWidth
    }
  },

  components: {
    ButtonHover,
    FocusTrap
  },

  data() {
    return {
      isActive: false,
      isError: false,
      isOut: false,

      day: '',
      month: '',
      year: ''
    }
  },

  mounted() {
    window.addEventListener('resize', this.onResize)
    
    this.onResize()

    this.init()
  },

  unmounted() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    init() {
      let ageGate = window.localStorage.getItem('BarSmarts:ageGate')

      this.isActive = !(typeof ageGate === 'string')
      AppStore.popins.ageGate = this.isActive;
    },

    onResize() {
      if (Viewport.isMobile && Viewport.windowWidth === this.oldWindowWidth) return
      
      this.oldWindowWidth = Viewport.windowWidth;

      (this.$refs.ageGate as HTMLElement).style.height = `${Viewport.windowHeight}px`
    },

    onKeyUp(event: KeyboardEvent) {
      if (event.key === 'Tab' || event.key === 'Shift' || event.key === 'Meta' || event.key === 'Control' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'Enter') return
      
      const target = event.target as HTMLInputElement
      const { maxLength, value, nextElementSibling } = target;

      if (value.length >= maxLength) {
        console.log('(nextElementSibling as HTMLInputElement)', (nextElementSibling as HTMLInputElement));
        (nextElementSibling as HTMLInputElement).focus()
        event.preventDefault();
      }
    },

    onKeyPress(event: KeyboardEvent) {
      if (event.key === 'Enter') return
      
      let keyCode = (event.keyCode ? event.keyCode : event.which);

      if ((keyCode > 31 && (keyCode < 48 || keyCode > 57)) && keyCode !== 46) {
        event.preventDefault();
      }

      const target = event.target as HTMLInputElement
      const { maxLength, value } = target;

      if (value.length >= maxLength) {
        event.preventDefault();
      }
    },

    showError() {
      clearTimeout(this.timerError)

      this.isError = true

      this.timerError = window.setTimeout(() => {
        this.isError = false
      }, 820)
    },

    onSubmit() {
      if (this.day == "" || this.month == "" || this.year == "" || this.month.length < 2 || this.day.length < 2 || this.year.length < 4){
        console.error('Please fill fields')
        this.showError()
        return
      }

      const mydate = new Date();
      mydate.setFullYear(parseInt(this.year), parseInt(this.month)-1, parseInt(this.day));

      const currdate = new Date();
      currdate.setFullYear(currdate.getFullYear() - 21);

      if ((currdate.getTime() - mydate.getTime()) < 0){
        console.error('Underage')
        this.showError()
        return
      }

      window.localStorage.setItem('BarSmarts:ageGate', 'ok')
      window.scrollTo(0, 0)

      this.isOut = true;

      (document.activeElement as HTMLElement).blur();

      setTimeout(() => {
        this.isActive = false
        AppStore.popins.ageGate = this.isActive;
      }, 600)
    }
  }
})
