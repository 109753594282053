
import { defineComponent } from 'vue'

import gsap from 'gsap';

export default defineComponent({
  setup() {
    return {
      timeline: null as gsap.core.Timeline | null
    }
  },
  mounted() {
    const _dot = this.$refs.dot as HTMLElement;
    this.timeline = gsap.timeline({
      repeat: -1
    })
      .fromTo(_dot, {opacity: 0}, {opacity: 1, duration: 0.3, ease: 'quart.out'}, 0)
      .to(_dot, {y: 20, duration: 0.4, ease: 'quart.inOut'}, 2)
      .to(_dot, {strokeDashoffset: 10, duration: 0.2, ease: 'quart.in'}, 2)
      .to(_dot, {strokeDashoffset: 19, duration: 0.2, ease: 'quart.out'}, 2.2)
      .to(_dot, {opacity: 0, duration: 0.2, ease: 'quart.out'}, 2.4)
  },
  
  unmounted() {
    if (this.timeline) {
      this.timeline.kill();
    }
  }
})
