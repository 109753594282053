
import { defineComponent } from 'vue'
import axios from 'axios'
import Logo from '@/components/Header/Logo.vue'
import FormAccount from '@/components/Register/Standard/FormAccount.vue'
import FormGeneral from '@/components/Register/Standard/FormGeneral.vue'
import FormCheckout from '@/components/Register/Standard/FormCheckout.vue'
import ThanksPanel from '@/components/Register/ThanksPanel.vue'
import HeaderNotConnected from '@/components/Header/HeaderNotConnected.vue';
import ErrorPopin from '@/components/not-connected/ErrorPopin.vue';
import { pageview } from 'vue-gtag'
import { isAuthenticated } from '@/store/data-sources/user'
import sleep from '@/utils/Sleep'
import { AppStore } from '@/store/modules/AppStore'
import setCookie from '@/utils/SetCookie'

interface User {
  first_name: string
  last_name: string
  email: string
  password: string
  address: string
  zip: string
  birthdate: string
  city: string
  city_id: number
  state: number
  country: number
  position_title: string
  work_stablishment: string
  workplace_city: string
  workplace_state: string
  workplace_country: string
  referal: string
  lang: string
  terms: boolean
  marketing_barsmarts: boolean
  marketing_group: boolean
}

interface Payment {
  number: number
  exp_month: number
  exp_year: number
  cvc: number
}

export interface FormRegister {
  user: User
  event: string
  promocode: string
  payment: Payment
}

let price = 0;

export default defineComponent({
  components: {
    Logo,
    FormAccount,
    FormGeneral,
    FormCheckout,
    ThanksPanel,
    HeaderNotConnected,
    ErrorPopin
  },

  beforeRouteEnter(to, from, next) {
    axios.get(process.env.VUE_APP_API + 'price').then((response) => {
      if (response.status === 200 && response.data.success) {
        price = parseFloat(response.data.data.value)
      }
  
      next();
    }).catch((error) => {
      console.error(error);

      next();
    })
  },

  data() {
    let initialPrice = 0;
    if (!price) {
      initialPrice = parseFloat(this.$t('register-form.price-default'));
    } else {
      initialPrice = price;
    }

    return {
      initialPrice,

      formData: {} as FormRegister,
      currentTab: 0,
      progression: 0,

      countries: null,
      states: null,
      email: '',

      isLoading: false,
      errors: null,
      errorRequest: false
    }
  },

  mounted() {
    pageview({page_path: "/bartender-form"+this.currentTab+1});
  },

  methods: {
    onNavKeyDown(event: KeyboardEvent) {
      if (event.key === "ArrowLeft") {
        this.prevTab()
      } else if (event.key === "ArrowRight")  {
        this.nextTab()
      }
    },

    nextTab() {
      this.currentTab++

      if (this.currentTab >= 3 || this.currentTab > this.progression) {
        this.currentTab = 0;
      }

      window.scrollTo(0, 0)
      pageview({page_path: "/bartender-form"+this.currentTab+1});
    },

    prevTab() {
      this.currentTab--

      if (this.currentTab < 0) {
        this.currentTab = this.progression;
      }

      this.progression = this.currentTab

      window.scrollTo(0, 0)
    },

    onNavClick(tab) {
      if (tab > this.progression) return

      this.currentTab = tab
      this.progression = tab
    },

    mergeFormData(fields: FormRegister) {
      for (const key in fields) {
        if (fields[key] && typeof fields[key] === 'object' && !Array.isArray(fields[key])) {
          for (const subKey in fields[key]) {
            if(!this.formData[key]) this.formData[key] = {}
            this.formData[key][subKey] =  fields[key][subKey]
          }
        } else {
          this.formData[key] = fields[key]
        }
      }
    },

    onAccountSubmit(fields, countries, states) {
      this.email = fields.user.email

      this.mergeFormData(fields)

      if (!this.countries || !this.states) {
        this.countries = countries 
        this.states = states 
      }

      this.progression = 1
      this.nextTab()
    },

    onGeneralSubmit(fields) {
      this.mergeFormData(fields)
      
      this.progression = 2
      this.nextTab()
    },

    async onCheckoutSubmit(fields) {
      this.isLoading = true

      this.mergeFormData(fields)

      const data = await this.sendForm()

      if (!data) {
        this.isLoading = false
        return
      }

      this.progression = 3
      pageview({page_path: "/bartender-confirmation"});

      // window.localStorage.setItem('token', data.data?.token);
      setCookie('token_auth', data.data?.token, process.env.VUE_APP_COOKIE_DOMAIN)
      await sleep(300);
      // TO BE SURE TO LOG NEW USER
      AppStore.user = null
      this.$router.push({ path: '/dashboard' })
    },

    async sendForm() {
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

      const data = JSON.stringify(this.formData)

      try {
        const response = await axios.post(process.env.VUE_APP_API + 'users/checkout', data, { headers });
        return response.data
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data && error.response.data.errors) {
          this.errors = Object.values(error.response.data.errors)
        } else if (error.response && error.response.data && error.response.data.msg ) {
          this.errors = [error.response.data.msg]
        } else {
          this.errorRequest = true
        }
        return null
      }
    },
  }
});
