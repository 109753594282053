
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    variant: {
      type: String,
      default: 'center'
    }
  }
})
