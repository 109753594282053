
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    to: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: ''
    }
  },

  computed: {
    type() {
      return this.to ? 'router-link' : (this.href ? 'a' : 'button')
    }
  }
})
