
import { defineComponent } from 'vue'
import Flickity from 'flickity'

export default defineComponent({
  setup() {
    let flkty: Flickity
    let timerAutoPlay

    return {
      flkty,
      timerAutoPlay
    }
  },

  props: {
    autoPlay: { type: Boolean, default: false},
    wrapAround:  { type: Boolean, default: true},
    contain:  { type: Boolean, default: false},
  },

  mounted() {
    this.flkty = new Flickity( this.$el, {
      cellAlign: 'left',
      contain: this.contain,
      autoPlay: this.autoPlay ? 10000 : false,
      pauseAutoPlayOnHover: false,
      prevNextButtons: false,
      pageDots: false,
      friction: 0.6,
      wrapAround: this.wrapAround
    });
    
    this.flkty.on('dragStart', () => {
      this.$emit('dragStart')
    });
  },

  onUnmounted() {
    this.flkty.destroy()
  },

  methods: {
    onPrev() {
      this.flkty.previous()

      if (this.autoPlay) {
        clearTimeout(this.timerAutoPlay)
        this.flkty.stopPlayer()

        this.timerAutoPlay = window.setTimeout(() => {
          this.flkty.playPlayer()
        }, 10)
      }
    },

    onNext() {
      this.flkty.next()

      if (this.autoPlay) {
        clearTimeout(this.timerAutoPlay)
        this.flkty.stopPlayer()

        this.timerAutoPlay = window.setTimeout(() => {
          this.flkty.playPlayer()
        }, 10)
      }
    }
  }
})
