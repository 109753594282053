import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-093f463b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "label",
  class: "Label small-caps"
}
const _hoisted_2 = {
  ref: "circleFrom",
  class: "Circle Circle--from"
}
const _hoisted_3 = {
  ref: "circleTo",
  class: "Circle Circle--to"
}
const _hoisted_4 = {
  ref: "arrow",
  class: "Arrow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["UIBtn UIBtnSecondary", [
      `UIBtnSecondary--${_ctx.direction}`,
      `UIBtnSecondary--${_ctx.theme}`,
      `UIBtnSecondary--${_ctx.disabled}`,
    ]])
  }, [
    _createElementVNode("span", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 512),
    _createElementVNode("span", _hoisted_2, null, 512),
    _createElementVNode("span", _hoisted_3, null, 512),
    _createElementVNode("span", _hoisted_4, null, 512)
  ], 2))
}