
import { ChapterSlide } from '@/@types/props'
import { defineComponent, PropType } from 'vue'
import LazyPicture from '@/components/Global/LazyPicture.vue'
import { paragraph } from '@/utils/text'

export default defineComponent ({
  props: {
    slide: {
      type: Object as PropType<ChapterSlide>,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  },

  components: {
    LazyPicture
  },

  methods: {
    paragraph
  }
})
