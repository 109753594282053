
import { defineComponent, ref, Ref } from 'vue';
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import DashboardJobsCard from '@/components/connected/Dashboard/DashboardJobsCard.vue';
import { AppStore } from '@/store/modules/AppStore';

type State = 'no-jobs' | 'complete-profile' | 'default'

export default defineComponent({
  setup() {
    const state: Ref<State>  = ref('default');
    const jobs = AppStore.dashboard?.jobs || [];

    if (!jobs || jobs.length === 0) {
      state.value = 'no-jobs'
    }
    if (!AppStore.user.hasCompletedProfile) {
      state.value = 'complete-profile'
    }

    return {
      state,
      jobs
    }
  },

  components: {
    DashboardJobsCard,
    ButtonHover
  }
})

