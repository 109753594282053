
import { defineComponent } from "@vue/runtime-core";

import gsap from 'gsap';

export default defineComponent({
  props: {
    score: {
      type: Number,
      required: true
    },
    scoreForSuccess: {
      type: Number,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  },

  setup() {
    return {
      timeline: null as gsap.core.Timeline | null
    }
  },

  watch: {
    active() {
      if (this.active) {
        this.enter();
      }
    }
  },

  mounted() {
    if (this.active) {
      this.enter();
    }
  },

  methods: {
    reset() {
      gsap.set(this.$refs.bar as HTMLSpanElement, {clearProps: 'all'});
      gsap.set(this.$refs.base as HTMLSpanElement, {clearProps: 'all'});
      gsap.set(this.$refs.scoreForSuccess as HTMLSpanElement, {clearProps: 'all'});
      gsap.set(this.$refs.score as HTMLSpanElement, {clearProps: 'all'});
    },
    enter() {
      this.timeline = gsap.timeline({})
        .fromTo(
          this.$refs.bar as HTMLSpanElement,
          { opacity: 0 },
          { opacity: 1, duration: 0 },
          0
        )
        .fromTo(
          this.$refs.base as HTMLSpanElement,
          { xPercent: -100 },
          { xPercent: 0, duration: 1, ease: 'quart.inOut' },
          0
        )
        .fromTo(
          this.$refs.scoreForSuccess as HTMLSpanElement,
          { xPercent: -100 },
          { xPercent: -100 + (this.scoreForSuccess as number), duration: 1, ease: 'quart.inOut' },
          0.1
        )
        .fromTo(
          this.$refs.score as HTMLSpanElement,
          { xPercent: -100 },
          { xPercent: -100 + (this.score as number), duration: 1, ease: 'quart.inOut' },
          0.4
        );
    }
  }
})
