
import { Chapter, Course } from '@/@types/props'
import { getCourseBySlug } from '@/store/data-sources/course'
import { AppStore } from '@/store/modules/AppStore'
import { computed, defineComponent, PropType } from 'vue'
import LazyPicture from '@/components/Global/LazyPicture.vue'

export default defineComponent({
  props: {
    chapter: {
      type: Object as PropType<Chapter>,
      required: true,
    },
    active: {
      type: Boolean,
      required: true
    },
  },

  components: {
    LazyPicture
  },

  setup() {
    return {
      themeForceDark: computed(() => AppStore.themeForceDark)
    }
  },

  data() {
    return {
      userName: AppStore.user.firstName
    }
  },

  computed: {
    nextTo() {
      const chapter = this.chapter as Chapter
      if (chapter && chapter?.end) {
        if (chapter.end.type === 'course') {
          return '/course/' + chapter.end.id
        } else if (chapter.end.type === 'chapter') {
          return '/chapter/' + chapter.end.id
        } else {
          return '/test/' + chapter.end.id
        }
      }

      return ''
    },

    nextText() {
      const chapter = this.chapter as Chapter
      if (chapter && chapter?.end) {
        if (chapter.end.type === 'course') {
          return this.$t('chapter.nextCourse', { current: chapter.courseTitle, next: chapter.end.title })
        } else if (chapter.end.type === 'chapter') {
          return this.$t('chapter.nextChapter', { current: chapter.title, next: chapter.end.title })
        } else {

          return this.$t('chapter.next_test_' + chapter.category, { current: chapter.courseTitle, next: chapter.end.title })
        }
      }

      return ''
    },

    nextImage() {
      const chapter = this.chapter as Chapter
      const category = chapter.end.id === '1' ? 'basics' : 'professional'

      if (chapter && chapter?.end) {
        const image = chapter.end.type === 'test' ? 
        {
          url: `/images/courses/${category}-test.jpg`,
          double: {
            url: `/images/courses/${category}-test@2x.jpg`
          }
        } : chapter.end.thumbnail

        return image
      }

      return null
    }
  }
})
