
import { defineComponent } from 'vue'
import LayoutConnected from '@/layouts/Connected.vue'
import JobBoardPage from '@/components/connected/JobBoard/JobBoardPage.vue'

export default defineComponent({
  components: {
    LayoutConnected,
    JobBoardPage,
  },
})
