
import { Image } from "@/@types/props";
import { defineComponent, PropType } from "@vue/runtime-core";

export default defineComponent({
  props: {
    image: {
      type: Object as PropType<Image | null>,
      default : null
    },
    alt:{
      type: String,
      default : ''
    },
    imgClass: {
      type: String,
      default : ''
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      loaded: false
    }
  },

  methods: {
    async onLoad() {
      await this.$nextTick();
      this.loaded = true
    }
  }
})
