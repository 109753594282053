import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "fieldset__avatar" }
const _hoisted_2 = { class: "fieldset__avatar__inner" }
const _hoisted_3 = { class: "fieldset__avatar__image" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "error-msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonHover = _resolveComponent("ButtonHover")!

  return (_openBlock(), _createElementBlock("fieldset", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("input", {
          id: "avatar",
          type: "file",
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onAvatarChange && _ctx.onAvatarChange(...args))),
          accept: "image/*"
        }, null, 32),
        _createElementVNode("picture", null, [
          (_ctx.avatar)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.avatar
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode(_component_ButtonHover, {
        for: "avatar",
        class: "button button--white button--border button--small"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('profileEdit.uploadPicture')), 1)
        ]),
        _: 1
      }),
      (_ctx.hasError)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('profileEdit.uploadPictureError')), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}