
import { defineComponent } from 'vue'
import { login } from '@/store/data-sources/user'
import Logo from '@/components/Header/Logo.vue'

import LayoutConnected from '@/layouts/Connected.vue'
import LineByLine from '@/components/not-connected/LineByLine.vue'
import AppearObject from '@/components/not-connected/AppearObject.vue'
import BackButton from '@/components/buttons/BackButton.vue'
import ButtonClose from '@/components/buttons/ButtonClose.vue'
import ButtonSave from '@/components/buttons/ButtonSave.vue'
import LazyPicture from '@/components/Global/LazyPicture.vue'

export default defineComponent({
  data() {
    return {
      isPasswordObscurated: true,
      isLoading: false,
      error: null,

      password: null,
      email: null
    };
  },

  components: {
    Logo,
    ButtonSave,
    LineByLine,
    AppearObject,
    LayoutConnected,
    BackButton,
    ButtonClose,
    LazyPicture
  },

  methods: {
    submit(event: Event) {
      event.preventDefault();

      if (process.env.VUE_APP_PREVENTLOG) {
        return
      }

      if (!this.isLoading) {
        this.isLoading = true;
        this.error = null;
        login(this.email, this.password)
          .then(() => {
            if (this.$route?.hash === '#events') {
              this.$router.push('/events')
            } else {
              this.$router.push('/dashboard')
            }
          })
          .catch(this.onError)
          .finally(this.stopLoading)
      }
    },

    onError(error = null) {
      if (error && error.response && error.response.status === 401) {
        this.error = 'badcredentials';
      } else {
        this.error = 'default';
      }
    },

    stopLoading() {
      this.isLoading = false;
    },
  }
})
