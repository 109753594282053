
import { defineComponent } from 'vue'
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import ButtonClose from '@/components/buttons/ButtonClose.vue';

export default defineComponent({
  props: {
    active: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    labelAccept: {
      type: String,
      default: ''
    },
    labelDecline: {
      type: String,
      default: ''
    },
  },

  emits: ['onAccept', 'onDecline'],

  components: {
    ButtonHover,
    ButtonClose
  },

  methods: {
    onAccept() {
      this.$emit('onAccept');
    },
    onDecline() {
      this.$emit('onDecline');
    }
  }
})
