
import { Scroll } from "@/@types/scroll"
import { Viewport } from "@/store/modules/Viewport"
import { defineComponent, inject, provide, ref, Ref, watch } from "@vue/runtime-core"
import { onBeforeRouteUpdate, useRoute } from "vue-router"
import JobBoardForm from './JobBoardForm.vue'
import JobBoardList from './JobBoardList.vue'
import JobBoardItem from './JobBoardItem.vue'
import StickyElement from '@/components/not-connected/StickyElement.vue';
import { Job, JobPage } from "@/@types/props"
import { AppStore } from "@/store/modules/AppStore"
import { fetchJob, fetchJobPage } from "@/store/data-sources/job"

export default defineComponent({
  setup() {
    const jobPage: Ref<JobPage> = ref(AppStore.jobPage);
    const route = useRoute()

    const job = ref(AppStore.job) as Ref<Job | null>
    const isLoading = ref(false)

    watch(
      () => route.path,
      () => {
        if (route.path.startsWith('/job-board')) {
          if (route.params.job) {
            fetchJob(route.params.job as string)
          } else {
            AppStore.job = null
          }
        }
      }
    )
    watch(
      () => AppStore.job,
      () => {
        job.value = AppStore.job
      }
    )

    const scroll: Ref<Scroll> = inject('scroll') as Ref<Scroll>;
    onBeforeRouteUpdate(() => {
      scroll.value.resetOnRouteChange = false
    })

    provide('job', job)
    provide('jobPage', jobPage)
    provide('isLoading', isLoading)

    return {
      jobPage,
      job,
      isLoading,
      // search
    }
  },

  components: { 
    JobBoardForm,
    JobBoardList,
    JobBoardItem,
    StickyElement
  },

  mounted() {
    if (!Viewport.isMobile) {
      // TODO Redirect to first job
    }
  },

  methods: {
    async onChange() {
      this.isLoading = true
      AppStore.job = null
      this.jobPage = await fetchJobPage(this.jobPage.country, this.jobPage.state, this.jobPage.city, 0)
      this.isLoading = false
    },
    async onLoadMore() {

      this.isLoading = true
      const nextPage = await fetchJobPage(this.jobPage.country, this.jobPage.state, this.jobPage.city, this.jobPage.page + 1)
      this.isLoading = false

      this.jobPage.jobs = this.jobPage.jobs.concat(nextPage.jobs)
      this.jobPage.page = nextPage.page
    }
  }
})
