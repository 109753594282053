import { AppStore } from "../modules/AppStore";

import { Job, JobPage } from "@/@types/props";
import axios from "axios";
import { Viewport } from "../modules/Viewport";
import { addRewards } from "../modules/Rewards";
import formatMediaUrl from "@/utils/mediaUrl";
import { parseAchievements } from "./user";

export async function fetchDashboardJobs(): Promise<Array<Job> | null> {
  // /jobs/dashboard

  return axios.get(
    process.env.VUE_APP_API + 'jobs/dashboard', 
    {
      headers: {
        'x-token-auth': AppStore.user.token,
        'x-lang': AppStore.user?.lang,
      }
    }
  ).then((result) => {
    if (result.status === 200 && result.data.success) {
      return parseJobs(result.data.data.jobs)
    }

    return null
  })
  .catch((error) => {
    console.warn(error);
    return null
  })
}

export async function fetchInitialJobPage(jobSlug = null): Promise<JobPage | null> {

  if (jobSlug) {
    await fetchJob(jobSlug);
  }

  if (AppStore.job) {
    AppStore.jobPage = await innerFetchJobs({ country: AppStore.job.countryId, state: AppStore.job.stateId, city: AppStore.job.cityId })

    if (AppStore.jobPage) {
      AppStore.jobPage.country = AppStore.job.countryId;
      AppStore.jobPage.state = AppStore.job.stateId;
      AppStore.jobPage.city = AppStore.job.cityId;
    }

  } else {
    AppStore.jobPage = await innerFetchJobs()
  }

  if (AppStore.jobPage) {
    if (AppStore.jobPage.jobs.length === 0) {
      AppStore.jobPage = await innerFetchJobs({country: 230})
      AppStore.jobPage.country = 230;
      AppStore.jobPage.state = null;
      AppStore.jobPage.city = null;
    }
  
    if (!Viewport.isMobile && !AppStore.job) {
      AppStore.job = AppStore.jobPage.jobs[0]
    }
  }

  return AppStore.jobPage;
}


export async function fetchJobPage(country:number|string|null = null, state:number|string|null = null, city:number|string|null = null, page = 0): Promise<JobPage | null> {
  const params = {
    country: country === '' ? null : country,
    state: country !== 230 || state === '' ? null : state,
    city: country !== 230 || city === '' ? null : city,
  }

  AppStore.jobPage = await innerFetchJobs(params, page)
  if (AppStore.jobPage) {
    AppStore.jobPage.country = country;
    AppStore.jobPage.state = state;
    AppStore.jobPage.city = city;

    if (!Viewport.isMobile && page === 0 && !AppStore.job) {
      AppStore.job = AppStore.jobPage.jobs[0]
    }
  }
  return AppStore.jobPage;
}

type JobsParams = {
  country?: number|string
  state?: number|string
  city?: number|string
  offset?: number
  limit?: number
}
function innerFetchJobs(params: JobsParams = {}, page = 0): Promise<JobPage | null> {
  const limit = parseInt(process.env.VUE_APP_JOB_PER_PAGE) || 5;

  params.offset = page * limit;
  params.limit = limit

  return axios.get(
    process.env.VUE_APP_API + 'jobs/browse', 
    {
      params,
      headers: {
        'x-token-auth': AppStore.user.token,
        'x-lang': AppStore.user?.lang,
      }
    }
  ).then((result) => {
    if (result.status === 200 && result.data.success) {
      AppStore.jobPage = parseJobPage(result.data.data, page)

      return AppStore.jobPage
    }

    return null
  })
  .catch((error) => {
    console.warn(error);
    return null
  })
}


export function fetchJob(jobSlug: string): Promise<Job> {
  if (AppStore.job && AppStore.job.slug === jobSlug) {
    return Promise.resolve().then(() => AppStore.job)
  } else {
    return axios.get(
      process.env.VUE_APP_API + 'jobs/'+jobSlug, 
      {
        headers: {
          'x-token-auth': AppStore.user.token,
          'x-lang': AppStore.user?.lang,
        }
      }
    ).then((result) => {
      if (result.status === 200 && result.data.success) {
        AppStore.job = parseJob(result.data.data)
  
        return AppStore.job
      }
  
      return null
    })
    .catch((error) => {
      console.warn(error);
      return null
    })
  }
}

function parseJobPage(data, offset: number): JobPage | null {
  const limit = parseInt(process.env.VUE_APP_JOB_PER_PAGE) || 5;

  return {
    country: data.country,
    state: data.state,
    city: data.city,
    countries: data.countries,
    states: data.states,
    cities: data.cities,
    jobs: parseJobs(data.jobs),
    count: data.count,
    limit,
    page: offset,
    maxPage: Math.ceil(data.count / limit)
  }
}

function parseJobs(items): Array<Job> {
  const jobs: Array<Job> = [];

  items.forEach((item) => {
    jobs.push(parseJob(item))
  })

  return jobs
}

function parseJob(item): Job {
  return {
    countryId: item.countryId,
    stateId: item.stateId,
    cityId: item.cityId,

    title: item.title,
    slug: item.id,
    restaurant: item.stablishment,
    location: item.location,
    recruiter: item.recruiter,
    recruiter_img: formatMediaUrl(item.recruiterAvatar),

    aboutUs: item.aboutUs,
    aboutJob: item.aboutJob,
    salary: item.salary,
    applied: item.applied,
  }
}


export async function applyToJob(jobSlug: string): Promise<boolean> {
  return axios.post(
    process.env.VUE_APP_API + 'jobs/' + jobSlug + '/apply', 
    {}, 
    {
      headers: {
        'x-token-auth': AppStore.user.token,
        'x-lang': AppStore.user?.lang,
      }
    }
  ).then((result) => {
    if (result.status === 200 && result.data.success) {
      if (result.data?.data?.achievements) {
        addRewards(parseAchievements(result.data?.data?.achievements))
      }
      const job: Job = AppStore.jobPage.jobs.find((job) => job.slug === jobSlug)
      if (job) {
        job.applied = true
      }
      if (AppStore.job && AppStore.job.slug === jobSlug) {
        AppStore.job.applied = true
      }
      return true
    }

    return false
  })
  .catch((error) => {
    console.warn(error);
    return false
  })

  
  // const job: Job = AppStore.jobPage.jobs.find((job) => job.slug === jobSlug)
  // if (job) {
  //   job.applied = true
  // }
  // if (AppStore.job && AppStore.job.slug === jobSlug) {
  //   AppStore.job.applied = true
  // }
  // return Promise.resolve().then(() => true)
}

export async function reportJob(jobSlug: string): Promise<boolean> {
  return axios.post(
    process.env.VUE_APP_API + 'jobs/' + jobSlug + '/report', 
    {}, 
    {
      headers: {
        'x-token-auth': AppStore.user.token,
        'x-lang': AppStore.user?.lang,
      }
    }
  ).then((result) => {
    if (result.status === 200 && result.data.success) {
      const job: Job = AppStore.jobPage.jobs.find((job) => job.slug === jobSlug)
      if (job) {
        job.reported = true
      }
      if (AppStore.job && AppStore.job.slug === jobSlug) {
        AppStore.job.reported = true
      }
      return true
    }

    return false
  })
  .catch((error) => {
    console.warn(error);
    return false
  })

  
  // const job: Job = AppStore.jobPage.jobs.find((job) => job.slug === jobSlug)
  // if (job) {
  //   job.applied = true
  // }
  // if (AppStore.job && AppStore.job.slug === jobSlug) {
  //   AppStore.job.applied = true
  // }
  // return Promise.resolve().then(() => true)
}