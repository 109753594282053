
import { defineComponent } from 'vue'
import ParallaxObject from '@/components/not-connected/ParallaxObject.vue'
import LayoutNotConnected from '@/layouts/NotConnected.vue'
import ButtonHover from '@/components/not-connected/ButtonHover.vue'
import AppearObject from '@/components/not-connected/AppearObject.vue'
import LineByLine from '@/components/not-connected/LineByLine.vue'
// import RegisterPopin from '@/components/not-connected/RegisterPopin.vue';
import { event } from 'vue-gtag'
import axios from 'axios'

let price = null;

export default defineComponent({
  components: {
    LayoutNotConnected,
    ParallaxObject,
    ButtonHover,
    AppearObject,
    LineByLine,
    // RegisterPopin
  },

  async beforeRouteEnter(to, from, next) {
    const response = await axios.get(process.env.VUE_APP_API + 'price')

    if (response.status === 200 && response.data.success) {
      price = parseFloat(response.data.data.value)
    }

    next();
  },

  data() {
    let formatedPrice = '';
    if (!price) {
      formatedPrice = this.$t('register-page.price-default');
    } else {
      formatedPrice = this.$t('register-page.price-format', {price: price.toFixed(2)});
    }

    return {
      price: formatedPrice,
      // showRegisterPopin: false,
    }
  },

  methods: {
    // onRegisterPopinClose() {
    //   this.showRegisterPopin = false
    // },

    onRegister() {
      // this.showRegisterPopin = true

      event('register', {
        'event_category': 'register',
      })
    }
  }
})
