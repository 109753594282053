import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-958688ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Terms" }
const _hoisted_2 = {
  class: "Terms-intro padded",
  "data-scroll-section": ""
}
const _hoisted_3 = { class: "Terms-intro-title h1 h1--big" }
const _hoisted_4 = {
  class: "Terms-content grid padded",
  "data-scroll-section": ""
}
const _hoisted_5 = { class: "Terms-content-right" }
const _hoisted_6 = { class: "wysiwyg" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutNotConnected = _resolveComponent("LayoutNotConnected")!

  return (_openBlock(), _createBlock(_component_LayoutNotConnected, null, {
    default: _withCtx(() => [
      _createElementVNode("main", _hoisted_1, [
        _createElementVNode("section", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('disclosure.title')), 1)
        ]),
        _createElementVNode("section", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h4", null, _toDisplayString(_ctx.$t('disclosure.subtitle')), 1),
              _createElementVNode("p", {
                innerHTML: _ctx.$t('disclosure.description')
              }, null, 8, _hoisted_7)
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}