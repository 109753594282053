
import { defineComponent } from "@vue/runtime-core"
import gsap, { Back } from 'gsap'

export default defineComponent({
  props: {
    playing: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      timeline: null as gsap.core.Timeline | null
    }
  },

  watch: {
    playing() {
      if (this.timeline) {
        if (this.playing) {
          this.timeline.play(0);
        } else {
          this.timeline.pause();
        }
      }
    }
  },

  mounted() {
    const _card = this.$el.querySelector('.card');
    const _rect = this.$el.querySelector('rect');

    const _circle = this.$el.querySelector('circle');
    const _path = this.$el.querySelector('path');

    this.timeline = gsap.timeline({ repeat: 0, repeatDelay: 0.5, pause: !this.playing })
      .fromTo(
        _rect,
        { opacity: 0 },
        { opacity: 1, duration: 1, ease: 'quart.inOut' },
        0
      )
      .fromTo(
        _rect,
        { transformOrigin: '50% 50%', rotate: -180, scale: 0.66 },
        { rotate: 4, scale: 1, duration: 1, ease: Back.easeOut.config(1) },
        0
      )
      .fromTo(
        _card,
        { opacity: 1 },
        { opacity: 0.3, duration: 1, ease: 'quad.out' },
        0.7
      )
      .fromTo(
        _card,
        { transformOrigin: '50% 120', rotate: -30 },
        { rotate: 0, duration: 1, ease: Back.easeOut.config(1) },
        0
      )

      .fromTo(
        _circle,
        { transformOrigin: '50% 50%', scale: 0 },
        { scale: 1, duration: 1, ease: Back.easeOut.config(1.7) },
        0.7
      )
      .fromTo(
        _path,
        { strokeDasharray: 13, strokeDashoffset: 13 },
        { strokeDashoffset: 0, duration: 1, ease: 'quart.out' },
        1
      )

  },

  unmounted() {
    this.timeline && this.timeline.kill()
  }
})
