
import { defineComponent } from 'vue'

import LayoutConnected from '@/layouts/Connected.vue'
import Logo from '@/components/Header/Logo.vue'
import LineByLine from '@/components/not-connected/LineByLine.vue'
import AppearObject from '@/components/not-connected/AppearObject.vue'
import BackButton from '@/components/buttons/BackButton.vue'
import ButtonClose from '@/components/buttons/ButtonClose.vue'
import ButtonSave from '@/components/buttons/ButtonSave.vue'
import LazyPicture from '@/components/Global/LazyPicture.vue'
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import { required, email, maxLength, helpers } from '@vuelidate/validators'
import getOffsetTop from '@/utils/getOffsetTop'
import gsap from 'gsap'
import sleep from '@/utils/Sleep'

export default defineComponent({

  setup () {
    return { 
      v$: useVuelidate(),
    }
  },

  data() {
    return {
      step: 1,

      sended: false,
      loading: false,

      errorMessage: null,
      form: {
        email: ''
      },
    };
  },

  components: {
    Logo,
    ButtonSave,
    LineByLine,
    AppearObject,
    LayoutConnected,
    BackButton,
    ButtonClose,
    LazyPicture
  },

  watch: {
    'form': {
      handler() {
        this.sended = false;
        this.errorMessage = null;
      },
      deep: true
    }
  },

  validations () {
    return {
      form: {
        email: { 
          email: helpers.withMessage(this.$t('account.email_validation'), email), 
          required: helpers.withMessage(this.$t('account.email_validation'), required), 
          maxLength: maxLength(255), 
        }
      }
    }
  },

  methods: {
    async onSubmit() {
      this.loading = true

      const result = await this.v$?.$validate()
      if (!result) {
        console.warn('form not valid')
        this.errorMessage = this.$t('account.validation_error');

        const firstError = this.$el.querySelector('input.error, select.error');
        if (firstError) {
          gsap.to(
            [window, document.body],
            { scrollTo: {y: getOffsetTop(firstError) - 100}}
          )
        }
        this.loading = false
        
        return
      }

      const response = await axios.post(
        process.env.VUE_APP_API + 'users/forget', 
        { 
          email: this.form.email 
        },
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      ).catch((error) => {
        console.error(error);
        this.loading = false 
        this.errorMessage = this.$t('account.default_error') 

        return false;
      });

      console.log({response})
      this.loading = false;
      if (response && (response as any).data?.success) {
        this.sended = true
        await sleep(1500);
        this.step = 2;
      } else {
        console.log('default error');
        this.errorMessage = this.$t('account.default_error')
      }
    }
  }
})
