import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9053410a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "popin",
  class: "NewTermsPopin padded grid",
  key: 'new-terms-popin'
}
const _hoisted_2 = { class: "NewTermsPopin__Content" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "NewTermsPopin__Button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonHover = _resolveComponent("ButtonHover")!
  const _component_FocusTrap = _resolveComponent("FocusTrap")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, {
      name: "popin",
      duration: 1200
    }, {
      default: _withCtx(() => [
        (_ctx.active)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_FocusTrap, { active: _ctx.active }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", {
                      class: "NewTermsPopin__Title h1",
                      innerHTML: _ctx.$t('newTerms.title')
                    }, null, 8, _hoisted_3),
                    _createElementVNode("div", {
                      class: "NewTermsPopin__Text text-lg",
                      innerHTML: _ctx.$t('newTerms.text')
                    }, null, 8, _hoisted_4),
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_ButtonHover, {
                        "data-fixed": "",
                        onClick: _ctx.onClick,
                        class: "button"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('newTerms.action')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["active"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}