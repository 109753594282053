
import { defineComponent, ref } from "@vue/runtime-core";
import gsap from 'gsap';

import LazyPicture from '@/components/Global/LazyPicture.vue';
import { Viewport } from "@/store/modules/Viewport";


export default defineComponent({
  setup() {
    return {
      timeline: null as null | gsap.core.Timeline,
      isTouch: ref(Viewport.isTouch)
    }
  },

  components: {
    LazyPicture,
  },

  mounted() {
    this.timeline = gsap.timeline({repeat: -1, repeatDelay: 1});

    this.timeline.fromTo(
      this.$refs.phone as SVGElement,
      { rotate: -90 },
      { rotate: 0, ease: 'back.inOut', duration: 1 },
      0
    )
  },

  unmounted() {
    this.timeline && this.timeline.kill();
  }
});
