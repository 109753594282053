import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7fba0bc7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Menu__Wrapper" }
const _hoisted_2 = { class: "Menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/dashboard" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('menu_connected.item1')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/courses" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('menu_connected.item2')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/glossary" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('menu_connected.item3')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/job-board" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('menu_connected.item4')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/events" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('menu_connected.item5')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/resources" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('menu_connected.item6')), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}