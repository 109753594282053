
import { defineComponent } from 'vue'
import LayoutConnected from '@/layouts/Connected.vue';
import ProfilePage from '@/components/connected/Profile/ProfilePage.vue'

export default defineComponent({

  components: {
    LayoutConnected,
    ProfilePage,
  },
})
