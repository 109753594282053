
import { defineComponent, inject, Ref, ref, watch} from 'vue'
import ButtonSave from '@/components/buttons/ButtonSave.vue'
import ButtonClose from '@/components/buttons/ButtonClose.vue'
import { Job } from '@/@types/props'
import sleep from '@/utils/Sleep'
import { reportJob } from '@/store/data-sources/job'
import { FocusTrap } from 'focus-trap-vue'

export default defineComponent({
  setup() {
    const active = ref(false)


    return {
      active,
      job: inject('job') as Ref<Job>,
      loading: ref(false),
    }
  },

  components: {
    ButtonSave,
    ButtonClose,
    FocusTrap
  },

  methods: {
    open() {
      this.active = true
    },

    async onApply() {
      this.loading = true

      await reportJob(this.job.slug);

      this.loading = false
    },

    onCompleted() {
      this.active = false
    },

    onClose() {
      this.active = false
    }
  }
})
