import '@/styles/index.styl';

import i18n from '@/core/i18n';
import { createApp } from 'vue'
import App from './App.vue'
import GlobalComponents from '@/components'
import Router from "@/core/Router";
import VueGtag from "vue-gtag";

const app = createApp(App);

app.use(Router);

app.use(
  VueGtag, 
  {
    bootstrap: false,
    config: { 
      id: process.env.VUE_APP_GTM_ID
    },
  }, 
  Router
);

GlobalComponents.register(app);

app.use(i18n);

app.mount('#app');
