import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-229e8051"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "GlossaryHeader__Inner" }
const _hoisted_2 = { class: "base" }
const _hoisted_3 = ["data-title"]
const _hoisted_4 = {
  class: "GlossaryHeader__Menu__Top",
  ref: "menuTop"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LineByLine = _resolveComponent("LineByLine")!
  const _component_AppearObject = _resolveComponent("AppearObject")!
  const _component_GlossaryMenu = _resolveComponent("GlossaryMenu")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["GlossaryHeader", { 'is-mobile-sticked': _ctx.Viewport.isMobile && _ctx.isMobileSticked }]),
    "data-scroll-section": "",
    "data-scroll-sticky": "",
    "data-scroll-sticky-offset": "54.861%"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AppearObject, { class: "GlossaryHeader__Title" }, {
        default: _withCtx(() => [
          _createElementVNode("h1", null, [
            _createElementVNode("span", _hoisted_2, [
              _createVNode(_component_LineByLine, {
                innerHTML: _ctx.$t('glossary.title')
              }, null, 8, ["innerHTML"])
            ]),
            _createElementVNode("span", {
              class: "alt",
              "data-title": _ctx.$t('glossary.title')
            }, null, 8, _hoisted_3)
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_4, null, 512),
      _createVNode(_component_GlossaryMenu, {
        class: "GlossaryHeader__Menu",
        onGoToLetter: _cache[0] || (_cache[0] = (letter) => _ctx.$emit('goToLetter', letter))
      })
    ])
  ], 2))
}