import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2000b4f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "courses-completion__progress" }
const _hoisted_2 = { ref: "top" }
const _hoisted_3 = { ref: "bottom" }
const _hoisted_4 = { ref: "center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512),
    _createElementVNode("div", _hoisted_3, null, 512),
    _createElementVNode("div", _hoisted_4, null, 512)
  ]))
}