
import { defineComponent } from 'vue'
import useVuelidate from '@vuelidate/core'
import { minLength, maxLength, minValue, maxValue, required, requiredIf } from '@vuelidate/validators'
import ButtonSave from '@/components/buttons/ButtonSave.vue'
import axios from 'axios'
import { AppStore } from '@/store/modules/AppStore'
import { User } from '@/@types/props'

import ErrorPopin from '@/components/not-connected/ErrorPopin.vue'
import LeavePopin from '@/components/connected/popins/LeavePopin.vue'
import { updateCurrentUser } from '@/store/data-sources/user'
import getOffsetTop from '@/utils/getOffsetTop'
import gsap from 'gsap'


export default defineComponent({
  setup () {
    return { 
      v$: useVuelidate(),
    }
  },

  components: {
    ButtonSave,
    ErrorPopin,
    LeavePopin
  },

  data() {
    const user: User | null = AppStore.user

    const birthdayParse = Date.parse(user?.birthday)
    // const debug = (new Date()).toDateString();
    // const birthdayParse = Date.parse(debug)
    let birthdayYear = null
    let birthdayMonth = null
    let birthdayDay = null
    if (!isNaN(birthdayParse)) {
      const birthday: Date = new Date(birthdayParse);

      birthdayYear = birthday.getFullYear();
      birthdayMonth = (birthday.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false});
      birthdayDay = birthday.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false});
    }

    return {
      countries: null,
      states: null,
      
      form: {
        address: {
          cities: null,

          countryId: user?.address?.countryId,
          stateId: user?.address?.stateId,
          city: user?.address?.city,
          cityId: user?.address?.cityId || '',
        },
        streetAddress: user?.address?.streetAddress,
        zip: user?.address?.zip,
        
        birthdayYear,
        birthdayMonth,
        birthdayDay,
  
        establishment: user?.workplace?.establishment,
        position: user?.workplace?.position,
        // workplace: {
        //   cities: null,

        //   countryId: user?.workplace?.countryId,
        //   stateId: user?.workplace?.stateId,
        //   city: user?.workplace?.city,
        //   cityId: user?.workplace?.cityId || '',
        // }
        
      },

      errorRequest: false,
      errorMessage: null,

      edited: false,
      saved: false,
      isLoading: false,
      showLeavePopin: false,
      preventedTo: null,

      user,
    }
  },

  watch: {
    'form.address.countryId'() {
      if (this.form.address.countryId !== 230) {
        this.form.address.stateId = 52
      }
      if (this.form.address.stateId && this.form.address.stateId !== 52) {
        this.loadCities(this.form.address)
      }
    },

    'user.lang': async function() {
      const data = await Promise.all([
        await this.getCountries(),
        await this.getStates(),
      ])

      if (!data[0] || !data[1]) {
        this.isLoading = false
        return
      }

      this.countries = data[0]
      this.states = data[1]
    },

    // 'form.workplace.countryId'() {
    //   if (this.form.workplace.countryId !== 230) {
    //     this.form.workplace.stateId = 52
    //   }
    //    if (this.form.workplace.stateId && this.form.workplace.stateId !== 52) {
    //     this.loadCities(this.form.workplace)
    //   }
    // },

    async 'form.address.stateId'() {
      if (this.form.address.countryId === 230) {
        this.loadCities(this.form.address)
      }
    },

    // async 'form.workplace.stateId'() {
    //   if (this.form.workplace.countryId === 230) {
    //     this.loadCities(this.form.workplace)
    //   }
    // },

    async 'form.address.cityId'() {
      this.form.address.city = ''
    },

    // async 'form.workplace.cityId'() {
    //   this.form.workplace.city = ''
    // },

    'form': {
      handler() {
        this.saved = false;
        this.edited = true;
        this.errorMessage = null;
      },
      deep: true
    }
  },

  validations () {
    return {
      form: {
        address: {
          countryId: { required },
          stateId: { requiredIf: requiredIf(this.form.address.countryId === 230) },
          cityId: { requiredIf: requiredIf(this.form.address.countryId === 230 && (this.form.address.stateId && this.form.address.stateId !== 52)) },
          city: { requiredIf: requiredIf(this.form.address.countryId !== 230) },
        },

        // workplace: {
        //   countryId: { required },
        //   stateId: { requiredIf: requiredIf(this.form.workplace.countryId === 230) },
        //   cityId: { requiredIf: requiredIf(this.form.workplace.countryId === 230 && (this.form.workplace.stateId && this.form.workplace.stateId !== 52)) },
        //   city: { requiredIf: requiredIf(this.form.workplace.countryId !== 230) },
        // },

        birthdayMonth: { minLength: minLength(2), maxLength: maxLength(2), minValue: minValue(1), maxValue: maxValue(12) },
        birthdayDay: { minLength: minLength(2), maxLength: maxLength(2), minValue: minValue(1), maxValue: maxValue(31) },
        birthdayYear: { minLength: minLength(4), maxLength: maxLength(4) },
      }
    }
  },

  async mounted() {
    if (!this.countries || !this.states) {
        const data = await Promise.all([
          await this.getCountries(),
          await this.getStates(),
          await this.getCities(this.form.address),
          // await this.getCities(this.form.workplace),
        ])

        if (!data[0] || !data[1] || !data[2]) {
          this.isLoading = false
          return
        }

        this.countries = data[0]
        this.states = data[1]
        this.form.address.cities = data[2]
        // this.form.workplace.cities = data[3]
        await this.$nextTick()
        this.edited = false
      }
  },

  methods: {
    onBeforeRouteLeaveGuard(to, from, next) {
      if (!this.saved && this.edited ) {
        this.preventedTo = to;
        next(false)
        this.showLeavePopin = true;
      } else {
        next();
      }
    },
    onLeaveAccept() {
      this.showLeavePopin = false;
      this.edited = false;
      if (this.preventedTo) {
        this.$router.push(this.preventedTo);
      }
    },
    onLeaveDecline() {
      this.showLeavePopin = false;
    },

    async loadCities(group) {
      this.isLoading = true
      group.cities = await this.getCities(group);
      this.isLoading = false
    },

    async getCountries() {
      try {
        const response = await axios.get(process.env.VUE_APP_API + 'countries', { params: { lang: this.user.lang } });
        return response.data.data
      } catch (error) {
        console.error(error);
        this.errorRequest = true
        return null
      }
    },

    async getStates() {
      try {
        const response = await axios.get(process.env.VUE_APP_API + 'states', { params: { lang: this.user.lang } });
        return response.data.data
      } catch (error) {
        console.error(error);
        this.errorRequest = true
        return null
      }
    },

    async getCities(group) {
      if (group.countryId === 230 && group.stateId && group.stateId !== 52) {
        try {
          const response = await axios.get(process.env.VUE_APP_API + `states/${ group.stateId }/cities`, { headers: { 'x-token-auth': AppStore.user?.token, 'x-lang': AppStore.user?.lang, } });
          return response.data.data
        } catch (error) {
          console.error(error);
          this.errorRequest = true
          return null
        }
      }

      return Promise.resolve().then(() => []);
    },

    onDateKeyUp(event: KeyboardEvent) {
      if(event.key === 'Tab' || event.key === 'Shift' || event.key === 'Meta' || event.key === 'Control' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') return
      
      const target = event.target as HTMLInputElement
      const { maxLength, value, nextElementSibling } = target;

      if (value.length >= maxLength) {
        const nextInput = (nextElementSibling as HTMLInputElement);
        nextInput.focus()
      }
    },

    onDateKeyPress(event: KeyboardEvent) {
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      const target = event.target as HTMLInputElement
      const currentValue = target.value;
      const maxlength = parseInt(target.getAttribute('maxlength'))
      if ((currentValue.length) >= maxlength) {
        event.preventDefault();
        return false;
      }

      if ((keyCode > 31 && (keyCode < 48 || keyCode > 57)) && keyCode !== 46) {
        event.preventDefault();
      }
    },

    async onSubmit() {
      const result = await this.v$?.$validate()

      if (!result) {
        console.warn('form not valid')
        this.errorMessage = this.$t('account.validation_error');

        const firstError = this.$el.querySelector('input.error, select.error');
        if (firstError) {
          gsap.to(
            [window, document.body],
            { scrollTo: {y: getOffsetTop(firstError) - 100}}
          )
        }
        
        return
      }

      this.isLoading = true;

      const data = {
        country: this.form.address.countryId,
        state: this.form.address.stateId,
        city: this.form.address.city,
        city_id: this.form.address.cityId,
        address: this.form.streetAddress || '',
        zip: this.form.zip || '',

        birthdate: this.form.birthdayYear && this.form.birthdayMonth && this.form.birthdayDay ? `${this.form.birthdayYear}-${this.form.birthdayMonth}-${this.form.birthdayDay}` : '',

        position_title: this.form.position || '',
        work_stablishment: this.form.establishment || '',
        // workplace_country: this.form.workplace.countryId,
        // workplace_state: this.form.workplace.stateId,
        // workplace_city: this.form.workplace.city,
        // workplace_city_id: this.form.workplace.cityId
      }


      if (data.city_id === '') {
        delete data.city_id;
      }
      if (data.city === '') {
        delete data.city;
      }
      // if (data.workplace_city_id === '') {
      //   delete data.workplace_city_id;
      // }
      // if (data.workplace_city === '') {
      //   delete data.workplace_city;
      // }


      const response = await updateCurrentUser(data)

      this.isLoading = false
      this.saved = response === true
      this.edited = false


      if (response !== true) {
        this.errorMessage = response ? response : this.$t('account.default_error');
      }
    }
  }
})
