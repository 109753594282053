
import { defineComponent } from 'vue'

import LayoutConnected from '@/layouts/Connected.vue'
import Logo from '@/components/Header/Logo.vue'
import LineByLine from '@/components/not-connected/LineByLine.vue'
import AppearObject from '@/components/not-connected/AppearObject.vue'
import BackButton from '@/components/buttons/BackButton.vue'
import ButtonClose from '@/components/buttons/ButtonClose.vue'
import ButtonSave from '@/components/buttons/ButtonSave.vue'
import ButtonHover from '@/components/not-connected/ButtonHover.vue'
import sleep from '@/utils/Sleep'
import LazyPicture from '@/components/Global/LazyPicture.vue'
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, sameAs, helpers } from '@vuelidate/validators'
import axios from 'axios'
import gsap from 'gsap'
import getOffsetTop from '@/utils/getOffsetTop'

export default defineComponent({

  setup () {
    return { 
      v$: useVuelidate(),
    }
  },

  data() {
    return {
      step: 1,

      new_password: null,
      confirm_new_password: null,
      token: null,

      isPasswordObscurated: true,

      sended: false,
      loading: false,

      errorMessage: null,
    };
  },

  components: {
    Logo,
    ButtonSave,
    LineByLine,
    AppearObject,
    LayoutConnected,
    BackButton,
    ButtonClose,
    ButtonHover,
    LazyPicture
  },

  validations () {
    return {
      // new_password: { 
      //   required, 
      //   minLength: helpers.withMessage(this.$t('account.password_validation'), minLength(8)), 
      //   maxLength: maxLength(255) 
      // },
      // confirm_new_password: { 
      //   required, 
      //   minLength: helpers.withMessage(this.$t('account.password_validation'), minLength(8)), 
      //   sameAs: helpers.withMessage(this.$t('register-form.error-password-same-as'), sameAs(this.new_password)),
      //   maxLength: maxLength(255) 
      // },

      new_password: { 
          minLength: helpers.withMessage(this.$t('account.password_validation'), minLength(8)), 
          maxLength: maxLength(255), 
          required: helpers.withMessage(this.$t('account.password_validation'), required), 
        },
        confirm_new_password: { 
          sameAs: helpers.withMessage(this.$t('account.password_error'), sameAs(this.new_password)),
        },
    }
  },

  methods: {
    async onSubmit() {
      this.loading = true

      const result = await this.v$?.$validate()
      if (!result) {
        console.warn('form not valid')
        this.errorMessage = this.$t('account.validation_error');

        const firstError = this.$el.querySelector('input.error, select.error');
        if (firstError) {
          gsap.to(
            [window, document.body],
            { scrollTo: {y: getOffsetTop(firstError) - 100}}
          )
        }
        this.loading = false
        
        return;
      }
      
      axios.post(
        process.env.VUE_APP_API + 'users/forgetreset', 
        {
          password: this.new_password,
          token: this.$route.query.token
        },
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      ).then((response) => {
        this.loading = false
        if (response && (response as any).data?.success) {
          this.sended = true
          sleep(1500).then(() => {
            this.step = 2;
          })
        } else {
          this.errorMessage = this.$t('account.default_error')
        }
      })
      .catch((error) => {
        console.error(error);
        this.loading = false 
        if (error.response.status === 404) {
          this.errorMessage = this.$t('resetPassword.token_already_used') 
        } else {
          this.errorMessage = this.$t('account.default_error') 
        }
      });
    }
  }
})
