
import { defineComponent, onMounted, onUnmounted, Ref, ref, watch} from 'vue'
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import { FocusTrap } from 'focus-trap-vue'
import { AppStore } from '@/store/modules/AppStore';
import axios from 'axios'

export default defineComponent({
  setup() {
    const active = ref(false);
    
    const checkIfNeeded = () => {
      if (AppStore.user && AppStore.user.needToRevalidateTerms) {
        if (window.localStorage) {
          const token = window.localStorage.getItem('new_terms::'+AppStore.user?.slug);

          if (token !== (process.env.VUE_APP_TUTORIAL_TOKEN || 'yes')) {
            active.value = true
            AppStore.popins.newTerms = true;
            document.body.parentElement.classList.add('has-terms-open');
          }
        }
      }
    }

    onMounted(() => {
      setTimeout(checkIfNeeded, 600)
    })
    onUnmounted(() => {
      document.body.parentElement.classList.remove('has-terms-open');
    })

    return {
      active
    }
  },

  components: {
    ButtonHover,
    FocusTrap
  },

  methods: {
    async onClick() {
      const result = await axios.post(
        process.env.VUE_APP_API + 'users/current', 
        { 'terms': true }, 
        {
          headers: {
            'x-token-auth': AppStore.user?.token,
            'x-lang': AppStore.user?.lang,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );

      if (result.data?.success) {
        window.localStorage.setItem('new_terms::' + AppStore.user?.slug, (process.env.VUE_APP_TUTORIAL_TOKEN || 'yes'));
        AppStore.user.needToRevalidateTerms = false
        this.active = false
        AppStore.popins.newTerms = false;
        document.body.parentElement.classList.remove('has-terms-open');
      }
    }
  }
})
