import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-faf12b12"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "Progress__Bar",
  ref: "bar"
}
const _hoisted_2 = { ref: "base" }
const _hoisted_3 = { ref: "scoreForSuccess" }
const _hoisted_4 = { ref: "score" }
const _hoisted_5 = { class: "Progress__Score" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['Progress', Math.round(_ctx.score) >= _ctx.scoreForSuccess ? 'Progress--Succeeded' : 'Progress--Failed'])
  }, [
    _createElementVNode("span", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, null, 512),
      _createElementVNode("span", _hoisted_3, null, 512),
      _createElementVNode("span", _hoisted_4, null, 512)
    ], 512),
    _createElementVNode("span", _hoisted_5, _toDisplayString(Math.round(_ctx.score)) + "/100", 1)
  ], 2))
}