
import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue'
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import ButtonClose from '@/components/buttons/ButtonClose.vue';
import ButtonArrow from '@/components/buttons/ButtonArrow.vue';
import { AppStore } from '@/store/modules/AppStore';
import { Viewport } from '@/store/modules/Viewport';
import MainTutorialIcon1 from './MainTutorialIcon1.vue';
import MainTutorialIcon2 from './MainTutorialIcon2.vue';
import MainTutorialIcon3 from './MainTutorialIcon3.vue';
import { FocusTrap } from 'focus-trap-vue'


const nbStep = 3;

export default defineComponent({
  setup() {
    const active = ref(false);
    const first = ref(true);
    const isPreamble = ref(true);
    const step = ref(1);

    const open = async () => {
      step.value = 1
      active.value = true
      first.value = !AppStore.showMainTutorial
      document.body.parentElement.classList.add('has-main-tutorial-open');
    }

    const checkIfNeeded = () => {
      if (!AppStore.popins.newTerms) {
        if (AppStore.user) {
          if (window.localStorage) {
            const token = window.localStorage.getItem('main_tutorial::' + AppStore.user?.slug);
            // const token = null
  
            if (token !== (process.env.VUE_APP_TUTORIAL_TOKEN || 'yes') || AppStore.showMainTutorial) {
              open();
            }
          }
        }
      }
    }

    onMounted(() => {
      setTimeout(() => {
        checkIfNeeded()
      }, 700)
    })
    onUnmounted(() => {
      document.body.parentElement.classList.remove('has-main-tutorial-open');
      AppStore.showMainTutorial = false
    })

    watch(
      () => AppStore.showMainTutorial,
      () => {
        if (AppStore.showMainTutorial) {
          open()
        }
      }
    )

    watch(
      () => AppStore.popins.newTerms,
      () => {
        console.log('AppStore.popins.newTerms', AppStore.popins.newTerms)
        checkIfNeeded();
      }
    )

    return {
      Viewport,
      active,
      first,
      isPreamble,
      step,
      nbStep,
      open,
    }
  },

  components: {
    ButtonHover,
    ButtonClose,
    ButtonArrow,
    MainTutorialIcon1,
    MainTutorialIcon2,
    MainTutorialIcon3,
    FocusTrap
  },

  methods: {
    next() {
      if (this.step < this.nbStep) {
        this.step = this.step + 1;
      } else {
        this.step = 1
        // this.close()
      }
    },

    previous() {
      if (this.step > 1) {
        this.step = this.step - 1;
      } else {
        this.step = this.nbStep;
      }
    },

    onAction() {
      if (this.first) {
        if (this.step == nbStep) {
          this.close();
        } else {
          this.next();
        }
        
      } else {
        this.close();
      }
    },

    close() {
      window.localStorage.setItem('main_tutorial::'+AppStore.user?.slug, process.env.VUE_APP_TUTORIAL_TOKEN || 'yes');
      // AppStore.user.needToRevalidateTerms = false
      this.active = false
      document.body.parentElement.classList.remove('has-main-tutorial-open');

      AppStore.showMainTutorial = false
    },

    onPreambleEnd() {
      this.isPreamble = false
    }
  }
})
