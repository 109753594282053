
import { defineComponent } from 'vue'
import LayoutConnected from '@/layouts/Connected.vue';
import ResourcesPage from '@/components/connected/Resources/ResourcesPage.vue'

export default defineComponent({

  components: {
    LayoutConnected,
    ResourcesPage,
  },
})
