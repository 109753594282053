
import { defineComponent } from 'vue'
import HeaderNotConnected from '@/components/Header/HeaderNotConnected.vue';
import SmoothScroll from '@/components/not-connected/SmoothScroll.vue'
import FooterVue from '@/components/Footer/Footer.vue'
import AgeGate from '@/components/not-connected/AgeGate.vue';
import NewWebsitePopin from '@/components/connected/popins/NewWebsitePopin.vue';
import CookieBanner from '@/components/not-connected/CookieBanner.vue';
// import PracticalPopin from '@/components/connected/popins/PracticalPopin.vue';

export default defineComponent({
  components: {
    HeaderNotConnected,
    SmoothScroll,
    FooterVue,
    AgeGate,
    NewWebsitePopin,
    CookieBanner,
    // PracticalPopin
}
});
