
import { defineComponent, ref } from "@vue/runtime-core"
import gsap, { Back } from 'gsap'

export default defineComponent({
  props: {
    progress: {
      type: Number,
      default: 0
    }
  },

  setup() {
    return {
      played: ref(false),
      timeline: null as null | gsap.core.Timeline
    }
  },

  mounted() {
    this.initTimeline();
    this.checkPlayed();
  },

  watch: {
    played() {
      this.checkPlayed();
    }
  },

  methods: {
    initTimeline() {
      const progress = (this.progress as number) / 100;
      // const progress = 1;
      const maxTopY = (this.$el.offsetHeight - (this.$refs.top as HTMLElement).offsetHeight);

      this.timeline = gsap.timeline({paused: true})
        .fromTo(
          (this.$refs.center as HTMLElement),
          { scaleY: 0 },
          { scaleY: 1 * progress, duration: 0.8, ease: Back.easeOut.config((1 - progress) * 4 + 0.7) },
          0
        )
        .fromTo(
          (this.$refs.top as HTMLElement),
          { y: maxTopY },
          { y: maxTopY * (1 - progress), duration: 0.8, ease: Back.easeOut.config((1 - progress) * 4 + 0.7) },
          0
        )
    },

    checkPlayed() {
      if (this.played) {
        setTimeout(() => {
          this.timeline && this.timeline.play();
        }, 600)
      }
    },

    async start() {
      this.played = true;
    }
  }
})
