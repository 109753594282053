
import { Chapter } from '@/@types/props'
import { defineComponent, PropType } from 'vue'

import LineByLine from '@/components/not-connected/LineByLine.vue'
import AppearObject from '@/components/not-connected/AppearObject.vue'
import LazyPicture from '@/components/Global/LazyPicture.vue'

export default defineComponent({
  props: {
    chapter: {
      type: Object as PropType<Chapter>,
      required: true,
    },
  },

  components: {
    LineByLine,
    AppearObject,
    LazyPicture
  }
})
