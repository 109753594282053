
import { Viewport } from '@/store/modules/Viewport'
import { defineComponent } from 'vue'
import { gsap, Quart } from 'gsap'
import { AppStore } from '@/store/modules/AppStore';

let first = true;

export default defineComponent({
  setup() {
    let newView: HTMLElement
    let oldView: HTMLElement

    return {
      newView,
      oldView
    }
  },

  methods: {
    async onEnter(el, done) {
      this.newView = el

      if (!first) {
        AppStore.isPageTransition = true;
        this.newView.style.position = 'fixed'
        this.newView.style.top = '0'
        this.newView.style.left = '0'
        this.newView.style.width = '100%'
        this.newView.style.transform = `translateY(${Viewport.windowHeight}px) translateZ(0)`
        this.newView.style.zIndex = '100'
        this.newView.style.overflow = 'hidden'
  
        const size = 10
        const radius = 100
        this.newView.style.clipPath = `inset(${size}rem ${size}rem 0px round ${radius}px ${radius}px 0px 0px)`
  
        await this.$nextTick()
      }
      first = false

      done()
    },

    onLeave(el, done) {
      AppStore.isPageTransition = true;

      let newFixed = null
      if (Viewport.isMobile) {
        // FIXED ELEMENT
        const scrollY = window.scrollY;
        const oldFixed = el.querySelector('[data-fixed]');
        const fixedTop = Viewport.windowHeight - 34;
        if (oldFixed) {
          gsap.set(oldFixed, { y: scrollY + fixedTop, position: 'absolute', top: 0, bottom: 'auto' })
        }

        newFixed = this.newView.querySelector('[data-fixed]');
        if (newFixed) {
          gsap.set(newFixed, { y: scrollY + fixedTop, position: 'absolute', top: 0, bottom: 'auto' })
        }
      } else {
        // thankspanel desktop
        const oldFixed = el.querySelector('[data-fixed]');
        const scrollY = window.scrollY;
        if (oldFixed) {
          gsap.set(oldFixed, { y: scrollY, position: 'absolute', top: 0, bottom: 'auto' })
        }
      }
      const isLayoutFixed = el.getAttribute('data-layout-fixed') === 'true'
      if (isLayoutFixed) {
        gsap.set(el, {height: Viewport.windowHeight});
      }

      this.oldView = el
      this.oldView.style.transformOrigin = `50% ${Viewport.windowHeight/2}px`
      this.oldView.style.overflow = 'hidden'

      const timeline = gsap.timeline({
        onComplete: () => {
          AppStore.isPageTransition = false;
          if (Viewport.isMobile) {
            window.scrollTo(0, 0)
          }
          if (newFixed) {
            gsap.set(newFixed, { clearProps: 'all' })
          }

          done()

          this.$nextTick(() => {
            window.dispatchEvent(new Event('resize'));
          }) // fix end transition split lines

        }
      });

      const debug = 1;
      

      timeline.to(this.newView, {
        duration: 1.2 * debug,
        y: 0,
        clipPath: 'inset(0rem 0rem 0px round 0px 0px 0px 0px)',
        ease: Quart.easeInOut,
        clearProps: 'all'
      })

      timeline.to(this.oldView, {
        duration: 1.2 * debug,
        opacity: 0,
        scale: 0.9,
        clipPath: isLayoutFixed ? 'inset(0rem 0rem 0px round 24px 24px 24px 24px)' : 'inset(0rem 0rem 0px round 24px 24px 0px 0px)',
        ease: Quart.easeInOut
      }, '<')
    }
  }
})
