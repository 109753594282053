
import { defineComponent } from "@vue/runtime-core";
import EventsDate from "./EventsDate.vue";
import AppearObject from '@/components/not-connected/AppearObject.vue'
import { formatEventTime } from '@/utils/EventFormatters'

export default defineComponent({
    props: {
        id: {
            type: String,
            default: null
        },
        place: {
            type: String,
            default: null
        },
        startDate: {
            type: String,
            default: null,
            required: true
        },
        endDate: {
            type: String,
            default: null,
            required: true
        },
        name: {
            type: String,
            default: null,
            required: true
        },
        timezone: {
            type: String,
            default: null,
            required: false
        },
    },
    computed: {
        formattedTime() {
          return `${formatEventTime(this.startDate, this.$i18n.locale)} - ${formatEventTime(this.endDate, this.$i18n.locale)}`
        }
    },
    components: { EventsDate, AppearObject }
})
