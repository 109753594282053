
import { AppStore } from "@/store/modules/AppStore"
import { computed, defineComponent, ref, SetupContext, watch } from "vue"
import GlossaryMenu from '@/components/connected/Glossary/GlossaryMenu.vue'
import LineByLine from '@/components/not-connected/LineByLine.vue';
import AppearObject from '@/components/not-connected/AppearObject.vue';
import { Viewport } from '@/store/modules/Viewport'

export default defineComponent({
  setup() {
    const glossary = computed(() => AppStore.glossary)

    return {
      glossary,
      Viewport,
      isMobileSticked: ref(false),
      observer: null as IntersectionObserver | null
    }
  },

  components: {
    GlossaryMenu,
    LineByLine,
    AppearObject
  },

  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      if(entries[0].intersectionRatio === 0) {
        this.isMobileSticked = true;
      }
      else if(entries[0].intersectionRatio === 1) {
        this.isMobileSticked = false;
      }
    }, { threshold: [0,1] });

    this.observer.observe(this.$refs.menuTop as HTMLElement);
  },

  unmounted() {
    this.observer.disconnect();
  }
})
