import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onPointerenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onPointerEnter && _ctx.onPointerEnter(...args))),
    onPointerleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onPointerLeave && _ctx.onPointerLeave(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createElementVNode("div", {
        ref: "cursor",
        class: _normalizeClass([{ 'is-active': _ctx.isActive && _ctx.isVisible }, "CursorFollow"])
      }, [
        _renderSlot(_ctx.$slots, "cursor")
      ], 2)
    ]))
  ], 32))
}