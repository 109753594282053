import { reactive } from "@vue/runtime-core";

type MenuMobileStore = {
  lastScroll: number
  isScrolled: boolean
  isScrollingTop: boolean

  headerHeight: number
  hasMobileHeaderDeployed: boolean
  forceDark: boolean
  isOpen: boolean
}

export const MenuMobileStore = reactive<MenuMobileStore>({
  lastScroll: 0,
  isScrolled: false,
  isScrollingTop: false,

  headerHeight: 0,
  hasMobileHeaderDeployed: false,
  forceDark: false,
  isOpen: false
}) as MenuMobileStore