import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74adeccb"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["tabindex", "data-index"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["srcset"]
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["Cell", [_ctx.variant ? 'Cell--' + _ctx.variant : '', _ctx.active ? 'active' : '', (_ctx.zoomOn?.index === _ctx.index) ? 'zoom-in' : '']])
  }, [
    _createElementVNode("button", {
      tabindex: _ctx.isRewards ? 0 : -1,
      "data-fixed": "",
      class: "Cell__Inner",
      "data-index": _ctx.index
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["Cell__Background", {'active': _ctx.active}])
      }, null, 2),
      (_ctx.bottle > 0)
        ? (_openBlock(), _createElementBlock("picture", _hoisted_2, [
            _createElementVNode("source", {
              srcset: `/images/backbar/bottles/${ _ctx.active ? '' : 'disabled/' }bottle${_ctx.bottle}.png 1x, /images/backbar/bottles/${ _ctx.active ? '' : 'disabled/' }bottle${_ctx.bottle}@2x.png 2x`
            }, null, 8, _hoisted_3),
            _createElementVNode("img", {
              src: `/images/backbar/bottles/${ _ctx.active ? '' : 'disabled/' }bottle${_ctx.bottle}.png`,
              alt: _ctx.$t('backbar.bottleTitle' + _ctx.bottle)
            }, null, 8, _hoisted_4)
          ]))
        : _createCommentVNode("", true)
    ], 8, _hoisted_1)
  ], 2))
}