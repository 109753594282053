
import { defineComponent, ref } from "@vue/runtime-core"
import ButtonHover from '@/components/not-connected/ButtonHover.vue'
import { FocusTrap } from 'focus-trap-vue'

export default defineComponent({
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    nbSteps: {
      type: Number,
      default: 0
    }
  },

  emits: ['ended'],

  setup() {
    return {
      step: ref(1)
    }
  },

  components: {
    ButtonHover,
    FocusTrap
  },

  methods: {
    nextStep() {
      this.step += 1;

      if (this.step > this.nbSteps) {
        this.$emit('ended');
      }
    }
  }
})
