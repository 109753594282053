import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9b1d0550"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "EventsDate-date h4" }
const _hoisted_2 = { class: "month" }
const _hoisted_3 = { class: "day" }
const _hoisted_4 = { class: "year" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([`EventsDate EventsDate--size-${_ctx.size}`])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.formattedDate[0]), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.formattedDate[1]), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.formattedDate[2]), 1)
    ])
  ], 2))
}