
import { defineComponent, ref, Ref } from 'vue';
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import MyEventsItem from '@/components/Events/MyEventsItem.vue';
import { AppStore } from '@/store/modules/AppStore';
import LineByLine from '@/components/not-connected/LineByLine.vue';

type State = 'no-events' | 'default'

export default defineComponent({
  setup() {
    const state: Ref<State>  = ref('default');
    const events = AppStore.dashboard?.events || [];


    const event = events.find((event)=> {
      return event.can_register === 'registered'
    })

    if (!event) {
      state.value = 'no-events'
    }
    
    return {
      state,
      event
    }
  },

  components: {
    MyEventsItem,
    ButtonHover,
    LineByLine
}
})

