
import { defineComponent} from 'vue'
import ButtonHover from '@/components/not-connected/ButtonHover.vue';

export default defineComponent({
  setup() {
    let intersectionObserver: IntersectionObserver 

    return {
      intersectionObserver
    }
  },

  components: {
    ButtonHover
  },

  props: {
    active: { type: Boolean, default: false }
  },

  data() {
    return {
      isIn: false,
    }
  },

  mounted() {
    this.intersectionObserver = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0) {
        this.isIn = true
      }
    })

    this.intersectionObserver.observe(this.$refs.popin as HTMLElement)
  },

  unmounted() {
    if (this.intersectionObserver && this.$refs.popin) {
      this.intersectionObserver.unobserve(this.$refs.popin as HTMLElement)
    }
  },

  methods: {
    onReload() {
      window.location.reload();
    }
  }
})
