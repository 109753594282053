
import { defineComponent } from 'vue'

export default defineComponent({

  data() {
    return {
      name: process.env.VUE_APP_NAME,
      version: process.env.VUE_APP_VERSION,
      commit: process.env.VUE_APP_COMMIT_HASH,
      env: process.env.VUE_APP_ENVCONFIG
    }
  }

})
