
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    job: {
      type: Object,
      required: true
    }
  }
})
