/*
 * register components globally
 */

import { App } from 'vue'
import UIBtnPrimary from './UIBtn/UIBtnPrimary.vue'
import UIBtnSecondary from './UIBtn/UIBtnSecondary.vue'
import UIBtnRound from './UIBtn/UIBtnRound.vue'

const register = (app: App<Element>): void => {
  app.component('ui-btn', UIBtnPrimary) // alias of UIBtnPrimary
  app.component('ui-btn-primary', UIBtnPrimary)
  app.component('ui-btn-secondary', UIBtnSecondary)
  app.component('ui-btn-round', UIBtnRound)
}

export default { register }