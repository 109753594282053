import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-120fdeb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "GlossaryDetail__Wrapper",
  "data-scroll-section": "",
  "data-scroll-sticky": "",
  "data-scroll-sticky-offset": "0"
}
const _hoisted_2 = ["data-title"]
const _hoisted_3 = {
  class: "GlossaryDetail__Background",
  ref: "background"
}
const _hoisted_4 = {
  class: "GlossaryDetail__Inner",
  ref: "inner"
}
const _hoisted_5 = { class: "GlossaryDetail__Scroller" }
const _hoisted_6 = { class: "h1" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ButtonClose = _resolveComponent("ButtonClose")!
  const _component_LineByLine = _resolveComponent("LineByLine")!
  const _component_AppearObject = _resolveComponent("AppearObject")!
  const _component_FocusTrap = _resolveComponent("FocusTrap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      name: "glossary-detail",
      onEnter: _cache[0] || (_cache[0] = (el, done) => _ctx.onEnter(el, done)),
      onLeave: _cache[1] || (_cache[1] = (el, done) => _ctx.onLeave(el, done)),
      mode: "in-out"
    }, {
      default: _withCtx(() => [
        (_ctx.selectedDefinition)
          ? (_openBlock(), _createElementBlock("aside", {
              key: 'glossary-detail-' + _ctx.selectedDefinition.slug,
              class: "GlossaryDetail",
              ref: "aside",
              "data-title": _ctx.selectedDefinition.title
            }, [
              _createVNode(_component_router_link, {
                to: "/glossary",
                class: "GlossaryDetail__Veil",
                ref: "background"
              }, null, 512),
              _createElementVNode("div", _hoisted_3, null, 512),
              _createVNode(_component_FocusTrap, { active: _ctx.selectedDefinition }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_ButtonClose, {
                      "data-fixed": "",
                      class: "GlossaryDetail__Close",
                      to: "/glossary"
                    }),
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_AppearObject, null, {
                        default: _withCtx(() => [
                          _createElementVNode("h2", _hoisted_6, [
                            _createVNode(_component_LineByLine, {
                              innerHTML: _ctx.selectedDefinition.title.replace('-', '<span>-</span>')
                            }, null, 8, ["innerHTML"])
                          ])
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", {
                        class: "GlossaryDetail__Text",
                        innerHTML: _ctx.selectedDefinition.text
                      }, null, 8, _hoisted_7)
                    ])
                  ], 512)
                ]),
                _: 1
              }, 8, ["active"])
            ], 8, _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}