import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "SmoothScroll" }
const _hoisted_2 = {
  ref: "fakeScroll",
  class: "SmoothScroll-fake"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512),
    _createElementVNode("div", {
      ref: "content",
      class: _normalizeClass(["SmoothScroll-content", { 'is-smooth-disabled': (!_ctx.active) }])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ]))
}