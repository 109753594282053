
import { defineComponent, onMounted, onUnmounted, Ref, ref, watch} from 'vue'
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import { FocusTrap } from 'focus-trap-vue'
import { AppStore } from '@/store/modules/AppStore';

export default defineComponent({
  setup() {
    const active = ref(false);
    
    const checkIfNeeded = () => {
      if (!AppStore.popins.ageGate) {
        const now = Date.now();
        const max = (new Date(process.env.VUE_APP_NEW_WEBSITE_MAX_DATE || '2000-09-01')).getTime();
        // console.log('VUE_APP_NEW_WEBSITE_MAX_DATE', process.env.VUE_APP_NEW_WEBSITE_MAX_DATE, new Date(process.env.VUE_APP_NEW_WEBSITE_MAX_DATE));
        if (now < max) {
          if (window.localStorage) {
            const token = window.localStorage.getItem('Barsmarts:new_website');
            // const token = null;
    
            if (token !== (process.env.VUE_APP_TUTORIAL_TOKEN || 'yes')) {
              active.value = true
              document.body.parentElement.classList.add('has-terms-open');
            }
          }
        }
      }
    }

    onMounted(() => {
      setTimeout(checkIfNeeded, 600)
    })
    onUnmounted(() => {
      document.body.parentElement.classList.remove('has-terms-open');
    })
    watch(
      () => AppStore.popins.ageGate,
      () => {
        console.log('AppStore.popins.ageGate', AppStore.popins.ageGate)
        checkIfNeeded();
      }
    )

    return {
      active
    }
  },

  components: {
    ButtonHover,
    FocusTrap
  },

  methods: {
    onClick() {
      window.localStorage.setItem('Barsmarts:new_website', (process.env.VUE_APP_TUTORIAL_TOKEN || 'yes'));
      this.active = false
      document.body.parentElement.classList.remove('has-terms-open');
    }
  }
})
