
import { defineComponent } from "@vue/runtime-core";
import { formatSplitEventDate } from '@/utils/EventFormatters'

export default defineComponent({
   props: {
    place: {
      type: String,
      default: null
    },
    date: {
      type: String,
      default: null,
      required: true
    },
    size: {
      type: String,
      default: 'small'
    },
    styled: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    isCurrentYear(){
      const currentYear = new Date().getFullYear()
      return new Date(this.date as Date)?.getFullYear() === currentYear
    },
    formattedDate() {
      const formattedDate = formatSplitEventDate(this.date, this.$i18n.locale)
      if (this.isCurrentYear) {
        // month / day
        return [formattedDate[0], formattedDate[1]]
      } else {
        // month / year
        return [formattedDate[0], formattedDate[2]]
      }
    },
  },
})
