
import { defineComponent } from "@vue/runtime-core";
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { formatSplitEventDate } from '@/utils/EventFormatters'

export default defineComponent({
  props: {
    date: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'small'
    },
  },
  setup(props){
    const { locale } = useI18n();

    const formattedDate = computed(
      () => {
          return formatSplitEventDate(props?.date, locale.value)
      }
    )
    return {
      formattedDate
    }
  }
})
