
import { computed, defineComponent, inject, Ref, ref } from "@vue/runtime-core"
import EventsCard from './EventsCard.vue'
import ButtonHover from '@/components/not-connected/ButtonHover.vue'

import gsap from 'gsap'
import { EventPage } from "@/@types/props"
import { MenuMobileStore } from "@/store/modules/MenuMobileStore"
import { Viewport } from "@/store/modules/Viewport"

const debugSpeed = 1;

export default defineComponent({

  setup() {
    const hasMobileHeaderDeployed = computed(() => MenuMobileStore.hasMobileHeaderDeployed)

    const eventPage = inject('eventPage') as Ref<EventPage>

    

    return {
      Viewport,
      hasMobileHeaderDeployed,
      eventPage,
      previousHeight: null,
      nextHeight: null,
      timeline: null as gsap.core.Timeline | null,

      observer: null as IntersectionObserver | null,
      isMobileSticked: ref(false),

      onEnterTimer: ref(null),
      hasLeavingItems: ref(false)
    }
  },
  

  components: {
    EventsCard,
    ButtonHover
  },

  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      if(entries[0].intersectionRatio === 0) {
        this.isMobileSticked = true;
      }
      else if(entries[0].intersectionRatio === 1) {
        this.isMobileSticked = false;
      }
    }, { threshold: [0,1] });

    this.observer.observe(this.$refs.stickyTop as HTMLElement);
  },

  unmounted() {
    this.observer.disconnect();
  },

  methods: {

    initHeights() {
      if (this.nextHeight === null) {
        this.nextHeight = (this.$refs.list as HTMLElement).offsetHeight
      }

      if (this.previousHeight === null) {
        this.previousHeight = (this.$refs.list as HTMLElement).offsetHeight
        gsap.set((this.$refs.list as HTMLElement), { height: this.previousHeight })
      }
    },
    applyResizeList() {
      clearTimeout(this.onEnterTimer);
      this.onEnterTimer = window.setTimeout(() => {
        gsap.timeline({
          onComplete: () => {
            this.nextHeight = null
            this.previousHeight = null
          }
        })
          .to(
            (this.$refs.list as HTMLElement),
            { height: this.nextHeight, duration: 1 * debugSpeed, ease: 'quart.out' },
            0
          )
          .set(
            (this.$refs.list as HTMLElement),
            { clearProps: 'height' }
          )
      }, 0)
    },

    onBeforeEnter() {
      this.initHeights()
    },

    onBeforeLeave(el) {
      this.initHeights()

      el.dataset.y = el.offsetTop
      // gsap.set(el, {y: el.offsetTop, position: 'absolute'})
    },

    onEnter(el, done) {
      this.nextHeight += el.offsetHeight; 

      gsap.timeline({
          onComplete: done,
        })
        .from(
          el, 
          { scale: 0.9, yPercent: 33, opacity: 0, duration: 0.5 * debugSpeed, ease: 'quart.out' },
          (this.hasLeavingItems ? 0.5 * debugSpeed : 0) + parseInt(el.getAttribute('data-index')) * 0.1
        )

      this.applyResizeList()
    },
  
    async onLeave(el, done) {
      this.nextHeight -= el.offsetHeight;
      this.hasLeavingItems = true

      await this.$nextTick()
      gsap.timeline({
          onComplete: () => {
            done && done()
            this.hasLeavingItems = false
          },
        })
        .set(
          el,
          { position: 'absolute', y: el.dataset.y }
        )
        .to(
          el, 
          { scale: 0.9, yPercent: 33, opacity: 0, duration: 0.5 * debugSpeed, ease: 'quart.out' }, 
          parseInt(el.getAttribute('data-index')) * 0.1
        )
      
      this.applyResizeList()
    }
  }
})
