
import { defineComponent } from 'vue'
import ParallaxObject from '@/components/not-connected/ParallaxObject.vue'
import LayoutNotConnected from '@/layouts/NotConnected.vue'
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import LineByLine from '@/components/not-connected/LineByLine.vue';
import AppearObject from '@/components/not-connected/AppearObject.vue';

export default defineComponent({
  components: {
    ParallaxObject,
    LayoutNotConnected,
    ButtonHover,
    LineByLine,
    AppearObject
  },

  beforeRouteEnter(to, from, next) {
    const img = new Image()
    img.onload = () => {
      next()
    }

    img.srcset = "/dev/company/hero.png 1x, /dev/company/hero@2x.png 2x"
  },
})
