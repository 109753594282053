
import { AppStore } from "@/store/modules/AppStore"
import { defineComponent, ref } from "@vue/runtime-core"

import Slider from '@/components/not-connected/Slider.vue'
import CursorFollow from '@/components/not-connected/CursorFollow.vue'
import LineByLine from '@/components/not-connected/LineByLine.vue'
import AppearObject from '@/components/not-connected/AppearObject.vue'

export default defineComponent({
  setup() {
    return {
      profile: ref(AppStore.profile)
    }
  },

  components: {
    Slider,
    CursorFollow,
    LineByLine,
    AppearObject
  },
  
  methods: {
    onPrev() {
      (this.$refs.slider as typeof Slider).onPrev()
    },

    onNext() {
      (this.$refs.slider as typeof Slider).onNext()
    }
  }
})
