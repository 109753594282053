import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "FieldsetExperiences" }
const _hoisted_2 = { class: "margin-small" }
const _hoisted_3 = ["onUpdate:modelValue"]
const _hoisted_4 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_5 = { class: "margin-small" }
const _hoisted_6 = ["onUpdate:modelValue"]
const _hoisted_7 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_8 = { class: "margin-small" }
const _hoisted_9 = ["onUpdate:modelValue"]
const _hoisted_10 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_11 = { class: "margin-small" }
const _hoisted_12 = ["onUpdate:modelValue"]
const _hoisted_13 = {
  key: 0,
  class: "error-msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonHover = _resolveComponent("ButtonHover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.experiences, (experience, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: 'fieldset-experience-' + index,
        class: "fieldset__row fieldset__row--4"
      }, [
        _createElementVNode("fieldset", _hoisted_2, [
          _createElementVNode("legend", null, _toDisplayString(_ctx.$t('profileEdit.jobTitle')), 1),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": ($event: any) => ((experience.jobTitle) = $event),
            onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.v$?.jobTitle?.$touch && _ctx.v$?.jobTitle?.$touch(...args))),
            class: _normalizeClass({ 'error': _ctx.v$?.jobTitle?.$errors.length }),
            type: "text",
            name: "jobTitle",
            spellcheck: "false",
            autocorrect: "off"
          }, null, 42, _hoisted_3), [
            [
              _vModelText,
              experience.jobTitle,
              void 0,
              { trim: true }
            ]
          ]),
          (_ctx.v$?.jobTitle?.$errors?.length)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.v$?.jobTitle?.$errors[0]?.$message), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("fieldset", _hoisted_5, [
          _createElementVNode("legend", null, _toDisplayString(_ctx.$t('profileEdit.place')), 1),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": ($event: any) => ((experience.place) = $event),
            onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.v$?.place?.$touch && _ctx.v$?.place?.$touch(...args))),
            class: _normalizeClass({ 'error': _ctx.v$?.place?.$errors.length }),
            type: "text",
            name: "place",
            spellcheck: "false",
            autocorrect: "off"
          }, null, 42, _hoisted_6), [
            [
              _vModelText,
              experience.place,
              void 0,
              { trim: true }
            ]
          ]),
          (_ctx.v$?.place?.$errors?.length)
            ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.v$?.place?.$errors[0]?.$message), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("fieldset", _hoisted_8, [
          _createElementVNode("legend", null, _toDisplayString(_ctx.$t('profileEdit.location')), 1),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": ($event: any) => ((experience.location) = $event),
            onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.v$?.location?.$touch && _ctx.v$?.location?.$touch(...args))),
            class: _normalizeClass({ 'error': _ctx.v$?.location?.$errors.length }),
            type: "text",
            name: "location",
            spellcheck: "false",
            autocorrect: "off"
          }, null, 42, _hoisted_9), [
            [
              _vModelText,
              experience.location,
              void 0,
              { trim: true }
            ]
          ]),
          (_ctx.v$?.location?.$errors?.length)
            ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.v$?.location?.$errors[0]?.$message), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("fieldset", _hoisted_11, [
          _createElementVNode("legend", null, _toDisplayString(_ctx.$t('profileEdit.year')), 1),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": ($event: any) => ((experience.year) = $event),
            onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.v$?.year?.$touch && _ctx.v$?.year?.$touch(...args))),
            class: _normalizeClass({ 'error': _ctx.v$?.year?.$errors.length }),
            type: "text",
            name: "year",
            spellcheck: "false",
            autocorrect: "off"
          }, null, 42, _hoisted_12), [
            [
              _vModelText,
              experience.year,
              void 0,
              { trim: true }
            ]
          ]),
          (_ctx.v$?.year?.$errors?.length)
            ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.v$?.year?.$errors[0]?.$message), 1))
            : _createCommentVNode("", true)
        ])
      ]))
    }), 128)),
    (this.nbExperiences < this.maxExperiences)
      ? (_openBlock(), _createBlock(_component_ButtonHover, {
          key: 0,
          type: "button",
          class: "button button--white button--border",
          onClick: _ctx.addRow
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('profileEdit.addExperience')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}