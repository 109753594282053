import { Resource, Resources } from '@/@types/props';
import i18n from '@/utils/i18n';
import axios from 'axios';
import { AppStore } from '../modules/AppStore';

export function fetchResources(): Promise<Resources> {
  // TODO use axios to request API


  if (AppStore.resources) {
    return Promise.resolve().then(() => AppStore.resources)
  } else {
    return axios.get(
      process.env.VUE_APP_API + 'resources', 
      {
        headers: {
          'x-token-auth': AppStore.user.token,
          'x-lang': AppStore.user?.lang,
        }
      }
    ).then((result) => {
      if (result.status === 200 && result.data.success) {
        AppStore.resources = parseResources(result?.data?.data)
  
        return AppStore.resources
      }
  
      return null
    })
    .catch((error) => {
      console.warn(error);
      return null
    })
  }
}

function parseResources(data): Resources {
  const resources: Resources = []

  data.forEach((s) => {
    const items: Array<Resource> = []

    s.items.forEach((i) => {
      items.push({
        title: i18n(i.title),
        url: i18n(i.url),
        download: i.download
      })
    })

    resources.push({
      title: i18n(s.title),
      items
    })
  })

  return resources
}