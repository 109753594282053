import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderNotConnected = _resolveComponent("HeaderNotConnected")!
  const _component_FooterVue = _resolveComponent("FooterVue")!
  const _component_CookieBanner = _resolveComponent("CookieBanner")!
  const _component_NewWebsitePopin = _resolveComponent("NewWebsitePopin")!
  const _component_AgeGate = _resolveComponent("AgeGate")!
  const _component_SmoothScroll = _resolveComponent("SmoothScroll")!

  return (_openBlock(), _createBlock(_component_SmoothScroll, { "security-culling": 4 }, {
    default: _withCtx(() => [
      _createVNode(_component_HeaderNotConnected, {
        theme: _ctx.$route.meta.headerLight ? 'light' : 'dark',
        "data-scroll-section": ""
      }, null, 8, ["theme"]),
      _renderSlot(_ctx.$slots, "default"),
      _createVNode(_component_FooterVue, {
        theme: _ctx.$route.meta.footerLight ? 'light' : 'dark',
        "data-scroll-section": ""
      }, null, 8, ["theme"]),
      _createVNode(_component_CookieBanner),
      _createVNode(_component_NewWebsitePopin),
      _createVNode(_component_AgeGate)
    ]),
    _: 3
  }))
}