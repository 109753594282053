
import { defineComponent } from "@vue/runtime-core"
import { AppStore } from "@/store/modules/AppStore"

import ButtonHover from '@/components/not-connected/ButtonHover.vue'
import useVuelidate from "@vuelidate/core";


const maxExperiences = 5;

export default defineComponent({
  setup() {

    return {
      v$: useVuelidate()
    }
  },

  data() {
    const experiences = []

    if (AppStore.profile?.experiences) {
      AppStore.profile?.experiences.forEach((experience) => {
        if (experience.jobTitle || experience.place || experience.location || experience.year) {
          experiences.push(experience)
        }
      })
    }

    if (experiences.length === 0) {
      experiences.push({jobTitle: '', place: '', location: '', year: ''})
    }
    
    return {
      experiences,
      nbExperiences: experiences.length,
      maxExperiences
    }
  },

  components: {
    ButtonHover
  },

  watch: {
    'experiences': {
      handler() {
        this.$emit('changed')
      },
      deep: true
    }
  },

  methods: {
    addRow() {
      if (this.experiences.length < maxExperiences) {
        this.experiences.push({jobTitle: '', place: '', location: '', year: ''})
        this.nbExperiences = this.experiences.length
      }
    }
  }
})
