
import { defineComponent, PropType } from 'vue'

enum ProgressType {
  Percent = "percent",
  Value = "score"
}

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: false,
    },
    type: {
      type: String as PropType<ProgressType>,
      required: false,
      default: ProgressType.Percent
    },
  }
})

