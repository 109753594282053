
import { defineComponent, inject, PropType, Ref } from 'vue';
import { Job } from '@/@types/props';

export default defineComponent({
  props: {
    job: {
      type: Object as PropType<Job>,
      required: true
    }
  },

  setup() {
    const selectedJob = inject('job') as Ref<Job>;

    return {
      selectedJob
    }
  }
})
