
import { defineComponent, inject, PropType, Ref } from 'vue';
import { Event } from '@/@types/props';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import EventsDate from '@/components/Events/EventsDate.vue';
import { formatEventTime } from '@/utils/EventFormatters'

export default defineComponent({
    props: {
        event: {
            type: Object as PropType<Event>,
            required: true
        }
    },
    setup(props) {
        const selectedEvent = inject('event') as Ref<Event>;
        const event = props.event as Event;
        const { locale } = useI18n();
        
        const formattedTime = computed(() => {
            return `${formatEventTime(event.start_date, locale.value)} - ${formatEventTime(event.end_date, locale.value,)}`
        });
        return {
            selectedEvent,
            event,
            formattedTime
        };
    },
    components: { EventsDate }
})
