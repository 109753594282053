
import { defineComponent } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, minLength, minValue, maxLength } from '@vuelidate/validators'
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import { gsap, Quart } from 'gsap'
import getOffsetTop from '@/utils/getOffsetTop'

export default defineComponent({
  setup () {
    return { v$: useVuelidate() }
  },

  components: {
    ButtonHover
  },

  props: {
    email: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
    errors: { type: Array, default: null },
  },

  data() {
    return {
      hear: '',
      how: '',
      participants: '',
      referred: '',
      // market: '',
      // multiplesLocals: false,
      reason: '',
      terms: false,

      isSubmit: false
    }
  },

  validations () {
    return {
      hear: { required, minLength: minLength(2) },
      participants: { required, minValue: minValue(1) },
      // market: { required, minLength: minLength(2) },
      reason: { required, minLength: minLength(2), maxLength: maxLength(3000) },
    }
  },

  methods: {
    async onSubmit() {
      this.isSubmit = true

      const result = await this.v$.$validate()

      if (!result || !this.terms) {
        console.warn('form not valid')

        const firstInputError = this.$el.querySelector('.error')
        if (firstInputError) {
          const offset = getOffsetTop(firstInputError) - 20
          gsap.to(window, { duration: 0.6, scrollTo: offset, ease: Quart.easeOut })
        }
        
        return
      }

      this.$emit('onSubmit', {
        business: {
          hear: (this.hear === 'other') ? this.how : this.hear,
          total_participants: this.participants,
          referal: this.referred,
          // multiples_locals: this.multiplesLocals,
          // market: this.market,
          reason: this.reason
        },
        user: {
          terms: this.terms
        }
      })
    }
  }
})
