
import { defineComponent, PropType } from 'vue'
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf, minLength, maxLength } from '@vuelidate/validators'
import ButtonHover from '@/components/not-connected/ButtonHover.vue';
import ErrorPopin from '@/components/not-connected/ErrorPopin.vue'
import { gsap, Quart } from 'gsap'
import getOffsetTop from '@/utils/getOffsetTop'

interface City {
  id: number
  name: string
}

type Cities = Array<City>

interface Country {
  id: number
  name: string
  code: string
}

type Countries = Array<Country>

interface State {
  country: number
  id: number
  name: string
  abbreviation: string
  assoc_press: string
  latitude: string
  longitude: string
}

type States = Array<State>

export default defineComponent({
  setup () {
    return { v$: useVuelidate() }
  },

  components: {
    ButtonHover,
    ErrorPopin
  },

  props: {
    countries: { type: Array as PropType<Countries>, default: null },
    states: { type: Array as PropType<States>, default: null }
  },

  data() {
    return {
      errorRequest: false,
      cities: [],

      business: '',
      position: '',
      country: '' as string | number,
      state: '' as string | number,
      city: '',
      cityId: '',
      address: '',
      zip: '',
      phone: '',
    }
  },

  watch: {
    countries() {
      this.country = 230
    },

    country() {
      if (this.country !== 230) {
        this.state = 52
      } else {
        this.state = ''
      }
    },

    async state() {
      if (this.country === 230) {
        this.cityId = ''
        if(this.state !== '') {
          const data = await this.getCities(this.state)
          this.cities = data as Cities
        }
      }
    },
  },

  validations () {
    return {
      business: { required, minLength: minLength(2) },
      position: { required, minLength: minLength(2) },
      country: { required },
      state: { required },
      city: { requiredIfNotUS: requiredIf(this.country !== 230), minLength: minLength(2), maxLength: maxLength(255) },
      cityId: { requiredIfUS: requiredIf(this.country === 230) },
      address: { required, minLength: minLength(2) },
      zip: { required, minLength: minLength(2), maxLength: maxLength(12) },
      phone: { required }
    }
  },

  methods: {
    async onSubmit() {
      const result = await this.v$.$validate()

      if (!result) {
        console.warn('form not valid')

        const firstInputError = this.$el.querySelector('.error')
        if (firstInputError) {
          const offset = getOffsetTop(firstInputError) - 20
          gsap.to(window, { duration: 0.6, scrollTo: offset, ease: Quart.easeOut })
        }

        return
      }

      this.$emit('onSubmit', {
        user: {
          position_title: this.position,
          country: this.country,
          state: this.state,
          city: this.city,
          city_id: this.cityId || null,
          address: this.address,
          zip: this.zip,
          work_stablishment: this.business
        },
        business: {
          phone: this.phone,
        }
      })
    },

    async getCities(state) {
      try {
        const response = await axios.get(process.env.VUE_APP_API + 'states/' + state + '/cities');
        return response.data.data
      } catch (error) {
        console.error(error);
        this.errorRequest = true
        return []
      }
    },
  }
})
