
import { Question, QuestionResponse, Test } from "@/@types/props";
import { sendAnswer } from "@/store/data-sources/course";
import sleep from "@/utils/Sleep";
import { ComponentPublicInstance, defineComponent, PropType, Ref, ref } from "@vue/runtime-core";
import Timer from './Timer.vue';
import { FocusTrap } from 'focus-trap-vue'

export default defineComponent({
  props: {
    test: {
      type: Object as PropType<Test>,
      required: true
    },
    question: {
      type: Object as PropType<Question>,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  },

  setup() {
    const answerSlug: Ref<string | null> = ref(null)
    const isCorrected: Ref<boolean> = ref(false)
    const response: Ref<QuestionResponse | null> = ref(null)

    return {
      answerSlug,
      isCorrected,
      response
    }
  },

  methods: {
    reset() {
      this.answerSlug = null;
      this.isCorrected = false;

      (this.$refs.timer as ComponentPublicInstance<typeof Timer>).reset();
    },
    
    onAnswer(event: Event, answerSlug: string) {
      event.preventDefault();

      if ( this.answerSlug === null) {
        this.answerSlug = answerSlug;
        (this.$refs.timer as any).stop();

        this.sendAnswer();
      }
    },

    async sendAnswer() {
      const t = this.test as Test
      const q = this.question as Question

      const [response] = await Promise.all([
        sendAnswer(t.slug, t.game, q.slug, this.answerSlug),
        sleep(500)
      ])

      this.isCorrected = true;

      if (response) {
        this.response = response;

        setTimeout(() => {
          this.$emit('goNext', response);
        }, 2000);
      } else {
        console.warn('404 error -> back to courses')
        this.$router.push('/courses')
      }
    },
    
    timeElapsed() {
      this.answerSlug = '';
      this.sendAnswer();
    }
  },

  components: {
    Timer,
    FocusTrap
  }
})
