
import { computed, defineComponent, inject, Ref, ref } from "@vue/runtime-core"
import JobBoardCard from './JobBoardCard.vue'
import ButtonHover from '@/components/not-connected/ButtonHover.vue'

import gsap from 'gsap'
import { JobPage } from "@/@types/props"
import { MenuMobileStore } from "@/store/modules/MenuMobileStore"
import { Viewport } from "@/store/modules/Viewport"

const debugSpeed = 1;

export default defineComponent({

  setup() {
    const hasMobileHeaderDeployed = computed(() => MenuMobileStore.hasMobileHeaderDeployed)

    const jobPage = inject('jobPage') as Ref<JobPage>

    const hasMore = computed(() => {
      return (jobPage.value.page + 1) < jobPage.value.maxPage
    })
    

    // const search = inject('search') as Ref<{state: string; city: string}>;
    const searchText = computed(() => {
      const names = [];

      const state = jobPage.value.state ? jobPage.value.states?.find((s) => s.id === jobPage.value.state) : null
      if (state) {
        names.push(state.name)
      }
      const city = jobPage.value.city ? jobPage.value.cities?.find((s) => s.id === jobPage.value.city) : null
      if (city) {
        names.push(city.name)
      }

      const country = jobPage.value.country ? jobPage.value.countries.find((s) => s.id === jobPage.value.country) : null
      if (names.length < 1 && country) {
        names.unshift(country.name)
      }


      return names.join(', ')
    })
    
    return {
      Viewport,
      hasMobileHeaderDeployed,
      jobPage,
      // paginatedJobs,
      hasMore,
      searchText,

      previousHeight: null,
      nextHeight: null,
      timeline: null as gsap.core.Timeline | null,

      observer: null as IntersectionObserver | null,
      isMobileSticked: ref(false),

      onEnterTimer: ref(null),
      hasLeavingItems: ref(false)
    }
  },
  

  components: {
    JobBoardCard,
    ButtonHover
  },

  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      if(entries[0].intersectionRatio === 0) {
        this.isMobileSticked = true;
      }
      else if(entries[0].intersectionRatio === 1) {
        this.isMobileSticked = false;
      }
    }, { threshold: [0,1] });

    this.observer.observe(this.$refs.stickyTop as HTMLElement);
  },

  unmounted() {
    this.observer.disconnect();
  },

  methods: {
    async showMore() {
      if (this.hasMore) {
        this.$emit('loadMore')
      }
    },

    initHeights() {
      if (this.nextHeight === null) {
        this.nextHeight = (this.$refs.list as HTMLElement).offsetHeight
      }

      if (this.previousHeight === null) {
        this.previousHeight = (this.$refs.list as HTMLElement).offsetHeight
        gsap.set((this.$refs.list as HTMLElement), { height: this.previousHeight })
      }
    },
    applyResizeList() {
      clearTimeout(this.onEnterTimer);
      this.onEnterTimer = window.setTimeout(() => {
        gsap.timeline({
          onComplete: () => {
            this.nextHeight = null
            this.previousHeight = null
          }
        })
          .to(
            (this.$refs.list as HTMLElement),
            { height: this.nextHeight, duration: 1 * debugSpeed, ease: 'quart.out' },
            0
          )
          .set(
            (this.$refs.list as HTMLElement),
            { clearProps: 'height' }
          )
      }, 0)
    },

    onBeforeEnter() {
      this.initHeights()
    },

    onBeforeLeave(el) {
      this.initHeights()

      el.dataset.y = el.offsetTop
      // gsap.set(el, {y: el.offsetTop, position: 'absolute'})
    },

    onEnter(el, done) {
      this.nextHeight += el.offsetHeight; 

      gsap.timeline({
          onComplete: done,
        })
        .from(
          el, 
          { scale: 0.9, yPercent: 33, opacity: 0, duration: 0.5 * debugSpeed, ease: 'quart.out' },
          (this.hasLeavingItems ? 0.5 * debugSpeed : 0) + parseInt(el.getAttribute('data-index')) * 0.1
        )

      this.applyResizeList()
    },
  
    async onLeave(el, done) {
      this.nextHeight -= el.offsetHeight;
      this.hasLeavingItems = true

      await this.$nextTick()
      gsap.timeline({
          onComplete: () => {
            done && done()
            this.hasLeavingItems = false
          },
        })
        .set(
          el,
          { position: 'absolute', y: el.dataset.y }
        )
        .to(
          el, 
          { scale: 0.9, yPercent: 33, opacity: 0, duration: 0.5 * debugSpeed, ease: 'quart.out' }, 
          parseInt(el.getAttribute('data-index')) * 0.1
        )
      
      this.applyResizeList()
    }
  }
})
