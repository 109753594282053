
import { defineComponent } from 'vue'
import { v4 as uuidv4 } from 'uuid'

export default defineComponent({
  setup() {
    let timer

    return {
      timer
    }
  },

  props: {
    expanded: { type: Boolean, default: false }
  },

  data() {
    return {
      id: uuidv4(),
      isActive: false,
      isHidden: true,
    }
  },

  mounted() {
    if (!this.expanded) {
      this.close(0)
    } else {
      this.open(0)
    }
  },

  methods: {
    onCollapse() {
      if(!this.isActive) {
        this.open()
      } else {
        this.close()
      }
    },

    async open(duration = 800) {
      clearTimeout(this.timer)
      this.isHidden = false;

      await this.$nextTick();

      (this.$refs.content as HTMLElement).style.height = `${(this.$refs.contentInner as HTMLElement).scrollHeight}px`

      this.isActive = true;

      this.timer = window.setTimeout(() => {
        (this.$refs.content as HTMLElement).style.height = null
      }, duration)
    },

    async wait(ms:number) {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    },

    async close(duration = 800) {
      clearTimeout(this.timer);

      (this.$refs.content as HTMLElement).style.height = `${(this.$refs.contentInner as HTMLElement).scrollHeight}px`

      await this.wait(0);

      (this.$refs.content as HTMLElement).style.height = '0px';
      
      this.isActive = false;

      this.timer = window.setTimeout(() => {
        this.isHidden = true
      }, duration)
    }
  }
})
