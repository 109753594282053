
import { ChapterSlide } from '@/@types/props'
import { defineComponent, PropType } from 'vue'
import LazyPicture from '@/components/Global/LazyPicture.vue'

export default defineComponent ({
  props: {
    slide: {
      type: Object as PropType<ChapterSlide>,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  },

  components: {
    LazyPicture
  }
})
